import { Order, OrderStatus } from '../core/order/order';

export function isWaitingDurationExceeded(order: Order) {
  const currentHandover = order.handovers[order.currentHandoverIndex];
  if (!currentHandover || order.status !== OrderStatus.WAITING_FOR_HANDOVER) {
    return false;
  }
  return order.statusLog
    .at(-1)
    ?.comment?.startsWith('Maximum waiting duration exceeded');
}
