import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersViewComponent } from './users-view/users-view.component';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';

@NgModule({
  declarations: [
    UsersViewComponent,
    LoginComponent,
    SsoLoginComponent,
    CreateUserDialogComponent,
    EditUserDialogComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class UsersModule {}
