import { MapElementDto } from '@cartken/map-types';

export function parseLatLonString(latLonString: string) {
  const latLon = latLonString.split(/, ?/);
  if (latLon.length !== 2) {
    return undefined;
  }
  // override check of undefined, length is checked
  const lat = parseFloat(latLon[0]!);
  const lon = parseFloat(latLon[1]!);
  if (isNaN(lat) || isNaN(lon)) {
    return undefined;
  }
  return new google.maps.LatLng(lat, lon);
}
export function parseId(idString: string): number | undefined {
  const id = Number(idString);
  return Number.isInteger(id) ? id : undefined;
}
export function parseLocationName(
  locationNameString: string,
): string | undefined {
  const trimmed = locationNameString.trim();
  const regex = /^[A-Za-z0-9_@.-]*$/;
  return regex.test(trimmed) ? trimmed : undefined;
}
export function getLatLng(
  mapElement: MapElementDto,
): google.maps.LatLng | undefined {
  const coords = mapElement.geometry.coordinates;
  if (typeof coords[0] === 'number' && typeof coords[1] === 'number') {
    return new google.maps.LatLng(coords[1], coords[0]);
  } else if (
    Array.isArray(coords[0]) &&
    typeof coords[0][0] === 'number' &&
    typeof coords[0][1] === 'number'
  ) {
    return new google.maps.LatLng(coords[0][1], coords[0][0]);
  } else if (
    Array.isArray(coords[0]) &&
    Array.isArray(coords[0][0]) &&
    typeof coords[0][0][0] === 'number' &&
    typeof coords[0][0][1] === 'number'
  ) {
    return new google.maps.LatLng(coords[0][0][1], coords[0][0][0]);
  } else {
    return undefined;
  }
}
