import { mean, median, percentile } from 'stats-lite';
import { min } from '../../../utils/min';
import { max } from '../../../utils/max';

export type Statistics = {
  max: number;
  min: number;
  mean: number;
  median: number;
  p95: number;
  count: number;
};

export class StatisticalAggregator {
  private values: number[] = [];

  put(value: number): void {
    this.values?.push(value);
  }

  readStatisticsAndReset(): Statistics | null {
    if (this.values.length === 0) {
      return null;
    }
    const statistics = {
      max: max(this.values)!,
      min: min(this.values)!,
      mean: mean(this.values),
      median: median(this.values),
      p95: percentile(this.values, 0.95),
      count: this.values.length,
    };
    this.values.length = 0;
    return statistics;
  }
}
