<div mat-dialog-content>
  <table>
    <tr
      *ngFor="let shortcutDescription of shortcutsTableData; index as i"
      [class.odd-row]="i % 2 === 0"
    >
      <th class="shortcut">
        <ng-container *ngFor="let shortcut of shortcutDescription.shortcuts">
          <div class="shortcut-item">
            {{ shortcut }}
          </div>
          <div class="shortcut-delimeter">or</div>
        </ng-container>
      </th>
      <th class="description">{{ shortcutDescription.description }}</th>
    </tr>
  </table>
</div>
