import { Injectable } from '@angular/core';
import {
  CreateOrReplaceMapChangesetDto,
  ElementType,
  MapChangesetConflictDto,
  MapChangesetDto,
  MapChangesetInfoDto,
  MapElementDto,
  VersionsDto,
} from '@cartken/map-types';
import { EMPTY, firstValueFrom, throwError } from 'rxjs';

import { closePolygon } from '../../utils/geo-tools';
import { BackendService } from './backend.service';
import { ErrorService } from './error-system/error.service';

export interface BlockageUpdateDto {
  mapElementId: number;
  isBlocked: boolean;
  blockedUntil: Date;
}

function generateHistoryLookupUrl(
  boundingPolygon: number[][][],
  version: number | undefined,
): string {
  const url = `/map/history?region-polygon=${JSON.stringify(boundingPolygon)}`;
  if (version === undefined) {
    return url;
  }
  return url + `&version=${version}`;
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private backendService: BackendService,
    private errorService: ErrorService,
  ) {}

  async loadOperationRegions(version?: number): Promise<MapElementDto[]> {
    let url = '/map/history?element-types=' + ElementType.OPERATION_REGION;
    if (version !== undefined) {
      url += `&version=${version}`;
    }
    const operationRegions = await firstValueFrom(
      this.backendService.get<MapElementDto[]>(url),
    );
    return operationRegions.filter((region) => !region.deleted);
  }

  async loadMapElements(
    boundingCoordinates: number[][],
    version?: number,
  ): Promise<MapElementDto[]> {
    if (
      boundingCoordinates[0] !==
      boundingCoordinates[boundingCoordinates.length - 1]
    ) {
      closePolygon(boundingCoordinates);
    }
    if (boundingCoordinates.length < 4) {
      return [];
    }
    const url = generateHistoryLookupUrl([boundingCoordinates], version);
    return firstValueFrom(this.backendService.get<MapElementDto[]>(url));
  }

  async deployMapVersion(version: number): Promise<void> {
    return firstValueFrom(
      this.backendService.post<void>(`/map/deploy-version`, { version }),
    );
  }

  async getMapVersions(): Promise<VersionsDto | undefined> {
    return firstValueFrom(
      this.backendService.get<VersionsDto | undefined>(`/map/versions`),
    );
  }

  async loadMapChangesetInfos(): Promise<MapChangesetInfoDto[]> {
    return firstValueFrom(
      this.backendService.get<MapChangesetInfoDto[]>(`/map/changesets`),
    );
  }

  async loadMapChangeset(id: string): Promise<MapChangesetDto> {
    return firstValueFrom(
      this.backendService.get<MapChangesetDto>(`/map/changesets/${id}`),
    );
  }

  async createMapChangeset(
    changeset: CreateOrReplaceMapChangesetDto,
  ): Promise<MapChangesetDto> {
    return firstValueFrom(
      this.backendService.post<MapChangesetDto>(`/map/changesets`, changeset),
    );
  }

  async replaceMapChangeset(
    id: string,
    changeset: CreateOrReplaceMapChangesetDto,
  ): Promise<MapChangesetDto> {
    return firstValueFrom(
      this.backendService.put<MapChangesetDto>(
        `/map/changesets/${id}`,
        changeset,
      ),
    );
  }

  async commitMapChangeset(id: string): Promise<MapChangesetDto> {
    return firstValueFrom(
      this.backendService.post<MapChangesetDto>(
        `/map/changesets/${id}/commit`,
        {},
        (error) => {
          if (error.status === 409) {
            this.errorService.reportError(
              `Found changeset conflicts, you need to rebase`,
            );
            return EMPTY;
          }
          return throwError(() => error);
        },
      ),
    );
  }

  async getMapChangesetConflicts(
    id: string,
  ): Promise<MapChangesetConflictDto[]> {
    return firstValueFrom(
      this.backendService.get<MapChangesetConflictDto[]>(
        `/map/changesets/${id}/conflicts`,
      ),
    );
  }

  async deleteChangeset(id: string): Promise<boolean> {
    try {
      await firstValueFrom(this.backendService.delete(`/map/changesets/${id}`));
      return true;
    } catch (e) {
      this.errorService.reportError(`Could not delete change set.`);
      return false;
    }
  }

  async updateBlockages(
    blockageUpdates: BlockageUpdateDto[],
    description: string,
  ): Promise<boolean> {
    try {
      await firstValueFrom(
        this.backendService.put(`/map/update-blockages`, {
          blockageUpdates,
          description,
        }),
      );
      return true;
    } catch (e) {
      this.errorService.reportError(`Could not update blockages.`);
      return false;
    }
  }
}
