import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Role, User } from '../../../core/user';
import { AuthService } from '../../../core/auth.service';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';

@Component({
  selector: 'data-collection-configuration-dialog',
  templateUrl: './data-collection-configuration-dialog.component.html',
  styleUrls: ['./data-collection-configuration-dialog.component.css'],
})
export class DataCollectionConfigurationDialog {
  isUserAdmin$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<RobotCommunication, any | undefined>,
    @Inject(MAT_DIALOG_DATA) public robotCommunication: RobotCommunication,
    private authService: AuthService,
  ) {
    this.authService.user$.pipe(take(1)).subscribe((user?: User) => {
      this.isUserAdmin$.next(!!user?.roles?.includes(Role.ADMIN));
    });
  }
}
