<div
  class="robot-blocking-container"
  *ngIf="blockedByCurrentUserForMillis && blockedByCurrentUserForMillis > 0"
>
  <div class="blocked-icon-background">
    <mat-icon class="blocked-icon">pan_tool</mat-icon>
    <div class="duration-text">
      {{ blockedByCurrentUserForMillis | duration }}
    </div>
  </div>
</div>
