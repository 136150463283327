import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { ActivatedRoute } from '@angular/router';
import {
  catchError,
  exhaustMap,
  map,
  filter,
  switchMap,
  first,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { firstValueFrom, Observable, of, Subject, zip } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  RefinementDialogComponent,
  RefinementDialogData,
} from '../refinement-dialog/refinement-dialog.component';
import { TrackedOrder } from './tracked-order';
import {
  estimateMaxDropoffRefinementDistance,
  findDropoffHandover,
  getDropffHandoverPosition,
  getHandoverInfos,
  isOrderFinished,
  isOrderInProgress,
  HandoverInfo,
  getPickupHandoverPosition,
  canBeUnlocked,
} from './utils';
import { visiblePageTimer } from '../../../utils/page-visibility';
import { OrderStatus } from '../../core/order/order';
import { isDefined } from '../../../utils/typeGuards';
import { TackingViewStateService } from './tracking-view-state.service';
import Leaflet from 'leaflet';
import { HttpErrorResponse } from '@angular/common/http';

const SNACK_BAR_SHOW_DURATION = 2000;

enum OrderAccessability {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  OPEN = 'OPEN',
}

function evaluateOrderAccessability(
  trackedOrder: TrackedOrder,
): OrderAccessability {
  const lastHandoverIndex = trackedOrder.handovers.length - 1;
  if (trackedOrder.currentHandoverIndex !== lastHandoverIndex) {
    return OrderAccessability.NOT_AVAILABLE;
  }
  if (trackedOrder.status !== OrderStatus.WAITING_FOR_HANDOVER) {
    return OrderAccessability.NOT_AVAILABLE;
  }
  if (trackedOrder.compartmentLocked) {
    return OrderAccessability.LOCKED;
  }
  if (trackedOrder.compartmentClosed) {
    return OrderAccessability.UNLOCKED;
  }
  return OrderAccessability.OPEN;
}

function selectOptimalVideoSize(containerHeight: number): number {
  if (containerHeight <= 360) {
    return 360;
  }

  if (containerHeight <= 720) {
    return 720;
  }

  return 1080;
}

@Component({
  selector: 'app-tracking-view',
  templateUrl: './tracking-view.component.html',
  styleUrls: ['./tracking-view.component.sass'],
})
export class TrackingViewComponent implements OnInit, OnDestroy {
  @ViewChild('videoContainer') videoContainer?: ElementRef<HTMLDivElement>;

  private destroy$ = new Subject<void>();

  readonly mapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 1,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    tilt: 0,
    disableDefaultUI: true,
    clickableIcons: false,
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };

  maxDropoffRefinementDistance$!: Observable<number>;
  trackedOrder$!: Observable<TrackedOrder>;
  trackingCode$!: Observable<string>;
  handoversInfos$!: Observable<HandoverInfo[]>;
  supportPhoneNumber$!: Observable<string | undefined>;
  compartmentLocked$!: Observable<boolean | undefined>;
  showUnlockSlider$!: Observable<boolean | undefined>;
  robotRoute$!: Observable<number[][] | undefined>;
  sliderCooldownTimeout = SNACK_BAR_SHOW_DURATION;

  showMap: boolean = true;
  showOpenLidVideo: boolean = false;
  showCloseLidVideo: boolean = false;

  unlockButtonPressed = false;
  isOffline = false;
  orderNotFound = false;

  private googleMap!: google.maps.Map;

  private pickupLocationMarker = new google.maps.Marker();
  private dropoffLocationMarker = new google.maps.Marker();
  private robotLocationMarker = new google.maps.Marker();

  constructor(
    private trackingViewStateService: TackingViewStateService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

  async ngOnInit(): Promise<void> {
    this.trackingCode$ = this.route.paramMap.pipe(
      map((params) => params.get('tracking-code') || ''),
    );

    const trackedOrder$ = this.trackingCode$.pipe(
      switchMap((trackingCode) => this.pollTrackingOrder(trackingCode)),
      shareReplay(1),
    );

    this.maxDropoffRefinementDistance$ = trackedOrder$.pipe(
      map(estimateMaxDropoffRefinementDistance),
    );

    this.handoversInfos$ = trackedOrder$.pipe(map(getHandoverInfos));

    this.compartmentLocked$ = trackedOrder$.pipe(
      map((trackedOrder) => trackedOrder.compartmentLocked),
    );

    this.supportPhoneNumber$ = trackedOrder$.pipe(
      map((order) => order.supportPhoneNumber),
    );

    this.robotRoute$ = trackedOrder$.pipe(map((order) => order.robotRoute));

    this.trackedOrder$ = trackedOrder$;
    this.showUnlockSlider$ = this.trackedOrder$.pipe(map(canBeUnlocked));

    trackedOrder$.subscribe(
      (trackedOrder: TrackedOrder) => {
        this.updateMap(trackedOrder);
      },
      (error) => {
        console.error(error);
        this.trackingCode$.subscribe((trackingCode) =>
          this.snackBar.open(`Can not find order for "${trackingCode}"`),
        );
      },
    );

    trackedOrder$
      .pipe(map(evaluateOrderAccessability))
      .subscribe((orderAccessability) => {
        switch (orderAccessability) {
          case OrderAccessability.UNLOCKED:
            this.showOpenLidVideo = true;
            this.showCloseLidVideo = false;
            this.showMap = false;
            break;
          case OrderAccessability.OPEN:
            this.showOpenLidVideo = false;
            this.showCloseLidVideo = true;
            this.showMap = false;
            break;
          default:
            this.showOpenLidVideo = false;
            this.showCloseLidVideo = false;
            this.showMap = true;
        }
      });

    this.setRobotMarker();
    this.setHandoverMarkers();

    this.fixPageHeight();

    await this.showStartupDialog();
  }

  private fixPageHeight() {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    const updateHeight = () => {
      const mainContainer = document.getElementById('mainBody')!;
      mainContainer.style.height = `${window.innerHeight}px`;
      const videoContainerHeight = this.getVideoContainerHeight();
      this.updateAnimationSrc(videoContainerHeight);
    };
    // UI height should be configure after browser is fully loaded
    // and ready to render following frames
    window.requestAnimationFrame(updateHeight);
    window.addEventListener('load', updateHeight);
    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);
  }

  openAnimationSrc!: string;
  closeAnimationSrc!: string;

  private getVideoContainerHeight(): number {
    return (
      this.videoContainer?.nativeElement?.offsetHeight ?? window.innerHeight
    );
  }

  private updateAnimationSrc(containerHeight: number) {
    const videoSize = selectOptimalVideoSize(containerHeight);

    this.openAnimationSrc = `https://storage.googleapis.com/lid-animation/Opening_${videoSize}`;
    this.closeAnimationSrc = `https://storage.googleapis.com/lid-animation/Closing_${videoSize}`;
  }

  private async showStartupDialog() {
    const trackingCode = await firstValueFrom(this.trackingCode$.pipe(first()));

    const isLocationConfirmed =
      this.trackingViewStateService.isOrderDropoffConfirmed(trackingCode);
    zip(this.maxDropoffRefinementDistance$, this.trackedOrder$)
      .pipe(first())
      .subscribe(([maxDropoffRefinementDistance, order]) => {
        if (
          !isOrderFinished(order) &&
          maxDropoffRefinementDistance > 0 &&
          isLocationConfirmed === false
        ) {
          this.onRefinementClick();
        }
      });
  }

  private setRobotMarker() {
    const robotIcon = {
      url: 'assets/robot.png', // url
      scaledSize: new google.maps.Size(50, 50), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(25, 25), // anchor
    };
    this.robotLocationMarker.setIcon(robotIcon);
  }

  private setHandoverMarkers() {
    const pickup = {
      url: 'assets/shopping_basket.svg',
      scaledSize: new google.maps.Size(25, 25),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(6, 25),
    };
    this.pickupLocationMarker.setIcon(pickup);

    const dropoff = {
      url: 'assets/flag.svg',
      scaledSize: new google.maps.Size(25, 25),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(6, 25),
    };
    this.dropoffLocationMarker.setIcon(dropoff);
  }

  onMap(googleMap: google.maps.Map) {
    this.googleMap = googleMap;
    this.robotRoute$.subscribe((robotRoute: number[][] | undefined) => {
      this.googleMap.data.forEach((feature) =>
        this.googleMap.data.remove(feature),
      );
      if (!robotRoute) {
        return;
      }
      this.googleMap.data.addGeoJson({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: robotRoute,
            },
          },
        ],
      });
    });
  }

  async onRefinementClick() {
    const trackingCode = await this.trackingCode$.pipe(first()).toPromise();
    const refinedLocation$ = zip(
      this.trackedOrder$,
      this.maxDropoffRefinementDistance$,
    ).pipe(
      first(),
      switchMap(([trackedOrder, maxDropoffRefinementDistance]) => {
        const dropoffHandover = findDropoffHandover(trackedOrder);
        const refinementData = {
          width: '100vw',
          maxWidth: '500px',
          data: {
            trackingCode: trackingCode ?? '',
            latitude: dropoffHandover.latitude,
            longitude: dropoffHandover.longitude,
            originalLatitude:
              dropoffHandover.originalLatitude || dropoffHandover.latitude,
            originalLongitude:
              dropoffHandover.originalLongitude || dropoffHandover.longitude,
            refinementRadius: maxDropoffRefinementDistance,
          },
        };

        return this.dialog
          .open<
            RefinementDialogComponent,
            RefinementDialogData,
            Leaflet.LatLng
          >(RefinementDialogComponent, refinementData)
          .afterClosed();
      }),
      filter(isDefined),
    );

    zip(refinedLocation$, this.trackingCode$)
      .pipe(
        switchMap(([{ lat, lng }, trackingCode]) => {
          return this.backendService.post(
            `/tracking/${trackingCode}/refine-handover-location`,
            {
              handoverIndex: 1,
              latitude: lat,
              longitude: lng,
            },
          );
        }),
      )
      .subscribe(
        () => {
          this.snackBar.open('Refined meeting point', '', {
            duration: SNACK_BAR_SHOW_DURATION,
          });
        },
        () =>
          this.snackBar.open('Meeting point could not be updated', '', {
            duration: SNACK_BAR_SHOW_DURATION,
          }),
      );
  }

  onUnlockClick() {
    this.trackingCode$
      .pipe(
        switchMap((trackingCode: string) => {
          return this.backendService.post(
            `/tracking/${trackingCode}/open-compartment`,
            {},
          );
        }),
      )
      .subscribe(
        () => {
          this.snackBar.open('Unlocking...', '', {
            duration: SNACK_BAR_SHOW_DURATION,
          });
          this.unlockButtonPressed = true;
        },
        () => {
          this.snackBar.open('Failed to unlock', '', {
            duration: SNACK_BAR_SHOW_DURATION,
          });
        },
      );
  }

  private pollTrackingOrder(trackingCode: string): Observable<TrackedOrder> {
    return visiblePageTimer(0, 4000).pipe(
      takeUntil(this.destroy$),
      exhaustMap(() => {
        return this.backendService
          .get<TrackedOrder>(`/tracking/${trackingCode}`)
          .pipe(
            catchError((err) => {
              if ((err as HttpErrorResponse).status === 404) {
                this.orderNotFound = true;
                this.isOffline = false;
              } else {
                this.isOffline = true;
                this.orderNotFound = false;
              }
              return of(undefined);
            }),
          );
      }),
      filter(isDefined),
      tap(() => {
        this.isOffline = false;
        this.orderNotFound = false;
      }),
    );
  }

  private putDropoffPositionMap(latlngHandover: google.maps.LatLngLiteral) {
    this.dropoffLocationMarker.setPosition(latlngHandover);
    this.googleMap.setCenter(latlngHandover);
  }

  private setPickupPositionInMap(latlngHandover: google.maps.LatLngLiteral) {
    this.pickupLocationMarker.setPosition(latlngHandover);
  }

  private updateMap(trackedOrder: TrackedOrder) {
    this.pickupLocationMarker.setMap(this.googleMap);
    this.dropoffLocationMarker.setMap(this.googleMap);
    this.setPickupPositionInMap(getPickupHandoverPosition(trackedOrder));
    const latlngHandover = getDropffHandoverPosition(trackedOrder);
    this.putDropoffPositionMap(latlngHandover);

    if (
      isOrderInProgress(trackedOrder) &&
      trackedOrder.latitude &&
      trackedOrder.longitude
    ) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(latlngHandover);
      const latlngRobot = {
        lat: trackedOrder.latitude,
        lng: trackedOrder.longitude,
      };
      this.robotLocationMarker.setMap(this.googleMap);
      this.robotLocationMarker.setPosition(latlngRobot);
      bounds.extend(latlngRobot);
      this.googleMap.fitBounds(bounds);
      const zoom = this.googleMap.getZoom() ?? 15;
      this.googleMap.setZoom(zoom > 21 ? 21 : zoom); // restrict zoom level
    } else {
      this.robotLocationMarker.setMap(null);
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(getPickupHandoverPosition(trackedOrder));
      bounds.extend(latlngHandover);
      this.googleMap.fitBounds(bounds);
    }
  }
}
