import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { BackendService } from './backend.service';
import { MapComponent } from './map/map.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from './pipes/pipes.module';
import { ServerUpdatesService } from './server-updates.service';
import { AttributeListInputComponent } from './attribute-list-input/attribute-list-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialog } from './confirmation-dialog/confirmation-dialog.component';
import { RobotsService } from './robots-service/robots.service';
import { BatteryStatusComponent } from './battery-status/battery-status.component';
import { ConnectivityStatusComponent } from './connectivity-status/connectivity-status.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { QuickAddRemoveDialogComponent } from './quick-add-remove-dialog/quick-add-remove-dialog.component';
import { WebSocketService } from './web-socket/web-socket.service';
import { MetricsService } from './metrics/metrics.service';
import { RobotControlPanelComponent } from './robot-control-panel/robot-control-panel.component';
import { UserSessionService } from './user-session/user-session.service';
import { UserEventService } from './user-session/user-events.service';
import { MapConfirmationDialog } from './confirmation-dialog/map-confirmation-dialog.component';
import { MiniMapComponent } from './mini-map/mini-map.component';
import { RobotLastStateService } from './robots-service/robot-last-state.service';
import { ShortcutDialogComponent } from './toolbar/shortcut-dialog.component';
import { CompartmentsDialogComponent } from './compartments-dialog/compartments-dialog.component';
import { SignalingService } from './robots-service/webrtc/signaling.service';
import { RobotsBackendService } from './robots-service/robots-backend.service';
import { MiniMapStateService } from './mini-map/mini-map-state.service';
import { LeafletMapComponent } from './leaflet-map/leaflet-map.component';
import { LeafletMapStateService } from './leaflet-map/leaflet-map-state.service';
import { AudioLevelOverlayComponent } from './robot-control-panel/audio-level-overlay/audio-level-overlay.component';
import { RouterModule } from '@angular/router';
import { ErrorSystemOverlayComponent } from './error-system/error-system-overlay.component';
import { SelectOperationDialogComponent } from './select-operation-dialog/select-operation-dialog.component';
import { SelectOperationButtonComponent } from './select-operation-button/select-operation-button.component';
import { MlBackendService } from './ml-backend.service';
import { SelectionDropboxComponent } from './selection-dropbox/selection-dropbox.component';
import { UserSessionConnectionService } from './user-session/user-session-connection.service';
import { UserSessionEventTrackingService } from './user-session/user-session-event-tracking.service';
import { ReportBlockageDialogComponent } from './report-blockage-dialog/report-blockage-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    RouterModule,
  ],
  declarations: [
    ConfirmationDialog,
    MapComponent,
    ToolbarComponent,
    ShortcutDialogComponent,
    AttributeListInputComponent,
    BatteryStatusComponent,
    ConnectivityStatusComponent,
    PlaceholderComponent,
    WarningDialogComponent,
    QuickAddRemoveDialogComponent,
    RobotControlPanelComponent,
    MapConfirmationDialog,
    MiniMapComponent,
    CompartmentsDialogComponent,
    LeafletMapComponent,
    AudioLevelOverlayComponent,
    ErrorSystemOverlayComponent,
    SelectOperationDialogComponent,
    SelectOperationButtonComponent,
    SelectionDropboxComponent,
    ReportBlockageDialogComponent,
  ],
  providers: [
    AuthService,
    AuthGuard,
    BackendService,
    ServerUpdatesService,
    RobotsService,
    RobotsBackendService,
    SignalingService,
    WebSocketService,
    MetricsService,
    UserSessionService,
    UserSessionConnectionService,
    UserEventService,
    RobotLastStateService,
    MiniMapStateService,
    LeafletMapStateService,
    MlBackendService,
    UserSessionEventTrackingService,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    FormsModule,
    ConfirmationDialog,
    MapComponent,
    ToolbarComponent,
    AttributeListInputComponent,
    BatteryStatusComponent,
    ConnectivityStatusComponent,
    PlaceholderComponent,
    WarningDialogComponent,
    QuickAddRemoveDialogComponent,
    RobotControlPanelComponent,
    MiniMapComponent,
    LeafletMapComponent,
    MapConfirmationDialog,
    ErrorSystemOverlayComponent,
    SelectOperationDialogComponent,
    SelectOperationButtonComponent,
    SelectionDropboxComponent,
  ],
})
export class CoreModule {}
