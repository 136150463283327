<div class="screen-container">
  <app-deadman-switch-overlay
    class="deadman-switch-overlay"
  ></app-deadman-switch-overlay>
  <app-toolbar class="full-screen-top-bar">
    <button mat-menu-item [routerLink]="''">Home</button>
    <button mat-menu-item (click)="openSupervisionSettings()">Setting</button>
  </app-toolbar>
  <div *ngIf="!robotCommunicationInFocus" class="robot-control-grid">
    <ng-template
      ngFor
      let-slot
      [ngForOf]="robotSlots$ | async"
      let-i="index"
      [ngForTrackBy]="trackByIndex"
    >
      <app-mini-slot-robot-supervision
        [slotIndex]="i"
        *ngIf="slot.slotType === 'taken'"
        [robotCommunication]="slot.robotCommunication"
        (onFocusChange)="focusRobotView(slot.robotCommunication)"
        [isFocused]="false"
      ></app-mini-slot-robot-supervision>
      <robot-placeholder
        *ngIf="slot.slotType !== 'taken'"
        class="robot-item"
        [isEnabled]="slot.slotType === 'empty'"
        [slotId]="i"
        [lastUpdateTime]="slot.lastRequestTime"
      ></robot-placeholder>
    </ng-template>
  </div>
  <app-focus-robot-supervision
    class="focused-robot-supervision"
    *ngIf="robotCommunicationInFocus"
    [robotCommunication]="robotCommunicationInFocus"
    [isFocused]="true"
    (onFocusChange)="unfocusRobotView()"
  ></app-focus-robot-supervision>
  <div
    class="ping-indicator"
    [class.ping-indicator-scale]="(pingCount$ | async) % 2 === 0"
  ></div>
</div>
