import { arrayDifference } from '../../utils/array-difference';
import { RobotCommunication } from '../core/robots-service/robot-communication';
import { RobotSlotCell } from './supervision-slot';

function getEmptySlotIndices(slots: RobotSlotCell[]): number[] {
  return slots.flatMap((slot, index) => {
    return slot.slotType === 'empty' ? [index] : [];
  });
}

export type NewRobotConnectionRequest = {
  robotId: string;
  slotIndex: number;
};

export type RobotUpdateState = {
  newRobotIds: NewRobotConnectionRequest[];
  spilloverRobotIds: string[];
  unexpectedlyUnassignedRobotIds: string[];
};

type NewRobotAssignmentResult = {
  newRobotIds: NewRobotConnectionRequest[];
  spilloverRobotIds: string[];
};

function assignedNewRobotsToSlots(
  slots: RobotSlotCell[],
  newRobotIds: string[],
): NewRobotAssignmentResult {
  const emptySlotIndices = getEmptySlotIndices(slots);
  const newRobotsLeft = newRobotIds.slice();
  const newRobotConnectionRequests: NewRobotConnectionRequest[] = [];

  for (const slotIndex of emptySlotIndices) {
    if (newRobotsLeft.length === 0) {
      break;
    }
    newRobotConnectionRequests.push({
      robotId: newRobotsLeft.pop()!,
      slotIndex,
    });
  }

  return {
    newRobotIds: newRobotConnectionRequests,
    spilloverRobotIds: newRobotsLeft,
  };
}

export function updateRobotSlotState(
  slots: RobotSlotCell[],
  robotCommunications: RobotCommunication[],
): RobotUpdateState {
  const connectedRobotIds = slots.flatMap((slot) =>
    slot.slotType === 'taken' ? [slot.robotCommunication.robotId] : [],
  );
  const requestedRobotIds = robotCommunications.map((robot) => robot.robotId);
  const [newRobotIds, unexpectedlyUnassignedRobotIds] = arrayDifference(
    connectedRobotIds,
    requestedRobotIds,
  );
  const robotAssignmentResult = assignedNewRobotsToSlots(slots, newRobotIds);
  return {
    newRobotIds: robotAssignmentResult.newRobotIds,
    spilloverRobotIds: robotAssignmentResult.spilloverRobotIds,
    unexpectedlyUnassignedRobotIds,
  };
}
