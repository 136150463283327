<robot-control
  [robotCommunication]="robotCommunication"
  [highResolution]="active"
  [activeControl]="active"
  [isKeyboardEnabled]="true"
></robot-control>
<div
  class="all-controls"
  *ngIf="active"
  [class.instructor-mode-background]="
    robotCommunication.instructorModeActive$ | async
  "
>
  <app-robot-control-panel
    class="robot-control-panel"
    [robotCommunication]="robotCommunication"
    [active]="active"
  ></app-robot-control-panel>
  <div class="extra-controls">
    <a mat-raised-button href="/supervise-robots"> Robot Supervision </a>
  </div>
</div>

<ng-container
  *ngIf="active && (robotCommunication.robotState$ | async) as robotState"
>
  <app-robot-issue-panel
    *ngIf="robotState.issues"
    [issues]="robotState.issues"
  ></app-robot-issue-panel>
</ng-container>
