import { point, lineString as toLineString } from '@turf/helpers';
import {
  recursivelyTraverseNestedArrays,
  nearestPointOnProjectedLine,
  nearestPointOnLine,
  getPickedEditHandle,
  getPickedExistingEditHandle,
  getPickedIntermediateEditHandle,
  NearestPointType,
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import { LineString, Point, FeatureOf } from '../visualization/geojson-types';
import {
  ModeProps,
  ClickEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  DraggingEvent,
  Viewport,
  GuideFeatureCollection,
  EditHandleFeature,
  Pick,
} from '../visualization/types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { callOnEdit } from './utils';
import { InteractiveMode } from '../visualization/interactive-mode';
import { addPosition, replacePosition } from './geometry-manipulation-utils';

export class SelectAndEditMode extends InteractiveMode {
  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const hoveredPick = getUnderlyingFeaturePick(
      props.lastHoverEvent?.picks[0],
      props,
    );
    return {
      type: 'FeatureCollection',
      features: [
        ...getHandlesForPick(hoveredPick),
        ...this.generateIntermediateHandles(props),
      ],
    };
  }

  private generateIntermediateHandles(props: ModeProps): EditHandleFeature[] {
    const { lastHoverEvent } = props;
    const picks = lastHoverEvent?.picks;
    const mapCoords = lastHoverEvent && lastHoverEvent.mapCoords;

    // don't show intermediate point when too close to an existing edit handle
    if (!picks?.length || !mapCoords || getPickedExistingEditHandle(picks)) {
      return [];
    }

    const featureAsPick = picks.find((pick) => !pick.isGuide);
    const featureGeometry = featureAsPick?.object?.geometry;
    if (
      !featureGeometry ||
      featureGeometry.type === 'Point' ||
      featureGeometry.type === 'MultiPoint' ||
      !props.selectedIndexes.includes(featureAsPick.index)
    ) {
      return [];
    }

    const handles: EditHandleFeature[] = [];
    let intermediatePoint: NearestPointType | null | undefined = null;
    let positionIndexPrefix: number[] = [];
    const referencePoint = point(mapCoords);
    // process all lines of the (single) feature
    recursivelyTraverseNestedArrays(
      featureAsPick.object.geometry.coordinates,
      [],
      (lineString: number[][], prefix: number[]) => {
        const lineStringFeature = toLineString(lineString);

        // Caveat: This code sends the coordinates with the original altitudes, so
        // projections can end up off-screen which leads to inaccuracies. This has so
        // been acceptable, but a better solution would actually send the geometry that
        // is actually displayed to gain correct projections.
        const candidateIntermediatePoint = this.getNearestPoint(
          // @ts-expect-error
          lineStringFeature,
          referencePoint,
          props.modeConfig?.viewport,
          props.modeConfig.altitudeTransformer,
        );
        if (
          !intermediatePoint ||
          candidateIntermediatePoint.properties.dist <
            intermediatePoint.properties.dist
        ) {
          intermediatePoint = candidateIntermediatePoint;
          positionIndexPrefix = prefix;
        }
      },
    );
    // tack on the lone intermediate point to the set of handles
    if (intermediatePoint) {
      const {
        geometry: { coordinates: position },
        properties: { index },
      } = intermediatePoint;
      handles.push({
        type: 'Feature',
        properties: {
          guideType: 'editHandle',
          editHandleType: 'intermediate',
          featureIndex: featureAsPick.index,
          positionIndexes: [...positionIndexPrefix, index + 1],
        },
        geometry: {
          type: 'Point',
          coordinates: position,
        },
      });
    }
    return handles;
  }

  // turf.js does not support elevation for nearestPointOnLine
  private getNearestPoint(
    line: FeatureOf<LineString>,
    inPoint: FeatureOf<Point>,
    viewport?: Viewport,
    altitudeTransformer?: (a: number | undefined) => number,
  ): NearestPointType {
    const { coordinates } = line.geometry;
    if (coordinates.some((coord: number[]) => coord.length > 2)) {
      if (viewport) {
        // This line has elevation, we need to use alternative algorithm
        return nearestPointOnProjectedLine(
          line,
          inPoint,
          viewport,
          altitudeTransformer,
        );
      }
      // eslint-disable-next-line no-console,no-undef
      console.log(
        'Editing 3D point but modeConfig.viewport not provided. Falling back to 2D logic.',
      );
    }
    return nearestPointOnLine(line, inPoint, viewport);
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const pickedIntermediateHandle = getPickedIntermediateEditHandle(
      event.picks,
    );

    if (pickedIntermediateHandle) {
      const { featureIndex, positionIndexes } =
        pickedIntermediateHandle.properties;
      const feature = structuredClone(props.data.features[featureIndex]);
      const coords = pickedIntermediateHandle.originalGeometry?.coordinates;
      if (!coords) {
        return;
      }
      feature.geometry = addPosition(feature.geometry, positionIndexes, coords);
      const updatedData = { ...props.data, features: [...props.data.features] };
      updatedData.features[featureIndex] = feature;

      props.onEdit({
        updatedData,
        editType: 'addPosition',
        editContext: {
          featureIndexes: [featureIndex],
          positionIndexes,
          position: pickedIntermediateHandle.geometry.coordinates,
        },
      });
    } else {
      const pick = getUnderlyingFeaturePick(event.picks[0], props);
      this.mapElementManager.selectedMapElement = pick?.object;
    }
  }

  override onDrag(event: DraggingEvent, props: ModeProps): void {
    const pick = event.pointerDownPicks?.[0];
    if (!pick) {
      return;
    }
    const editHandle = getPickedEditHandle([pick]);
    if (editHandle) {
      // Cancel map panning if pointer went down on an edit handle
      event.cancelPan();

      this.dragEditHandle('movePosition', props, editHandle, event);
    } else if (pick.object?.geometry?.type === 'Point') {
      this.dragPointFeature('movePosition', props, pick, event);
    }
  }

  private dragEditHandle(
    editType: string,
    props: ModeProps,
    editHandle: EditHandleFeature,
    event: StopDraggingEvent | DraggingEvent,
  ) {
    const editHandleProperties = editHandle.properties;
    const feature = structuredClone(
      props.data.features[editHandleProperties.featureIndex],
    );
    feature.geometry = replacePosition(
      feature.geometry,
      editHandleProperties.positionIndexes,
      event.mapCoords,
    );
    const updatedData = { ...props.data, features: [...props.data.features] };
    updatedData.features[editHandleProperties.featureIndex] = feature;

    props.onEdit({
      updatedData,
      editType,
      editContext: {
        featureIndexes: [editHandleProperties.featureIndex],
        positionIndexes: editHandleProperties.positionIndexes,
        position: event.mapCoords,
      },
    });
  }

  private dragPointFeature(
    editType: string,
    props: ModeProps,
    pick: Pick,
    event: StopDraggingEvent | DraggingEvent,
  ) {
    const changedPointFeature = structuredClone(
      props.data.features[pick.index],
    );
    changedPointFeature.geometry.coordinates = event.mapCoords;

    callOnEdit(
      changedPointFeature,
      this.mapElementManager,
      editType,
      event.mapCoords,
      props,
    );
  }

  override onDragStart(event: StartDraggingEvent, props: ModeProps) {
    const selectedFeatureIndexes = props.selectedIndexes;
    const editHandle = getPickedIntermediateEditHandle(event.picks);
    if (selectedFeatureIndexes.length && editHandle) {
      const editHandleProperties = editHandle.properties;
      const feature = structuredClone(
        props.data.features[editHandleProperties.featureIndex],
      );
      feature.geometry = addPosition(
        feature.geometry,
        editHandleProperties.positionIndexes,
        event.mapCoords,
      );
      const updatedData = { ...props.data, features: [...props.data.features] };
      updatedData.features[editHandleProperties.featureIndex] = feature;

      props.onEdit({
        updatedData,
        editType: 'addPosition',
        editContext: {
          featureIndexes: [editHandleProperties.featureIndex],
          positionIndexes: editHandleProperties.positionIndexes,
          position: event.mapCoords,
        },
      });
    }
  }

  override onDragEnd(event: StopDraggingEvent, props: ModeProps) {
    const pick = event.pointerDownPicks?.[0];
    if (!pick) {
      return;
    }
    const editHandle = getPickedEditHandle([pick]);
    if (editHandle) {
      this.dragEditHandle('finishMovePosition', props, editHandle, event);
    } else if (pick.object?.geometry?.type === 'Point') {
      this.dragPointFeature('finishMovePosition', props, pick, event);
    }
  }
}
