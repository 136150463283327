import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-custom-field-map-element-dialog',
  templateUrl: './create-custom-field-map-element-dialog.component.html',
  styleUrls: ['./create-custom-field-map-element-dialog.component.sass'],
})
export class CreateCustomFieldMapElementDialogComponent {
  key = '';
  value = '';

  constructor(
    private readonly dialogRef: MatDialogRef<CreateCustomFieldMapElementDialogComponent>,
  ) {}

  async onSubmit() {
    this.dialogRef.close({
      key: this.key,
      value: this.value,
    });
  }
}
