<div>
  <br />
  <b>Data Upload:</b>
  <br />

  <data-upload [robotCommunication]="robotCommunication"></data-upload>

  <br />
  <br />
  <b>Trigger Service Configuration:</b>

  <trigger-service-config [robotCommunication]="robotCommunication"
    >Loading...</trigger-service-config
  >
</div>
