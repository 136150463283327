<div class="dialog-container">
  Control robot lid locks:
  <div class="buttons-row">
    <button
      mat-raised-button
      color="primary"
      (click)="unlockRobots()"
      matTooltip="Unlock {{ dialogInput.selectedRobots.length }} robots"
    >
      <mat-icon class="mat-icon-no-margin">lock_open</mat-icon>
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="lockRobots()"
      matTooltip="Lock {{ dialogInput.selectedRobots.length }} robots"
    >
      <mat-icon class="mat-icon-no-margin">lock</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  Set robots ready for order:
  <div class="buttons-row">
    <button
      mat-raised-button
      color="primary"
      (click)="notReadyForOrder()"
      matTooltip="Not ready for operations {{
        dialogInput.selectedRobots.length
      }} robots"
    >
      <mat-icon class="mat-icon-no-margin">check_box_outline_blank</mat-icon>
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="readyForOrder()"
      matTooltip="Ready for operations {{
        dialogInput.selectedRobots.length
      }} robots"
    >
      <mat-icon class="mat-icon-no-margin">check_box</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
  Set operation id :
  <mat-form-field appearance="fill">
    <mat-label>Assigned Operation</mat-label>
    <mat-select
      [(value)]="operationIdSelection.selectedOperationId"
      (selectionChange)="setOperationId()"
    >
      <mat-option
        *ngFor="let operationId of operationIdSelection.operationIds"
        [value]="operationId"
      >
        {{ operationId }}
      </mat-option>
      <mat-option [value]="''">-</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-divider></mat-divider>

  <div class="buttons-row">
    <button mat-raised-button color="primary" (click)="openRobotsReport()">
      Open robots report
    </button>

    <button
      mat-raised-button
      color="primary"
      matTooltip="Submit an Issue"
      (click)="createClickupTickets()"
    >
      Create Clickup Tickets
    </button>
  </div>

  <mat-divider></mat-divider>

  Update robot container versions:
  <div class="buttons-row">
    <div class="update-selection-col">
      <mat-form-field appearance="fill">
        <mat-label>Job</mat-label>
        <mat-select [(value)]="awxUpdateData.selectedJobTemplate">
          <mat-option
            *ngFor="let jobTemplate of awxUpdateData.availableJobTemplates"
            [value]="jobTemplate"
          >
            {{ jobTemplate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showInterfaceSelection()">
        <mat-label>Interface Strategy</mat-label>
        <mat-select [(value)]="awxUpdateData.selectedInterfaceStrategy">
          <mat-option
            *ngFor="let strategy of awxUpdateData.availableInterfaceStrategies"
            [value]="strategy"
          >
            {{ strategy }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Version Tag</mat-label>
        <input
          matInput
          placeholder="e.g. v3-1-1 or latest"
          [(ngModel)]="awxUpdateData.versionTag"
        />
      </mat-form-field>
    </div>
    <button
      [disabled]="!launchUpdatePossible()"
      color="primary"
      mat-mini-fab
      (click)="sendAwxLaunchCommand()"
    >
      <mat-icon class="mat-icon-no-margin">upgrade</mat-icon>
    </button>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Done</button>
  </div>
</div>
