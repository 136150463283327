<ng-container *ngIf="template$ | async as template; else loading">
  <h1 mat-dialog-title *ngIf="data.type === 'cart'">Create a Robot</h1>
  <h1 mat-dialog-title *ngIf="data.type === 'virtual'">Create a Virtual Robot</h1>

  <form class="robot-creation-form" [formGroup]="form" (ngSubmit)="onSubmit()">

    <main mat-dialog-content>

      <mat-form-field apperance="fill" class="robot-creation-field">
        <mat-label>Serial Number</mat-label>
        <input
          *ngIf="data.type === 'cart'"
          matInput
          formControlName="serialNumber"
          type="number"
          [min]="template.serialNumber" />
        <input
          *ngIf="data.type === 'virtual'"
          matInput
          formControlName="serialNumber"
          type="number"
          [max]="template.serialNumber" />
      </mat-form-field>

      <mat-form-field apperance="fill" class="robot-creation-field">
        <mat-label>Password</mat-label>
        <input matInput type="text" formControlName="password" />
      </mat-form-field>

    </main>

    <footer class="robot-creation-actions" mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" [disabled]="form.invalid" type="submit">
        Create
      </button>
    </footer>

  </form>
</ng-container>

<ng-template #loading>
  <section class="loader">
    <mat-spinner></mat-spinner>
    <span>Loading...</span>
  </section>
</ng-template>
