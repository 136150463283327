<placeholder
  class="optional-status-tree-placeholder"
  *ngIf="!isOpen"
  iconName="troubleshoot"
  text="Click to open the robot status"
  (click)="openRobotStatus()"
>
</placeholder>

<status-tree *ngIf="isOpen" [robotCommunication]="robotCommunication"
  >Loading...</status-tree
>
