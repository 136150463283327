<div class="grid-container">
  <div
    *ngFor="let robotCommunication of robotCommunications; let i = index"
    (click)="setActiveConnection(i)"
    (mouseenter)="mouseOverConnectionIndex = i"
    (mouseleave)="mouseOverConnectionIndex = -1"
    [@connectionState]="getConnectionState(i, mouseOverConnectionIndex === i)"
  >
    <robot-operator-control
      [robotCommunication]="robotCommunication"
      [active]="i === activeConnectionIndex && !inhibitActive"
      [style.width]="'100%'"
    ></robot-operator-control>
  </div>
</div>

<ng-container *ngIf="activeConnectionIndex >= 0">
  <mat-expansion-panel
    (opened)="routingPanelOpen = true"
    (closed)="routingPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Routing</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="flex-container">
        <mini-map
          class="map"
          [robotCommunication]="
            routingPanelOpen
              ? robotCommunications[activeConnectionIndex]
              : undefined
          "
          (directionsDialogOpen)="onDialogStateChange($event)"
        ></mini-map>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="statusPanelOpen = true"
    (closed)="statusPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Robot Status</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div>
        <button
          mat-raised-button
          (click)="
            robotCommunications[activeConnectionIndex]?.forceConnectionSwitch()
          "
        >
          Force Connection Switch
        </button>
        <button
          style="margin-left: 16px"
          mat-raised-button
          (click)="
            robotCommunications[activeConnectionIndex]?.resetConnection()
          "
        >
          Reset Connection
        </button>
        <mat-form-field style="margin-left: 16px">
          <mat-label>Switch Video Stream</mat-label>
          <mat-select
            [value]="
              robotCommunications[activeConnectionIndex]?.videoChannel$ | async
            "
            (selectionChange)="setVideoChannel($event.value)"
          >
            <mat-option
              *ngFor="let videoChannel of videoChannels | keyvalue"
              [value]="videoChannel.value"
            >
              {{ videoChannel.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
          [ngModel]="
            robotCommunications[activeConnectionIndex]?.isPowerSaving$ | async
          "
          (change)="
            robotCommunications[activeConnectionIndex]?.powerSaving(
              $event.checked
            )
          "
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          >Power saving
        </mat-slide-toggle>

        <mat-slide-toggle
          [ngModel]="
            robotCommunications[activeConnectionIndex]?.instructorModeActive$
              | async
          "
          (change)="
            robotCommunications[activeConnectionIndex]?.setInstructorModeActive(
              $event.checked
            )
          "
          >Instructor Mode
        </mat-slide-toggle>
      </div>
      <div>
        <button
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="robotCommunications[activeConnectionIndex]?.relocalize()"
        >
          Relocalize
        </button>
        <span
          *ngIf="
            robotCommunications[activeConnectionIndex]?.freeRobotDiskSpace$
              | async as freeDiskSpace
          "
        >
          <button
            mat-raised-button
            [disabled]="toGB(freeDiskSpace) < 5"
            (click)="
              robotCommunications[activeConnectionIndex]?.triggerSnapshot()
            "
            style="margin-left: 16px"
          >
            Trigger Snapshot [s] ({{ toGB(freeDiskSpace) | number : '1.1-1' }}
            GB free)
          </button>
        </span>

        <button
          *ngIf="isAdmin$ | async"
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="
            robotCommunications[
              activeConnectionIndex
            ]?.sendDrivetrainResetRequest()
          "
        >
          Drivetrain Reset
        </button>

        <button
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="
            robotCommunications[
              activeConnectionIndex
            ]?.sendEstopReleaseRequest()
          "
        >
          Release Estop
        </button>

        <!-- 'hubReset' label is deprecated, only here for backwards compatability -->
        <button
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="powerCycle()"
        >
          Remote Power Cycle
        </button>

        <button
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="calibrateEndstop()"
        >
          Calibrate Endstops / IMU
        </button>

        <button
          *ngIf="
            (robotCommunications[activeConnectionIndex]?.robotState$ | async)
              ?.orderDetected !== undefined
          "
          mat-raised-button
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (click)="
            robotCommunications[activeConnectionIndex]?.sendTareScale(true)
          "
        >
          Tare Scale
        </button>
        <mat-slide-toggle
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (change)="
            robotCommunications[activeConnectionIndex]?.enableSirenAndAlarm(
              $event.checked
            )
          "
          color="warn"
          >Siren
        </mat-slide-toggle>

        <mat-slide-toggle
          style="margin-left: 16px"
          [disabled]="
            !(robotCommunications[activeConnectionIndex]?.isInControl$ | async)
          "
          (change)="
            robotCommunications[activeConnectionIndex]?.turnUpHighBeams(
              $event.checked
            )
          "
          >Turn Up High Beams
        </mat-slide-toggle>
      </div>
      <div>
        <status-tree
          fxFlex="100%"
          [robotCommunication]="
            statusPanelOpen
              ? robotCommunications[activeConnectionIndex]
              : undefined
          "
          >Loading...
        </status-tree>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="dataCollectionPanelOpen = true"
    (closed)="dataCollectionPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Data Collection</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div
        *ngIf="
          robotCommunications[activeConnectionIndex]?.freeRobotDiskSpace$
            | async
        "
      >
        <button
          mat-raised-button
          *ngIf="
            robotCommunications[activeConnectionIndex]
              ?.mapDataPreprocessorState$ | async as mapDataPreprocessorState
          "
          [disabled]="
            mapDataPreprocessorState != 'mapping' &&
            mapDataPreprocessorState != 'ready'
          "
          (click)="
            robotCommunications[
              activeConnectionIndex
            ]?.mapDataPreprocessorRequest(
              mapDataPreprocessorState == 'mapping' ? 'finish' : 'start'
            )
          "
          style="margin-left: 16px"
        >
          {{
            mapDataPreprocessorState == 'mapping'
              ? 'Finish Map Data Collection'
              : 'Record Mapping Data'
          }}
        </button>
        <button
          *ngIf="
            robotCommunications[activeConnectionIndex]?.freeRobotDiskSpace$
              | async as freeDiskSpace
          "
          mat-raised-button
          [disabled]="toGB(freeDiskSpace) < 30"
          (click)="
            robotCommunications[
              activeConnectionIndex
            ]?.triggerDataCollectionSnapshot()
          "
          style="margin-left: 16px"
        >
          Trigger Data Collection Snapshot [s] ({{
            toGB(freeDiskSpace) | number : '1.1-1'
          }}
          GB free)
        </button>
      </div>

      <br />
      <button
        mat-raised-button
        *ngIf="isUserAdmin$ | async"
        (click)="openDataCollectionConfigurationDialog()"
        style="margin-left: 16px"
      >
        Configure data collection
      </button>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    (opened)="joystickPanelOpen = true"
    (closed)="joystickPanelOpen = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Virtual Joystick</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-joystick
        fxFlex="auto"
        [robotCommunication]="
          joystickPanelOpen
            ? robotCommunications[activeConnectionIndex]
            : undefined
        "
      ></app-joystick>
    </ng-template>
  </mat-expansion-panel>
</ng-container>

<button
  *ngIf="!isAnyRobotActive()"
  class="add-button"
  mat-icon-button
  (click)="openAddRobotsDialog()"
>
  <mat-icon>add_task</mat-icon>
</button>
