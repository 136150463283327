import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-robot-blocking',
  templateUrl: './robot-blocking.component.html',
  styleUrls: ['./robot-blocking.component.sass'],
})
export class RobotBlockingComponent {
  @Input()
  blockedByCurrentUserForMillis: number | null = null;
}
