import { LRUCache } from 'lru-cache';
import { clone } from 'ramda';

type CacheConfig = {
  size?: number;
  ttl?: number;
  noCopy?: boolean;
};

export const DEFAULT_CACHE_TTL = 30 * 1000;
export const TEST_CACHE_TTL = 1000;

const DEFAULT_CONFIG = {
  size: 100,
  ttl: DEFAULT_CACHE_TTL,
};

function isPromiseLike(obj: unknown): obj is PromiseLike<unknown> {
  return typeof obj === 'object' && obj !== null && 'then' in obj;
}

export function Cache(config: CacheConfig = {}) {
  return function (
    _target: Object,
    _key: string | symbol,
    descriptor: PropertyDescriptor,
  ) {
    const { size, ttl } = { ...DEFAULT_CONFIG, ...config };
    const cache = new LRUCache({ max: size, ttl });
    const method: unknown = descriptor.value;

    if (method instanceof Function) {
      function getResult(
        thisValue: unknown,
        originalMethod: Function,
        args: unknown[],
      ) {
        const argsKey = JSON.stringify(args);
        const cachedResult = cache.get(argsKey);

        if (cachedResult !== undefined) {
          return cachedResult;
        }

        const result = originalMethod.call(thisValue, ...args);
        cache.set(argsKey, result);
        return result;
      }

      descriptor.value = function (...args: unknown[]) {
        const result = getResult(this, method, args);
        // It is necessary to copy the objects from cache
        // since there are some mutations of the objects in the business logic
        // which can corrupt integrity of cached results

        if (config.noCopy) {
          return result;
        }
        if (isPromiseLike(result)) {
          return result.then(clone);
        }

        return clone(result);
      };
    }
  };
}
