<div class="robot-control-panel-container">
  <mat-slide-toggle
    class="mat-slide-toggle-margin"
    *ngIf="isClaimControlEnabled"
    [ngModel]="robotCommunication?.isInControl$ | async"
    (change)="setInControl($event.checked, $event)"
    >Claim Control</mat-slide-toggle
  >
  <mat-slide-toggle
    class="mat-slide-toggle-margin"
    *ngIf="isManualControlEnabled"
    [ngModel]="robotCommunication?.isManualMouseControl$ | async"
    [disabled]="isNotInControl$ | async"
    (change)="enableManualMouseControl($event.checked)"
    >Manual Control</mat-slide-toggle
  >

  <mat-slide-toggle
    class="mat-slide-toggle-margin"
    *ngIf="autonomyControlType === 'AUTONOMY_TOGGLE'"
    [ngModel]="this.robotCommunication?.isAutonomyEnabled$ | async"
    (change)="toggleIsAutonomyEnabled()"
    [disabled]="isNotInControl$ | async"
    >Autonomy</mat-slide-toggle
  >

  <mat-slide-toggle
    class="mat-slide-toggle-margin"
    *ngIf="isCurbClimbingControlEnabled"
    [ngModel]="this?.robotCommunication?.isCurbClimbingOn$ | async"
    [disabled]="isNotInControl$ | async"
    (change)="this.robotCommunication?.enableCurbClimbingMode($event.checked)"
    >Curb Climbing</mat-slide-toggle
  >

  <mat-slide-toggle
    class="mat-slide-toggle-margin"
    *ngIf="isReadyForOrderControlEnabled"
    [checked]="robotCommunication?.readyForOrders$ | async"
    (change)="setRobotReadyForOrder($event.checked)"
    [disabled]="isNotInControl$ | async"
    >Ready For Orders</mat-slide-toggle
  >

  <mat-form-field
    *ngIf="hazardLightControlType === 'HAZARD_LIGHTS_DROPDOWN'"
    class="robotCommunication-form-menu"
  >
    <mat-label>Hazard Lights</mat-label>
    <mat-select
      [ngModel]="hazardLightState$ | async"
      [disabled]="isNotInControl$ | async"
      (selectionChange)="sendLightingCommand($event.value)"
    >
      <mat-option *ngFor="let state of HAZARD_LIGHTS_STATES" [value]="state">
        {{ state }} ({{ hazardLightState$ | async }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngIf="isNetworkInterfaceControlEnabled"
    class="robotCommunication-form-menu"
  >
    <mat-label>Network Interface</mat-label>
    <mat-select
      (selectionChange)="selectNetworkInterface($event.value)"
      [value]="robotCommunication?.selectedNetworkInterface$ | async"
    >
      <mat-option
        *ngFor="
          let iface of robotCommunication?.availableNetworkInterfaces$ | async
        "
        [value]="iface.interfaceName"
      >
        <span *ngIf="iface.interfaceName"
          >{{ iface.interfaceDisplayName || iface.interfaceName
          }}<span *ngIf="iface.minPing">
            ({{ iface.minPing | number: '1.1-1' }}&lt;{{
              iface.avgPing | number: '1.1-1'
            }}&lt;{{ iface.maxPing | number: '1.1-1' }}ms |
            {{ iface.packageLoss | number: '1.0-0' }}% )</span
          ></span
        >

        <span *ngIf="!iface.interfaceName">No Preference</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button
    class="mat-button-aspect-ratio"
    [disabled]="isNotInControl$ | async"
    *ngIf="hazardLightControlType === 'HAZARD_LIGHT_TOGGLE'"
    mat-mini-fab
    [matTooltip]="'Enable hazard lights'"
    color="primary"
    (click)="toggleHazardLightMode()"
    [class.on-mode]="(hazardLightState$ | async) !== 'Auto'"
  >
    <mat-icon>online_prediction</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    [disabled]="isNotInControl$ | async"
    *ngIf="isLidClockControlEnabled && legacyLidControlActive"
    mat-mini-fab
    [matTooltip]="isLocked ? 'Unlock' : 'Lock'"
    color="primary"
    (click)="toggleLock()"
    [class.on-mode]="!isLocked"
  >
    <mat-icon *ngIf="isLocked">lock_open</mat-icon>
    <mat-icon *ngIf="!isLocked">lock</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    [disabled]="isNotInControl$ | async"
    *ngIf="isCompartmentControlEnabled && !legacyLidControlActive"
    mat-mini-fab
    [matTooltip]="'Handle Compartments'"
    color="primary"
    (click)="openCompartmentsDialog()"
  >
    <mat-icon>widgets</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="autonomyControlType === 'PARKING_MODE'"
    [disabled]="isNotInControl$ | async"
    mat-mini-fab
    [matTooltip]="'Parking mode'"
    color="primary"
    (click)="toggleIsAutonomyEnabled()"
    [class.on-mode]="!(robotCommunication?.isAutonomyEnabled$ | async)"
  >
    <mat-icon>local_parking</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isOverlayMapControlEnabled"
    mat-mini-fab
    [matTooltip]="'Enable overlay map'"
    color="primary"
    (click)="toggleIsOverlayMapEnabled()"
    [class.on-mode]="robotCommunication?.isOverlayMapEnabled$ | async"
  >
    <mat-icon>map</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isBlockageReportEnabled"
    mat-mini-fab
    [matTooltip]="'Report blockage'"
    color="primary"
    (click)="openReportBlockageDialog()"
  >
    <mat-icon>block</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isArrivedControlEnabled"
    [disabled]="!(robotCommunication?.enableArrivedButton$ | async)"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Arrived'"
    (click)="arrivedAtStop()"
  >
    <mat-icon>flag</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isSnapshotControlEnabled"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Take a snapshot'"
    (click)="triggerSnapshot()"
  >
    <mat-icon>add_a_photo</mat-icon>
  </button>
  <button
    class="mat-button-aspect-ratio"
    *ngIf="
      isPowerSavingControlEnabled && (isNotAutonomicPowerSavingControl$ | async)
    "
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle power saving mode'"
    [disabled]="isNotInControl$ | async"
    (click)="togglePowerSaving()"
    [class.on-mode]="robotCommunication?.isPowerSaving$ | async"
  >
    <mat-icon>bolt</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isAutomaticPowerControlEnabled"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle automatic power saving'"
    [disabled]="isNotInControl$ | async"
    (click)="toggleAutomaticPowerSaving()"
    [class.on-mode]="robotCommunication?.isAutomaticPowerSaving$ | async"
  >
    <mat-icon> energy_savings_leaf</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isHighBeamsControlEnabled"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle High Beams'"
    [disabled]="isNotInControl$ | async"
    (click)="toggleHighBeams()"
    [class.on-mode]="isHighBeamsEnabled"
  >
    <mat-icon>highlight</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isSirenControlEnabled"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle Siren'"
    [disabled]="isNotInControl$ | async"
    [class.on-mode]="isSirenOn"
    (click)="toggleSiren()"
  >
    <mat-icon>notification_important</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="
      isIssueReportingEnabled && robotCommunication?.isAutonomyEnabled$ | async
    "
    mat-mini-fab
    color="primary"
    matTooltip="Please STOP the robot before submitting an issue!"
    disabled
  >
    <mat-icon>priority_high</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="
      isIssueReportingEnabled &&
      !(robotCommunication?.isAutonomyEnabled$ | async)
    "
    mat-mini-fab
    color="primary"
    [disabled]="robotCommunication?.isAutonomyEnabled$ | async"
    matTooltip="Submit an Issue"
    (click)="openRobotIssue()"
  >
    <mat-icon>priority_high</mat-icon>
  </button>
  <div *ngIf="robotSoundAvailable$ | async" class="robot-audio">
    <button
      class="mat-button-aspect-ratio"
      *ngIf="!(audioLevelActive$ | async)"
      mat-mini-fab
      color="primary"
      [matTooltip]="
        (speakerActive$ | async) ? 'Disable Speaker' : 'Enable Audio Level'
      "
      [class.on-mode]="speakerActive$ | async"
      (click)="toggleRobotAudio()"
    >
      <mat-icon>campaign</mat-icon>
    </button>

    <button
      class="mat-button-aspect-ratio"
      *ngIf="audioLevelActive$ | async"
      mat-mini-fab
      color="primary"
      [matTooltip]="'Enable Speaker'"
      [class.on-mode]="true"
      (click)="toggleRobotAudio()"
    >
      <mat-icon>equalizer</mat-icon>
    </button>

    <div *ngIf="audioLevelActive$ | async" class="audio-plot">
      <app-audio-level-overlay
        [robotCommunication]="robotCommunication"
        class="audio-button-plot"
      >
      </app-audio-level-overlay>
    </div>
  </div>

  <button
    class="mat-button-aspect-ratio"
    *ngIf="isNavigationToMaintenanceEnabled"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Open in maintenance mode'"
    (click)="openMaintenanceUi()"
  >
    <mat-icon>build</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    *ngIf="isRelocalizeEnabled"
    [disabled]="isNotInControl$ | async"
    [matTooltip]="'Relocalize'"
    (click)="relocalize()"
  >
    <mat-icon>mode_of_travel</mat-icon>
  </button>

  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    *ngIf="isCalibrateEndstopEnabled"
    [disabled]="isNotInControl$ | async"
    [matTooltip]="'Calibrate Endstops / IMU'"
    (click)="calibrateEndstop()"
  >
    <mat-icon>play_for_work</mat-icon>
  </button>
</div>
