<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title> Robot Issues ({{ issues | length }}) </mat-panel-title>
  </mat-expansion-panel-header>

  <table class="robot-issues-table" mat-table [dataSource]="issues">
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        class="robot-issues-table-id-column"
        *matHeaderCellDef
      >
        ID
      </th>
      <td mat-cell *matCellDef="let issue">
        {{ '' // TODO: Get the Organization ID from the Backend }}
        <a
          href="https://app.clickup.com/t/9004035169/{{
            issue.customId | default : issue.id
          }}"
          target="_blank"
        >
          {{ issue.customId | default : issue.id }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Summary</th>
      <td mat-cell *matCellDef="let issue">{{ issue.name }}</td>
    </ng-container>
    <ng-container matColumnDef="severity">
      <th
        mat-header-cell
        class="robot-issues-table-severity-column"
        *matHeaderCellDef
      >
        Severity
      </th>
      <td mat-cell *matCellDef="let issue">
        {{ issue.severity | default : 'Unknown' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-expansion-panel>
