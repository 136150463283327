import {
  IsDefined,
  IsLatitude,
  IsLongitude,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsUrl,
} from 'class-validator';

// The localization map format is built on a set of layered tiles that share
// a common integer-based coordinate system. The tile coordinate system is
// determined by the origin latitude/longitude and the tileSize (in meters).
// All map layers share this origin & tileSize so that all tiles with the same
// integer coordinates overlap in the resulting ENU-aligned coordinate frame:
//
//            y (north)
//                /     +-------+
//               /     /| 0,1,1/|
//     z (up) 2 +-----/-+-----/-+-------+-------+
//        ^    /     +-------+ /       /       /
//        |   /-1,1,0|/ 0,1,0|/ 1,1,0 / 2,1,0 /
//        |1 +-------+-------+-------+-------+
//        | /       /       /       /       /
//        |/-1,0,0 / 0,0,0 / 1,0,0 / 2,0,0 /
//      0 +-------+-------+-------+-------+----> x (east)
//       -1       0       1       2
//                ^
//                |
//      origin of ENU-aligned frame
//
// To get to the x-y cartesian coordinates in this ENU frame, the tile
// coordinates can be multiplied by the tilesSize. Different levels are
// represented inside the .tile protobuf and just use a layer index to
// associate different levels from different map layers. A tile URL would
// look like this:
//
// tilesBaseUrl/X_Y_LAYERNAME.tile
//
// Negative numbers are allowed.

export class LocalizationMapTilesPropertiesDto {
  @IsDefined()
  @IsNotEmpty()
  name!: string;

  @IsDefined()
  @IsLatitude()
  tilesOriginLatitude!: number;

  @IsDefined()
  @IsLongitude()
  tilesOriginLongitude!: number;

  @IsDefined()
  @IsUrl()
  tilesBaseUrl!: string;

  @IsDefined()
  @IsPositive()
  tilesSize!: number; // meters

  @IsDefined()
  layerNames!: string[];

  @IsOptional()
  @IsNumber()
  tilesOriginAltitude?: number;

  @IsOptional()
  @IsNumber()
  minAltitude?: number;

  @IsOptional()
  @IsNumber()
  maxAltitude?: number;
}
