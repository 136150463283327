<div class="quick-add-dialog">
  <div class="list">
    <div
      *ngFor="let item of _items"
      class="entry"
      [class.selected]="item.isSelected"
      (click)="toggleRobot(item)"
    >
      <div>
        {{ item.itemName }}
      </div>
      <button class="add-button" mat-icon-button>
        <mat-icon>{{ item.isSelected ? 'remove' : 'add' }}</mat-icon>
      </button>
    </div>
  </div>

  <div class="control-buttons">
    <button
      class="action-button"
      (click)="close()"
      mat-raised-button
      color="primary"
    >
      Cancel
    </button>
    <button
      class="action-button"
      (click)="update()"
      mat-raised-button
      color="primary"
    >
      Update
    </button>
  </div>
</div>
