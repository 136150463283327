import { NgModule } from '@angular/core';
import { RobotsViewComponent } from './robots-view/robots-view.component';
import { OperationsModule } from '../operations/operations.module';
import { CoreModule } from '../core/core.module';
import { CreateRobotDialogComponent } from './create-robot-dialog/create-robot-dialog.component';
import { RouterModule } from '@angular/router';
import { EditRobotDialogComponent } from './edit-robot-dialog/edit-robot-dialog.component';
import { RobotOperationModule } from './robot-operation/robot-operation.module';
import { MassActionDialogComponent } from './robots-view/mass-action-dialog.component';
import { RobotIssuesModule } from '../core/robot-issues';
import { RobotsReportComponent } from './robots-view/robots-report-dialog.componen';
import { RobotBlackboxComponent } from './robot-blackbox/robot-blackbox.component';
import { ClaimRobotDialogComponent } from './robots-view/claim-robot-dialog/claim-robot-dialog.component';
import { RobotUpdateHistoryDialogComponent } from './robots-view/robot-update-history-dialog/robot-update-history-dialog.component';
import { RobotFiltersComponent } from './robots-view/robot-filters.component';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  imports: [
    CoreModule,
    RouterModule,
    RobotIssuesModule,
    OperationsModule,
    RobotOperationModule,
    MatNativeDateModule,
  ],
  declarations: [
    RobotsViewComponent,
    RobotFiltersComponent,
    CreateRobotDialogComponent,
    EditRobotDialogComponent,
    MassActionDialogComponent,
    RobotsReportComponent,
    RobotBlackboxComponent,
    ClaimRobotDialogComponent,
    RobotUpdateHistoryDialogComponent,
  ],
  exports: [RobotOperationModule],
})
export class RobotsModule {}
