import { RobotIssueSeverity } from './robot-issue.types';

export type RobotStatusByIssues = 'ok' | 'warning' | 'error' | 'unknown';

export function robotStatusFromIssueSeverity(
  severity?: RobotIssueSeverity,
): RobotStatusByIssues {
  switch (severity) {
    case RobotIssueSeverity.BREAKING:
      return 'error';
    case RobotIssueSeverity.DEGRADED:
      return 'warning';
    case RobotIssueSeverity.INFORMATIONAL:
      return 'ok';
    default:
      return 'unknown';
  }
}
