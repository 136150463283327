import { Injectable } from '@angular/core';

const CROSSING_MONITORING_SELECTED_IDS = 'crossing-monitoring-selected-ids';
const CROSSING_MONITORING_STANDING_DURATION_THRESHOLD =
  'crossing-monitoring-standing-duration-threshold';

@Injectable()
export class CrossingMonitoringLocalStateService {
  setSelectedOperationIds(selectedOperationIds: string[]) {
    localStorage.setItem(
      CROSSING_MONITORING_SELECTED_IDS,
      JSON.stringify(selectedOperationIds),
    );
  }

  getSelectedOperationIds(): string[] {
    const selectedOperationIds = localStorage.getItem(
      CROSSING_MONITORING_SELECTED_IDS,
    );
    if (selectedOperationIds === null) {
      return [];
    }
    return JSON.parse(selectedOperationIds);
  }

  setStandingDurationThreshold(standingDurationThreshold: number | null) {
    localStorage.setItem(
      CROSSING_MONITORING_STANDING_DURATION_THRESHOLD,
      JSON.stringify(standingDurationThreshold),
    );
  }

  getStandingDurationThreshold(): number | null {
    const selectedOperationIds = localStorage.getItem(
      CROSSING_MONITORING_STANDING_DURATION_THRESHOLD,
    );
    if (selectedOperationIds === null) {
      return null;
    }
    return JSON.parse(selectedOperationIds);
  }
}
