import { SimpleChanges } from '@angular/core';

export function hasInputChanged(
  changes: SimpleChanges,
  inputName: string,
): boolean {
  const inputValue = changes[inputName];

  return inputValue?.currentValue !== inputValue?.previousValue;
}
