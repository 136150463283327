import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationType } from '../operation';

export interface CreateOperationDialogInput {
  availableRegions: string[];
}
export interface CreateOperationData {
  id: string;
  accessGroups: string[];
  operationType: string;
}

@Component({
  selector: 'app-create-operation-dialog',
  templateUrl: './create-operation-dialog.component.html',
  styleUrls: ['./create-operation-dialog.component.sass'],
})
export class CreateOperationDialogComponent {
  operation: CreateOperationData;
  availableOperationTypes: string[];
  constructor(
    public dialogRef: MatDialogRef<CreateOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInput: CreateOperationDialogInput,
  ) {
    this.availableOperationTypes = Object.values(OperationType);

    this.operation = {
      id: '',
      accessGroups: [],
      operationType:
        this.availableOperationTypes[0] ?? OperationType.OrderOperation,
    };
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
