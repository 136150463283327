export function getOrCreate<K, T>(
  groupedValues: Map<K, T>,
  key: K,
  createNew: () => T,
): T {
  const value = groupedValues.get(key);

  if (value) {
    return value;
  }

  const newValue = createNew();

  groupedValues.set(key, newValue);

  return newValue;
}
