import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataItem } from '../data-viewer/data-viewer.service';
import { MlDataService } from '../ml-data.service';
import { CollectionDto } from '../ml-data-types';
import { MlActionService } from '../ml-action.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface AddFramesToCollectionDialogData {
  frames: DataItem[];
}

@Component({
  selector: 'app-add-frames-to-collection-dialog',
  templateUrl: './add-frames-to-collection-dialog.component.html',
  styleUrls: ['./add-frames-to-collection-dialog.component.sass'],
})
export class AddFramesToCollectionDialog implements OnInit {
  selectedCollectionIds = new Set<number>();
  collections: CollectionDto[] = [];

  errorMessages: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<AddFramesToCollectionDialogData, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: AddFramesToCollectionDialogData,
    readonly mlDataService: MlDataService,
    readonly mlActionService: MlActionService,
  ) {}

  async ngOnInit(): Promise<void> {
    const allCollections = await this.mlDataService.getCollections();
    this.collections = allCollections.filter(
      (collection) => !collection.locked,
    );
  }

  onChange(isChecked: boolean, collectionId: number): void {
    if (isChecked) {
      this.selectedCollectionIds.add(collectionId);
    } else {
      this.selectedCollectionIds.delete(collectionId);
    }
  }

  successCount?: number;
  failureCount?: number;

  async onAddFrames() {
    const frames = this.data.frames;
    const collectionIds = Array.from(this.selectedCollectionIds);

    for (const frame of frames) {
      for (const collectionId of collectionIds) {
        try {
          await this.mlActionService.addFrameToCollection(
            collectionId,
            frame.id,
          );
          if (this.successCount === undefined) {
            this.successCount = 0;
          }
          this.successCount++;
        } catch (e) {
          console.error(e);
          if (this.failureCount === undefined) {
            this.failureCount = 0;
          }
          this.failureCount++;
          console.error(e);
          if (e instanceof HttpErrorResponse && 'error' in e.error) {
            this.errorMessages.push(`Frame ${frame.id} - ${e.error.error}`);
          }
        }
      }
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
