<div class="img-grid-scroll-container">
  <div class="img-grid">
    <app-grid-img-item
      *ngFor="let item of items; index as i"
      (click)="itemClick.emit(item)"
      (pickClicked)="pickItem.emit(item)"
      [isSelected]="item.id === selectedItemId"
      [item]="item"
    ></app-grid-img-item>
  </div>
  <div class="next-page-button" *ngIf="canFetchMore" (click)="nextPage.emit()">
    Get more
  </div>
</div>
