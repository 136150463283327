import {
  ArrayNotEmpty,
  ArrayUnique,
  IsNotEmpty,
  IsInt,
  IsPositive,
  IsOptional,
  Min,
  IsBoolean,
} from 'class-validator';

export class RobotQueueEdgePropertiesDto {
  @IsInt()
  startNodeId!: number;

  @IsInt()
  endNodeId!: number;

  @IsPositive()
  length!: number;

  @ArrayNotEmpty()
  @Min(0, { each: true })
  queueSlotPriorities!: number[];

  @IsOptional()
  @IsNotEmpty({ each: true })
  @ArrayUnique()
  names?: string[]; //unique for all queue edges

  @IsOptional()
  @IsNotEmpty({ each: true })
  @ArrayUnique()
  displayNames?: string[]; // should be unique within the array and for a single operation

  @IsOptional()
  @IsPositive()
  maxSpeed?: number;

  @IsOptional()
  @IsBoolean()
  requiresHazardLights?: boolean;

  @IsOptional()
  @IsBoolean()
  allowUnsupervisedAutonomy?: boolean;
}
