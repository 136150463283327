import { Observable, combineLatest } from 'rxjs';

import { TtlSet } from '../../../../utils/set-ttl';
import { Finalizable } from '../../../../utils/finalizable';
import { map, takeUntil } from 'rxjs/operators';
import { RobotSystemStatus } from '../backend/types';
import { RtcEvents } from '../webrtc/rtc-events';
import { fuzzyStringEqual } from '../../../../utils/fuzzy-string-equal';

const SNOOZE_STATUS_DURATION_MILLIS = 1000 * 60 * 10;
const SNOOZE_STATUS_STRING_EQUALITY_THRESHOLD = 0.25;

export class RobotStatusSnoozingManager extends Finalizable {
  private hiddenStatusMap = new TtlSet<string>(SNOOZE_STATUS_DURATION_MILLIS);

  readonly robotSystemStatuses$: Observable<RobotSystemStatus[]>;

  constructor(rtc: RtcEvents) {
    super();
    this.robotSystemStatuses$ = combineLatest([
      rtc.robotSystemStatuses$,
      this.hiddenStatusMap.updated$,
    ]).pipe(
      takeUntil(this.finalized$),
      map(([attentionStatus]) => {
        const snoozedMessage = this.hiddenStatusMap.keys();
        return attentionStatus.filter(
          (status) =>
            snoozedMessage.findIndex((snoozedMessage) => {
              return fuzzyStringEqual(
                status.message,
                snoozedMessage,
                SNOOZE_STATUS_STRING_EQUALITY_THRESHOLD,
              );
            }) === -1,
        );
      }),
    );
  }

  snoozeStatus(statusString: string) {
    this.hiddenStatusMap.add(statusString);
  }

  protected async onFinalize(): Promise<void> {
    this.hiddenStatusMap.clear;
  }
}
