import {
  Order,
  OrderStatus,
  CommunicationLogEntryType,
  CommunicationDeliveryStatus,
  CommunicationMedium,
  PhoneCallStatus,
} from '../../core/order/order';
import { orderStatusToString } from '../utils';
import { HttpErrorResponse } from '@angular/common/http';
import { Role, User } from '../../../app/core/user';
import {
  formatCreated,
  formatHandoverString,
  formatIds,
} from '../../../app/core/order/order-utils';

export function orderToTableRow(order: Order): OrderTableRow {
  return {
    order,
    robot: order.assignedRobotName ?? '',
    created: formatCreated(order),
    id: formatIds(order),
    pickup: formatHandoverString(order.handovers[0]),
    dropoff: formatHandoverString(order.handovers[1]),
    status: orderStatusToString(order),
    testOrder: !!order.testOrder,
    compartmentCanBeOpenend:
      order.status === OrderStatus.WAITING_FOR_HANDOVER &&
      !!order.compartmentLocked,
    derivedStates: {
      hasSentEmail: hasSentEmail(order),
      hasNotCompletedPhoneCall: hasNotCompletedPhoneCall(order),
      hasCompletedPhoneCall: hasCompletedPhoneCall(order),
      hasInboundMessages: hasInboundMessages(order),
      hasMessageSendFailures: hasMessageSendFailures(order),
      hasPendingMessages: hasPendingMessages(order),
      hasDeliveredMessages: hasDeliveredMessages(order),
      hasFailedEmail: hasFailedEmail(order),
    },
  };
}

function hasDeliveredMessages(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      (log.communicationMedium === CommunicationMedium.SMS &&
        (log.messageDeliveryStatus === CommunicationDeliveryStatus.DELIVERED ||
          log.messageDeliveryStatus === CommunicationDeliveryStatus.SENT)) ||
      ([
        CommunicationLogEntryType.ORDER_STATUS_UPDATE,
        CommunicationLogEntryType.ORDER_STATUS_UPDATE_REMINDER,
      ].includes(log.type) &&
        log.messageDeliveryStatus === undefined),
  );
}

function hasPendingMessages(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.SMS &&
      log.messageDeliveryStatus === CommunicationDeliveryStatus.SENDING,
  );
}

function hasMessageSendFailures(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.SMS &&
      (log.type === CommunicationLogEntryType.SEND_FAILURE ||
        log.messageDeliveryStatus === CommunicationDeliveryStatus.FAILED),
  );
}

function hasInboundMessages(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.SMS &&
      log.type === CommunicationLogEntryType.INBOUND,
  );
}

function hasCompletedPhoneCall(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.PHONE_CALL &&
      log.phoneCallStatus === PhoneCallStatus.COMPLETED,
  );
}

function hasNotCompletedPhoneCall(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.PHONE_CALL &&
      log.phoneCallStatus !== PhoneCallStatus.COMPLETED,
  );
}

function hasSentEmail(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.EMAIL &&
      log.messageDeliveryStatus === CommunicationDeliveryStatus.SENT,
  );
}

function hasFailedEmail(order: Order): boolean {
  return order.communicationLog.some(
    (log) =>
      log.communicationMedium === CommunicationMedium.EMAIL &&
      log.messageDeliveryStatus === CommunicationDeliveryStatus.FAILED,
  );
}

const ROLES_WITH_LOCK_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.ROBOT_OPERATOR,
  Role.ROBOT_MAINTAINER,
]);

const ROLES_WITH_CANCEL_ORDER_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.OPERATIONS_MANAGER,
  Role.OPERATIONS_USER,
]);

export function hasCancelOrderAccess(user?: User): boolean {
  return !!user?.roles.some((role) => ROLES_WITH_CANCEL_ORDER_ACCESS.has(role));
}

const ROLES_WITH_ORDER_CREATION_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.OPERATIONS_MANAGER,
  Role.OPERATIONS_USER,
]);

export function hasCreateOrderAccess(user?: User): boolean {
  return !!user?.roles.some((role) =>
    ROLES_WITH_ORDER_CREATION_ACCESS.has(role),
  );
}

export function hasRobotLockAccess(user?: User): boolean {
  return !!user?.roles.some((role) => ROLES_WITH_LOCK_ACCESS.has(role));
}

export type OrderTableRow = {
  order: Order;
  robot: string;
  id: string;
  created: Date;
  pickup: string;
  dropoff: string;
  status: string;
  testOrder: boolean;
  compartmentCanBeOpenend: boolean;
  derivedStates: {
    hasSentEmail: boolean;
    hasNotCompletedPhoneCall: boolean;
    hasCompletedPhoneCall: boolean;
    hasInboundMessages: boolean;
    hasMessageSendFailures: boolean;
    hasPendingMessages: boolean;
    hasDeliveredMessages: boolean;
    hasFailedEmail: boolean;
  };
};

export function createErrorMessage(httpError: HttpErrorResponse) {
  return httpError.error?.message?.match(/^[\w ]+:/i)
    ? httpError.error.message
    : `Order creation failed: ${httpError.error.message}`;
}
