import { Component, OnDestroy } from '@angular/core';
import { merge, of } from 'rxjs';
import { catchError, takeUntil, retry } from 'rxjs/operators';
import { BackendService } from '../../core/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RobotDto } from '../../core/robots-service/backend/robot.dto';
import { AuthService } from '../../core/auth.service';
import {
  Operation,
  OrderOperation,
  RobotQueueEdgeHandover,
} from '../operation';
import { visiblePageTimer } from '../../../utils/page-visibility';
import {
  INITIAL_ORDER_STATUSES,
  Order,
  OrderStatus,
} from '../../core/order/order';
import { RobotWithOrders } from './robot-card.component';
import { sortHandovers } from '../utils';
import { millisBetween } from '../../../utils/millis-between';
import { groupBy, partition } from 'ramda';
import { MatSnackBar } from '@angular/material/snack-bar';

function isEdgeEqualById(locationId: string) {
  return ({ name }: RobotQueueEdgeHandover): boolean => {
    return name === locationId;
  };
}

const POLLING_INTERVAL_MILLIS = 5000;

type UpdateState = 'updated' | 'sending' | 'waiting';

const MIN_UPDATE_OVERLAY_DURATION = 1000;

@Component({
  selector: 'app-handover-location-view',
  templateUrl: './handover-location-view.component.html',
  styleUrls: ['./handover-location-view.component.sass'],
})
export class HandoverLocationViewComponent implements OnDestroy {
  updateState: UpdateState = 'waiting';
  private lastStateUpdateStarted = new Date();

  handoverDisplayName!: string;

  dropoffs: RobotQueueEdgeHandover[] = [];
  pickups: RobotQueueEdgeHandover[] = [];
  private returnToHandoverTimoutMillis = 0;

  arrivingRobots: RobotWithOrders[] = [];
  arrivedRobots: RobotWithOrders[] = [];
  otherRobots: RobotWithOrders[] = [];

  waitingForRobotOrderCount = 0;

  arrivingInLessThanMillis = 15 * 60 * 1000; // 15 minutes
  departedLessThanMillisAgo = 60 * 60 * 1000; // 60 minutes

  hasActiveRobot = false;

  private operationId!: string;
  locationId!: string;

  private stopPolling$ = new Subject();
  private refresh$ = new Subject();

  constructor(
    private authService: AuthService,
    private backendService: BackendService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.paramMap.subscribe((params) => {
      if (!params.get('operation-id') || !params.get('location-id')) {
        this.router.navigate(['/operations/']);
        return;
      }
      this.startPolling(
        params.get('operation-id')!,
        params.get('location-id')!,
      );
    });
  }

  requestRobot(pickup: RobotQueueEdgeHandover) {
    this.updateState = 'sending';
    this.lastStateUpdateStarted = new Date();
    this.backendService
      .post('/orders', {
        pickupHandover: {
          locationId: pickup.name,
          displayName: pickup.displayName,
        },
        dropoffHandover: {
          locationId: this.locationId,
        },
        operationId: this.operationId,
      })
      .pipe(retry(5))
      .subscribe(() => {
        this.updateState = 'waiting';
        this.snackBar.open('Order is created', undefined, {
          verticalPosition: 'top',
          duration: 2000,
        });
        this.refresh$.next(undefined);
      });
  }

  sendRobot(dropoff: RobotQueueEdgeHandover) {
    this.updateState = 'sending';
    this.lastStateUpdateStarted = new Date();
    this.backendService
      .post('/orders', {
        pickupHandover: {
          locationId: this.locationId,
        },
        dropoffHandover: {
          locationId: dropoff.name,
          displayName: dropoff.displayName,
        },
        operationId: this.operationId,
      })
      .pipe(retry(5))
      .subscribe(() => {
        this.updateState = 'waiting';
        this.snackBar.open('Order is created', undefined, {
          verticalPosition: 'top',
          duration: 2000,
        });
        this.refresh$.next(undefined);
      });
  }

  ngOnDestroy() {
    this.stopPolling$.next(undefined);
  }

  async logout() {
    this.stopPolling$.next(undefined);
    await this.authService.logout();
    this.router.navigate(['/login']);
  }

  async back() {
    this.stopPolling$.next(undefined);
    const handoverPath = window.location.pathname.split('/');
    const handoverSelectionPath = handoverPath.slice(0, -1).join('/');
    this.router.navigate([handoverSelectionPath]);
  }

  private async loadOperationData(operationId: string, locationId: string) {
    const operation = await this.backendService
      .get<Operation | undefined>(`/operations/${operationId}`)
      .pipe(catchError(() => of(undefined)))
      .toPromise();
    if (!operation) {
      return false;
    }

    const pickup = operation.operationData?.pickups?.find(
      isEdgeEqualById(locationId),
    );
    const dropoff = operation.operationData?.dropoffs?.find(
      isEdgeEqualById(locationId),
    );

    const handoverName = (pickup ?? dropoff)?.displayName ?? locationId;

    this.handoverDisplayName = `${
      operation.displayName ?? operation.id
    } - ${handoverName}`;

    if (pickup !== undefined) {
      this.dropoffs =
        operation.operationData?.dropoffs?.sort(sortHandovers) ?? [];
    }

    if (dropoff !== undefined) {
      this.pickups =
        operation.operationData?.pickups?.sort(sortHandovers) ?? [];
    }

    this.returnToHandoverTimoutMillis =
      (operation.operationData?.returnToHandoverTimeoutMins ?? 0) * 60 * 1000;

    return true;
  }

  private async startPolling(operationId: string, locationId: string) {
    if (!(await this.loadOperationData(operationId, locationId))) {
      return;
    }
    this.operationId = operationId;
    this.locationId = locationId;
    merge(visiblePageTimer(0, POLLING_INTERVAL_MILLIS), this.refresh$)
      .pipe(takeUntil(this.stopPolling$))
      .subscribe(() => {
        this.refreshETAs(operationId);
      });
  }

  async refreshETAs(operationId: string) {
    const robots: RobotDto[] =
      (await this.backendService
        .get(`/robots?assigned_operation_id=${operationId}`)
        .pipe(catchError(() => of([])))
        .toPromise()) ?? [];

    this.hasActiveRobot =
      robots.filter((robot) => robot.readyForOrders).length > 0;
    const activeOrders: Order[] =
      (await this.backendService
        .get(
          `/orders?operation_id=${operationId}&status=inprogress&per_page=100`,
        )
        .pipe(catchError(() => of([])))
        .toPromise()) ?? [];

    const finalizedOrders: Order[] =
      (await this.backendService
        .get(`/orders?operation_id=${operationId}&status=final&per_page=10`)
        .pipe(catchError(() => of([])))
        .toPromise()) ?? [];
    const allOrders = [...activeOrders, ...finalizedOrders];
    this.createRobotsWithOrders(robots, allOrders);

    if (this.updateState === 'waiting') {
      this.updateState = 'updated';
    }
  }

  private createRobotsWithOrders(robots: RobotDto[], allOrders: Order[]) {
    const ordersFromThisLocation = allOrders.filter(({ handovers }) =>
      handovers.some((handover) => handover.locationId === this.locationId),
    );
    const isDrivingOrWaiting = (order: Order) =>
      [
        OrderStatus.DRIVING_TO_HANDOVER,
        OrderStatus.WAITING_FOR_HANDOVER,
      ].includes(order.status);
    const [drivingOrWaitingOrders, otherOrders] = partition(
      isDrivingOrWaiting,
      ordersFromThisLocation.filter((order) => !!order.assignedRobotId),
    );
    const ordersByRobotId = groupBy(
      (order: Order) => order.assignedRobotId ?? '',
      drivingOrWaitingOrders,
    );
    const robotsById = new Map(robots.map((robot) => [robot.id, robot]));

    const robotsWithOrders: RobotWithOrders[] = [];
    for (const robotId of Object.keys(ordersByRobotId)) {
      const orders = ordersByRobotId[robotId]!;
      const robot = robotsById.get(robotId);
      if (!robot) {
        continue;
      }
      const arrivingAtMillis = this.computeArrivingAtMillis(orders);
      robotsWithOrders.push({
        displayName: robot.shortName ?? '',
        pictureUrl: robot.pictureUrl,
        orders,
        compartments: robot.compartments ?? [],
        hasArrived:
          (robot.arrivedAtStop ?? false) &&
          Date.now() >= (arrivingAtMillis ?? Infinity),
        arrivingAtMillis,
        //canReturnToHandover: false,
        isBlocked: robot.stopState?.isBlocked ?? false,
      });
    }
    const now = Date.now();
    const [arrivingAndArrivedRobots, otherRobots] = partition(
      (robot: RobotWithOrders) =>
        !!robot.arrivingAtMillis &&
        robot.arrivingAtMillis < now + 5 * 60 * 1000,
      robotsWithOrders,
    );
    const [arrivedRobots, arrivingRobots] = partition(
      (robot: RobotWithOrders) => robot.hasArrived,
      arrivingAndArrivedRobots,
    );
    this.arrivingRobots = arrivingRobots;
    this.arrivedRobots = arrivedRobots;
    this.otherRobots = otherRobots;

    this.waitingForRobotOrderCount = ordersFromThisLocation.filter((order) =>
      INITIAL_ORDER_STATUSES.includes(order.status),
    ).length;
  }

  private computeArrivingAtMillis(orders: Order[]): number | undefined {
    const arrivingAtHandovers = orders.map(
      (order) => order.handovers[order.currentHandoverIndex],
    );
    let arrivingAtMillis: number | undefined;

    for (const arrivingAtHandover of arrivingAtHandovers) {
      if (
        arrivingAtHandover?.locationId !== this.locationId ||
        !arrivingAtHandover.estimatedArrivalTime
      ) {
        continue;
      }
      const estimatedArrivalMillis = new Date(
        arrivingAtHandover.estimatedArrivalTime,
      ).getTime();

      if (!arrivingAtMillis || estimatedArrivalMillis < arrivingAtMillis) {
        arrivingAtMillis = estimatedArrivalMillis;
      }
    }
    return arrivingAtMillis;
  }

  triggerRefresh() {
    this.refresh$.next(undefined);
  }

  trackByRobotName(robot: RobotWithOrders): string {
    return robot.displayName;
  }

  isMinUpdateDuration() {
    return (
      millisBetween(new Date(), this.lastStateUpdateStarted) <
      MIN_UPDATE_OVERLAY_DURATION
    );
  }
}
