import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  RobotSlotIndex,
  SupervisionSettingsService,
} from './supervision-settings/supervision-settings.service';
import { RobotSlots } from './supervision-slot';
import { Throttle } from '../../utils/throttle.decorator';

const EXTRA_ROBOT_IS_ADDED_POP_UP_DURATION = 10 * 1000;
const AUTOMATIC_ROBOT_SLOT_ENABLING_MIN_INTERVAL = 15 * 1000;

@Injectable()
export class EnableExtraSlotsService implements OnDestroy {
  private stopAllSubscriptions$ = new Subject();

  private prevRobotSlots?: RobotSlots;

  private destroy$ = new Subject();

  constructor(
    private readonly supervisionSettingService: SupervisionSettingsService,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnDestroy(): void {
    this.stopAllSubscriptions$.next(undefined);
    this.destroy$.next(undefined);
  }

  private detectChangeOfRobotSlots(robotSlots: RobotSlots) {
    for (const [i, slot] of robotSlots.entries()) {
      const prevSlot = this.prevRobotSlots?.[i];
      if (prevSlot?.slotType !== slot.slotType) {
        return true;
      }

      if (
        prevSlot?.slotType === 'taken' &&
        slot.slotType === 'taken' &&
        prevSlot.robotCommunication.robotId !== slot.robotCommunication.robotId
      ) {
        return true;
      }
    }
    return false;
  }

  updateRobotSlots(robotSlots: RobotSlots) {
    if (!this.detectChangeOfRobotSlots(robotSlots)) {
      return;
    }

    this.prevRobotSlots = robotSlots;
    this.stopAllSubscriptions$.next(undefined);

    const hasEmptySlots =
      robotSlots.find((slot) => slot.slotType === 'empty') !== undefined;
    const indexOfFirstDisabledSlot = robotSlots.findIndex(
      (slot) => slot.slotType === 'disabled',
    );
    const hasDisabledSlots = indexOfFirstDisabledSlot !== -1;

    if (!hasDisabledSlots || hasEmptySlots) {
      return;
    }

    const robotStates = robotSlots
      .flatMap((slot) =>
        slot.slotType === 'taken' ? [slot.robotCommunication] : [],
      )
      .map((robotCommunication) => robotCommunication.robotState$);

    combineLatest(robotStates)
      .pipe(
        takeUntil(this.stopAllSubscriptions$),
        map((robotStates) => {
          return robotStates.filter(
            ({ arrivedAtStop }) => arrivedAtStop === false,
          ).length;
        }),
      )
      .subscribe((numberOfDrivingRobots) => {
        if (numberOfDrivingRobots === 0) {
          // indexOfFirstDisabledSlot is index in an array of slots or -1
          // if it is -1, hasDisabledSlots will be true and exit earlier
          this.enableExtraRobotSlot(indexOfFirstDisabledSlot as RobotSlotIndex);
        }
      });
  }

  @Throttle({
    minCallIntervalMillis: AUTOMATIC_ROBOT_SLOT_ENABLING_MIN_INTERVAL,
  })
  enableExtraRobotSlot(indexOfFirstDisabledSlot: RobotSlotIndex) {
    this.supervisionSettingService.setRobotSlotsByIndex(
      true,
      indexOfFirstDisabledSlot as RobotSlotIndex,
    );
    this.snackBar.open(
      'Congratulations, all of your robots arrived! We enabled one more robot for you. :)',
      'Hide',
      {
        verticalPosition: 'top',
        duration: EXTRA_ROBOT_IS_ADDED_POP_UP_DURATION,
      },
    );
  }
}
