export function groupBy<T, K>(array: T[], group: (value: T) => K): Map<K, T[]> {
  const groupedBy = new Map<K, T[]>();

  for (const item of array) {
    const key = group(item);
    const groupedValues = groupedBy.get(key) ?? [];
    groupedBy.set(key, [...groupedValues, item]);
  }

  return groupedBy;
}
