import { Injectable } from '@angular/core';

const SELECTED_DATA_VIEWER_TAB = 'selectedDataViewerTab';
export type DataViewerTabName = 'snippets' | 'frames';

@Injectable()
export class DataViewerStateService {
  setTabName(tabName: DataViewerTabName) {
    localStorage.setItem(SELECTED_DATA_VIEWER_TAB, tabName);
  }

  getTabName(): DataViewerTabName {
    const tabName = localStorage.getItem(SELECTED_DATA_VIEWER_TAB);
    if (tabName === null) {
      return 'snippets';
    }

    return tabName as DataViewerTabName;
  }
}
