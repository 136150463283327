import { BehaviorSubject } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';

export class OverlayMapState extends Finalizable {
  private _isOverlayMapEnabled$ = new BehaviorSubject(false);
  readonly isOverlayMapEnabled$ = this._isOverlayMapEnabled$.asObservable();

  constructor() {
    super();
  }

  enableOverlayMap(isOverlayMapEnabled: boolean) {
    this._isOverlayMapEnabled$.next(isOverlayMapEnabled);
  }

  protected async onFinalize(): Promise<void> {
    this._isOverlayMapEnabled$.complete();
  }
}
