import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapChangesetInfoDto, MapElementDto } from '@cartken/map-types';

export interface LoadChangesetDialogData {
  changesets: MapChangesetInfoDto[];
}

@Component({
  selector: 'load-changeset-dialog',
  templateUrl: 'load-changeset-dialog.component.html',
})
export class LoadChangesetDialogComponent {
  showCommittedChangesets = false;

  constructor(
    public dialogRef: MatDialogRef<LoadChangesetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadChangesetDialogData,
  ) {
    data.changesets = data.changesets
      .filter((changeset) => changeset.committedAsVersion === undefined)
      .sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
  }
}
