import { IsOptional, IsInt, IsPositive } from 'class-validator';

export class HandoverLocationPropertiesDto {
  @IsOptional()
  @IsInt()
  @IsPositive()
  streetNumber?: string;

  @IsOptional()
  streetName?: string;

  @IsOptional()
  subpremise?: string; // E.g. Unit, Suite, Apt number
}
