import { BehaviorSubject } from 'rxjs';
import { VideoChannel, VideoChannelConfiguration } from './types';
import { WebRtcManager } from './webrtc-manager';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Finalizable } from '../../../../utils/finalizable';
import { RtcSendDataChannels } from './rtc-send-data-channels';
import { completeAll } from '../../../../utils/complete-all';

export const HIGH_QUALITY_VIDEO_CONFIG: Required<VideoChannelConfiguration> = {
  fps: 10,
  maxPixelCount: 300000,
  maxBitrate: 1500000,
  channelName: VideoChannel.Default,
};

export const LOW_QUALITY_VIDEO_CONFIG: Required<VideoChannelConfiguration> = {
  fps: 7,
  maxPixelCount: 500 * 200,
  maxBitrate: 400000,
  channelName: VideoChannel.Default,
};

export const SURROUND_VIEW_VIDEO_CONFIG: Required<VideoChannelConfiguration> = {
  fps: 15,
  maxPixelCount: 500000,
  maxBitrate: 2000000,
  channelName: VideoChannel.Surround,
};

export class VideoStream extends Finalizable {
  private requestedFps = LOW_QUALITY_VIDEO_CONFIG.fps;
  private requestedMaxBitrate = LOW_QUALITY_VIDEO_CONFIG.maxBitrate;
  private requestedMaxPixelCount = LOW_QUALITY_VIDEO_CONFIG.maxPixelCount;

  private readonly _videoChannel$ = new BehaviorSubject<VideoChannel>(
    LOW_QUALITY_VIDEO_CONFIG.channelName,
  );
  readonly videoChannel$ = this._videoChannel$.asObservable();

  readonly videoElement: HTMLVideoElement;

  constructor(
    private readonly rtcSendDataChannels: RtcSendDataChannels,
    private readonly webRtcManager: WebRtcManager,
  ) {
    super();
    this.videoElement = document.createElement('video');
    this.videoElement.muted = true;

    this.webRtcManager.videoStream$
      .pipe(takeUntil(this.finalized$))
      .subscribe(async (mediaStreamTrack: MediaStreamTrack) => {
        try {
          this.videoElement.srcObject = new MediaStream([mediaStreamTrack]);
          await this.videoElement.play();
        } catch (e) {
          console.error('Video play failed', e);
        }
      });

    this.initVideoConfig();
  }

  private initVideoConfig() {
    this.rtcSendDataChannels.reliableDataChannelOpen$
      .pipe(takeUntil(this.finalized$), filter(Boolean), take(1))
      .subscribe(() => {
        this.applyConfiguration();
      });
  }

  sendVideoQualityRequest(
    desiredChannelName?: VideoChannel,
    isHighQuality?: boolean,
  ): void {
    const videoConfig = isHighQuality
      ? HIGH_QUALITY_VIDEO_CONFIG
      : LOW_QUALITY_VIDEO_CONFIG;

    this.sendVideoConfiguration({
      ...videoConfig,
      channelName: desiredChannelName,
    });
  }

  sendVideoConfiguration(videoConfig: VideoChannelConfiguration): void {
    if (videoConfig.channelName) {
      this._videoChannel$.next(videoConfig.channelName);
    }
    this.requestedFps = videoConfig.fps ?? this.requestedFps;
    this.requestedMaxBitrate =
      videoConfig.maxBitrate ?? this.requestedMaxBitrate;
    this.requestedMaxPixelCount =
      videoConfig.maxPixelCount ?? this.requestedMaxPixelCount;

    this.applyConfiguration();
  }

  applyConfiguration() {
    this.rtcSendDataChannels.sendReliable({
      videoConfig: {
        channelName: this._videoChannel$.value,
        maxPixelCount: this.requestedMaxPixelCount,
        fps: this.requestedFps,
        maxBitrate: this.requestedMaxBitrate,
      },
    });
  }

  getConfiguration(): Required<VideoChannelConfiguration> {
    return {
      channelName: this._videoChannel$.value,
      maxPixelCount: this.requestedMaxPixelCount,
      fps: this.requestedFps,
      maxBitrate: this.requestedMaxBitrate,
    };
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._videoChannel$);
  }
}
