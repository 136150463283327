import { Handover, Order, OrderStatus } from '../core/order/order';
import { RobotQueueEdgeHandover } from './operation';

export function createHandoverMarkers(
  handovers: Handover[],
  googleMap: google.maps.Map,
): google.maps.Marker[] {
  return handovers.map((handover) => {
    const latlng = { lat: handover.latitude, lng: handover.longitude };
    return new google.maps.Marker({
      label: handover.displayName,
      map: googleMap,
      position: latlng,
      zIndex: 2,
    });
  });
}

export function computeBounds(handovers: Handover[]): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();
  handovers.forEach((handover) =>
    bounds.extend({ lat: handover.latitude, lng: handover.longitude }),
  );
  return bounds;
}

export function orderStatusToString(order: Order): string {
  const currentHandoverType =
    order.handovers[order.currentHandoverIndex]?.handoverType;
  switch (order.status) {
    case OrderStatus.CREATED:
    case OrderStatus.ASSIGNED:
      return 'Waiting for free robot';
    case OrderStatus.DRIVING_TO_HANDOVER:
      return `Robot driving to ${currentHandoverType}`;
    case OrderStatus.WAITING_FOR_HANDOVER:
      return `Robot waiting for ${
        order.handovers[order.currentHandoverIndex]?.handoverType
      }`;
    case OrderStatus.FULFILLED:
      return 'Fulfilled';
    case OrderStatus.CANCELED:
      return 'Canceled: ' + (order.failureReason ?? '');
    case OrderStatus.FAILED:
      return 'Failed: ' + (order.failureReason ?? '');
    default:
      return 'Unknown status';
  }
}

export function sortHandovers(
  handover1: RobotQueueEdgeHandover,
  handover2: RobotQueueEdgeHandover,
) {
  return handover1.displayName && handover2.displayName
    ? handover1.displayName.localeCompare(handover2.displayName)
    : handover1.name.localeCompare(handover2.name);
}
