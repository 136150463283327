import { LatLngBounds } from 'spherical-geometry-js';
import {
  LocalizationMapTile,
  PngGridLevel,
  PngHeightGridLevel,
} from '../../../../generated/slam_msgs/localization_map_tile_pb';
import { visualizeSemanticClassAsRgba } from './sematic-segmentation';
import { MapElementDto } from '@cartken/map-types';
import * as UPNG from 'upng-js';
import { LayerName } from '../visualization-styles';

enum HeightLayerName {
  HEIGHT = 'height',
}

export type TileLayerName = LayerName | HeightLayerName;
export const TileLayerName = { ...LayerName, ...HeightLayerName };
export type LocalizationMapLayers = Map<TileLayerName, LocalizationMapTile>;

export type Texture = {
  width: number;
  height: number;
  data: Uint8Array;
};

export type HeightMap = {
  width: number;
  height: number;
  data: Float32Array;
};

export async function fetchTileLayer(
  baseUrl: string,
  tileCoords: [number, number],
  layerName: string,
): Promise<LocalizationMapTile> {
  const tileUrl = `${baseUrl}/${tileCoords[0]}_${tileCoords[1]}_${layerName}.tile`;
  const response = await fetch(tileUrl);
  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  const binary = await new Promise<Uint8Array>((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as Uint8Array);
    };
  });
  return LocalizationMapTile.deserializeBinary(binary);
}

function isTileLayerName(value: string): value is TileLayerName {
  return Object.values(TileLayerName).includes(value as TileLayerName);
}

export async function fetchTileLayers(
  tileBaseUrl: string,
  tileCoords: [number, number],
  layerNames: string[],
): Promise<LocalizationMapLayers> {
  const filteredLayerNames = layerNames.filter(isTileLayerName);
  const layerNameAndTileTupleList = await Promise.all(
    filteredLayerNames.map(
      async (layerName): Promise<[TileLayerName, LocalizationMapTile]> => {
        const tileLayer = await fetchTileLayer(
          tileBaseUrl,
          tileCoords,
          layerName,
        );
        return [layerName, tileLayer];
      },
    ),
  );
  return new Map(layerNameAndTileTupleList);
}

export function getHeightMap(heightLevel?: PngHeightGridLevel): HeightMap {
  if (!heightLevel) {
    return { width: 0, height: 0, data: new Float32Array() };
  }
  const heightOffset = heightLevel.getHeightOffset() ?? 0;
  const heightGridPng = UPNG.decode(heightLevel.getGrid_asU8());
  const heightData = new Uint8Array(heightGridPng.data);
  const heightMap = {
    width: heightGridPng.width,
    height: heightGridPng.height,
    data: new Float32Array(heightGridPng.width * heightGridPng.height),
  };
  const PIXEL_TO_METER = 1 / 100;
  const LEVEL_BASE_LEVEL_OFFSET = 32768 * PIXEL_TO_METER;

  for (let i = 0; i < heightMap.data.length; ++i) {
    const byteOffset = i << 1;
    const val = (heightData[byteOffset + 1] << 8) | heightData[byteOffset];

    if (!val) {
      heightMap.data[i] = NaN;
    } else {
      heightMap.data[i] =
        val * PIXEL_TO_METER - LEVEL_BASE_LEVEL_OFFSET + heightOffset;
    }
  }
  return heightMap;
}

export function getSteepnessTexture(png?: PngGridLevel): Texture {
  if (!png) {
    return { width: 0, height: 0, data: new Uint8Array() };
  }
  const decoded = UPNG.decode(png.getGrid_asU8());
  const data = new Uint8Array(decoded.data);
  const texture = {
    width: decoded.width,
    height: decoded.height,
    data: new Uint8Array(decoded.width * decoded.height * 4),
  };

  for (let i = 0, j = 0; i < data.length; ++i, j += 4) {
    texture.data[j + 0] = data[i];
    texture.data[j + 1] = data[i];
    texture.data[j + 2] = data[i];
    texture.data[j + 3] = data[i] !== 0 ? 255 : 0;
  }
  return texture;
}

export function getSemanticTexture(png?: PngGridLevel): Texture {
  if (!png) {
    return { width: 0, height: 0, data: new Uint8Array() };
  }
  const decoded = UPNG.decode(png.getGrid_asU8());
  const data = new Uint8Array(decoded.data);
  const texture = {
    width: decoded.width,
    height: decoded.height,
    data: new Uint8Array(decoded.width * decoded.height * 4),
  };

  // Iterates over each semantic 16bit pixel and transforms it to it's
  // corresponding 32bit RGBA color.
  for (let i = 0, j = 0; i < data.length; i += 2, j += 4) {
    const semanticClass = (data[i + 1] << 8) | data[i];
    const semanticPixel = visualizeSemanticClassAsRgba(semanticClass);
    texture.data[j + 0] = semanticPixel[0];
    texture.data[j + 1] = semanticPixel[1];
    texture.data[j + 2] = semanticPixel[2];
    texture.data[j + 3] = semanticPixel[3];
  }
  return texture;
}

export function getColorTexture(png?: PngGridLevel): Texture {
  if (!png) {
    return { width: 0, height: 0, data: new Uint8Array() };
  }
  const decoded = UPNG.decode(png.getGrid_asU8());
  const texture = {
    width: decoded.width,
    height: decoded.height,
    data: new Uint8Array(decoded.data),
  };
  return texture;
}

export function getMapElementsBounds(mapElement: MapElementDto): LatLngBounds {
  const bounds = new LatLngBounds([180, 90], [-180, -90]);
  if (mapElement.geometry.type !== 'Polygon') {
    return bounds;
  }
  for (const coordinate of mapElement.geometry.coordinates[0]) {
    bounds.extend(coordinate as [number, number]);
  }
  return bounds;
}
