import {
  FINAL_ORDER_STATUSES,
  HandoverType,
  IN_PROGRESS_ORDER_STATUSES,
  OrderStatus,
} from '../../core/order/order';
import { isDefined } from '../../../utils/typeGuards';
import { TrackedHandover, TrackedOrder } from './tracked-order';

function isArrived(trackedOrder: TrackedOrder): boolean {
  if (isOrderFinished(trackedOrder)) {
    return true;
  }
  const currentHandover = getCurrentHandover(trackedOrder);
  return (
    getIsDropoff(currentHandover) &&
    trackedOrder.status === OrderStatus.WAITING_FOR_HANDOVER
  );
}

function estimateSecondsToDropoff(
  trackedOrder: TrackedOrder,
): number | undefined {
  const dropoffHandover = findDropoffHandover(trackedOrder);

  if (isArrived(trackedOrder)) {
    return;
  }

  const dropoffHandoverEstimatedArrivalTime =
    dropoffHandover?.estimatedArrivalTime;

  if (!dropoffHandoverEstimatedArrivalTime) {
    return;
  }

  const eta = new Date(dropoffHandoverEstimatedArrivalTime);
  const remainingSecondsToDropoff = (eta.getTime() - Date.now()) / 1000;

  if (remainingSecondsToDropoff < 0) {
    return;
  }

  return remainingSecondsToDropoff;
}

export function findDropoffHandover(
  trackedOrder: TrackedOrder,
): TrackedHandover {
  const dropoffHandorverIndex = trackedOrder.handovers.findIndex(getIsDropoff);
  const dropoffHandover = trackedOrder.handovers[dropoffHandorverIndex];

  if (!dropoffHandover) {
    // should never happen
    throw Error('No dropoff handover');
  }

  return dropoffHandover;
}

export function findPickupHandover(
  trackedOrder: TrackedOrder,
): TrackedHandover {
  const pickupHandoverIndex = trackedOrder.handovers.findIndex(getIsPickup);
  const pickupHandover = trackedOrder.handovers[pickupHandoverIndex];

  if (!pickupHandover) {
    // should never happen
    throw Error('No pickup handover');
  }

  return pickupHandover;
}

export function estimateMaxDropoffRefinementDistance(
  trackedOrder: TrackedOrder,
): number {
  if (getIsArrivedAtDropoff(trackedOrder) || isOrderFinished(trackedOrder)) {
    return 0;
  }

  const dropoffHandover = findDropoffHandover(trackedOrder);
  return dropoffHandover?.maxLocationRefinementDistance || 0;
}

function selectOrderStateString(trackedOrder: TrackedOrder) {
  const currentHandover = getCurrentHandover(trackedOrder);
  const robotNamePrefix =
    trackedOrder.assignedRobotName !== undefined
      ? `Robot ${trackedOrder.assignedRobotName}`
      : 'Robot';

  switch (trackedOrder.status) {
    case OrderStatus.CREATED:
      return 'Looking for a robot...';
    case OrderStatus.ASSIGNED:
      return `Your order is next up for ${robotNamePrefix}`;
    case OrderStatus.DRIVING_TO_HANDOVER:
      if (getIsDropoff(currentHandover)) {
        return `${robotNamePrefix} on its way`;
      } else {
        return `${robotNamePrefix} is collecting your order`;
      }
    case OrderStatus.WAITING_FOR_HANDOVER:
      if (getIsDropoff(currentHandover)) {
        return `${robotNamePrefix} is waiting for you`;
      } else {
        return `${robotNamePrefix} is collecting your order`;
      }
    case OrderStatus.FULFILLED:
      return `${robotNamePrefix} delivered the order`;
    case OrderStatus.CANCELED:
      return 'The order was canceled. Please, contact support';
    case OrderStatus.FAILED:
      return 'Maximum waiting duration was exceeded - the delivery failed';
  }
  throw Error('Unknown order status');
}

export function getIsArrivedAtDropoff(trackedOrder: TrackedOrder) {
  const currentHandorver = getCurrentHandover(trackedOrder);
  const isRobotWaiting =
    trackedOrder.status === OrderStatus.WAITING_FOR_HANDOVER;
  return isRobotWaiting && getIsDropoff(currentHandorver);
}

export function canBeUnlocked(trackedOrder: TrackedOrder): boolean {
  return (
    getIsArrivedAtDropoff(trackedOrder) && !!trackedOrder.compartmentLocked
  );
}

export function getDropffHandoverPosition(
  trackedOrder: TrackedOrder,
): google.maps.LatLngLiteral {
  const dropoffHandover = findDropoffHandover(trackedOrder);
  return {
    lat: dropoffHandover.latitude,
    lng: dropoffHandover.longitude,
  };
}

export function getPickupHandoverPosition(
  trackedOrder: TrackedOrder,
): google.maps.LatLngLiteral {
  const pickupHandover = findPickupHandover(trackedOrder);
  return {
    lat: pickupHandover.latitude,
    lng: pickupHandover.longitude,
  };
}

function getCurrentHandover(trackedOrder: TrackedOrder): TrackedHandover {
  const currentHandover =
    trackedOrder.handovers[trackedOrder.currentHandoverIndex];

  if (currentHandover === undefined) {
    // should never happen
    throw Error('Current Handover Index is out of range');
  }

  return currentHandover;
}

function getIsDisposal(handover: TrackedHandover): boolean {
  return handover.handoverType === 'disposal';
}

function getIsDropoff(handover: TrackedHandover): boolean {
  return handover.handoverType === 'dropoff';
}

function getIsPickup(handover: TrackedHandover): boolean {
  return handover.handoverType === 'pickup';
}

export function isOrderFinished(order: TrackedOrder): boolean {
  return (
    getIsDisposal(getCurrentHandover(order)) ||
    FINAL_ORDER_STATUSES.includes(order.status)
  );
}

export function isOrderInProgress(order: TrackedOrder): boolean {
  return IN_PROGRESS_ORDER_STATUSES.includes(order.status);
}

type BaseHandoverInfo = {
  name: string;
  type: HandoverType;
  isCurrent: boolean;
  orderStateDescription?: string;
};

export type PickHandoverInfo = {
  type: HandoverType.PICKUP;
} & BaseHandoverInfo;

export type DropoffHandoverInfo = {
  type: HandoverType.DROPOFF;
  remainingSecondsToDropoff: number | undefined;
} & BaseHandoverInfo;

export type HandoverInfo = DropoffHandoverInfo | PickHandoverInfo;

export function getDropoffInfo(handover: TrackedHandover): string {
  const displayName = handover?.displayName;
  const address = handover.address;

  return displayName ?? address ?? '';
}

export function getHandoverInfos(trackedOrder: TrackedOrder): HandoverInfo[] {
  return trackedOrder.handovers
    .filter((handover) => handover.handoverType !== 'disposal')
    .filter(isDefined)
    .map((handover, index): HandoverInfo => {
      const name = getDropoffInfo(handover);
      const isCurrent = index === trackedOrder.currentHandoverIndex;
      const stateDescription = isCurrent
        ? selectOrderStateString(trackedOrder)
        : undefined;
      if (getIsDropoff(handover)) {
        return {
          name,
          type: HandoverType.DROPOFF,
          isCurrent,
          orderStateDescription: stateDescription,
          remainingSecondsToDropoff: estimateSecondsToDropoff(trackedOrder),
        };
      } else {
        return {
          name,
          type: HandoverType.PICKUP,
          isCurrent,
          orderStateDescription: stateDescription,
        };
      }
    });
}
