import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsDefined,
  IsString,
  IsArray,
  ArrayMinSize,
  ValidateNested,
  IsOptional,
  IsInt,
  Min,
} from 'class-validator';
import { MapElementDto } from './map-element.dto';

export class CreateOrReplaceMapChangesetDto {
  @IsNotEmpty()
  @IsDefined()
  title!: string;

  @IsNotEmpty()
  @IsDefined()
  description!: string;

  @IsDefined()
  @IsArray()
  @ArrayMinSize(1)
  @ValidateNested({ each: true })
  @Type(() => MapElementDto)
  changedMapElements!: MapElementDto[];

  @IsOptional()
  @IsInt()
  @Min(0)
  basedOnVersion?: number;
}
