import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { BackendService } from '../backend.service';
import {
  CreateRobotIssue,
  RobotIssue,
  RobotIssueTemplate,
} from './robot-issue.types';

export type TemplateType = 'generic' | 'power-cycle';

@Injectable({
  providedIn: 'root',
})
export class RobotIssueService {
  constructor(private api: BackendService) {}

  fetchTemplate(type: TemplateType): Observable<RobotIssueTemplate> {
    return this.api.get<RobotIssueTemplate>(`/robot-issue-templates/${type}`);
  }

  create(robotId: string, issue: CreateRobotIssue): Observable<RobotIssue> {
    return this.api.post<RobotIssue>(`/robots/${robotId}/issues`, issue);
  }

  createMany(
    robotIds: string[],
    issue: CreateRobotIssue,
  ): Observable<RobotIssue[]> {
    return forkJoin(
      robotIds.map((robotId) =>
        this.api.post<RobotIssue>(`/robots/${robotId}/issues`, issue),
      ),
    );
  }
}
