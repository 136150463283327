<div mat-dialog-content>
  <div class="dialog-contianer">
    <div class="robot-loading-spinner" *ngIf="!isLoaded">
      <mat-spinner></mat-spinner>
    </div>

    <app-quick-add-remove-dialog
      [class.hidden]="!isLoaded"
      [items]="operations | async"
      (onClose)="close()"
      (onUpdate)="addSelectedOperations($event)"
    ></app-quick-add-remove-dialog>
  </div>
</div>
