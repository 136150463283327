import { LineStringGeometryDto, MapElementDto } from '@cartken/map-types';
import {
  computeLength,
  computeOffset,
  computeHeading,
} from 'spherical-geometry-js';

export interface EdgeDto extends MapElementDto {
  properties: { startNodeId: number; endNodeId: number; length: number };
  geometry: LineStringGeometryDto;
}

export function isEdge(mapElement: MapElementDto): mapElement is EdgeDto {
  return (
    mapElement.geometry.type === 'LineString' &&
    !!mapElement.properties &&
    'startNodeId' in mapElement.properties &&
    'endNodeId' in mapElement.properties &&
    'length' in mapElement.properties
  );
}

export function updateEdgeLength(edge: EdgeDto) {
  edge.properties.length = computeLength(
    edge.geometry.coordinates as [number, number][],
  );
}

function toLatLngAlt(coord: number[]) {
  return { lng: coord[0], lat: coord[1], alt: coord[2] ?? 0 };
}

export function generateCorridorPolygon(
  edgeCoordinates: number[][],
  startWidth: number,
  endWidth: number,
): number[][] {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const widthDifference = endWidth - startWidth;
  const leftSide: number[][] = [];
  const rightSide: number[][] = [];

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    const fromWidth =
      (startWidth +
        ((i - 1) / (edgeCoordinates.length - 1)) * widthDifference) /
      2.0;
    const toWidth =
      (startWidth + (i / (edgeCoordinates.length - 1)) * widthDifference) / 2.0;
    const fromP = edgeCoordinates[i - 1] as [number, number];
    const fromPAltitude = edgeCoordinates[i - 1][2] ?? 0;
    const toP = edgeCoordinates[i] as [number, number];
    const toPAltitude = edgeCoordinates[i][2] ?? 0;
    const heading = computeHeading(fromP, toP);

    const leftP1 = computeOffset(fromP, fromWidth, heading + 90);
    leftSide.push([leftP1.lng(), leftP1.lat(), fromPAltitude]);
    const rightP1 = computeOffset(fromP, fromWidth, heading - 90);
    rightSide.push([rightP1.lng(), rightP1.lat(), fromPAltitude]);

    const leftP2 = computeOffset(toP, toWidth, heading + 90);
    leftSide.push([leftP2.lng(), leftP2.lat(), toPAltitude]);
    const rightP2 = computeOffset(toP, toWidth, heading - 90);
    rightSide.push([rightP2.lng(), rightP2.lat(), toPAltitude]);
  }
  leftSide.push(...rightSide.reverse(), leftSide[0]);
  return leftSide;
}

export function generateOnewayArrows(
  edgeCoordinates: number[][],
): number[][][] {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const arrows: number[][][] = [];
  const arrowLength = 1;

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    // override unchecked index access warning, since it is iteration of indexes of a array itself
    const fromP = toLatLngAlt(edgeCoordinates[i - 1]!);
    const toP = toLatLngAlt(edgeCoordinates[i]!);
    const heading = computeHeading(fromP, toP);

    const p = {
      lat: (fromP.lat + toP.lat) / 2,
      lng: (fromP.lng + toP.lng) / 2,
      alt: (fromP.alt + toP.alt) / 2,
    };

    const left = computeOffset(p, arrowLength, heading + 135);
    const right = computeOffset(p, arrowLength, heading - 135);
    arrows.push([
      [left.lng(), left.lat(), p.alt],
      [p.lng, p.lat, p.alt],
      [right.lng(), right.lat(), p.alt],
    ]);
  }
  return arrows;
}

export function generateBlockedEdgePoints(
  edgeCoordinates: number[][],
): number[][] {
  if (edgeCoordinates.length < 2) {
    return [];
  }
  const points: number[][] = [];

  for (let i = 1; i < edgeCoordinates.length; ++i) {
    const fromP = toLatLngAlt(edgeCoordinates[i - 1]!);
    const toP = toLatLngAlt(edgeCoordinates[i]!);
    const p = {
      lat: (fromP.lat + toP.lat) / 2,
      lng: (fromP.lng + toP.lng) / 2,
      alt: (fromP.alt + toP.alt) / 2,
    };

    points.push([p.lng, p.lat, p.alt]);
  }
  return points;
}
