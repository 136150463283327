import {
  IsDefined,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsUrl,
} from 'class-validator';

export class SlippyTilesPropertiesDto {
  @IsDefined()
  @IsUrl()
  tilesBaseUrl!: string;

  @IsDefined()
  @IsNotEmpty()
  name!: string;

  @IsOptional()
  @IsInt()
  minZoom?: number;

  @IsOptional()
  @IsInt()
  maxZoom?: number;
}
