<app-toolbar>
  <button
    *ngFor="let page of accessibleRoutes | async"
    mat-menu-item
    [routerLink]="page.path"
  >
    {{ page.displayName }}
  </button>
</app-toolbar>

<button mat-raised-button (click)="switchSite()" id="site-switch-button">
  Switch to {{ switchSiteName }} <mat-icon>sync_alt</mat-icon>
</button>

<div class="flex-container">
  <button
    *ngFor="let page of accessibleRoutes | async"
    mat-stroked-button
    [routerLink]="page.path"
  >
    {{ page.displayName }}
  </button>
  <mat-card
    *ngIf="(accessibleRoutes | async)?.length === 0"
    class="span-columns"
  >
    <mat-card-content
      >You have no privileges. Please contact Cartken if this is
      incorrect.</mat-card-content
    >
  </mat-card>
</div>
