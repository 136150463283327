import { CustomError } from 'ts-custom-error';
import { sleep } from './sleep';

export class TimeoutError extends CustomError {
  constructor(message: string) {
    super(`Timeout exceed: ${message}`);
  }
}

export async function throwAfter(
  timeOutMillis: number,
  errorMessage: string,
): Promise<never> {
  await sleep(timeOutMillis);
  throw new TimeoutError(errorMessage);
}
