<div class="collection-input-container">
  <input
    class="collection-input"
    type="text"
    aria-label="Number"
    matInput
    autofocus
    placeholder="Collection name"
    (input)="onInput($event.target.value)"
  />

  <mat-icon (click)="emitCreateCollection()" class="action-icon"
    >check</mat-icon
  >
  <mat-icon (click)="closeInput.emit()" class="action-icon">clear</mat-icon>
</div>
