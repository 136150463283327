import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'directions-dialog',
  template: `
    <directions
      (newDirections)="newDirections($event)"
      [currentRoute]="data.currentRoute"
      [currentLocation]="data.currentLocation"
      [currentGoal]="data.currentGoal"
    ></directions>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button
        mat-button
        [mat-dialog-close]="directions"
        [disabled]="directions === undefined"
        cdkFocusInitial
      >
        Accept
      </button>
    </div>
  `,
})
export class DirectionsDialogComponent {
  directions?: google.maps.DirectionsResult;
  constructor(
    public dialogRef: MatDialogRef<DirectionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  newDirections(directions: google.maps.DirectionsResult | undefined) {
    this.directions = directions;
  }
  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
