import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { BackendService } from '../../core/backend.service';
import { ErrorService } from '../../core/error-system/error.service';
import { User } from '../../core/user';
import { OperatorBlackboxAggregation } from './operator-blackbox-aggregation';
import { dateToLocalISOString } from '../../../utils/dateToLocalISOString';

const HALF_HOUR_MS = 30 * 60 * 1000;

function isVideoAvailable(record: BlackboxRecord): boolean {
  return record.status === 'AVAILABLE' && !!record.link;
}

function isVideoUploading(record: BlackboxRecord): boolean {
  return record.status === 'UPLOADING';
}

@Component({
  selector: 'app-operator-blackbox-aggregation-view',
  templateUrl: './operator-blackbox-aggregation-view.component.html',
  styleUrls: ['./operator-blackbox-aggregation-view.component.sass'],
})
export class OperatorBlackboxAggregationViewComponent {
  userId$!: Observable<string>;
  user?: User;

  blackboxRequestStartTime = dateToLocalISOString(
    new Date(Date.now() - HALF_HOUR_MS),
  );
  blackboxRequestEndTime = dateToLocalISOString(
    new Date(Date.now() - HALF_HOUR_MS),
  );

  selectedTimeZone: string | undefined = undefined;
  aggregations: OperatorBlackboxAggregation[] = [];

  activeAggregation: OperatorBlackboxAggregation | undefined;

  activeVideoIndex = 0; //Start with first video
  currentPlaybackRate = 1;

  readonly supportedTimeZones = [
    'America/New_York',
    'America/Los_Angeles',
    'Asia/Tokyo',
    'Europe/Berlin',
    'UTC',
  ];

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
  ) {
    this.userId$ = this.route.paramMap.pipe(
      map((params) => {
        const userId = params.get('user-id');
        if (userId === null) {
          this.errorService.reportError(
            `URL is not correct, user-id is not found`,
          );
          return '';
        }

        return userId;
      }),
    );

    this.userId$
      .pipe(
        switchMap((userId) =>
          this.backendService
            .get<User>(`/users/${userId}`)
            .pipe(
              this.errorService.handleStreamErrors(
                `User with name '${userId}' could not be retrieved.`,
              ),
            ),
        ),
      )
      .subscribe((user) => {
        this.user = user;
        this.onUpdate();
      });
  }

  countAvailableVideos(
    operatorBlackboxAggregation: OperatorBlackboxAggregation,
  ) {
    const recordings =
      operatorBlackboxAggregation.aggregatedBlackboxRecordings ?? [];

    return recordings.reduce(
      (acc, cur) => acc + (isVideoAvailable(cur.blackboxRecord) ? 1 : 0),
      0,
    );
  }

  countUploadingVideos(
    operatorBlackboxAggregation: OperatorBlackboxAggregation,
  ) {
    const recordings =
      operatorBlackboxAggregation.aggregatedBlackboxRecordings ?? [];

    return recordings.reduce(
      (acc, cur) => acc + (isVideoUploading(cur.blackboxRecord) ? 1 : 0),
      0,
    );
  }

  countRobots(operatorBlackboxAggregation: OperatorBlackboxAggregation) {
    const recordings =
      operatorBlackboxAggregation.aggregatedBlackboxRecordings ?? [];
    const robotSerialNumbers = recordings.map(
      (recording) => recording.robotSerialNumber,
    );
    const uniqueSerialNumbers = new Set(robotSerialNumbers);
    return uniqueSerialNumbers.size;
  }

  getIndexOfFirstAvailableVideo(
    operatorBlackboxAggregation: OperatorBlackboxAggregation,
  ) {
    const recordings =
      operatorBlackboxAggregation.aggregatedBlackboxRecordings ?? [];
    return recordings.findIndex((r) => isVideoAvailable(r.blackboxRecord));
  }

  onPlayClick(operatorBlackboxAggregation: OperatorBlackboxAggregation) {
    const activeVideoIndex = this.getIndexOfFirstAvailableVideo(
      operatorBlackboxAggregation,
    );
    if (activeVideoIndex < 0) {
      return;
    }
    this.activeAggregation = operatorBlackboxAggregation;
    this.activeVideoIndex = activeVideoIndex;
    this.playVideosFromIndex();
  }

  onUpdate() {
    const userId = this.user?.id;
    if (!userId) {
      return;
    }

    this.backendService
      .get<OperatorBlackboxAggregation[]>(
        `/operator-blackbox-aggregation?operator-user-id=${userId}`,
      )
      .pipe(this.errorService.handleStreamErrors(`backend issues`))
      .subscribe((aggregations) => {
        aggregations.sort(
          (a, b) =>
            new Date(b.aggregationRequestTime ?? Date.now()).getTime() -
            new Date(a.aggregationRequestTime ?? Date.now()).getTime(),
        );
        this.aggregations = aggregations;
      });
  }

  playVideosFromIndex() {
    const videoElm = document.getElementById('video') as HTMLVideoElement;
    const videoSources = this.activeAggregation?.aggregatedBlackboxRecordings;
    if (!videoSources) {
      return;
    }

    videoElm.src =
      videoSources[this.activeVideoIndex].blackboxRecord.link ?? '';
    this.setPlaybackRate();
    videoElm.addEventListener('ended', () => {
      do {
        this.activeVideoIndex = ++this.activeVideoIndex % videoSources.length;
      } while (
        !isVideoAvailable(videoSources[this.activeVideoIndex].blackboxRecord)
      );
      videoElm.src =
        videoSources[this.activeVideoIndex].blackboxRecord.link ?? '';
      this.setPlaybackRate();
    });
  }

  nextVideoInAggregation() {
    const videoSources = this.activeAggregation?.aggregatedBlackboxRecordings;
    if (!videoSources) {
      return;
    }
    for (let i = this.activeVideoIndex + 1; i < videoSources.length; ++i) {
      if (isVideoAvailable(videoSources[i].blackboxRecord)) {
        this.activeVideoIndex = i;
        break;
      }
    }
    this.playVideosFromIndex();
  }

  previousVideoInAggregation() {
    const videoSources = this.activeAggregation?.aggregatedBlackboxRecordings;
    if (!videoSources) {
      return;
    }
    for (let i = this.activeVideoIndex - 1; i >= 0; --i) {
      if (isVideoAvailable(videoSources[i].blackboxRecord)) {
        this.activeVideoIndex = i;
        break;
      }
    }
    this.playVideosFromIndex();
  }

  speed1x() {
    this.currentPlaybackRate = 1;
    this.setPlaybackRate();
  }

  speed2x() {
    this.currentPlaybackRate = 2;
    this.setPlaybackRate();
  }

  speed4x() {
    this.currentPlaybackRate = 4;
    this.setPlaybackRate();
  }

  speed8x() {
    this.currentPlaybackRate = 8;
    this.setPlaybackRate();
  }

  setPlaybackRate() {
    const videoElm = document.getElementById('video') as HTMLVideoElement;
    videoElm.playbackRate = this.currentPlaybackRate;
  }

  applyTimezone(date: Date) {
    if (!this.selectedTimeZone) {
      return date;
    }
    return moment(date).tz(this.selectedTimeZone).toDate();
  }

  requestAggregation() {
    const request = {
      userId: this.user?.id ?? '',
      startTime: this.applyTimezone(new Date(this.blackboxRequestStartTime)),
      endTime: this.applyTimezone(new Date(this.blackboxRequestEndTime)),
    };
    this.backendService
      .post(`/operator-blackbox-aggregation`, request)
      .pipe(this.errorService.handleStreamErrors(`backend issues`))
      .subscribe(() => this.onUpdate());
  }
}
