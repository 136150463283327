<section class="mini-map-container">
  <section
    *ngIf="!hasPrecisePosition && shouldShowPrecisePositionWarning"
    class="mini-map-missing-data"
    [class.outdated]="useBackendFallbackPosition"
  >
    <div>
      Use outdated robot position, please report the issue if this persists!
    </div>
    <button
      *ngIf="!useBackendFallbackPosition"
      mat-raised-button
      color="primary"
      (click)="showLastPosition()"
    >
      <mat-icon>pin_drop</mat-icon>
      <span>Show me the last known location!</span>
    </button>
  </section>
  <leaflet-map
    (onDoubleClick)="resetFollow(); $event?.stopPropagation()"
    (onPanning)="onPanning()"
    (onMapReady)="onMapReady($event)"
    [shouldShowMapProviderSelection]="shouldShowMapProviderSelection"
    [shouldShowZoomControl]="shouldShowZoomControl"
    [refreshTrigger]="refreshTrigger"
  ></leaflet-map>
  <aside class="mini-map-actions">
    <section>
      <button
        *ngIf="isDirectionsClickEnabled"
        class="mini-map-action"
        matTooltip="Check directions, and update positions"
        matTooltipPosition="before"
        (click)="openDirectionsDialog()"
      >
        <mat-icon>directions</mat-icon>
      </button>
      <button
        *ngIf="isCustomGpsClickEnabled"
        class="mini-map-action"
        matTooltip="Input custom GPS coordinates"
        matTooltipPosition="before"
        (click)="openCustomGpsDialog()"
      >
        <mat-icon>share_location</mat-icon>
      </button>
    </section>
    <div class="flex-grow"></div>
    <section *ngIf="shouldUIButtonsBeEnables">
      <button
        #rotationBtn
        class="mini-map-action"
        [matTooltip]="
          shouldRotate
            ? 'Rotate towards north instead of the robot'
            : 'Rotate with the robot'
        "
        matTooltipPosition="before"
        (click)="toggleRotation()"
      >
        <mat-icon
          *ngIf="shouldRotate; else noRotate"
          class="mini-map-rotation-icon"
          >navigation</mat-icon
        >
        <ng-template #noRotate>
          <mat-icon>rotate_left</mat-icon>
        </ng-template>
      </button>
      <button
        #locationBtn
        class="mini-map-action"
        [matTooltip]="
          shouldFollow ? 'Stop following the robot' : 'Follow the robot'
        "
        matTooltipPosition="before"
        (click)="toggleFollow()"
      >
        <mat-icon *ngIf="undefined === shouldFollow; else tryFollow"
          >location_disabled</mat-icon
        >
        <ng-template #tryFollow>
          <mat-icon *ngIf="shouldFollow; else noFollow">my_location</mat-icon>
        </ng-template>
        <ng-template #noFollow>
          <mat-icon>location_searching</mat-icon>
        </ng-template>
      </button>
    </section>
  </aside>
</section>
