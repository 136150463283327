<div class="confirmation-container">
  <p>
    {{ message }}
  </p>
  <mat-form-field
    *ngIf="!data.askedToPark"
    appearance="fill"
    class="input-container"
  >
    <input
      matInput
      placeholder="Reason"
      [(ngModel)]="reason"
      (input)="onReasonChange()"
      class="input-field"
    />
  </mat-form-field>
  <div class="buttons">
    <button mat-raised-button class="cancel" (click)="onCancelClick()">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button
      mat-raised-button
      class="confirm"
      (click)="onConfirmClick()"
      [disabled]="!data.askedToPark && !canConfirm"
    >
      <mat-icon>done</mat-icon> Confirm
    </button>
  </div>
</div>
