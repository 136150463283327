import {
  ElementType,
  MapElementDto,
  PointGeometryDto,
} from '@cartken/map-types';

export interface NodeDto extends MapElementDto {
  elementType: ElementType.NODE;
  geometry: PointGeometryDto;
}

export function isNode(mapElement?: MapElementDto): mapElement is NodeDto {
  return (
    mapElement?.elementType === ElementType.NODE &&
    mapElement.geometry.type === 'Point' &&
    !mapElement.properties
  );
}
