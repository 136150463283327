import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {
  init as Sentry,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/angular';
import type { Integration } from '@sentry/types';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getBuildHash } from './buildHash';

const integrations: Integration[] = [browserTracingIntegration({})];

if (environment.sentry.replay)
  integrations.push(
    replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  );

if (environment.sentry.enabled)
  Sentry({
    dsn: 'https://1d294c4164714b6aa072bcbbd806405e@o4505049490194432.ingest.sentry.io/4505049522044928',
    tunnel: environment.sentry.tunnel,
    environment: environment.name,
    release: getBuildHash(),
    integrations,

    tracesSampleRate: environment.sentry.tracing,
    replaysSessionSampleRate: environment.sentry.replaySessions,
    replaysOnErrorSampleRate: environment.sentry.replayErrors,
  });

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
