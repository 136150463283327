import { BehaviorSubject } from 'rxjs';

export class TtlSet<T> {
  private store = new Map<T, NodeJS.Timeout>();

  private emitUpdate$ = new BehaviorSubject<void>(undefined);
  readonly updated$ = this.emitUpdate$.asObservable();

  constructor(private readonly retentionDurationMillis: number) {}

  add(value: T) {
    const oldTimeout = this.store.get(value);
    if (oldTimeout !== undefined) {
      clearTimeout(oldTimeout);
    }
    const timeout = setTimeout(() => {
      this.store.delete(value);
      this.emitUpdate$.next(undefined);
    }, this.retentionDurationMillis);
    this.store.set(value, timeout);
    this.emitUpdate$.next(undefined);
  }

  has(value: T) {
    return this.store.has(value);
  }

  clear() {
    this.store = new Map();
  }

  keys() {
    return Array.from(this.store.keys());
  }
}
