<div class="compartment-container">
  <h1>Compartments</h1>
  <div class="compartments-with-spinner">
    <div class="compartments">
      <div
        *ngFor="let compartment of compartments$ | async"
        class="compartment"
      >
        {{ compartment.id }}

        <mat-icon matTooltip="{{ compartment.state }}">{{
          compartmentStateToIconName(compartment)
        }}</mat-icon>

        <mat-slide-toggle
          *ngIf="!this.waitingForUpdate"
          [ngModel]="isLocked(compartment)"
          (change)="setRobotCompartmentRequest(compartment, $event.checked)"
        ></mat-slide-toggle>
      </div>
    </div>

    <mat-spinner *ngIf="this.waitingForUpdate" [diameter]="70"> </mat-spinner>
  </div>
</div>
