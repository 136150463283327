import { ManualRobotControl } from '../../../core/robots-service/webrtc/types';
import { DriverConditionSupervisor } from './robot-movement/driver-condition-supervisor';

function isStopCommand({ speed, turnRate }: ManualRobotControl) {
  return speed === 0 && turnRate === 0;
}

export class ManualConditionSupervised implements DriverConditionSupervisor {
  active = false;
  inControl = false;
  manualControl = false;
  instructorModeActive = false;

  isDrivingAllowed(manualRobotControl: ManualRobotControl): boolean {
    const isInCharge =
      this.active && (this.inControl || this.instructorModeActive);
    const highPriorityCommand = isStopCommand(manualRobotControl);
    return isInCharge && (this.manualControl || highPriorityCommand);
  }
}
