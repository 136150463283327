import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class VersionsDto {
  @Expose()
  latestVersion?: number;

  @Expose()
  deployedVersion?: number;
}
