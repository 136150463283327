<app-toolbar>
  <button mat-menu-item (click)="createUser()">Create User</button>
</app-toolbar>

<div class="container">
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
    matSort
    matSortDisableClear
  >
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Name</th>
      <td mat-cell *matCellDef="let user">
        {{ user.displayName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
      <td mat-cell *matCellDef="let user">
        {{ user.username }}
      </td>
    </ng-container>

    <ng-container matColumnDef="picture">
      <th mat-header-cell *matHeaderCellDef>Image</th>
      <td mat-cell *matCellDef="let user">
        <img
          *ngIf="user.pictureUrl"
          src="{{ user.pictureUrl }}"
          class="google-profile-img"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="accessGroups">
      <th mat-header-cell *matHeaderCellDef>Access Groups</th>
      <td mat-cell *matCellDef="let user">
        <div class="role-badge" *ngFor="let accessGroup of user.accessGroups">
          {{ accessGroup }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>Roles</th>
      <td mat-cell *matCellDef="let user">
        <div class="role-badge" *ngFor="let role of user.roles">{{ role }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button
          mat-mini-fab
          color="primary"
          aria-label="Edit user"
          (click)="editUser(user)"
        >
          Edit
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let user; columns: columnsToDisplay"
      class="element-row"
    ></tr>
  </table>

  <div id="table-actions">
    <mat-form-field appearance="fill">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyNameFilter($event)" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Roles</mat-label>
      <mat-select multiple (valueChange)="applyRoleFilter($event)">
        <mat-option *ngFor="let role of allRoles" [value]="role">{{
          role
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Access Groups</mat-label>
      <mat-select multiple (valueChange)="applyAccessGroupFilter($event)">
        <mat-option *ngFor="let ag of allAccessGroups" [value]="ag">{{
          ag
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle checked (change)="applyRobotsFilter($event)"
      >Robots</mat-slide-toggle
    >

    <mat-paginator
      [pageSizeOptions]="[10, 25, 100]"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</div>
