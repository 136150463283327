<h2 mat-dialog-title>Order Statistics</h2>
<mat-dialog-content class="mat-typography" style="width: 80vh">
  <div *ngIf="orderStats.processed; else loading">
    Orders Fulfilled {{ orderStats.numSuccessfulOrders }} <br />
    Orders Canceled {{ orderStats.numCanceledOrders }} <br />
    Orders Failed {{ orderStats.numFailedOrders }} <br />
    Orders Rejected {{ orderStats.numRejectedOrders }} <br />
    Average Order Completion {{ orderStats.averageOrderCompletion | date :
    'mm:ss' }} <br />
    First Order {{ orderStats.firstOrder}} <br />
    Last Order {{ orderStats.lastOrder}} <br />
    <br />
    Rejections by reason: <br />
    <ul>
      <li
        *ngFor="let rejectionByReason of orderStats.numRejectedOrdersByReason"
      >
        {{ rejectionByReason.reason }}: {{ rejectionByReason.numOrders }}
      </li>
    </ul>
    <br />
    Orders per Robot: <br />
    <ul>
      <li *ngFor="let ordersPerRobot of orderStats.ordersPerRobot">
        Robot {{ ordersPerRobot.robotName }} : {{
        ordersPerRobot.numSuccessfulOrders }}
      </li>
    </ul>
    <br />

    Orders per Hour:
    <ul>
      <li *ngFor="let ordersPerHour of orderStats.ordersPerHour">
        Hour Start {{ ordersPerHour.hour }} : {{
        ordersPerHour.numSuccessfulOrders }}
      </li>
    </ul>
    <br />
    Orders per Pickup Display Name:
    <ul>
      <li *ngFor="let order of orderStats.ordersPerPickupDisplayName">
        {{ order.name }} : {{ order.numSuccessfulOrders }}
      </li>
    </ul>
    <br />
    Orders per Dropoff Display Name:
    <ul>
      <li *ngFor="let order of orderStats.ordersPerDropoffDisplayName">
        {{ order.name }} : {{ order.numSuccessfulOrders }}
      </li>
    </ul>

    <br />
    Orders per Pickup Location Id:
    <ul>
      <li *ngFor="let order of orderStats.ordersPerPickupLocationId">
        {{ order.name }} : {{ order.numSuccessfulOrders }}
      </li>
    </ul>
    <br />
    Orders per Dropoff Location Id:
    <ul>
      <li *ngFor="let order of orderStats.ordersPerDropoffLocationId">
        {{ order.name }} : {{ order.numSuccessfulOrders }}
      </li>
    </ul>
  </div>

  <ng-template #loading> Loading Data... </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
