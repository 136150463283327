import { MapChangesetConflictDto } from '@cartken/map-types';
import { EMPTY, Observable } from 'rxjs';
import { MapEditorMode } from './mode-manager';
import {
  RebaseChoice,
  RebaseManager,
  ResolvedMapChangesetConflict,
} from './rebase-manager';
import { VisualizationManager } from '../visualization/visualization-manager';
import { FeatureCollection, Geometry } from '../visualization/geojson-types';
import {
  ModeProps,
  NonInteractiveFeature,
  NonInteractiveFeatureCollection,
} from '../visualization/types';
import { getBoundingBox } from '../map-elements/bounding-box-helpers';
import { getLineColor } from '../visualization/visualization-styles';
import { zOffsetGeometry } from './utils';
import { InteractiveMode } from '../visualization/interactive-mode';

export class RebaseMode extends InteractiveMode {
  private rebaseManager?: RebaseManager;
  private readonly zEps = 0.001;
  rebaseConflictFeatures: NonInteractiveFeature[] = [];
  selectionHighlightFeatures: NonInteractiveFeature[] = [];

  currentConflict$: Observable<ResolvedMapChangesetConflict> = EMPTY;
  previousConflictAvailable$: Observable<boolean> = EMPTY;
  nextConflictAvailable$: Observable<boolean> = EMPTY;
  allConflictsResolved$: Observable<boolean> = EMPTY;

  constructor(private visualizationManager: VisualizationManager) {
    super();
  }

  override setActive(active: boolean, mode?: MapEditorMode): void {
    if (active) {
      this.visualizationManager.setMapElementOpacity(0.2);
    } else {
      this.visualizationManager.setMapElementOpacity(1);
    }
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return {
      type: 'FeatureCollection',
      features: [
        ...this.selectionHighlightFeatures,
        ...this.rebaseConflictFeatures,
      ],
    };
  }

  allConflictsResolved() {
    return this.rebaseManager?.allConflictsResolved() ?? true;
  }

  getResolvedConflicts() {
    return this.rebaseManager?.getResolvedConflicts() ?? [];
  }

  resolveCurrentConflict(choice: RebaseChoice) {
    this.rebaseManager?.resolveCurrentConflict(choice);
  }

  nextConflict() {
    this.rebaseManager?.nextConflict();
  }

  previousConflict() {
    this.rebaseManager?.previousConflict();
  }

  setConflicts(rebaseConflicts: MapChangesetConflictDto[]) {
    this.rebaseManager = new RebaseManager(rebaseConflicts);
    this.allConflictsResolved$ = this.rebaseManager.allConflictsResolved$;
    this.currentConflict$ = this.rebaseManager.currentConflict$;
    this.nextConflictAvailable$ = this.rebaseManager.nextConflictAvailable$;
    this.previousConflictAvailable$ =
      this.rebaseManager.previousConflictAvailable$;
    this.rebaseConflictFeatures = [];

    for (const conflict of rebaseConflicts) {
      this.rebaseConflictFeatures.push({
        type: 'Feature',
        geometry: zOffsetGeometry(
          conflict.changesetMapElement.geometry as Geometry,
          0.001,
        ),
        properties: { lineColor: [0, 0, 255, 255], lineWidth: 13 },
      });
      this.rebaseConflictFeatures.push({
        type: 'Feature',
        geometry: zOffsetGeometry(
          conflict.changesetMapElement.geometry as Geometry,
          0.002,
        ),
        properties: {
          lineColor: getLineColor(conflict.changesetMapElement, false),
          lineWidth: 7,
        },
      });
      this.rebaseConflictFeatures.push({
        type: 'Feature',
        geometry: zOffsetGeometry(
          conflict.latestMapElement.geometry as Geometry,
          0.001,
        ),
        properties: { lineColor: [0x33, 0xb2, 0xff, 0xff], lineWidth: 13 },
      });
      this.rebaseConflictFeatures.push({
        type: 'Feature',
        geometry: zOffsetGeometry(
          conflict.latestMapElement.geometry as Geometry,
          0.002,
        ),
        properties: {
          lineColor: getLineColor(conflict.latestMapElement, false),
          lineWidth: 7,
        },
      });
    }
    this.rebaseManager.currentConflict$.subscribe((conflict) =>
      this.onCurrentConflictChanged(conflict),
    );
  }

  private onCurrentConflictChanged(
    currentConflict: ResolvedMapChangesetConflict,
  ) {
    const bounds = getBoundingBox(currentConflict.changesetMapElement);
    if (!bounds) {
      return;
    }
    this.visualizationManager.fitBounds(bounds);
    this.selectionHighlightFeatures = [];

    this.selectionHighlightFeatures.push({
      type: 'Feature',
      geometry: currentConflict.changesetMapElement.geometry as Geometry,
      properties: { lineColor: [0xff, 0x99, 0xff, 0xff], lineWidth: 21 },
    });

    this.selectionHighlightFeatures.push({
      type: 'Feature',
      geometry: currentConflict.latestMapElement.geometry as Geometry,
      properties: { lineColor: [0xff, 0x99, 0xff, 0xff], lineWidth: 21 },
    });
  }
}
