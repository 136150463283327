import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MappingComponent } from './mapping.component';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../material/material.module';
import { MapRecordingsComponent } from './map-recordings.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MappingRobotsComponent } from './mapping-robots.component';
import { MappingAutomationComponent } from './mapping-automation.component';

@NgModule({
  declarations: [
    MappingComponent,
    MapRecordingsComponent,
    MappingRobotsComponent,
    MappingAutomationComponent,
  ],
  imports: [CommonModule, CoreModule, MaterialModule, MatTabsModule],
})
export class MappingModule {}
