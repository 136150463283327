import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { RobotsModule } from '../robots/robots.module';
import { FormsModule } from '@angular/forms';
import { OperationManagementComponent } from './operation-management.component';
import { OperationQuickAddDialogComponent } from './operation-quick-add-dialog.component';
import { OperationOverviewComponent } from './operation-overview.component';
import { OperationRobotsStatsComponent } from './operation-robots-stats.component';
import { OperationOrdersStatsComponent } from './operation-orders-stats.component';
import { WaitingQueueManagementComponent } from './waiting-queue-management/waiting-queue-management.component';
import { EmergencyStopActionService } from './emergency-stop-action.service';

@NgModule({
  declarations: [
    OperationManagementComponent,
    OperationQuickAddDialogComponent,
    OperationOverviewComponent,
    OperationRobotsStatsComponent,
    OperationOrdersStatsComponent,
    WaitingQueueManagementComponent,
  ],
  imports: [
    CoreModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    RobotsModule,
  ],
  providers: [EmergencyStopActionService],
})
export class OperationManagementModule {}
