import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

function hasWhiteSpace(str: string) {
  return /\s/g.test(str);
}

function noWhitespaceValidator(control: FormControl): ValidationErrors | null {
  const isValid = !hasWhiteSpace(control.value);
  return isValid ? null : { whitespace: true };
}

@Component({
  selector: 'app-attribute-list-input',
  templateUrl: './attribute-list-input.component.html',
  styleUrls: ['./attribute-list-input.component.sass'],
})
export class AttributeListInputComponent implements OnChanges {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formCtrl = new FormControl('', [Validators.required, noWhitespaceValidator]);
  filteredAttributes: Observable<string[]>;

  @Input() autocompleteAttributes: string[] = [];
  @Input() label = 'Attributes';
  @Input() selectedAttributes: string[] = [];
  @Output() selectedAttributesChange = new EventEmitter<string[]>();

  @ViewChild('attributeInput')
  attributeInputElement!: ElementRef<HTMLInputElement>;

  constructor() {
    this.filteredAttributes = this.formCtrl.valueChanges.pipe(
      startWith(''),
      filter((attribute): attribute is string => typeof attribute === 'string'),
      map((attribute) => this.filterAutocompleteAttributes(attribute)),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autocompleteAttributes']) {
      // Trigger this.formCtrl.valueChanges to update autocompleteAttribute suggestions.
      this.formCtrl.setValue(this.formCtrl.value);
    }
  }

  addAttribute(attribute?: string): void {
    if (this.formCtrl.invalid) {
      return;
    }
    if (attribute) {
      this.selectedAttributes.push(attribute);
      this.selectedAttributesChange.emit(this.selectedAttributes);
    }

    // Clear the input value
    this.attributeInputElement.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  removeAttribute(attribute: string): void {
    const index = this.selectedAttributes.indexOf(attribute);
    if (index < 0) {
      return;
    }
    this.selectedAttributes.splice(index, 1);
    this.selectedAttributesChange.emit(this.selectedAttributes);
  }

  private filterAutocompleteAttributes(filterText: string): string[] {
    if (!filterText) {
      return this.autocompleteAttributes.slice();
    }
    return this.autocompleteAttributes.filter((attribute) =>
      attribute?.toLowerCase().includes(filterText.toLowerCase()),
    );
  }
}
