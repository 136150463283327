import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TriggerServiceConfigTreeComponent } from '../trigger_service_config_tree/trigger-service-config-tree.component';
import { RobotCommunication } from '../../../../app/core/robots-service/robot-communication';

enum UIState {
  //Original config is different but the current config is equal to the new robot trigger config.
  //In that case a update was probably successful.
  RobotUpdatedToCurrentConfig,
  //Original and current config different from the new robot trigger config.
  NewConfigAvailable,
  //Default state, nothing should be displayed
  Idle,
}

class RobotTriggerConfigUpdate {
  constructor(
    public config: any,
    public receiveTime: Date,
  ) {}
}

@Component({
  selector: 'trigger-service-config',
  templateUrl: './trigger-service-config.component.html',
  styleUrls: ['./trigger-service-config.component.css'],
})
export class TriggerServiceConfigComponent implements OnDestroy {
  @Input()
  set robotCommunication(robotCommunication: RobotCommunication | undefined) {
    this.updateRobotConnection(robotCommunication);
  }
  get robotCommunication(): RobotCommunication | undefined {
    return this._robotCommunication;
  }

  @ViewChild('triggerConfigTree')
  triggerConfigTree!: TriggerServiceConfigTreeComponent;

  private _robotCommunication?: RobotCommunication;
  private readonly onDestroy$ = new Subject<void>();
  private readonly unsubscribeRobotConnection$ = new Subject<void>();
  private readonly robotConfig$ = new BehaviorSubject<
    RobotTriggerConfigUpdate | undefined
  >(undefined);
  private uiStateChangeTimeoutId?: ReturnType<typeof setTimeout>;

  //A trigger service configuration that came from the robot
  lastRobotTriggerConfigUpdate: RobotTriggerConfigUpdate | undefined;
  //The trigger service configuration that is currently being displayed
  private currentTriggerConfig: any;
  changedTriggerConfigComponents: string[] = [];

  displayNewTriggerConfigAvailable = false;
  displayRobotTriggerConfigMatchesCurrentConfig = false;

  constructor() {
    this.robotConfig$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((new_config: RobotTriggerConfigUpdate | undefined) => {
        this.onNewTriggerConfigAvailable(new_config);
      });
  }

  ngOnDestroy() {
    this.updateRobotConnection(undefined);
    this.onDestroy$.next(undefined);
  }

  private updateRobotConnection(robotCommunication?: RobotCommunication) {
    if (robotCommunication === this.robotCommunication) {
      return;
    }
    this.unsubscribeRobotConnection$.next(undefined);
    this._robotCommunication = robotCommunication;
    if (!this.robotCommunication) {
      this.loadTriggerConfig(undefined);
      return;
    }

    this.robotCommunication.triggerServiceConfig$
      .pipe(takeUntil(this.unsubscribeRobotConnection$))
      .subscribe((new_robot_config: any) => {
        this.robotConfig$.next(
          new RobotTriggerConfigUpdate(new_robot_config, new Date()),
        );
      });
  }

  private setUIState(new_state: UIState) {
    if (this.uiStateChangeTimeoutId) {
      clearTimeout(this.uiStateChangeTimeoutId);
      this.uiStateChangeTimeoutId = undefined;
    }
    if (new_state == UIState.Idle) {
      this.displayNewTriggerConfigAvailable = false;
      this.displayRobotTriggerConfigMatchesCurrentConfig = false;
    } else if (new_state == UIState.NewConfigAvailable) {
      this.displayNewTriggerConfigAvailable = true;
      this.displayRobotTriggerConfigMatchesCurrentConfig = false;
    } else if (new_state == UIState.RobotUpdatedToCurrentConfig) {
      this.displayRobotTriggerConfigMatchesCurrentConfig = true;
      this.displayNewTriggerConfigAvailable = false;
    } else {
      console.warn('TriggerServiceConfig: Encountered unknown UIState');
    }
  }
  private scheduleUIStateChange(new_state: UIState, toWaitMillis: number) {
    if (this.uiStateChangeTimeoutId) {
      clearTimeout(this.uiStateChangeTimeoutId);
    }
    this.uiStateChangeTimeoutId = setTimeout(
      () => this.setUIState(new_state),
      toWaitMillis,
    );
  }

  onTriggerConfigChange() {
    this.changedTriggerConfigComponents =
      this.detectChangedTriggerConfigComponents();
  }

  onApply() {
    this.sendTriggerServiceReconfiguration();
  }

  sendTriggerServiceReconfiguration(): boolean {
    const reconfigureObj: any = {};
    for (const componentName of this.changedTriggerConfigComponents) {
      reconfigureObj[componentName] = JSON.parse(
        JSON.stringify(this.currentTriggerConfig[componentName]),
      );
    }
    if (this.robotCommunication) {
      this.robotCommunication.reconfigureTriggerService(reconfigureObj);
      return true;
    }
    return false;
  }

  onReset() {
    this.loadTriggerConfig(this.lastRobotTriggerConfigUpdate);
  }

  detectChangedTriggerConfigComponents(): string[] {
    if (!this.lastRobotTriggerConfigUpdate) {
      return [];
    }
    const changedComponents: string[] = [];
    for (const [key, val] of Object.entries(
      this.lastRobotTriggerConfigUpdate.config,
    )) {
      if (!this.isDeepEqual(val, this.currentTriggerConfig[key])) {
        changedComponents.push(key);
      }
    }
    return changedComponents;
  }

  onNewTriggerConfigAvailable(update: RobotTriggerConfigUpdate | undefined) {
    const isNewConfigEqToCurrConfig = this.isDeepEqual(
      update?.config,
      this.currentTriggerConfig,
    );
    const isNewConfigEqToOrigConfig = this.isDeepEqual(
      update?.config,
      this.lastRobotTriggerConfigUpdate?.config,
    );

    if (isNewConfigEqToCurrConfig && !isNewConfigEqToOrigConfig) {
      this.setUIState(UIState.RobotUpdatedToCurrentConfig);
      this.scheduleUIStateChange(UIState.Idle, 5000);
      this.loadTriggerConfig(update);
    } else if (!isNewConfigEqToOrigConfig) {
      this.setUIState(UIState.NewConfigAvailable);
    } else if (isNewConfigEqToOrigConfig) {
      this.setUIState(UIState.Idle);
      this.lastRobotTriggerConfigUpdate = update;
    } else {
      this.setUIState(UIState.Idle);
    }
  }

  loadTriggerConfig(update: RobotTriggerConfigUpdate | undefined) {
    if (!update || !update.config) {
      this.triggerConfigTree.clearTree();
      this.currentTriggerConfig = undefined;
      this.lastRobotTriggerConfigUpdate = undefined;
      this.changedTriggerConfigComponents = [];
      return;
    }
    this.currentTriggerConfig = JSON.parse(JSON.stringify(update.config));
    this.lastRobotTriggerConfigUpdate = update;

    this.triggerConfigTree.loadConfig(this.currentTriggerConfig, () =>
      this.onTriggerConfigChange(),
    );

    this.changedTriggerConfigComponents = [];
  }

  onLoadNewTriggerConfig() {
    this.loadTriggerConfig(this.robotConfig$.getValue());
    this.setUIState(UIState.Idle);
  }

  private isDeepEqual(val1: any, val2: any): boolean {
    return JSON.stringify(val1) === JSON.stringify(val2);
  }
}
