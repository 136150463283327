<div class="screen-container">
  <app-toolbar> </app-toolbar>
  <div class="container">
    <mat-tab-group *ngIf="googleMap !== undefined" class="sidebar">
      <mat-tab label="Recordings">
        <ng-template matTabContent>
          <map-recordings
            class="recordings-list"
            (onShowTrajectory)="showGeometry($event)"
          ></map-recordings>
        </ng-template>
      </mat-tab>
      <mat-tab label="Robots">
        <ng-template matTabContent>
          <mapping-robots
            class="recordings-list"
            (onShowTrajectory)="showGeometry($event)"
            (onSetOverlay)="showOverlay($event)"
          ></mapping-robots>
        </ng-template>
      </mat-tab>
      <mat-tab label="Automation">
        <ng-template matTabContent>
          <mapping-automation
            class="recordings-list"
            (onShowGeometry)="showGeometry($event)"
          ></mapping-automation>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <mat-divider vertical></mat-divider>
    <map [mapOptions]="googleMapOptions" (mapReady)="onGoogleMap($event)"></map>
  </div>
</div>
