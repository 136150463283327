import {
  DataSortingOrder,
  MlDataService,
  PAGE_SIZE,
} from '../../ml-data.service';
import { FrameDto, SnippetDto } from '../../ml-data-types';
import { PageFetcher } from './page-fetcher';

export class ConsistentRandomPageFetcher implements PageFetcher {
  private initialRandomOffset: number;
  private nextRandomOffset: number;
  private loopedAround: boolean = false;

  constructor(
    private dataService: MlDataService,
    private modality: 'snippets' | 'frames',
    private searchString: string,
  ) {
    this.initialRandomOffset = Math.random();
    this.nextRandomOffset = this.initialRandomOffset;
  }

  private composeSearchString(): string {
    let orderingFieldName: string;
    if (this.modality == 'frames') {
      orderingFieldName = 'frames.random_ordering_value';
    } else if (this.modality == 'snippets') {
      orderingFieldName = 'snippets.random_ordering_value';
    } else {
      throw Error(`Got unsupported modality: '${this.modality}'`);
    }

    let suffixCondition = '';
    if (this.loopedAround) {
      suffixCondition = `${orderingFieldName} < ${this.initialRandomOffset} AND ${orderingFieldName} > ${this.nextRandomOffset}`;
    } else {
      suffixCondition = `${orderingFieldName} > ${this.nextRandomOffset}`;
    }

    let searchString: string = suffixCondition;
    if (this.searchString.length > 0) {
      searchString = `(${this.searchString}) AND (${suffixCondition})`;
    }

    return searchString;
  }

  private loopAround() {
    this.loopedAround = true;
    this.nextRandomOffset = 0;
  }

  private async fetch(
    searchString: string,
  ): Promise<(FrameDto | SnippetDto)[]> {
    let entries: (FrameDto | SnippetDto)[];
    if (this.modality == 'frames') {
      entries = await this.dataService.getFrames(0, searchString, 'random');
    } else if (this.modality == 'snippets') {
      entries = await this.dataService.getSnippets(0, searchString, 'random');
    } else {
      throw Error(`Got unsupported modality: '${this.modality}'`);
    }

    return entries;
  }

  async step(): Promise<(FrameDto | SnippetDto)[]> {
    let entries = await this.fetch(this.composeSearchString());
    if (entries.length != 0) {
      this.nextRandomOffset = entries[entries.length - 1].randomOrderingValue;
    }

    if (!this.loopedAround && entries.length < PAGE_SIZE) {
      this.loopAround();
      const additionalItems = await this.fetch(this.composeSearchString());
      entries = entries.concat(
        additionalItems.slice(0, PAGE_SIZE - entries.length),
      );
    }

    if (entries.length != 0) {
      this.nextRandomOffset = entries[entries.length - 1].randomOrderingValue;
    }

    return entries;
  }
}
