import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { inFocus$ } from '../../../utils/page-visibility';
import {
  UserSessionInteractionEvent,
  UserSessionInteractionEventName,
  UserSessionInteractionEventPayload,
} from './user-session-interaction-events';
import {
  UserSessionSystemEvent,
  UserSessionSystemEventName,
  UserSessionSystemEventPayload,
} from './user-session-system-events';

export type UserSessionEvent =
  | UserSessionInteractionEvent<UserSessionInteractionEventName>
  | UserSessionSystemEvent<UserSessionSystemEventName>;

@Injectable({
  providedIn: 'root',
})
export class UserSessionEventTrackingService {
  private _systemEvent$ = new Subject<
    UserSessionSystemEvent<UserSessionSystemEventName>
  >();
  private _interactionEvent$ = new Subject<
    UserSessionInteractionEvent<UserSessionInteractionEventName>
  >();

  readonly systemEvent$ = this._systemEvent$.asObservable();
  readonly interactionEvent$ = this._interactionEvent$.asObservable();

  readonly events$: Observable<UserSessionEvent> = merge(
    this.systemEvent$,
    this.interactionEvent$,
  );

  constructor() {
    inFocus$.subscribe(() => {
      this.refreshLastInteractionTime();
    });
  }

  private lastInteractionTime = new Date();

  trackInteractionEvent<
    I extends UserSessionInteractionEventName = UserSessionInteractionEventName,
  >(type: I, payload?: UserSessionInteractionEventPayload[I]) {
    this._interactionEvent$.next({ type, payload });
    this.refreshLastInteractionTime();
  }

  trackSystemEvent<
    I extends UserSessionSystemEventName = UserSessionSystemEventName,
  >(type: I, payload?: UserSessionSystemEventPayload[I]) {
    this._systemEvent$.next({ type, payload });
  }

  getLastInteractionTime(): Date {
    return this.lastInteractionTime;
  }

  private refreshLastInteractionTime() {
    this.lastInteractionTime = new Date();
  }
}
