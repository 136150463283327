<div class="container">
  <div *ngFor="let awxJobTracker of history">
    <mat-card class="job-tracker-card">
      <mat-card-header>
        <mat-card-title>Update to {{ awxJobTracker.versionTag }} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          {{ awxJobTracker.status }}<mat-icon>{{ awxJobTracker.icon }}</mat-icon>
        </p>
        <p>Created: {{ awxJobTracker.createdAt | date: 'short' }}</p>
        <p>Finished: {{ awxJobTracker.finishedAt | date: 'short' }}</p>
        
        <p>Interface: {{ awxJobTracker.interfaceStrategy }}</p>
        <p>Job Template: {{ awxJobTracker.awxJobTemplate }}</p>
      </mat-card-content>
      <mat-card-actions>
        <a mat-button (click)="openAwxJobLink(awxJobTracker)">Open Job</a>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
