import { Exclude, Expose, Transform } from 'class-transformer';
import { MapElementDto } from './map-element.dto';

@Exclude()
export class MapChangesetInfoDto {
  @Expose({ name: '_id' })
  @Transform(({ obj }) => obj._id.toString(), {
    toClassOnly: false,
  })
  id!: string;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;

  @Expose()
  authorIds!: string[];

  @Expose()
  title!: string;

  @Expose()
  description!: string;

  @Expose()
  committedAt?: Date;

  @Expose()
  committedAsVersion?: number;

  @Expose()
  basedOnVersion?: number;
}

@Exclude()
export class MapChangesetDto extends MapChangesetInfoDto {
  @Expose()
  changedMapElements!: MapElementDto[];
}
