<div class="page-outer-container">
  <app-toolbar style="height: fit-content">
    <button mat-menu-item [routerLink]="'/'">Home</button>
  </app-toolbar>
  <div class="page-inner-container">
    <app-left-sidebar
      (selectCollection)="setCollection($event)"
      class="column"
    ></app-left-sidebar>
    <app-data-viewer
      *ngIf="!selectedCollection"
      class="column"
    ></app-data-viewer>
    <app-collection
      *ngIf="selectedCollection"
      [collection]="selectedCollection"
      class="column"
    >
    </app-collection>
  </div>
</div>
