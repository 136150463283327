import { inject, Injectable } from '@angular/core';
import { ErrorService } from '../error-system/error.service';
import { filter, map } from 'rxjs/operators';
import { MetricsService } from '../metrics/metrics.service';
import { UserEventService } from './user-events.service';
import {
  FAILED_ESTABLISH_CONNECTION_MSG,
  LOST_CONNECTION_MSG,
  ROBOT_CONTROL_VIEW_NAME,
  UserMessageType,
  ViewName,
} from './user-session.utils';
import { UserSessionConnectionService } from './user-session-connection.service';
import { identity } from 'rxjs';
import { LRUCache } from 'lru-cache';

const SKIPPED_ROBOT_DEBOUNCE_MILLIS = 15 * 1000;

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private readonly userSessionConnectionService = inject(
    UserSessionConnectionService,
  );
  private recentlyUnassignedRobots = new LRUCache<string, boolean>({
    ttl: SKIPPED_ROBOT_DEBOUNCE_MILLIS,
    max: 2,
  });
  readonly assignedRobots$ =
    this.userSessionConnectionService.assignedRobots$.pipe(
      map((robotIds) =>
        robotIds.filter((id) => !this.recentlyUnassignedRobots.get(id)),
      ),
    );
  readonly robotOperatorsData$ =
    this.userSessionConnectionService.robotOperatorData$;
  readonly pingCount$ = this.userSessionConnectionService.pingCount$;
  readonly resourceUpdatedEvent$ =
    this.userSessionConnectionService.resourceUpdatedEvent$;

  readonly onConnected$ =
    this.userSessionConnectionService.connection.isConnected$.pipe(
      filter(identity),
    );
  readonly sessionCollision$ =
    this.userSessionConnectionService.sessionCollision$;
  readonly robotSwapRequests$ =
    this.userSessionConnectionService.robotSwapRequests$;

  private currentViewName?: ViewName;
  private currentEnabledRobotSlotCount?: number;
  private listenRobotOperatorsData?: boolean;
  private readonly subscribedOrders: Set<string>;

  constructor(
    private readonly metricsService: MetricsService,
    private readonly errorService: ErrorService,
    private readonly userEventService: UserEventService,
  ) {
    this.subscribedOrders = new Set<string>();

    this.userEventService.userEvents$.subscribe((event) =>
      this.userSessionConnectionService.connection.send({
        type: UserMessageType.EVENT,
        ...event,
      }),
    );

    this.userSessionConnectionService.initialMessageNeeded$.subscribe(() => {
      if (this.currentViewName) {
        this.setViewName(this.currentViewName);
      }
      if (this.currentEnabledRobotSlotCount !== undefined) {
        this.setEnabledSlotCount(this.currentEnabledRobotSlotCount);
      }
      if (this.listenRobotOperatorsData) {
        this.startListenRobotOperatorsData(this.listenRobotOperatorsData);
      }
      if (this.subscribedOrders) {
        this.updateSubscribedOrders(
          Array.from(this.subscribedOrders.values()),
          [],
        );
      }
    });

    this.userSessionConnectionService.error$.subscribe((message) => {
      this.errorService.reportError(message);
    });

    this.userSessionConnectionService.checkIn$.subscribe(() => {
      const robotCount = this.metricsService.getCurrentRobotCount();
      const deltaTraveledDistance =
        this.metricsService.getTravelDistanceDeltaAndReset();
      const controllingRobotLatencyStatisticsMillis =
        this.metricsService.getControllingLatencyStatistics();

      this.userSessionConnectionService.connection.send({
        robotCount,
        deltaTraveledDistance,
        maxControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.max ?? undefined,
        minControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.min ?? undefined,
        meanControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.mean ?? undefined,
        medianControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.median ?? undefined,
        p95ControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.p95 ?? undefined,
        countControllingRobotLatencyMillis:
          controllingRobotLatencyStatisticsMillis?.count ?? undefined,
      });
    });

    this.userSessionConnectionService.pingCount$.subscribe(() => {
      // if ping happens, we can assume that the connection is alive
      this.hideConnectionErrorDialog();
    });
  }

  async goOnline() {
    await this.userSessionConnectionService.startConnection();
  }

  setViewName(viewName?: ViewName) {
    this.currentViewName = viewName;
    if (!viewName) {
      return;
    }
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.STATE_UPDATE,
      viewName,
    });

    if (ROBOT_CONTROL_VIEW_NAME.has(viewName)) {
      this.userSessionConnectionService.startRobotControlSession();
    } else {
      this.userSessionConnectionService.stopRobotControlSession();
    }
  }

  acknowledgeRobots(robotIds: string[]) {
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.ACKNOWLEDGE_ROBOTS,
      robotIds,
    });
  }

  robotConnectionEstablished(robotId: string) {
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.ROBOT_CONNECTION_ESTABLISHED,
      robotId,
    });
  }

  setEnabledSlotCount(enabledRobotSlotCount: number) {
    this.currentEnabledRobotSlotCount = enabledRobotSlotCount;
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.STATE_UPDATE,
      enabledRobotSlotCount,
    });
  }

  unassignRobots(robotIds: string[], debounce = false) {
    if (debounce) {
      for (const robotId of robotIds) {
        this.recentlyUnassignedRobots.set(robotId, true);
      }
    }
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.UNASSIGN_ROBOT,
      robotIds,
    });
  }

  applyRobotSwapRequest(currentRobotId: string, newRobotId: string) {
    this.recentlyUnassignedRobots.set(currentRobotId, true);
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.APPLY_ROBOT_SWAP_REQUEST,
      currentRobotId,
      newRobotId,
    });
  }

  startListenRobotOperatorsData(start: boolean) {
    if (start) {
      this.userSessionConnectionService.connection.send({
        type: UserMessageType.START_LISTENING_ROBOT_OPERATORS_DATA,
      });
    } else {
      this.userSessionConnectionService.connection.send({
        type: UserMessageType.STOP_LISTENING_ROBOT_OPERATORS_DATA,
      });
    }

    this.listenRobotOperatorsData = start;
  }

  unassignOperator(operatorId: string, robotIds: string[]) {
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.UNASSIGN_OPERATOR,
      operatorId,
      robotIds,
    });
  }

  private hideConnectionErrorDialog() {
    this.errorService.dismissError(LOST_CONNECTION_MSG);
    this.errorService.dismissError(FAILED_ESTABLISH_CONNECTION_MSG);
  }

  selectAccessGroups(accessGroups: string[]) {
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.STATE_UPDATE,
      selectedAccessGroups: accessGroups,
    });
  }

  selectOperatorAccessGroups(userId: string, accessGroups: string[]) {
    this.userSessionConnectionService.connection.send({
      type: UserMessageType.SELECT_OPERATOR_ACCESS_GROUPS,
      operatorId: userId,
      selectedAccessGroups: accessGroups,
    });
  }

  goOffline() {
    if (this.currentViewName) {
      this.setViewName(ViewName.IDLE);
    }

    this.currentViewName = undefined;
    this.currentEnabledRobotSlotCount = undefined;
    this.listenRobotOperatorsData = undefined;

    this.userSessionConnectionService.stopConnection();

    // unsubscribe from orders when it goes offline
    if (this.subscribedOrders) {
      this.updateSubscribedOrders(
        [],
        Array.from(this.subscribedOrders.values()),
      );
    }

    this.hideConnectionErrorDialog();
  }

  updateSubscribedOrders(newOrderIds: string[], goneOrderIds: string[]) {
    for (const id of newOrderIds) {
      this.subscribedOrders.add(id);
    }

    for (const id of goneOrderIds) {
      this.subscribedOrders.delete(id);
    }

    this.userSessionConnectionService.connection.send({
      type: UserMessageType.UPDATE_SUBSCRIBED_ORDERS,
      subscribedOrders: Array.from(this.subscribedOrders.values()),
    });
  }
}
