<div class="section-title">Waiting queues</div>

<mat-chip-set aria-label="Waiting queues" class="chips">
  <mat-chip-option
    class="remove-chip-selection-shade"
    (click)="toggleSelection('enabledQueuesSelection')"
    class="remove-chip-selection-shade"
    [selected]="selectedChip === 'enabledQueuesSelection'"
    disableRipple
  >
    Enabled - {{ numQueuesActive }}
  </mat-chip-option>

  <mat-chip-option
    class="remove-chip-selection-shade"
    (click)="toggleSelection('availableQueuesSelection')"
    [selected]="selectedChip === 'availableQueuesSelection'"
    disableRipple
  >
    Available - {{ numQueuesDisabled }}
  </mat-chip-option>

  <mat-chip-option
    class="remove-chip-selection-shade"
    (click)="toggleSelection('totalSlotsSelection')"
    [selected]="selectedChip === 'totalSlotsSelection'"
    disableRipple
  >
    Total Slots - {{ totalSlots }}
  </mat-chip-option>
  <mat-chip-option
    class="remove-chip-selection-shade"
    (click)="toggleSelection('occupiedSlotsSelection')"
    [selected]="selectedChip === 'occupiedSlotsSelection'"
    disableRipple
  >
    Occupied Slots - {{ slotsOccupied }}
  </mat-chip-option>

  <mat-chip-option
    *ngIf="activeStorageMapElement"
    class="remove-chip-selection-shade"
    (click)="toggleSelection('storageSelection')"
    [selected]="selectedChip === 'storageSelection'"
    disableRipple
  >
    Storage - {{ robotsReturningToStorage.length + robotsIdleAtStorage.length }}
  </mat-chip-option>
</mat-chip-set>

<table
  *ngIf="waitingQueueSummariesDataSource.length > 0"
  mat-table
  [dataSource]="waitingQueueSummariesDataSource"
>
  <ng-container matColumnDef="queue-name">
    <th mat-header-cell *matHeaderCellDef>Queue Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="queue-slots">
    <th mat-header-cell *matHeaderCellDef>Configured Slots</th>
    <td mat-cell *matCellDef="let element">{{ element.slots }}</td>
  </ng-container>

  <ng-container matColumnDef="occupied-slots">
    <th mat-header-cell *matHeaderCellDef>Occupied Slots</th>
    <td mat-cell *matCellDef="let element">
      {{ element.robotsComing.length + element.robotsArrived.length }}
    </td>
  </ng-container>

  <ng-container matColumnDef="robots-coming">
    <th mat-header-cell *matHeaderCellDef>Robots coming</th>
    <td mat-cell *matCellDef="let element">
      {{ element.robotsComing.length }}
    </td>
  </ng-container>

  <ng-container matColumnDef="robots-arrived">
    <th mat-header-cell *matHeaderCellDef>Robots arrived</th>
    <td mat-cell *matCellDef="let element">
      {{ element.robotsArrived.length }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedWaitingQueueColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedWaitingQueueColumns"></tr>
</table>
