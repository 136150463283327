import { fromEvent, merge } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

export function keyPressed(keyName: string) {
  const downPressed = fromEvent(window, 'keydown').pipe(
    filter((event) => (event as KeyboardEvent).code === keyName),
    mapTo(true),
  );
  const upPressed = fromEvent(window, 'keyup').pipe(
    filter((event) => (event as KeyboardEvent).code === keyName),
    mapTo(false),
  );

  return merge(downPressed, upPressed);
}
