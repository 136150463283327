<mat-form-field class="example-full-width">
  <mat-label>Operation Id</mat-label>
  <input matInput [(ngModel)]="operation.id" cdkFocusInitial />
</mat-form-field>

<mat-form-field class="example-full-width">
  <mat-label> Operation Type</mat-label>
  <mat-select placeholder="Type" [(ngModel)]="operation.operationType">
    <mat-option *ngFor="let type of availableOperationTypes" [value]="type">{{
      type
    }}</mat-option>
  </mat-select>
</mat-form-field>

<div mat-dialog-content></div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="operation" [disabled]="!operation.id">
    Create
  </button>
</div>
