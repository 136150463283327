import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  DEAD_MAN_INTERVAL_COUNT,
  DeadmanSwitchService,
} from './deadman-switch.service';
import { getRandomPositionForButton } from './utils';
import { UserSessionService } from '../../../app/core/user-session/user-session.service';
import { ErrorService } from '../../../app/core/error-system/error.service';
import { SupervisedRobotSlotsConfigService } from '../supervised-robots-slots.service';
import { Subject, take, takeUntil } from 'rxjs';
import { UserSessionEventTrackingService } from '../../../app/core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../../../app/core/user-session/user-session-interaction-events';

@Component({
  selector: 'app-deadman-switch-overlay',
  templateUrl: './deadman-switch-overlay.component.html',
  styleUrls: ['./deadman-switch-overlay.component.sass'],
})
export class DeadmanSwitchOverlayComponent implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();

  positionX = getRandomPositionForButton();
  positionY = getRandomPositionForButton();

  smileIcon: string | null = null;

  private lastCountdownValue: number | null = null;

  isStopped = false;

  private deadmanSwitchService = inject(DeadmanSwitchService);
  showCountdown$ = this.deadmanSwitchService.deadmanCountDown$;

  constructor(
    private userSessionService: UserSessionService,
    private supervisedRobotSlotsConfigService: SupervisedRobotSlotsConfigService,
    private errorService: ErrorService,
    private userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {
    this.deadmanSwitchService.deadmanCountDown$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => {
        this.lastCountdownValue = count;
        if (count === null) {
          this.positionX = getRandomPositionForButton();
          this.positionY = getRandomPositionForButton();
          this.smileIcon = null;
        } else {
          if (count > 10) {
            this.smileIcon = '😊';
          } else if (count > 5) {
            this.smileIcon = '😐';
          } else {
            this.smileIcon = '😬';
          }
        }
      });

    this.deadmanSwitchService.triggerDeadmanSwitch$
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.supervisedRobotSlotsConfigService.stopSupervision();
        this.userSessionService.goOffline();
        this.errorService.reportError(
          'Robot supervision is disabled due to inactivity. Please refresh the page to re-enable it.',
        );
        this.isStopped = true;
      });
  }

  ngOnInit(): void {
    this.deadmanSwitchService.startDeadmanSwitch();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.deadmanSwitchService.finishDeadmanSwitch();
  }

  stopDeadmanSwitch() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.DEADMAN_SWITCH_BUTTON_CLICKED,
      {
        reactionTime: DEAD_MAN_INTERVAL_COUNT - (this.lastCountdownValue ?? 0),
      },
    );
    this.deadmanSwitchService.stopDeadmanCountDown();
  }
}
