<mat-tree
  [dataSource]="treeDataSource"
  [treeControl]="treeControl"
  class="example-tree"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>
      {{ node.name }} : {{ node.message }} :
      <span class="{{ node.type }}">{{ node.type }}</span>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.name"
          (click)="toggleExpansion(node)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ node.name }}: {{ node.message }} :
        <span class="{{ node.type }}">{{ node.type }}</span>
      </div>
      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
