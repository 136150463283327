import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(milliseconds?: number): string {
    if (typeof milliseconds !== 'number') {
      return '-';
    }
    if (milliseconds < 0) {
      return `${Math.round(milliseconds / 1000)}s`;
    }
    const millisecondsInAMinute = 60 * 1000;
    const millisecondsInAnHour = 60 * millisecondsInAMinute;
    const hours = Math.floor(milliseconds / millisecondsInAnHour);
    milliseconds -= hours * millisecondsInAnHour;
    const minutes = Math.floor(milliseconds / millisecondsInAMinute);
    milliseconds -= minutes * millisecondsInAMinute;
    const seconds = Math.round(milliseconds / 1000);
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');
    if (hours > 0) {
      return `${hours}:${paddedMinutes}:${paddedSeconds}h`;
    }
    if (minutes > 0) {
      return `${minutes}:${paddedSeconds}m`;
    }
    return `${seconds}s`;
  }
}
