import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pick-label',
  templateUrl: './pick-label.component.html',
  styleUrls: ['./pick-label.component.sass'],
})
export class PickLabelComponent {
  @Input()
  isPicked: boolean = false;

  @Input()
  label: string = '';

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
