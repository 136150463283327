import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type AttentionResolver = () => boolean;
export type AttentionSource = 'crossing-confirmation' | 'robot-blocked';

@Injectable()
export class AttentionDetectionService {
  private resolvers = new Map<string, AttentionResolver>();
  public readonly state$ = new BehaviorSubject<boolean>(false);

  request(source: AttentionSource, resolver: AttentionResolver): void {
    this.resolvers.set(source, resolver);
    this.calculateState();
  }

  clear(source: AttentionSource): void {
    this.resolvers.delete(source);
    this.calculateState();
  }

  private calculateState() {
    for (const resolver of this.resolvers.values())
      if (resolver()) return this.state$.next(true);

    this.state$.next(false);
  }
}
