import {
  IsOptional,
  IsInt,
  IsPositive,
  Allow,
  IsEnum,
  IsBoolean,
  IsDate,
  IsString,
} from 'class-validator';

/* tslint:disable:max-classes-per-file */

export enum CrossingType {
  RESIDENTIAL_ROAD = 'ResidentialRoad', // Cars drive slowly and on a single lane.
  SINGLE_LANE_ROAD = 'SingleLaneRoad', // Cars drive faster than on residential roads.
  MULTI_LANE_ROAD = 'MultiLaneRoad', // Cars drive fast and on multiple lanes.
  DRIVEWAY = 'Driveway',
  BIKE_PATH = 'BikePath',
  CURB = 'Curb',
}

export class CrossingPropertiesDto {
  @IsEnum(CrossingType)
  crosses!: CrossingType;
  @Allow()
  trafficLightIds?: number[];
  @Allow()
  buttonPressRequired?: boolean;

  @Allow()
  @IsOptional()
  @IsPositive()
  estimatedCrossTrafficVelocity?: number;
}

export class RobotEdgePropertiesDto {
  @IsInt()
  startNodeId!: number;

  @IsInt()
  endNodeId!: number;

  @IsPositive()
  length!: number;

  @IsOptional()
  @IsBoolean()
  oneway?: boolean;

  @IsOptional()
  @IsPositive()
  startWidth?: number;

  @IsOptional()
  @IsPositive()
  endWidth?: number;

  @IsOptional()
  @IsPositive()
  slowDownFactor?: number;

  @Allow()
  crossing?: CrossingPropertiesDto;

  @IsOptional()
  @IsInt({ each: true })
  mutexIds?: number[];

  @IsOptional()
  @IsDate()
  blockedAt?: Date;

  @IsOptional()
  @IsDate()
  blockedUntil?: Date;

  @IsOptional()
  @IsPositive()
  maxSpeed?: number;

  @IsOptional()
  @IsBoolean()
  requiresHazardLights?: boolean;

  @IsOptional()
  @IsBoolean()
  allowUnsupervisedAutonomy?: boolean;

  @IsOptional()
  @IsInt()
  infrastructureId?: number;

  @IsOptional()
  @IsString()
  infrastructureLocationName?: string;
}

/* tslint:enable:max-classes-per-file */
