<div
  class="deadman-overlay-container"
  *ngIf="!isStopped && showCountdown$ | async as showCountdown"
>
  <div
    [ngStyle]="{ top: positionX + '%', left: positionY + '%' }"
    class="deadman-switch-button"
  >
    <div *ngIf="smileIcon">{{ smileIcon }}</div>
    Reaction time check {{ showCountdown }}
    <button mat-raised-button color="primary" (click)="stopDeadmanSwitch()">
      Click me
    </button>
  </div>
</div>
