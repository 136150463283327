import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HazardLightsState } from '../../core/robots-service/backend/robot.dto';

import { MatDialog } from '@angular/material/dialog';
import { RobotCommunication } from '../../core/robots-service/robot-communication';
import { VideoChannel } from '../../core/robots-service/webrtc/types';
import { identity } from 'ramda';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { UserSessionService } from '../../core/user-session/user-session.service';
import { UserSessionEventTrackingService } from '../../core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../../core/user-session/user-session-interaction-events';

const DROP_ROBOT_AFTER_TAKEOVER_BY_OTHER_OPERATOR_MILLIS = 10 * 1000;
const CONTROL_CLAIM_BY_OTHER_OPERATOR_ACTION_TYPE_ID =
  'robot-controlled-by-other-operator';

@Component({ template: '' })
export abstract class RobotSupervisionComponent
  implements OnDestroy, OnInit, AfterContentInit, OnChanges
{
  @Input()
  robotCommunication!: RobotCommunication;

  @Output()
  onFocusChange = new EventEmitter();

  readonly _destroy$ = new Subject<void>();
  readonly _unsubscribe$ = new Subject<void>();

  isControlledByOtherOperator = false;

  constructor(
    protected readonly router: Router,
    protected readonly authService: AuthService,
    protected readonly dialog: MatDialog,
    protected readonly userSessionService: UserSessionService,
    protected readonly userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {}

  ngAfterContentInit(): void {
    this.robotCommunication?.enableAutonomy(true);
    this.robotCommunication?.enableManualMouseControl(true);
  }

  async ngOnInit() {
    this.claimRobotControl();
    this.setVideoQuality();
    await this.resetRobotCommunicationToDefault();
  }

  async ngOnChanges(changes: SimpleChanges) {
    const robotCommunicationChange = changes['robotCommunication'];
    if (
      robotCommunicationChange?.firstChange === false &&
      robotCommunicationChange?.currentValue !==
        robotCommunicationChange?.previousValue
    ) {
      this.isControlledByOtherOperator = false;
      this._unsubscribe$.next(undefined);
      await this.ngOnInit();
      this.ngAfterContentInit();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(undefined);
    this.robotCommunication.claimRobotControl(false);
  }

  emitFocusChange() {
    this.onFocusChange.emit(false);
  }

  async triggerSnapshot() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SNAPSHOT_TRIGGER,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
    await this.robotCommunication.triggerSnapshot();
  }

  protected claimRobotControl() {
    combineLatest([
      this.robotCommunication.isInControl$,
      this.robotCommunication.controlledBy$,
      this.authService.user$,
    ])
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._unsubscribe$),
        filter(() => !this.isControlledByOtherOperator),
      )
      .subscribe({
        next: ([isInControl, controllingUser, user]) => {
          if (isInControl === false) {
            if (controllingUser === undefined) {
              this.robotCommunication.claimRobotControl(true);
              this.robotCommunication.sendClearPathCorridor();
            } else if (controllingUser?.id !== user?.id) {
              this.isControlledByOtherOperator = true;
              this.robotCommunication.requestRobotAction({
                actionDescription: 'Control was claimed by another operator',
                actionButton: 'Skip',
                actionIdType: CONTROL_CLAIM_BY_OTHER_OPERATOR_ACTION_TYPE_ID,
                onClick: () => {
                  this.robotCommunication.finalize();
                },
                onExpire: () => {
                  this.robotCommunication.finalize();
                },
                expiresAt: new Date(
                  Date.now() +
                    DROP_ROBOT_AFTER_TAKEOVER_BY_OTHER_OPERATOR_MILLIS,
                ),
              });
            }
          }
        },
      });
  }

  protected setVideoQuality() {
    this.robotCommunication.connected$
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._unsubscribe$),
        filter(identity),
      )
      .subscribe(() => {
        this.robotCommunication.sendVideoQualityRequest(
          VideoChannel.Default,
          this.isHighQualityVideo(),
        );
      });
  }

  protected async resetRobotCommunicationToDefault() {
    this.robotCommunication.sendLightingCommand(HazardLightsState.AUTO);
    await this.robotCommunication.automaticPowerSaving(false);
    await this.robotCommunication.powerSaving(false);

    await this.robotCommunication?.enableOverlayMap(false);
  }

  protected abstract isHighQualityVideo(): boolean;
}
