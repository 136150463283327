<app-toolbar>
  <button mat-menu-item [routerLink]="''">Home</button>
</app-toolbar>

<div class="robot-filters">
  <h5 style="width: 10vw">
    {{ robotsGroupedByCrossing.length }} crossings active
  </h5>
  <mat-form-field style="width: 20vw">
    <mat-label>Set min standing duration in seconds</mat-label>
    <input
      matInput
      type="number"
      placeholder="Seconds"
      [(ngModel)]="standingDuration"
      (change)="refetch()"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" class="operation-id-selection-form">
    <mat-label>Operations</mat-label>
    <mat-select
      multiple
      [(value)]="selectedOperationIds"
      (selectionChange)="refetch()"
    >
      <mat-option
        *ngFor="let operationId of operationIds | async"
        [value]="operationId"
        >{{ operationId }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>

<div class="page-center">
  <div class="map" *ngIf="focusOnCrossingId">
    <map
      [mapOptions]="googleMapOptions"
      (mapReady)="onGoogleMap($event)"
      class="inner-map"
    ></map>
  </div>
  <div class="groups-content">
    <div *ngFor="let robotGroup of robotsGroupedByCrossing">
      <div
        *ngIf="
          !focusOnCrossingId || focusOnCrossingId === robotGroup.crossingId
        "
        class="crossing"
      >
        <div class="crossing-title">
          <button
            mat-mini-fab
            color="primary"
            style="margin-left: 16px"
            (click)="emitFocusChange(robotGroup)"
            [matTooltip]="
              focusOnCrossingId
                ? 'Crossing overview'
                : 'Focus on single crossing'
            "
          >
            <mat-icon>zoom_in_map</mat-icon>
          </button>
          <h1>
            Crossing
            <a
              mat-flat-button
              href="/robots/supervise/{{ getRobotIdList(robotGroup.robots) }}"
              target="_blank"
            >
              {{ robotGroup.crossingId }}
            </a>
            at {{ robotGroup.operationId }}
            <span *ngIf="robotGroup.maxStandstillDuration > 0">
              with a max of
              {{ robotGroup.maxStandstillDuration | number: '1.0-0' }} sec
              standstill</span
            >
          </h1>
        </div>

        <div class="robots">
          <div *ngFor="let robot of robotGroup.robots">
            <div class="robot">
              <a href="/robots/supervise/{{ robot.id }}" target="_blank">
                <img
                  *ngIf="robot.robotView"
                  [src]="transform(robot.robotView)"
                  class="robot-view"
                />
              </a>
              <div class="robot-item-text">
                {{ robot.displayName }}
              </div>
              <div class="robot-item-text">
                {{ getDistanceToCrossingText(robot) }}
              </div>

              <div class="robot-item-text">
                Supervised by {{ getOperatorDisplayName(robot) }}
              </div>
              <div
                *ngIf="getStandStillDuration(robot) as standStill"
                class="robot-item-text"
              >
                Stands since {{ standStill | number: '1.0-0' }} sec
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
