import { vec2 } from '@tlaukkan/tsm';
import { ManualRobotControl } from '../../../core/robots-service/webrtc/types';
import {
  MouseDriveEvent,
  ZERO_SPEED_HEIGHT_FRACTION,
} from '../common/mouse-canvas-events';
import { Rect } from '../common/types';

export type DrawingResultMetadata = {
  boundingRect?: Rect;
};

const YELLOW = 'rgba(255,255,0,1)';
export class RobotCanvas {
  private readonly canvasContext: CanvasRenderingContext2D;

  constructor(private readonly canvas: HTMLCanvasElement) {
    this.canvasContext = this.canvas.getContext('2d')!;
  }

  private drawVideo(width: number, height: number, video: HTMLVideoElement) {
    this.canvasContext.clearRect(0, 0, width, height);
    this.canvasContext.drawImage(video, 0, 0, width, height);
  }

  private drawManualControl(manualControl: ManualRobotControl) {
    const drawSize = 100;
    const canvasRect = this.canvas.getBoundingClientRect();
    const xCenter = canvasRect.width / 2;
    const yCenter = canvasRect.height - drawSize / 2 - 2;
    const maxSpeed = 1;
    const maxTurnRate = 0.8;
    const speedScale = -drawSize / 2 / maxSpeed;
    const turnRateScale = -drawSize / 2 / maxTurnRate;

    const yellow = 'rgba(255,255,0,1)';
    this.canvasContext.save();
    // Draw background square.
    this.canvasContext.fillStyle = 'rgba(0,0,0,0.4)';
    this.canvasContext.strokeStyle = yellow;
    this.canvasContext.rect(
      xCenter - drawSize / 2,
      yCenter - drawSize / 2,
      drawSize,
      drawSize,
    );
    this.canvasContext.stroke();
    this.canvasContext.fill();
    // Draw dashed center lines.
    this.canvasContext.beginPath();
    this.canvasContext.setLineDash([5, 6]);
    this.canvasContext.moveTo(xCenter, yCenter);
    this.canvasContext.lineTo(xCenter, yCenter + drawSize / 2);
    this.canvasContext.moveTo(xCenter, yCenter);
    this.canvasContext.lineTo(xCenter, yCenter - drawSize / 2);
    this.canvasContext.moveTo(xCenter, yCenter);
    this.canvasContext.lineTo(xCenter + drawSize / 2, yCenter);
    this.canvasContext.moveTo(xCenter, yCenter);
    this.canvasContext.lineTo(xCenter - drawSize / 2, yCenter);
    this.canvasContext.stroke();
    // Draw manual control dot.
    this.canvasContext.fillStyle = yellow;
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      manualControl.turnRate * turnRateScale + xCenter,
      manualControl.speed * speedScale + yCenter,
      5,
      0,
      2 * Math.PI,
      true,
    );
    this.canvasContext.fill();
    this.canvasContext.restore();
  }

  private drawManualControlScale(
    xDivider: number,
    yDivider: number,
    width: number,
    height: number,
  ) {
    this.canvasContext.strokeStyle = YELLOW;
    this.canvasContext.beginPath();
    this.canvasContext.setLineDash([5, 6]);
    this.canvasContext.moveTo(xDivider, 0);
    this.canvasContext.lineTo(xDivider, height);
    this.canvasContext.moveTo(0, yDivider);
    this.canvasContext.lineTo(width, yDivider);
    this.canvasContext.stroke();
  }

  private drawMouseControlView(mouseDriveEvent: MouseDriveEvent) {
    const width = this.canvas.width;
    const height = this.canvas.height;
    const xDivider = width / 2;
    const yDivider = height * ZERO_SPEED_HEIGHT_FRACTION;
    // Draw quadrant divider lines.
    this.drawManualControlScale(xDivider, yDivider, width, height);

    const speedVector = mouseDriveEvent.speedVector ?? new vec2([0, 0]);
    const y = Math.min(
      (1 - speedVector.y) * ZERO_SPEED_HEIGHT_FRACTION,
      ZERO_SPEED_HEIGHT_FRACTION,
    );
    const x = (speedVector.x + 1) / 2;

    const xInPx = x * width;
    const yInPx = y * height;

    this.canvasContext.save();
    this.canvasContext.strokeStyle = YELLOW;

    // Draw line to control dot.
    this.canvasContext.beginPath();
    this.canvasContext.setLineDash([]);
    this.canvasContext.moveTo(xDivider, yDivider);
    this.canvasContext.lineTo(xInPx, yInPx);
    this.canvasContext.stroke();

    // Draw manual control dot.
    this.canvasContext.fillStyle = YELLOW;
    this.canvasContext.beginPath();
    this.canvasContext.arc(xInPx, yInPx, 8, 0, 2 * Math.PI, true);
    this.canvasContext.fill();
    this.canvasContext.restore();
  }

  draw(
    active: boolean,
    manualMouseControl: boolean,
    video: HTMLVideoElement,
    mouseDriveEvent: MouseDriveEvent,
    manualControl?: ManualRobotControl,
  ): DrawingResultMetadata {
    this.canvas.width = this.canvas.clientWidth;

    if (video.videoHeight === video.videoWidth) {
      // square video stream is not expected
      // most likely empty frame
      return {
        boundingRect: undefined,
      };
    }

    if (video.videoHeight > 0) {
      this.canvas.height =
        (video.videoHeight * this.canvas.width) / video.videoWidth;
    } else {
      this.canvas.height = this.canvas.width / 2.72;
    }

    this.drawVideo(this.canvas.width, this.canvas.height, video);

    if (active) {
      if (manualMouseControl) {
        this.drawMouseControlView(mouseDriveEvent);
      } else {
        if (manualControl) {
          this.drawManualControl(manualControl);
        }
      }
    }

    return {
      boundingRect: {
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
      },
    };
  }
}
