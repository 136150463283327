import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapChangesetInfoDto } from '@cartken/map-types';

export interface ChangeMapVersionDialogData {
  currentMapVersion?: number;
  changesets: MapChangesetInfoDto[];
}

@Component({
  selector: 'change-map-version-dialog',
  templateUrl: 'change-map-version-dialog.component.html',
})
export class ChangeMapVersionDialogComponent {
  selectedVersions: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChangeMapVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeMapVersionDialogData,
  ) {
    if (data.currentMapVersion !== undefined) {
      this.selectedVersions = [data.currentMapVersion];
    }
    data.changesets = data.changesets
      .filter((changeset) => changeset.committedAsVersion !== undefined)
      .sort((a, b) => b.committedAsVersion! - a.committedAsVersion!);
  }
}
