import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { visiblePageTimer } from '../../../utils/page-visibility';

export class Watchdog {
  private _problemDetected$: Subject<boolean> = new Subject();
  readonly problemDetected$ = this._problemDetected$.asObservable();
  private destroy$: Subject<void> = new Subject();
  private lastUpdateTimestamp?: Date;

  private isProblemDetected = false;

  constructor(private readonly deadlineIntervalMs: number) {}

  start() {
    this.lastUpdateTimestamp = new Date();
    visiblePageTimer(this.deadlineIntervalMs, this.deadlineIntervalMs)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const pollingDeadline = new Date(Date.now() - this.deadlineIntervalMs);

        if (pollingDeadline > this.lastUpdateTimestamp!) {
          this.isProblemDetected = true;
          this._problemDetected$.next(this.isProblemDetected);
        }
      });
  }

  destroy() {
    this.destroy$.next(undefined);
  }

  reset() {
    if (this.isProblemDetected) {
      this.isProblemDetected = false;
      this._problemDetected$.next(this.isProblemDetected);
    }
    this.lastUpdateTimestamp = new Date();
  }
}
