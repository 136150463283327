<div class="grid-img-container">
  <img
    class="img-placeholder"
    [class.is-selected-img-item]="isSelected"
    [src]="url"
  />
  <app-pick-icon
    *ngIf="itemType === 'frame' && isPicked !== undefined"
    (click)="pickClicked.emit(); $event.stopPropagation()"
    [isPicked]="isPicked"
    class="grid-img-picked"
  ></app-pick-icon>
  <div class="item-type-marker" *ngIf="itemType === 'frame'">F</div>
  <div class="item-type-marker" *ngIf="itemType === 'snippet'">S</div>
</div>
