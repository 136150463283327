import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.sass'],
})
export class SsoLoginComponent implements OnInit {
  returnUrl$: Observable<string | undefined>;
  form!: FormGroup;
  errorText = '';
  email = '';

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.returnUrl$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['returnUrl']),
    );

    // Navigate back to the sending page when requested role criteria are matched.
    combineLatest([this.auth.user$, this.returnUrl$]).subscribe(
      ([user, returnUrl]) => {
        if (user?.roles?.length && returnUrl) {
          this.router.navigate([returnUrl]);
        }
      },
    );
  }

  async ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        (control: AbstractControl<any, any>) => {
          if ((control.value as string).endsWith('@novonordisk.com')) {
            return null;
          }
          return { 'sso-not-linked': true };
        },
      ]),
    });

    this.form
      .get('email')
      ?.valueChanges.pipe(skip(1))
      .subscribe(() => {
        const email = this.form.get('email');
        const errors = email?.errors;
        if (errors?.['required']) {
          this.errorText = 'E-mail is required.';
        } else if (errors?.['email']) {
          this.errorText = 'Not a valid e-mail.';
        } else if (errors?.['sso-not-linked']) {
          this.errorText = 'SSO not linked, please login without SSO.';
        } else {
          this.errorText = '';
        }
        email?.markAsTouched();
      });
  }

  async onSubmit() {
    if (this.form.valid) {
      this.auth.signInWithSso(this.email);
    }
  }
}
