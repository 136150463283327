import { Component, Input } from '@angular/core';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';

export const ENABLE_AUTONOMY_FOR = 7; // seconds, might get manually configurable

@Component({
  selector: 'robot-operator-control',
  templateUrl: './robot-operator-control.component.html',
  styleUrls: ['./robot-operator-control.component.sass'],
})
export class RobotOperatorControlComponent {
  @Input()
  robotCommunication!: RobotCommunication;

  @Input()
  active = false;
}
