import { Component } from '@angular/core';
import { AttentionDetectionService } from '../attention-detection.service';
import { RobotSupervisionComponent } from './robot-supervision.component';

@Component({
  selector: 'app-focus-robot-supervision',
  templateUrl: './focus-robot-supervision.component.html',
  styleUrls: ['./focus-robot-supervision.component.sass'],
  providers: [AttentionDetectionService],
})
export class FocusRobotSupervisionComponent extends RobotSupervisionComponent {
  protected override isHighQualityVideo(): boolean {
    return true;
  }
}
