// source: slam_msgs/localization_map_tile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.slam_msgs.proto.HeightMapTile', null, global);
goog.exportSymbol('proto.slam_msgs.proto.LocalizationMapTile', null, global);
goog.exportSymbol('proto.slam_msgs.proto.LocalizationMapTile.LayerCase', null, global);
goog.exportSymbol('proto.slam_msgs.proto.PngGridLevel', null, global);
goog.exportSymbol('proto.slam_msgs.proto.PngGridMapTile', null, global);
goog.exportSymbol('proto.slam_msgs.proto.PngHeightGridLevel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.slam_msgs.proto.PngGridLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.slam_msgs.proto.PngGridLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.slam_msgs.proto.PngGridLevel.displayName = 'proto.slam_msgs.proto.PngGridLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.slam_msgs.proto.PngHeightGridLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.slam_msgs.proto.PngHeightGridLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.slam_msgs.proto.PngHeightGridLevel.displayName = 'proto.slam_msgs.proto.PngHeightGridLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.slam_msgs.proto.PngGridMapTile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.slam_msgs.proto.PngGridMapTile.repeatedFields_, null);
};
goog.inherits(proto.slam_msgs.proto.PngGridMapTile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.slam_msgs.proto.PngGridMapTile.displayName = 'proto.slam_msgs.proto.PngGridMapTile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.slam_msgs.proto.HeightMapTile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.slam_msgs.proto.HeightMapTile.repeatedFields_, null);
};
goog.inherits(proto.slam_msgs.proto.HeightMapTile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.slam_msgs.proto.HeightMapTile.displayName = 'proto.slam_msgs.proto.HeightMapTile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.slam_msgs.proto.LocalizationMapTile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_);
};
goog.inherits(proto.slam_msgs.proto.LocalizationMapTile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.slam_msgs.proto.LocalizationMapTile.displayName = 'proto.slam_msgs.proto.LocalizationMapTile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.slam_msgs.proto.PngGridLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.slam_msgs.proto.PngGridLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngGridLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelIndex: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    grid: msg.getGrid_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.slam_msgs.proto.PngGridLevel}
 */
proto.slam_msgs.proto.PngGridLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.slam_msgs.proto.PngGridLevel;
  return proto.slam_msgs.proto.PngGridLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.slam_msgs.proto.PngGridLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.slam_msgs.proto.PngGridLevel}
 */
proto.slam_msgs.proto.PngGridLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevelIndex(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGrid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.slam_msgs.proto.PngGridLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.slam_msgs.proto.PngGridLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngGridLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint32 level_index = 1;
 * @return {number}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.getLevelIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngGridLevel} returns this
 */
proto.slam_msgs.proto.PngGridLevel.prototype.setLevelIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngGridLevel} returns this
 */
proto.slam_msgs.proto.PngGridLevel.prototype.clearLevelIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.hasLevelIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes grid = 2;
 * @return {!(string|Uint8Array)}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.getGrid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes grid = 2;
 * This is a type-conversion wrapper around `getGrid()`
 * @return {string}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.getGrid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGrid()));
};


/**
 * optional bytes grid = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGrid()`
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.getGrid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGrid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.slam_msgs.proto.PngGridLevel} returns this
 */
proto.slam_msgs.proto.PngGridLevel.prototype.setGrid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngGridLevel} returns this
 */
proto.slam_msgs.proto.PngGridLevel.prototype.clearGrid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngGridLevel.prototype.hasGrid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.slam_msgs.proto.PngHeightGridLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.slam_msgs.proto.PngHeightGridLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngHeightGridLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelIndex: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    grid: msg.getGrid_asB64(),
    heightOffset: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    minHeight: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    maxHeight: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel}
 */
proto.slam_msgs.proto.PngHeightGridLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.slam_msgs.proto.PngHeightGridLevel;
  return proto.slam_msgs.proto.PngHeightGridLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.slam_msgs.proto.PngHeightGridLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel}
 */
proto.slam_msgs.proto.PngHeightGridLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevelIndex(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGrid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeightOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.slam_msgs.proto.PngHeightGridLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.slam_msgs.proto.PngHeightGridLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngHeightGridLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional uint32 level_index = 1;
 * @return {number}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getLevelIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.setLevelIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.clearLevelIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.hasLevelIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes grid = 2;
 * @return {!(string|Uint8Array)}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getGrid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes grid = 2;
 * This is a type-conversion wrapper around `getGrid()`
 * @return {string}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getGrid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGrid()));
};


/**
 * optional bytes grid = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGrid()`
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getGrid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGrid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.setGrid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.clearGrid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.hasGrid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double height_offset = 3;
 * @return {number}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getHeightOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.setHeightOffset = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.clearHeightOffset = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.hasHeightOffset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double min_height = 4;
 * @return {number}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getMinHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.setMinHeight = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.clearMinHeight = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.hasMinHeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double max_height = 5;
 * @return {number}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.getMaxHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.setMaxHeight = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel} returns this
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.clearMaxHeight = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngHeightGridLevel.prototype.hasMaxHeight = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.slam_msgs.proto.PngGridMapTile.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.toObject = function(opt_includeInstance) {
  return proto.slam_msgs.proto.PngGridMapTile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.slam_msgs.proto.PngGridMapTile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngGridMapTile.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto.slam_msgs.proto.PngGridLevel.toObject, includeInstance),
    gridCellSize: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.PngGridMapTile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.slam_msgs.proto.PngGridMapTile;
  return proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.slam_msgs.proto.PngGridMapTile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.slam_msgs.proto.PngGridLevel;
      reader.readMessage(value,proto.slam_msgs.proto.PngGridLevel.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGridCellSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.slam_msgs.proto.PngGridMapTile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.slam_msgs.proto.PngGridLevel.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * repeated PngGridLevel levels = 1;
 * @return {!Array<!proto.slam_msgs.proto.PngGridLevel>}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.slam_msgs.proto.PngGridLevel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.slam_msgs.proto.PngGridLevel, 1));
};


/**
 * @param {!Array<!proto.slam_msgs.proto.PngGridLevel>} value
 * @return {!proto.slam_msgs.proto.PngGridMapTile} returns this
*/
proto.slam_msgs.proto.PngGridMapTile.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.slam_msgs.proto.PngGridLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.slam_msgs.proto.PngGridLevel}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.slam_msgs.proto.PngGridLevel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.slam_msgs.proto.PngGridMapTile} returns this
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * optional double grid_cell_size = 2;
 * @return {number}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.getGridCellSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.PngGridMapTile} returns this
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.setGridCellSize = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.PngGridMapTile} returns this
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.clearGridCellSize = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.PngGridMapTile.prototype.hasGridCellSize = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.slam_msgs.proto.HeightMapTile.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.toObject = function(opt_includeInstance) {
  return proto.slam_msgs.proto.HeightMapTile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.slam_msgs.proto.HeightMapTile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.HeightMapTile.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto.slam_msgs.proto.PngHeightGridLevel.toObject, includeInstance),
    gridCellSize: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.slam_msgs.proto.HeightMapTile}
 */
proto.slam_msgs.proto.HeightMapTile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.slam_msgs.proto.HeightMapTile;
  return proto.slam_msgs.proto.HeightMapTile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.slam_msgs.proto.HeightMapTile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.slam_msgs.proto.HeightMapTile}
 */
proto.slam_msgs.proto.HeightMapTile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.slam_msgs.proto.PngHeightGridLevel;
      reader.readMessage(value,proto.slam_msgs.proto.PngHeightGridLevel.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGridCellSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.slam_msgs.proto.HeightMapTile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.slam_msgs.proto.HeightMapTile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.HeightMapTile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.slam_msgs.proto.PngHeightGridLevel.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * repeated PngHeightGridLevel levels = 1;
 * @return {!Array<!proto.slam_msgs.proto.PngHeightGridLevel>}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.slam_msgs.proto.PngHeightGridLevel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.slam_msgs.proto.PngHeightGridLevel, 1));
};


/**
 * @param {!Array<!proto.slam_msgs.proto.PngHeightGridLevel>} value
 * @return {!proto.slam_msgs.proto.HeightMapTile} returns this
*/
proto.slam_msgs.proto.HeightMapTile.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.slam_msgs.proto.PngHeightGridLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.slam_msgs.proto.PngHeightGridLevel}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.slam_msgs.proto.PngHeightGridLevel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.slam_msgs.proto.HeightMapTile} returns this
 */
proto.slam_msgs.proto.HeightMapTile.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * optional double grid_cell_size = 2;
 * @return {number}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.getGridCellSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.slam_msgs.proto.HeightMapTile} returns this
 */
proto.slam_msgs.proto.HeightMapTile.prototype.setGridCellSize = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.slam_msgs.proto.HeightMapTile} returns this
 */
proto.slam_msgs.proto.HeightMapTile.prototype.clearGridCellSize = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.HeightMapTile.prototype.hasGridCellSize = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.slam_msgs.proto.LocalizationMapTile.LayerCase = {
  LAYER_NOT_SET: 0,
  STEEPNESS_MAP_TILE: 1,
  SEMANTIC_MAP_TILE: 2,
  HEIGHT_MAP_TILE: 3,
  COLOR_MAP_TILE: 4,
  DIFF_MAP_TILE: 5
};

/**
 * @return {proto.slam_msgs.proto.LocalizationMapTile.LayerCase}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getLayerCase = function() {
  return /** @type {proto.slam_msgs.proto.LocalizationMapTile.LayerCase} */(jspb.Message.computeOneofCase(this, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.toObject = function(opt_includeInstance) {
  return proto.slam_msgs.proto.LocalizationMapTile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.slam_msgs.proto.LocalizationMapTile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.LocalizationMapTile.toObject = function(includeInstance, msg) {
  var f, obj = {
    steepnessMapTile: (f = msg.getSteepnessMapTile()) && proto.slam_msgs.proto.PngGridMapTile.toObject(includeInstance, f),
    semanticMapTile: (f = msg.getSemanticMapTile()) && proto.slam_msgs.proto.PngGridMapTile.toObject(includeInstance, f),
    heightMapTile: (f = msg.getHeightMapTile()) && proto.slam_msgs.proto.HeightMapTile.toObject(includeInstance, f),
    colorMapTile: (f = msg.getColorMapTile()) && proto.slam_msgs.proto.PngGridMapTile.toObject(includeInstance, f),
    diffMapTile: (f = msg.getDiffMapTile()) && proto.slam_msgs.proto.PngGridMapTile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.slam_msgs.proto.LocalizationMapTile;
  return proto.slam_msgs.proto.LocalizationMapTile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.slam_msgs.proto.LocalizationMapTile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.slam_msgs.proto.PngGridMapTile;
      reader.readMessage(value,proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader);
      msg.setSteepnessMapTile(value);
      break;
    case 2:
      var value = new proto.slam_msgs.proto.PngGridMapTile;
      reader.readMessage(value,proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader);
      msg.setSemanticMapTile(value);
      break;
    case 3:
      var value = new proto.slam_msgs.proto.HeightMapTile;
      reader.readMessage(value,proto.slam_msgs.proto.HeightMapTile.deserializeBinaryFromReader);
      msg.setHeightMapTile(value);
      break;
    case 4:
      var value = new proto.slam_msgs.proto.PngGridMapTile;
      reader.readMessage(value,proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader);
      msg.setColorMapTile(value);
      break;
    case 5:
      var value = new proto.slam_msgs.proto.PngGridMapTile;
      reader.readMessage(value,proto.slam_msgs.proto.PngGridMapTile.deserializeBinaryFromReader);
      msg.setDiffMapTile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.slam_msgs.proto.LocalizationMapTile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.slam_msgs.proto.LocalizationMapTile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.slam_msgs.proto.LocalizationMapTile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSteepnessMapTile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter
    );
  }
  f = message.getSemanticMapTile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter
    );
  }
  f = message.getHeightMapTile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.slam_msgs.proto.HeightMapTile.serializeBinaryToWriter
    );
  }
  f = message.getColorMapTile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter
    );
  }
  f = message.getDiffMapTile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.slam_msgs.proto.PngGridMapTile.serializeBinaryToWriter
    );
  }
};


/**
 * optional PngGridMapTile steepness_map_tile = 1;
 * @return {?proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getSteepnessMapTile = function() {
  return /** @type{?proto.slam_msgs.proto.PngGridMapTile} */ (
    jspb.Message.getWrapperField(this, proto.slam_msgs.proto.PngGridMapTile, 1));
};


/**
 * @param {?proto.slam_msgs.proto.PngGridMapTile|undefined} value
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
*/
proto.slam_msgs.proto.LocalizationMapTile.prototype.setSteepnessMapTile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.clearSteepnessMapTile = function() {
  return this.setSteepnessMapTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.hasSteepnessMapTile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PngGridMapTile semantic_map_tile = 2;
 * @return {?proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getSemanticMapTile = function() {
  return /** @type{?proto.slam_msgs.proto.PngGridMapTile} */ (
    jspb.Message.getWrapperField(this, proto.slam_msgs.proto.PngGridMapTile, 2));
};


/**
 * @param {?proto.slam_msgs.proto.PngGridMapTile|undefined} value
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
*/
proto.slam_msgs.proto.LocalizationMapTile.prototype.setSemanticMapTile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.clearSemanticMapTile = function() {
  return this.setSemanticMapTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.hasSemanticMapTile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HeightMapTile height_map_tile = 3;
 * @return {?proto.slam_msgs.proto.HeightMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getHeightMapTile = function() {
  return /** @type{?proto.slam_msgs.proto.HeightMapTile} */ (
    jspb.Message.getWrapperField(this, proto.slam_msgs.proto.HeightMapTile, 3));
};


/**
 * @param {?proto.slam_msgs.proto.HeightMapTile|undefined} value
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
*/
proto.slam_msgs.proto.LocalizationMapTile.prototype.setHeightMapTile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.clearHeightMapTile = function() {
  return this.setHeightMapTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.hasHeightMapTile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PngGridMapTile color_map_tile = 4;
 * @return {?proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getColorMapTile = function() {
  return /** @type{?proto.slam_msgs.proto.PngGridMapTile} */ (
    jspb.Message.getWrapperField(this, proto.slam_msgs.proto.PngGridMapTile, 4));
};


/**
 * @param {?proto.slam_msgs.proto.PngGridMapTile|undefined} value
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
*/
proto.slam_msgs.proto.LocalizationMapTile.prototype.setColorMapTile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.clearColorMapTile = function() {
  return this.setColorMapTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.hasColorMapTile = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PngGridMapTile diff_map_tile = 5;
 * @return {?proto.slam_msgs.proto.PngGridMapTile}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.getDiffMapTile = function() {
  return /** @type{?proto.slam_msgs.proto.PngGridMapTile} */ (
    jspb.Message.getWrapperField(this, proto.slam_msgs.proto.PngGridMapTile, 5));
};


/**
 * @param {?proto.slam_msgs.proto.PngGridMapTile|undefined} value
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
*/
proto.slam_msgs.proto.LocalizationMapTile.prototype.setDiffMapTile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.slam_msgs.proto.LocalizationMapTile.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.slam_msgs.proto.LocalizationMapTile} returns this
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.clearDiffMapTile = function() {
  return this.setDiffMapTile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.slam_msgs.proto.LocalizationMapTile.prototype.hasDiffMapTile = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.slam_msgs.proto);
