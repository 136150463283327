import { Type } from 'class-transformer';
import {
  ArrayMinSize,
  IsDefined,
  IsArray,
  IsBoolean,
  IsInt,
  IsOptional,
  Min,
} from 'class-validator';
import { MapElementTransientDataDto } from './map-element-transient-data.dto';

export class UpsertMapElementTransientDataDto {
  @IsDefined()
  @IsArray()
  @ArrayMinSize(1)
  @IsInt({ each: true })
  upsertedIds!: number[];

  @IsOptional()
  @IsInt()
  @Min(0)
  setNumDesiredAcquisitions?: number;

  @IsOptional()
  @IsBoolean()
  removeRunningAcquisitions?: boolean;
}
