<div
  class="unsupervised-autonomy-container"
  *ngIf="unsupervisedAutonomyAvailable"
>
  <div class="robot-icon-background">
    <mat-icon
      class="robot-icon"
      [class.blinking-border]="unsupervisedAutonomyActive"
    >
      smart_toy
    </mat-icon>
    <div class="text">
      {{ remainingUnsupervisedAutonomyDistanceText }}
    </div>
  </div>
</div>
