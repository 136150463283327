import { IsInt, IsPositive } from 'class-validator';

export class RoadEdgePropertiesDto {
  @IsInt()
  startNodeId!: number;

  @IsInt()
  endNodeId!: number;

  @IsPositive()
  length!: number;

  @IsPositive()
  estimatedDuration!: number; // seconds
}
