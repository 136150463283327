<div class="canvas-container">
  <action-notification
    style="display: contents"
    [robotCommunication]="robotCommunication"
  ></action-notification>
  <mini-map
    *ngIf="
      (robotCommunication?.connected$ | async) &&
      (robotCommunication?.isOverlayMapEnabled$ | async)
    "
    class="mini-map-container"
    [robotCommunication]="robotCommunication"
    [shouldRotate]="true"
    [shouldFollow]="true"
    [isCustomGpsClickEnabled]="false"
    [isDirectionsClickEnabled]="false"
    [shouldShowMapProviderSelection]="false"
    [shouldShowMiniMapActions]="false"
    [shouldShowZoomControl]="false"
    [shouldUIButtonsBeEnables]="false"
    [shouldShowPrecisePositionWarning]="false"
    [refreshTrigger]="active && robotCommunication?.connected$ | async"
  >
  </mini-map>

  <robot-video
    [active]="activeControl"
    [manualMouseControl]="robotCommunication?.isManualMouseControl$ | async"
    [robotStateReport]="robotStateReport$ | async"
    [userClaimReport]="userClaimReport$ | async"
    [attentionStatusList]="attentionStatusList"
    [statusStrings]="statusStrings$ | async"
    [manualControl]="manualControl$ | async"
    [robotState]="robotCommunication?.robotState$ | async"
    [connectionStats]="robotCommunication?.stats$ | async"
    [robotAttentionReport]="robotCommunication?.robotAttentionReport$ | async"
    [video]="robotCommunication?.videoElement"
    [isCharging]="isBatteryCharging$ | async"
    [batteryPercentage]="batteryPercentage$ | async"
    [supervisionState]="supervisionState$ | async"
    [operationEmergencyStopActive]="operationEmergencyStopActive$ | async"
    (mouseDriveEvent)="updateManualControl($event)"
    (lastMouseRelativePosition)="updateLastMouseRelativePosition($event)"
    (onStatusClick)="hideStatus($event)"
    class="robot-video"
  ></robot-video>
  <div class="status-bar">
    <ng-container *ngIf="robotCommunication?.robotState$ | async as robotState">
      <app-robot-issues-widget
        *ngIf="robotState.issues?.length"
        [issues]="robotState.issues ?? []"
        style="display: contents"
      ></app-robot-issues-widget>
    </ng-container>

    <mat-icon
      class="order-icon"
      *ngIf="robotCommunication?.isServingOrder$ | async"
      >shopping_basket</mat-icon
    >

    <div class="order-info">
      <order-overlay-info [order]="robotCommunication?.orders$ | async">
      </order-overlay-info>
    </div>

    <app-robot-blocking
      [blockedByCurrentUserForMillis]="
        robotCommunication?.isBlockedForMillis$ | async
      "
    ></app-robot-blocking>

    <mat-icon class="order-icon" *ngIf="microphoneEnabled$ | async"
      >mic</mat-icon
    >
    <app-unsupervised-autonomy-interaction
      [state]="robotCommunication?.unsupervisedAutonomyState$ | async"
    ></app-unsupervised-autonomy-interaction>
  </div>
  <div class="action-bar">
    <app-corridor-confirmation
      *ngIf="routeCorridorConfirmationState$ | async"
      [state]="routeCorridorConfirmationState$ | async"
      (confirmRouteCorridorEdgeId)="confirmRouteCorridorEdgeId($event)"
      (revokeRouteCorridorConfirmation)="
        revokeRouteCorridorConfirmation($event)
      "
    ></app-corridor-confirmation>
    <app-arrive-at-route-end
      [distanceToEndOfRoute]="robotCommunication?.routeToEndDistance$ | async"
      (arrive)="arriveAtStop()"
    >
    </app-arrive-at-route-end>

    <app-infrastructure-transaction
      [robotState]="robotCommunication?.robotState$ | async"
      (abortInfrastructureTransaction)="abortInfrastructureTransaction()"
    >
    </app-infrastructure-transaction>
  </div>
  <div *ngIf="overlayIcon" class="icon-overlay-container">
    <mat-icon class="icon-overlay">{{ overlayIcon }} </mat-icon>
  </div>
  <div class="robot-loading" *ngIf="isConnecting$ | async">
    <mat-spinner></mat-spinner>
  </div>
</div>
