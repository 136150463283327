import { firstValueFrom, retry } from 'rxjs';
import { BackendService } from '../../backend.service';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';
import { ErrorService } from '../../error-system/error.service';
import { UserSessionInteractionEventName } from '../../user-session/user-session-interaction-events';

export class CompartmentManager {
  constructor(
    private robotId: string,
    private backendService: BackendService,
    private userInteractionsTrackingService: UserSessionEventTrackingService,
    private errorService: ErrorService,
  ) {}

  async openCompartment(compartmentId: string): Promise<boolean> {
    try {
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.COMPARTMENT_LOCK_STATUS_UPDATE,
        {
          robotId: this.robotId,
          isLocked: false,
          compartmentId: compartmentId,
        },
      );
      await firstValueFrom(
        this.backendService
          .post(`/robots/${this.robotId}/open-compartment/${compartmentId}`, {})
          .pipe(retry(5)),
      );
      return true;
    } catch (e) {
      console.error('Open Compartment failed', e);
      this.errorService.reportError(
        `Failed to open compartment ${compartmentId}`,
      );
      return false;
    }
  }

  async closeCompartment(compartmentId: string): Promise<boolean> {
    try {
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.COMPARTMENT_LOCK_STATUS_UPDATE,
        {
          robotId: this.robotId,
          isLocked: true,
          compartmentId: compartmentId,
        },
      );
      await firstValueFrom(
        this.backendService
          .post(
            `/robots/${this.robotId}/close-compartment/${compartmentId}`,
            {},
          )
          .pipe(retry(5)),
      );
      return true;
    } catch (e) {
      console.error('Close Compartment failed', e);
      this.errorService.reportError(
        `Failed to close compartment ${compartmentId}`,
      );
      return false;
    }
  }
}
