import {
  IsDefined,
  IsInt,
  IsOptional,
  IsEnum,
  IsBoolean,
} from 'class-validator';
import { AprilTagPropertiesDto } from './april-tag-properties.dto';
import { LineStringGeometryDto } from './linestring-geometry.dto';
import { PointGeometryDto } from './point-geometry.dto';
import { PolePropertiesDto } from './pole-properties.dto';
import { PolygonGeometryDto } from './polygon-geometry.dto';
import { RoadEdgePropertiesDto } from './road-edge-properties.dto';
import { RobotEdgePropertiesDto } from './robot-edge-properties.dto';
import { RobotQueueEdgePropertiesDto } from './robot-queue-edge-properties.dto';
import { HandoverLocationPropertiesDto } from './handover-location-properties.dto';
import { OperationRegionPropertiesDto } from './operation-region-properties.dto';
import { Exclude, Expose } from 'class-transformer';
import { SlippyTilesPropertiesDto } from './slippy-tiles-properties.dto';
import { LocalizationMapTilesPropertiesDto } from './localization-map-tiles-properties.dto';
import { InfrastructurePropertiesDto } from './infrastructure-properties.dto';
import { MovablePlatformEdgePropertiesDto } from './movable-platform-edge-properties.dto';

export enum ElementType {
  NODE = 'Node',
  ROBOT_EDGE = 'RobotEdge',
  ROBOT_QUEUE_EDGE = 'RobotQueueEdge',
  ROAD_EDGE = 'RoadEdge',
  CACHED_ROAD_EDGE = 'CachedRoadEdge',
  TRAFFIC_LIGHT = 'TrafficLight',
  APRIL_TAG = 'AprilTag',
  POLE = 'Pole',
  OPERATION_REGION = 'OperationRegion',
  HANDOVER_LOCATION = 'HandoverLocation',
  MUTEX = 'Mutex',
  SLIPPY_TILES = 'SlippyTiles',
  LOCALIZATION_MAP_TILES = 'LocalizationMapTiles',
  INFRASTRUCTURE_EDGE = 'InfrastructureEdge', // Uses RobotEdgeProperties
  INFRASTRUCTURE_WAITING_EDGE = 'InfrastructureWaitingEdge', // Uses RobotEdgeProperties
  INFRASTRUCTURE = 'Infrastructure',
  MOVABLE_PLATFORM_EDGE = 'MovablePlatformEdge',
}

@Exclude() // Exclude everything not explicitly exposed.
export class MapElementDto {
  @Expose()
  @IsInt()
  id!: number;

  @Expose()
  @IsInt()
  version!: number;

  @Expose()
  @IsEnum(ElementType)
  elementType!: ElementType;

  @Expose()
  @IsOptional()
  @IsBoolean()
  deleted?: boolean;

  @Expose()
  @IsOptional()
  properties?:
    | AprilTagPropertiesDto
    | PolePropertiesDto
    | RobotEdgePropertiesDto
    | RobotQueueEdgePropertiesDto
    | RoadEdgePropertiesDto
    | OperationRegionPropertiesDto
    | HandoverLocationPropertiesDto
    | SlippyTilesPropertiesDto
    | LocalizationMapTilesPropertiesDto
    | MovablePlatformEdgePropertiesDto
    | InfrastructurePropertiesDto;

  @Expose()
  @IsDefined()
  geometry!: PointGeometryDto | LineStringGeometryDto | PolygonGeometryDto;
}
