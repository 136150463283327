import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../app/core/auth.service';
import { UserSessionService } from '../../../app/core/user-session/user-session.service';
import { BackendService } from '../../../app/core/backend.service';

@Component({
  selector: 'app-supervision-settings-dialog',
  templateUrl: './supervision-settings.component.html',
  styleUrls: ['./supervision-settings.component.sass'],
})
export class SupervisionSettingsDialog {
  accessGroups: string[] = [];
  selectedAccessGroups: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<void, void>,
    private authService: AuthService,
    private userSessionService: UserSessionService,
    private backendService: BackendService,
  ) {
    this.initUserState();
  }

  async initUserState() {
    const authUser = await firstValueFrom(this.authService.user$);
    const user = await firstValueFrom(
      this.backendService.get(`/users/${authUser?.uid}`),
    );
    if (user === undefined) {
      return;
    }

    this.accessGroups = user.accessGroups;

    this.selectedAccessGroups = user.session?.selectedAccessGroups ?? [];
  }

  selectAccessGroup() {
    this.userSessionService.selectAccessGroups(this.selectedAccessGroups);
  }
}
