import { Injectable } from '@angular/core';

export const MAP_PROVIDERS = {
  'ESRI: Satellite': 'Esri.WorldImagery',
  'ESRI: Topography': 'Esri.WorldTopoMap',
  'OSM: Mapnik': 'OpenStreetMap.Mapnik',
  'OSM: HOT': 'OpenStreetMap.HOT',
  'CartoDB: Positron': 'CartoDB.Positron',
  'CartoDB: Voyager': 'CartoDB.Voyager',
};

export type MapProvider = keyof typeof MAP_PROVIDERS;
const MAP_PROVIDER_KEYS = Object.keys(MAP_PROVIDERS) as MapProvider[];

const COMPONENT_LOCAL_STORAGE_PREFIX = 'leaflet-map-state';
const CURRENT_MAP_PROVIDER = `${COMPONENT_LOCAL_STORAGE_PREFIX}_map-provider`;

@Injectable()
export class LeafletMapStateService {
  setMapProvider(mapProvider: MapProvider) {
    localStorage.setItem(CURRENT_MAP_PROVIDER, JSON.stringify(mapProvider));
  }

  getMapProvider(): MapProvider {
    const mapProviderString = localStorage.getItem(CURRENT_MAP_PROVIDER);
    const mapProvider = JSON.parse(mapProviderString!) as MapProvider;

    return MAP_PROVIDER_KEYS.includes(mapProvider)
      ? mapProvider
      : 'ESRI: Satellite';
  }
}
