import { Injectable } from '@angular/core';

export const MAP_PROVIDERS = {
  'ESRI: Satellite': 'Esri.WorldImagery',
  'ESRI: Topography': 'Esri.WorldTopoMap',
  'OSM: Mapnik': 'OpenStreetMap.Mapnik',
  'OSM: HOT': 'OpenStreetMap.HOT',
  'CartoDB: Positron': 'CartoDB.Positron',
  'CartoDB: Voyager': 'CartoDB.Voyager',
};

export type MapProvider = keyof typeof MAP_PROVIDERS;
const MAP_PROVIDER_KEYS = Object.keys(MAP_PROVIDERS) as MapProvider[];

const COMPONENT_LOCAL_STORAGE_PREFIX = 'min-map-state';

const SHOULD_ROTATE = `${COMPONENT_LOCAL_STORAGE_PREFIX}_should-rotate`;
const SHOULD_FOLLOW = `${COMPONENT_LOCAL_STORAGE_PREFIX}_should-follow`;

@Injectable()
export class MiniMapStateService {
  setShouldFollow(shouldFollow: boolean) {
    localStorage.setItem(SHOULD_FOLLOW, JSON.stringify(shouldFollow));
  }
  getShouldFollow(): boolean {
    const shouldFollowString = localStorage.getItem(SHOULD_FOLLOW);
    if (shouldFollowString === null) {
      return false;
    }
    return JSON.parse(shouldFollowString);
  }

  setShouldRotate(shouldRotate: boolean) {
    localStorage.setItem(SHOULD_ROTATE, JSON.stringify(shouldRotate));
  }

  getShouldRotate(): boolean {
    const shouldRotateString = localStorage.getItem(SHOULD_ROTATE);
    if (shouldRotateString === null) {
      return false;
    }
    return JSON.parse(shouldRotateString);
  }
}
