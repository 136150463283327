export enum OrderStatus {
  CREATED = 'Created',
  ASSIGNED = 'Assigned',
  DRIVING_TO_HANDOVER = 'DrivingToHandover',
  WAITING_FOR_HANDOVER = 'WaitingForHandover',
  FULFILLED = 'Fulfilled',
  FAILED = 'Failed',
  CANCELED = 'Canceled',
  REJECTED = 'Rejected',
}

export const INITIAL_ORDER_STATUSES = [
  OrderStatus.CREATED,
  OrderStatus.ASSIGNED,
];

export const IN_PROGRESS_ORDER_STATUSES = [
  OrderStatus.ASSIGNED,
  OrderStatus.DRIVING_TO_HANDOVER,
  OrderStatus.WAITING_FOR_HANDOVER,
];

export const ACTIVE_ORDER_STATUSES = [
  OrderStatus.CREATED,
  ...IN_PROGRESS_ORDER_STATUSES,
];

export const FINAL_ORDER_STATUSES = [
  OrderStatus.FULFILLED,
  OrderStatus.FAILED,
  OrderStatus.CANCELED,
];

export enum HandoverType {
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
  DISPOSAL = 'disposal',
}

export enum CommunicationLogEntryType {
  INBOUND = 'Inbound',
  ORDER_STATUS_UPDATE = 'OrderStatusUpdate',
  ORDER_STATUS_UPDATE_REMINDER = 'OrderStatusUpdateReminder',
  SEND_FAILURE = 'SendFailure',
}

export enum CommunicationDeliveryStatus {
  SENDING = 'Sending',
  SENT = 'Sent',
  DELIVERED = 'Delivered',
  FAILED = 'Failed',
}

export enum PhoneCallStatus {
  QUEUED = 'Queued',
  RINGING = 'Ringing',
  IN_PROGRESS = 'InProgress',
  BUSY = 'Busy',
  FAILED = 'Failed',
  NO_ANSWER = 'NoAnswer',
  COMPLETED = 'Completed',
}

export enum RejectionReason {
  CONSTRAINT_VIOLATION = 'ConstraintViolation',
  INVALID_ORDER_DATA = 'InvalidOrderData',
  NO_ROBOT_AVAILABLE = 'NoRobotAvailable',
  OTHER = 'Other',
}

export enum CommunicationMedium {
  SMS = 'Sms',
  PHONE_CALL = 'PhoneCall',
  EMAIL = 'Email',
}

export interface CreateHandoverDto {
  handoverType?: HandoverType;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  address?: string;
  email?: string;
  emailNotifications?: boolean;
  phone?: string;
  phoneNotifications?: boolean;
  phoneCallNotifications?: boolean;
  latitude?: number;
  longitude?: number;
  comments?: string;
  locationId?: string;
  pinCode?: string;
  noEarlierThan?: Date;
  noLaterThan?: Date;
}

export class CreateOrderDto {
  operationId!: string;
  externalId?: string;
  displayName?: string;
  pickupHandover!: CreateHandoverDto;
  dropoffHandover!: CreateHandoverDto;
  pickupExpectedReadyAt?: Date;
  webhookUrl?: string;
  testOrder?: boolean;
  assignedRobotId?: string;
  assignedCompartmentId?: string;
  pickupImmediately?: boolean;
}

export interface Handover {
  handoverType: HandoverType;
  displayName?: string;
  latitude: number;
  longitude: number;
  originalLatitude?: number;
  originalLongitude?: number;
  maxLocationRefinementDistance?: number;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  address?: string;
  phone?: string;
  comments?: string;
  locationId?: string;
  pinCode?: number;
  noEarlierThan?: string;
  noLaterThan?: string;
  estimatedArrivalTime?: string;
  initialEstimatedArrivalTime?: string;
  requestedAt?: string;
}

export interface OrderStatusLogEntry {
  timestamp: string;
  status: OrderStatus;
  handoverIndex: number;
  userId?: string;
  userDisplayName?: string;
  comment?: string;
}

export interface CommunicationLogEntry {
  timestamp: string;
  type: CommunicationLogEntryType;
  message: string;
  orderStatus?: OrderStatus;
  handoverIndex?: number;
  messageId?: string;
  messageDeliveryStatus?: CommunicationDeliveryStatus;
  phoneCallStatus?: PhoneCallStatus;
  updatedAt?: string;
  communicationMedium?: CommunicationMedium;
}

export interface Order {
  id: string;
  operationId?: string;
  assignedRobotId?: string;
  assignedRobotName?: string;
  assignedRobotColor?: string;
  externalId?: string;
  displayName?: string;
  trackingCode: string;
  status: OrderStatus;
  handovers: Handover[];
  currentHandoverIndex: number;
  actionRequired?: boolean;
  failureReason?: string;
  rejectionReason?: RejectionReason;
  rejectionDescription?: string;
  latitude?: number;
  longitude?: number;
  webhookUrl?: string;
  statusLog: OrderStatusLogEntry[];
  communicationLog: CommunicationLogEntry[];
  robotRoute?: number[][];
  testOrder?: boolean;
  assignedCompartmentId?: string;
  compartmentLocked?: boolean;
  compartmentClosed?: boolean;
}
