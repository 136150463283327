import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { SupervisedRobotsComponent } from './supervised-robots.component';
import { RobotsModule } from '../robots/robots.module';
import { RobotPlaceholderComponent } from './robot-placeholder/robot-placeholder.component';
import { RobotOperationModule } from '../robots/robot-operation/robot-operation.module';
import { MiniSlotRobotSupervisionComponent } from './robot-supervision/mini-slot-robot-supervision.component';
import { FocusRobotSupervisionComponent } from './robot-supervision/focus-robot-supervision.component';
import { OptionalStatusTreeComponent } from './optional-status-tree/optional-status-tree.component';
import { SupervisedRobotSlotsConfigService } from './supervised-robots-slots.service';
import { SupervisionSettingsService } from './supervision-settings/supervision-settings.service';
import { SupervisionSettingsDialog } from './supervision-settings/supervision-settings.component';
import { EnableExtraSlotsService } from './enable-extra-slots.service';
import { DeadmanSwitchService } from './deadman-switch/deadman-switch.service';
import { DeadmanSwitchOverlayComponent } from './deadman-switch/deadman-switch-overlay.component';
import { SkipRobotDialog } from './robot-supervision/skip-robot-dialog.component';
import { PipesModule } from '../core/pipes/pipes.module';

@NgModule({
  imports: [
    CoreModule,
    RouterModule,
    RobotsModule,
    RobotOperationModule,
    PipesModule,
  ],
  declarations: [
    SupervisedRobotsComponent,
    RobotPlaceholderComponent,
    MiniSlotRobotSupervisionComponent,
    FocusRobotSupervisionComponent,
    OptionalStatusTreeComponent,
    SupervisionSettingsDialog,
    DeadmanSwitchOverlayComponent,
    SkipRobotDialog,
    MiniSlotRobotSupervisionComponent,
    FocusRobotSupervisionComponent,
  ],
  providers: [
    SupervisedRobotSlotsConfigService,
    SupervisionSettingsService,
    EnableExtraSlotsService,
    DeadmanSwitchService,
  ],
})
export class SupervisedRobotsModule {}
