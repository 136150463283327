<div class="main-container">
  <div class="controls-container">
    <div class="map-and-control-grid">
      <div class="robot-control-container">
        <robot-control
          [isKeyboardEnabled]="true"
          [robotCommunication]="robotCommunication"
          [activeControl]="true"
          [highResolution]="true"
        ></robot-control>
      </div>
      <mini-map
        [isCustomGpsClickEnabled]="false"
        [isDirectionsClickEnabled]="false"
        [robotCommunication]="robotCommunication"
      ></mini-map>
    </div>

    <div class="controls">
      <app-robot-control-panel
        class="controls-panel"
        [robotCommunication]="robotCommunication"
        [isClaimControlEnabled]="false"
        [isManualControlEnabled]="false"
        [autonomyControlType]="'PARKING_MODE'"
        [hazardLightControlType]="'HAZARD_LIGHT_TOGGLE'"
        [isNavigationToMaintenanceEnabled]="true"
        [isCurbClimbingControlEnabled]="true"
        [isReadyForOrderControlEnabled]="false"
        [isIssueReportingEnabled]="true"
        [active]="true"
        [isRelocalizeEnabled]="true"
        [isCalibrateEndstopEnabled]="true"
        [isNetworkInterfaceControlEnabled]="true"
        [isBlockageReportEnabled]="true"
        [isOverlayMapControlEnabled]="false"
        [isHandlingSnapshotHotkeyEnabled]="true"
      >
      </app-robot-control-panel>
      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="emitFocusChange()"
        [matTooltip]="'Control multiple robots'"
      >
        <mat-icon>zoom_out_map</mat-icon>
      </button>
    </div>
  </div>

  <div class="status-tree-container">
    <optional-status-tree
      [robotCommunication]="robotCommunication"
    ></optional-status-tree>
  </div>
</div>
