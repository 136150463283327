import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class MappingDataAcquisitionInfoDto {
  @Expose()
  numDesiredAcquisitions?: number;

  @Expose()
  numRunningAcquisitions?: number;

  @Expose()
  acquiringRobotIds?: string[];
}
