import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Role } from '../../core/user';
import { BackendService } from '../../../app/core/backend.service';

export interface CreateUserData {
  username: string;
  password: string;
  displayName: string;
  accessGroups: string[];
  roles: string[];
}

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.sass'],
})
export class CreateUserDialogComponent {
  user: CreateUserData;
  availableRoles: string[];
  allOperationAccessGroups: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
    private backendService: BackendService,
  ) {
    this.availableRoles = Object.values(Role);
    this.user = {
      username: '',
      password: '',
      displayName: '',
      roles: [],
      accessGroups: [],
    };

    this.backendService
      .get<string[]>('/operations/access-groups')
      .subscribe((accessGroups: string[]) => {
        this.allOperationAccessGroups = accessGroups;
      });
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
