import { MapElementDto } from './map-element.dto';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class MapChangesetConflictDto {
  @Expose()
  changesetMapElement!: MapElementDto;

  @Expose()
  latestMapElement!: MapElementDto;
}
