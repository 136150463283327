<div class="confirmation-container">
  <p>
    {{ message }}
  </p>
  <div class="buttons">
    <button mat-raised-button class="cancel" (click)="onCancelClick()">
      <mat-icon>close</mat-icon> Cancel
    </button>
    <button mat-raised-button class="confirm" (click)="onConfirmClick()">
      <mat-icon>done</mat-icon> Confirm
    </button>
  </div>
</div>
