<div
  class="mat-elevation-z8 robot-card"
  [class.round-bottom]="robot.isBlocked"
  [class.alert-border]="startArrivingAlert()"
>
  <div class="robot-card-body">
    <div class="robot-eta-line">
      <div class="robot-name-box">
        <img
          [src]="robot.pictureUrl || 'assets/model-e-shelf.png'"
          height="40px"
        />

        <span class="name-text">
          {{ robot.displayName }}
        </span>
      </div>
      <div class="eta-text">
        {{ arrivalText }}
      </div>
    </div>
    <div class="orders-and-actions">
      <div class="order-rows">
        <ng-container *ngFor="let order of robot.orders">
          <div
            class="compartment-box"
            *ngIf="
              order.assignedCompartmentId ||
              order.compartmentLocked !== undefined
            "
          >
            <span
              class="compartment-text"
              *ngIf="order.assignedCompartmentId"
              >{{ order.assignedCompartmentId }}</span
            >

            <mat-icon
              class="compartment-icon"
              *ngIf="order.compartmentLocked === true"
              >lock</mat-icon
            >

            <mat-icon
              class="compartment-icon"
              *ngIf="order.compartmentLocked === false"
              >lock_open</mat-icon
            >
          </div>

          <div
            *ngIf="
              !(
                order.assignedCompartmentId ||
                order.compartmentLocked !== undefined
              )
            "
          ></div>

          <div class="order-status">
            <span
              *ngIf="!robot.hasArrived || order.status !== 'WaitingForHandover'"
            >
              Order {{ order.externalId }} is in progress
            </span>

            <span
              *ngIf="robot.hasArrived && order.status === 'WaitingForHandover'"
            >
              {{ getHandoverAction(order) }} order {{ order.externalId }}
            </span>
          </div>

          <ul class="handovers-container">
            <ng-container *ngFor="let handover of order.handovers">
              <li
                class="handover-text"
                *ngIf="handover.locationId !== locationId"
              >
                {{ handover.displayName }}
              </li>
              <li
                class="handover-text"
                *ngIf="handover.locationId === locationId"
              >
                Here
              </li>
            </ng-container>
          </ul>

          <div class="order-actions">
            <button
              *ngIf="
                robot.hasArrived &&
                order.compartmentLocked === true &&
                robot.orders.length > 1
              "
              class="action-button"
              mat-button
              aria-label="Unlock/Open"
              [disabled]="order.status !== 'WaitingForHandover'"
              (click)="openCompartment(order.id)"
            >
              <mat-icon>lock_open</mat-icon>
              Unlock
            </button>

            <button
              *ngIf="
                robot.hasArrived &&
                order.compartmentLocked === false &&
                robot.orders.length > 1
              "
              class="action-button"
              mat-button
              aria-label="Lock/Close"
              [disabled]="order.status !== 'WaitingForHandover'"
              (click)="closeCompartment(order.id)"
            >
              <mat-icon>lock</mat-icon>
              Lock
            </button>

            <button
              *ngIf="robot.hasArrived && robot.orders.length > 1"
              class="action-button"
              mat-button
              aria-label="Complete handover"
              [disabled]="
                order.status !== 'WaitingForHandover' ||
                !order.compartmentClosed
              "
              (click)="completeHandover(order.id)"
            >
              <mat-icon>done</mat-icon>
              Done
            </button>
            <button
              class="action-button"
              mat-button
              aria-label="Complete all handovers"
              [disabled]="!completeAllEnabled()"
              (click)="cancelOrder(order.id)"
            >
              <mat-icon>delete</mat-icon>
              Cancel
            </button>
          </div>
        </ng-container>
      </div>
      <button
        *ngIf="robot.hasArrived"
        class="action-button"
        mat-button
        aria-label="Unlock all compartments"
        (click)="unlockAllCompartments()"
      >
        <mat-icon>lock_open</mat-icon>
        Unlock
      </button>
      <button
        *ngIf="robot.hasArrived"
        class="action-button"
        mat-button
        aria-label="Complete all handovers"
        [disabled]="!completeAllEnabled()"
        (click)="completeAllHandovers()"
      >
        <mat-icon *ngIf="robot.orders.length > 1">done_all</mat-icon>
        <mat-icon *ngIf="robot.orders.length <= 1">done</mat-icon>
        Done
      </button>
    </div>
  </div>

  <div class="blocked-message round-bottom" *ngIf="robot.isBlocked">
    Blocked
  </div>
</div>
