import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-connectivity-status',
  template: `
    <div class="container">
      <mat-icon class="scaled">{{ modem1Icon }}</mat-icon>
      <mat-icon class="scaled">{{ modem2Icon }}</mat-icon>
      <mat-icon class="scaled">{{ modem3Icon }}</mat-icon>
      <mat-icon class="scaled">{{ wifiIcon }}</mat-icon>
    </div>
  `,
  styles: [
    `
          .container
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: center
    
          .scaled 
            transform: scale(0.6)
        `,
  ],
})
export class ConnectivityStatusComponent implements OnChanges {
  @Input() modem1Connected = false;
  @Input() modem2Connected = false;
  @Input() modem3Connected = false;
  @Input() wifiConnected = false;
  lteConnectedIcon = 'signal_cellular_4_bar';
  lteNotConnectedIcon = 'signal_cellular_connected_no_internet_0_bar';
  wifiConnectedIcon = 'wifi';
  wifiNotConnectedIcon = 'wifi_off';
  modem1Icon = this.lteNotConnectedIcon;
  modem2Icon = this.lteNotConnectedIcon;
  modem3Icon = this.lteNotConnectedIcon;
  wifiIcon = this.wifiNotConnectedIcon;

  ngOnChanges(): void {
    this.modem1Icon = this.modem1Connected
      ? this.lteConnectedIcon
      : this.lteNotConnectedIcon;
    this.modem2Icon = this.modem2Connected
      ? this.lteConnectedIcon
      : this.lteNotConnectedIcon;
    this.modem3Icon = this.modem3Connected
      ? this.lteConnectedIcon
      : this.lteNotConnectedIcon;
    this.wifiIcon = this.wifiConnected
      ? this.wifiConnectedIcon
      : this.wifiNotConnectedIcon;
  }
}
