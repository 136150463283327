import { map, share, tap } from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import { Finalizable } from '../../../../utils/finalizable';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BackendService } from '../../backend.service';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../../user-session/user-session-system-events';
import {
  RobotActionInfo,
  RobotActionRequestManager,
} from '../local-logic/action-request-manager';

const SHOW_SKIP_ROBOT_INQUIRY_MILLIS = 30 * 1000;
export const FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS = 30 * 1000;
const ROBOT_BLOCKED_MESSAGE =
  'Robot is currently blocked. It will be assigned to another operator in';
const ROBOT_BLOCKED_BUTTON_LABEL = 'Keep robot';
const BLOCKED_ROBOT_ACTION_ID_TYPE = 'blocked-robot-action';

const notificationDate: RobotActionInfo = {
  actionIdType: BLOCKED_ROBOT_ACTION_ID_TYPE,
  actionDescription: ROBOT_BLOCKED_MESSAGE,
  actionButton: ROBOT_BLOCKED_BUTTON_LABEL,
};

export class RobotBlocking extends Finalizable {
  private accessGroups: string[] = [];
  private blockedSinceMillis: number | null = null;
  private isNotificationUp = false;

  readonly isBlockedForMillis$;
  readonly robotIsBlockedByCurrentUserMillis$;

  private showSkipRobotSince: number | null = null;

  private _blockedRobotIsReadyForFinalization$ = new BehaviorSubject<boolean>(
    false,
  );

  readonly blockedRobotIsReadyForFinalization$ =
    this._blockedRobotIsReadyForFinalization$.asObservable();

  constructor(
    private readonly robotId: string,
    private readonly backendService: BackendService,
    private readonly backendStatePolling: BackendStatePolling,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
    private readonly robotActionManager: RobotActionRequestManager,
  ) {
    super();

    this.isBlockedForMillis$ = this.backendStatePolling.robotState$.pipe(
      tap((robot) => {
        this.accessGroups = robot.accessGroups ?? [];
      }),
      map((robot) => {
        if (!robot.stopState?.stoppedSince) {
          this.blockedSinceMillis = null;
          return 0;
        }
        if (this.blockedSinceMillis === null) {
          this.blockedSinceMillis = Date.now();
        }
        if (!robot.stopState.isBlocked) {
          return 0;
        }
        const blockedSince =
          new Date(robot.stopState.stoppedSince)?.getTime() ?? 0;
        return Date.now() - blockedSince;
      }),
      share(),
    );

    this.robotIsBlockedByCurrentUserMillis$ = this.isBlockedForMillis$.pipe(
      map(() =>
        this.blockedSinceMillis !== null
          ? Date.now() - this.blockedSinceMillis
          : 0,
      ),
      share(),
    );

    this.robotIsBlockedByCurrentUserMillis$.subscribe(
      async (blockedByCurrentUserForMillis) => {
        if (blockedByCurrentUserForMillis < SHOW_SKIP_ROBOT_INQUIRY_MILLIS) {
          this.showSkipRobotSince = null;
          this.robotActionManager.removeNotification(
            BLOCKED_ROBOT_ACTION_ID_TYPE,
          );
          return;
        }

        const { exists } = await firstValueFrom(
          this.backendService.get(
            `/robot-operator-matching/has-free-robot-slots?access_groups=${this.accessGroups.join(
              ',',
            )}`,
          ),
        );

        if (!exists) {
          this.userSessionEventTrackingService.trackSystemEvent(
            UserSessionSystemEventName.NO_SLOTS_FOR_BLOCKED_ROBOT,
            {
              robotId: this.robotId,
              blockedForSeconds: blockedByCurrentUserForMillis / 1000,
            },
          );
          this.showSkipRobotSince = null;
          this.robotActionManager.removeNotification(
            BLOCKED_ROBOT_ACTION_ID_TYPE,
          );
          return;
        }

        const now = Date.now();
        if (this.showSkipRobotSince === null) {
          this.showSkipRobotSince = now;
        }

        if (!this.isNotificationUp) {
          this.isNotificationUp = true;
          this.robotActionManager.notify({
            ...notificationDate,
            expiresAt: new Date(
              now + FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS,
            ),
            onClick: () => {
              this.snoozeSkipRobotInquiry();
            },
            onExpire: () => {
              this._blockedRobotIsReadyForFinalization$.next(true);
            },
          });
          this.userSessionEventTrackingService.trackSystemEvent(
            UserSessionSystemEventName.ROBOT_BLOCKED,
            { robotId: this.robotId },
          );
        }
      },
    );
  }

  snoozeSkipRobotInquiry() {
    this.blockedSinceMillis = null;
    this.showSkipRobotSince = null;
    this.isNotificationUp = false;
  }

  protected override async onFinalize(): Promise<void> {
    // nothing to do, just implement an interface
  }
}
