import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectionsDialogComponent } from './directions/directions-dialog.component';
import { DirectionsComponent } from './directions/directions.component';
import { RobotOperatorViewComponent } from './robot-operator-view/robot-operator-view.component';
import { StatusTreeComponent } from './status-tree/status-tree.component';
import { TriggerServiceConfigComponent } from './trigger_service_config/trigger-service-config.component';
import { TriggerServiceConfigTreeComponent } from './trigger_service_config_tree/trigger-service-config-tree.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { JoystickComponent } from './joystick/joystick.component';
import { CoreModule } from '../../core/core.module';
import { MaterialModule } from '../../material/material.module';
import { FormsModule } from '@angular/forms';
import { RobotOperatorControlComponent } from './robot-operator-view/robot-operator-control.component';
import { RobotVideoComponent } from './robot-control/robot-video.component';
import { RobotControlComponent } from './robot-control/robot-control.component';
import { CustomGpsDialogComponent } from '../../core/mini-map/custom-gps-dialog.component';
import { DataCollectionConfigurationDialog } from './data-collection-configuration-dialog/data-collection-configuration-dialog.component';

import { RobotQuickAddDialogComponent } from './robot-operator-view/robot-quick-add-dialog.component';
import { RobotTextLayoutComponent } from './robot-control/robot-text-layout.component';
import { RouterModule } from '@angular/router';
import { OrderOverlayInfoComponent } from './robot-control/order-overlay-info.component';
import { CorridorConfirmationComponent } from './common/corridor-confirmation/corridor-confirmation.component';
import { RobotIssuesModule } from '../../core/robot-issues';
import { RobotBlockingComponent } from './common/robot-blocking/robot-blocking.component';
import { InfrastructureTransactionComponent } from './common/infrastructure-transaction/infrastructure-transaction.component';
import { UnsupervisedAutonomyInteractionComponent } from './common/unsupervised-autonomy-state/unsupervised-autonomy-interaction.component';
import { ArrivedAtRouteEndComponent } from './common/arrive-at-route-end/arrive-at-route-end.component';
import { ActionNotificationComponent } from './robot-control/action-notification.component';

@NgModule({
  declarations: [
    StatusTreeComponent,
    TriggerServiceConfigComponent,
    TriggerServiceConfigTreeComponent,
    DirectionsComponent,
    DirectionsDialogComponent,
    CustomGpsDialogComponent,
    RobotOperatorViewComponent,
    JoystickComponent,
    DataUploadComponent,
    RobotOperatorControlComponent,
    RobotControlComponent,
    RobotVideoComponent,
    DataCollectionConfigurationDialog,
    RobotQuickAddDialogComponent,
    RobotTextLayoutComponent,
    OrderOverlayInfoComponent,
    CorridorConfirmationComponent,
    RobotBlockingComponent,
    InfrastructureTransactionComponent,
    UnsupervisedAutonomyInteractionComponent,
    ArrivedAtRouteEndComponent,
    ActionNotificationComponent,
  ],
  exports: [RobotControlComponent, StatusTreeComponent],
  imports: [
    CommonModule,
    CoreModule,
    RobotIssuesModule,
    MaterialModule,
    FormsModule,
    RouterModule,
  ],
})
export class RobotOperationModule {}
