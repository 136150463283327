<div class="robots-report-dialog">
  <div
    class="opreation-report mat-elevation-z8"
    *ngFor="let operationToRobotReport of operationsToRobotReport"
  >
    Operation id "{{ operationToRobotReport.operationId }}"
    <table>
      <tr>
        <th>Operational</th>
        <th>{{ operationToRobotReport.operational.length }}</th>
      </tr>
      <tr>
        <th>Degraded</th>
        <th>{{ operationToRobotReport.degraded.length }}</th>
      </tr>
      <tr>
        <th>Broken</th>
        <th>{{ operationToRobotReport.broken.length }}</th>
      </tr>
    </table>
  </div>
</div>
