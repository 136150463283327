import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const CLICK_DEBOUNCE_DURATION_MILLIS = 250;

export class KeyboardButtonsRobotControl {
  private readonly _manualMouseControlToggle$ = new Subject<void>();
  private readonly _claimControlToggle$ = new Subject<void>();
  private readonly _autonomyToggle$ = new Subject<void>();
  private readonly _triggerSnapshot$ = new Subject<void>();

  private readonly unsubscribeEvents$ = new Subject<void>();
  private readonly _triggerDataCollection$ = new Subject<void>();
  private active = false;

  // Observables.

  readonly manualMouseControlToggle$ = this._manualMouseControlToggle$.pipe(
    debounceTime(CLICK_DEBOUNCE_DURATION_MILLIS),
  );
  readonly claimControlToggle$ = this._claimControlToggle$.pipe(
    debounceTime(CLICK_DEBOUNCE_DURATION_MILLIS),
  );
  readonly autonomyToggle$ = this._autonomyToggle$.pipe(
    debounceTime(CLICK_DEBOUNCE_DURATION_MILLIS),
  );
  readonly triggerSnapshot$ = this._triggerSnapshot$.pipe(
    debounceTime(CLICK_DEBOUNCE_DURATION_MILLIS),
  );

  readonly triggerDataCollection$ = this._triggerDataCollection$.asObservable();

  setActive(active: boolean) {
    this.unsubscribeEvents$.next(undefined);
    if (active) {
      this.subscribeEvents();
    }
    this.active = active;
  }

  private subscribeEvents() {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.unsubscribeEvents$))
      .subscribe((event: Event) => {
        const isInputField =
          event.target instanceof HTMLInputElement &&
          (!event.target.type ||
            event.target.type === 'text' ||
            event.target.type === 'number');
        if (
          this.active &&
          !isInputField &&
          this.handleKeyDown((event as KeyboardEvent).code)
        ) {
          event.preventDefault();
        }
      });
  }

  private handleKeyDown(keyCode: string): boolean {
    if (this.handleModeKeyDown(keyCode)) {
      return true;
    }
    return false;
  }

  private handleModeKeyDown(keyCode: string): boolean {
    switch (keyCode) {
      case 'KeyD':
        this._triggerDataCollection$.next(undefined);
        break;
      case 'KeyM':
        this._manualMouseControlToggle$.next(undefined);
        break;
      case 'KeyC':
        this._claimControlToggle$.next(undefined);
        break;
      case 'KeyA':
      case 'KeyP':
        this._autonomyToggle$.next(undefined);
        break;
      case 'KeyS':
        this._triggerSnapshot$.next(undefined);
        break;
      default:
        // Return false to signal that the key was not handled here.
        return false;
    }
    return true;
  }
}
