<div class="data-viewer-selection-title" *ngIf="previewVideo">Snipper Info</div>
<div class="data-viewer-selection-title" *ngIf="!previewVideo">Frame Info</div>
<mat-divider></mat-divider>
<app-selected-item
  class="data-viewer-selection-container"
  [selectedItem]="selectedItem"
  [previewImage]="previewImage"
  [previewVideo]="previewVideo"
  [metadataItem]="metadataItem"
  [snippetFrames]="snippetFrames"
  (selectItem)="selectItem($event)"
>
  <button mat-icon-button>
    <mat-icon>lock</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon>settings</mat-icon>
  </button>
</app-selected-item>
