import { isDefined } from '../.././../utils/typeGuards';

type DeltaValueItem = {
  sampleStartValue: number;
  currentValue: number;
};

export class DeltaAggregator {
  private readonly store = new Map<string, DeltaValueItem>();

  put(key: string, value: number): void {
    const item = this.store.get(key);
    if (item !== undefined) {
      item.currentValue = value;
    } else {
      const newItem: DeltaValueItem = {
        currentValue: value,
        sampleStartValue: value,
      };
      this.store.set(key, newItem);
    }
  }

  readDeltaAndReset(key: string): number {
    const item = this.store.get(key);

    if (item === undefined) {
      return 0;
    }

    const lastSampleValue = item.sampleStartValue;
    item.sampleStartValue = item.currentValue;

    return item.currentValue - lastSampleValue;
  }

  readDeltaAndResetAll(): number[] {
    const keys = Array.from(this.store.keys());
    return keys.map((robotId) => this.readDeltaAndReset(robotId));
  }

  delete(key: string) {
    this.store.delete(key);
  }
}
