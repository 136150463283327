import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { focusLost$, inFocus$ } from '../../utils/page-visibility';

export class PageVisibilityWatchDog {
  private _destroy = new Subject();
  private visibilityLostTimer: null | NodeJS.Timeout = null;
  private isVisibilityLost = false;

  constructor(
    private pageInvisibleStoppingTimeoutMillis: number,
    private onVisibilityLost: VoidFunction,
    private onVisibilityReturn: VoidFunction,
  ) {}

  start() {
    focusLost$.pipe(takeUntil(this._destroy)).subscribe(() => {
      if (this.visibilityLostTimer === null) {
        this.visibilityLostTimer = setTimeout(async () => {
          this.onVisibilityLost();
          this.isVisibilityLost = true;
        }, this.pageInvisibleStoppingTimeoutMillis);
      }
    });

    inFocus$.pipe(takeUntil(this._destroy)).subscribe(() => {
      if (this.visibilityLostTimer !== null) {
        clearTimeout(this.visibilityLostTimer);
        this.visibilityLostTimer = null;
      }
      if (this.isVisibilityLost === true) {
        this.onVisibilityReturn();
        this.isVisibilityLost = false;
      }
    });
  }

  destroy() {
    this._destroy.next(undefined);
  }
}
