import { clamp } from '../../../utils/clamp';
import { RobotActionRequestNotification } from '../../../app/core/robots-service/local-logic/action-request-manager';

function findNotificationWithMinCountDown(
  notifications: RobotActionRequestNotification[],
): number {
  let minCountDown = Number.MAX_VALUE;
  let minCountDownIndex = -1;
  for (let i = 0; i < notifications.length; i++) {
    const notification = notifications[i];
    if (notification.countdown && notification.countdown < minCountDown) {
      minCountDown = notification.countdown;
      minCountDownIndex = i;
    }
  }
  return minCountDownIndex;
}

export function updateActiveNotificationIndex(
  oldNotification: RobotActionRequestNotification[],
  newNotification: RobotActionRequestNotification[],
  activeNotificationIndex: number,
): number {
  const oldMinCountDownIndex =
    findNotificationWithMinCountDown(oldNotification);
  const newMinCountDownIndex =
    findNotificationWithMinCountDown(newNotification);

  if (
    oldMinCountDownIndex !== newMinCountDownIndex &&
    newMinCountDownIndex !== -1
  ) {
    return newMinCountDownIndex;
  }

  return clamp(0, newNotification.length - 1, activeNotificationIndex);
}
