import { RouteDto } from './types';

export function routeBounds(route: RouteDto): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();

  for (const point of route?.geometry ?? []) {
    const latLon = {
      lat: point.latitude,
      lng: point.longitude,
    };
    bounds.extend(latLon);
  }

  return bounds;
}
