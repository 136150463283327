<div
  *ngIf="updateState !== 'updated' || isMinUpdateDuration()"
  class="refresh-overlay"
>
  Getting latest state...
  <mat-icon class="refresh-icon">refresh</mat-icon>
</div>
<div class="page-grid">
  <mat-toolbar>
    <div class="handover-title">
      {{ handoverDisplayName }}
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="back()">
          <mat-icon>arrow_back</mat-icon>
          <span>Select pickup</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <div class="eta-list">
    <div class="eta-grid">
      <div
        *ngIf="waitingForRobotOrderCount > 1"
        class="waiting-for-robot-order-count"
      >
        <strong> {{ waitingForRobotOrderCount }} </strong>orders are waiting for
        robots
      </div>

      <div
        *ngIf="waitingForRobotOrderCount === 1"
        class="waiting-for-robot-order-count"
      >
        <strong>1</strong> order is waiting for a robot
      </div>

      <app-handover-location-title-splitter
        class="grid-title"
        title="Arriving Soon"
      ></app-handover-location-title-splitter>

      <app-robot-card
        style="display: contents"
        *ngFor="let robot of arrivingRobots; trackBy: trackByRobotName"
        [robot]="robot"
        (triggerRefresh)="this.triggerRefresh()"
        [locationId]="locationId"
      ></app-robot-card>

      <app-handover-location-title-splitter
        class="grid-title"
        title="Arrived"
      ></app-handover-location-title-splitter>
      <app-robot-card
        style="display: contents"
        *ngFor="let robot of arrivedRobots; trackBy: trackByRobotName"
        [robot]="robot"
        (triggerRefresh)="this.triggerRefresh()"
        [locationId]="locationId"
      ></app-robot-card>

      <app-handover-location-title-splitter
        class="grid-title"
        title="In Progress"
      ></app-handover-location-title-splitter>
      <app-robot-card
        style="display: contents"
        *ngFor="let robot of otherRobots; trackBy: trackByRobotName"
        [robot]="robot"
        (triggerRefresh)="this.triggerRefresh()"
        [locationId]="locationId"
      ></app-robot-card>
    </div>
  </div>

  <div class="big-card" *ngIf="dropoffs.length > 0 && hasActiveRobot">
    <div class="send-robot-text">Send a robot to</div>
    <div class="dropoff-buttons-grid">
      <button
        *ngFor="let dropoff of dropoffs"
        mat-raised-button
        (click)="sendRobot(dropoff)"
        class="dropoff-button"
      >
        {{ dropoff.displayName || dropoff.name }}
      </button>
    </div>
  </div>

  <div class="big-card" *ngIf="pickups.length > 0 && hasActiveRobot">
    <div class="send-robot-text">Request a robot from</div>
    <div class="dropoff-buttons-grid">
      <button
        *ngFor="let pickup of pickups"
        mat-raised-button
        (click)="requestRobot(pickup)"
        class="dropoff-button"
      >
        {{ pickup.displayName || pickup.name }}
      </button>
    </div>
  </div>

  <div class="big-card" *ngIf="!hasActiveRobot">
    <div class="send-robot-text">
      There are no active robots in the operation
    </div>
  </div>
</div>
