<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="$event.stopPropagation()"
      *ngIf="template$ | async as template; else loading">
  <h1 class="robot-issue-header" mat-dialog-title>Submit an issue to Cartken</h1>
  <div class="robot-issue-form" mat-dialog-content>

    <mat-form-field appearance="fill" class="robot-issue-field">
      <mat-label>Problem</mat-label>
      <input matInput type="text" formControlName="name" required />
    </mat-form-field>

    <ng-container *ngIf="fields$ | async as fields; else loading">

      <mat-form-field
        *ngFor="let field of fields; index as index"
        class="robot-issue-field"
        appearance="fill">

        <mat-label>{{field.name}}</mat-label>
        <ng-container [ngSwitch]="field.type">

          <input
            matInput
            *ngSwitchCase="'short_text'"
            (change)="onCustomFieldChange(index, field.id, $event.target.value)"
            type="text"
            required />

          <mat-select
            *ngSwitchCase="'drop_down'"
            (selectionChange)="onCustomFieldChange(index, field.id, $event.value)"
            required>
            <mat-option *ngFor="let option of field.options" [value]="option.id">
              {{option.name}}
            </mat-option>
          </mat-select>

        </ng-container>
      </mat-form-field>

    </ng-container>

    <mat-form-field appearance="fill" class="robot-issue-field">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

  </div>

  <div class="robot-issue-actions" mat-dialog-actions>
    <button *ngIf="data.skippable" mat-button mat-dialog-close>Skip</button>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" type="submit">
      Submit
    </button>
  </div>
</form>
<ng-template #loading>
  <section class="loader">
    <mat-spinner></mat-spinner>
    <span>Loading...</span>
  </section>
</ng-template>
