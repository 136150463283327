import { User } from '../app/core/user';

export type RobotDrivingState =
  | 'PARKING'
  | 'BLOCKED'
  | 'AUTONOMOUSLY_DRIVING'
  | 'MANUALLY_DRIVEN'
  | 'UNKNOWN';

export interface RobotWithStatusFields {
  arrivedAtStop?: boolean;
  controllingRobotOperatorId?: string;
  controlType?: string;
  readyForOrders?: boolean;
  velocity?: number;
}

export function hasRobotArrived(robot: RobotWithStatusFields): boolean {
  return robot.arrivedAtStop !== false;
}

export function hasRobotStopped(robot: RobotWithStatusFields): boolean {
  return Math.abs(robot.velocity ?? 0) <= 0.1;
}

export function isRobotAutonomouslyDriving(
  robot: RobotWithStatusFields,
): boolean {
  return robot.controlType?.match(/autonomy/i) !== null;
}

export function isRobotManuallyDriven(robot: RobotWithStatusFields): boolean {
  return robot.controlType?.match(/webrtc/i) !== null;
}

export function determineRobotDrivingState(
  robot: RobotWithStatusFields,
  user: User,
): RobotDrivingState {
  if (user.id !== robot.controllingRobotOperatorId) return 'UNKNOWN';
  if (hasRobotArrived(robot)) return 'PARKING';
  if (hasRobotStopped(robot)) return 'BLOCKED';
  if (isRobotManuallyDriven(robot)) return 'MANUALLY_DRIVEN';
  if (isRobotAutonomouslyDriving(robot)) return 'AUTONOMOUSLY_DRIVING';

  return 'UNKNOWN';
}
