import { LatLng, LatLngBounds, computeOffset } from 'spherical-geometry-js';
import { Location } from '../app/core/robots-service/backend/robot.dto';

export function toLatLng([lng, lat]: number[]): google.maps.LatLng | undefined {
  if (lat === undefined || lng === undefined) {
    return;
  }
  return new google.maps.LatLng(lat, lng);
}

export function locationToCord(location: Location): [number, number] {
  return [location.longitude, location.latitude];
}

// It is generic since with have path represented with different kinds of points
// e.g. GoogleMap API LatLon, [number, number] and so on
export function closePolygon<T>(path?: T[]) {
  const firstPoint = path?.[0];
  if (firstPoint !== undefined) {
    path?.push(firstPoint);
  }
}

export function boundingPolygonFromBounds(bounds: LatLngBounds) {
  const minLng = bounds.getSouthWest().longitude;
  const minLat = bounds.getSouthWest().latitude;
  const maxLng = bounds.getNorthEast().longitude;
  const maxLat = bounds.getNorthEast().latitude;
  return [
    [minLng, minLat],
    [maxLng, minLat],
    [maxLng, maxLat],
    [minLng, maxLat],
    [minLng, minLat],
  ];
}

export function createBoundsFromCenterAndRadius(
  center: LatLng,
  radius: number,
) {
  const centerLiteral = {
    lat: center.lat(),
    lng: center.lng(),
  };
  const northEast = computeOffset(centerLiteral, radius, 45);
  const southWest = computeOffset(centerLiteral, radius, 45 + 180);
  const loadingRequestNorthEast = {
    lat: northEast.lat(),
    lng: northEast.lng(),
  };
  const loadingRequestSouthWest = {
    lat: southWest.lat(),
    lng: southWest.lng(),
  };
  const bounds = new LatLngBounds(
    loadingRequestSouthWest,
    loadingRequestNorthEast,
  );
  return bounds;
}

export function getMidPoint(
  p1: number[],
  p2: number[],
): [number, number, number] {
  return [
    (p1[0] + p2[0]) / 2,
    (p1[1] + p2[1]) / 2,
    ((p1[2] ?? 0) + (p2[2] ?? 0)) / 2,
  ];
}
