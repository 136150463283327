import { quat, vec2, vec3 } from '@tlaukkan/tsm';

export class Transform {
  translation: vec3;
  rotation: quat;

  constructor(rotation?: quat, translation?: vec3) {
    if (rotation) {
      this.rotation = rotation;
    } else {
      this.rotation = new quat([0, 0, 0, 1]);
    }
    if (translation) {
      this.translation = translation;
    } else {
      this.translation = new vec3([0, 0, 0]);
    }
  }

  transformVec3(v: vec3): vec3 {
    return this.rotation.multiplyVec3(v).add(this.translation);
  }

  inverse(): Transform {
    const inverseRotation = this.rotation.copy().conjugate();
    return new Transform(
      inverseRotation,
      inverseRotation.multiplyVec3(this.translation).negate(),
    );
  }
}

interface TransformCompatible {
  translation: vec3;
  rotation: quat;
}

interface QuaternionCompatible {
  x: number;
  y: number;
  z: number;
  w: number;
}

interface Vec3Compatible {
  x: number;
  y: number;
  z: number;
}

interface Vec2Compatible {
  x: number;
  y: number;
}

function isTransformCompatible(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).length === 2 &&
    'translation' in obj &&
    'rotation' in obj &&
    obj.translation instanceof vec3 &&
    obj.rotation instanceof quat
  );
}

function transformFromObject(transform: TransformCompatible): Transform {
  return new Transform(transform.rotation, transform.translation);
}

function isQuaternionCompatible(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).length === 4 &&
    'x' in obj &&
    'y' in obj &&
    'z' in obj &&
    'w' in obj
  );
}

function quaternionFromObject(q: QuaternionCompatible): quat {
  return new quat([q.x, q.y, q.z, q.w]);
}

function isVec3Compatible(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).length === 3 &&
    'x' in obj &&
    'y' in obj &&
    'z' in obj
  );
}

function vec3FromObject(obj: Vec3Compatible): vec3 {
  return new vec3([obj.x, obj.y, obj.z]);
}

function isVec2Compatible(obj: any): boolean {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).length === 2 &&
    'x' in obj &&
    'y' in obj
  );
}

function vec2FromObject(obj: Vec2Compatible): vec2 {
  return new vec2([obj.x, obj.y]);
}

export function mathReviver(key: string, value: any): any {
  if (!value) {
    return value;
  }
  if (isVec2Compatible(value)) {
    return vec2FromObject(value);
  }
  if (isVec3Compatible(value)) {
    return vec3FromObject(value);
  }
  if (isQuaternionCompatible(value)) {
    return quaternionFromObject(value);
  }
  if (isTransformCompatible(value)) {
    return transformFromObject(value);
  }
  return value;
}
