import { BehaviorSubject } from 'rxjs';
import { NetworkInterfaceStats } from './signaling-server-messages';
import { SignalingConnection } from './signaling-connection';
import { Finalizable } from '../../../../utils/finalizable';
import { completeAll } from '../../../../utils/complete-all';
import { shareReplay } from 'rxjs/operators';

export class RtcNetworkInterfaces extends Finalizable {
  private readonly _selectedNetworkInterface$ = new BehaviorSubject<
    string | undefined
  >(undefined);
  readonly selectedNetworkInterface$ =
    this._selectedNetworkInterface$.asObservable();

  private readonly _connectedNetworkInterface$ = new BehaviorSubject<
    string | undefined
  >(undefined);
  readonly connectedNetworkInterface$ =
    this._connectedNetworkInterface$.asObservable();

  private readonly _availableNetworkInterfaces$ = new BehaviorSubject<
    NetworkInterfaceStats[]
  >([]);
  readonly availableNetworkInterfaces$ =
    this._availableNetworkInterfaces$.asObservable();

  constructor(private readonly signalingConnection: SignalingConnection) {
    super();
    this.signalingConnection.networkInterfaceStats$.subscribe(
      (networkInterfaceStats) => {
        this.processNetworkInterfaces(networkInterfaceStats);
      },
    );
  }

  private processNetworkInterfaces(networkInterfaces: NetworkInterfaceStats[]) {
    networkInterfaces.sort(
      (a: NetworkInterfaceStats, b: NetworkInterfaceStats) =>
        a.isSelected ? -1 : b.isSelected ? 1 : -1,
    );
    if (networkInterfaces.some((nI) => nI.isSelected)) {
      networkInterfaces.push({
        interfaceName: '',
        interfaceDisplayName: '',
        isSelected: false,
        isConnected: false,
      });
    }

    this._availableNetworkInterfaces$.next(networkInterfaces);
    const firstNetworkInterface = networkInterfaces[0];
    this._selectedNetworkInterface$.next(
      firstNetworkInterface && firstNetworkInterface.isSelected
        ? firstNetworkInterface.interfaceName
        : undefined,
    );
    const connectedInterface = this._availableNetworkInterfaces$.value.find(
      (aI) => aI.isConnected,
    );
    this._connectedNetworkInterface$.next(
      connectedInterface
        ? connectedInterface.interfaceDisplayName ||
            connectedInterface.interfaceName
        : undefined,
    );
  }

  protected async onFinalize(): Promise<void> {
    completeAll(
      this._selectedNetworkInterface$,
      this._connectedNetworkInterface$,
      this._availableNetworkInterfaces$,
    );
  }
}
