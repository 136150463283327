import { Order, OrderStatus } from '../core/order/order';

export const ORDER_DELAY_THRESHOLD_MILLIS = 15 * 60 * 1000;

export function isOrderDelayed(order: Order) {
  return order.handovers.some((handover, index) => {
    if (
      index < order.currentHandoverIndex ||
      (index === order.currentHandoverIndex &&
        order.status === OrderStatus.WAITING_FOR_HANDOVER)
    ) {
      return false; //Only consider upcoming handovers
    }
    const { initialEstimatedArrivalTime, estimatedArrivalTime } = handover;
    if (!initialEstimatedArrivalTime || !estimatedArrivalTime) {
      return false;
    }
    const initialEstimatedArrivalTimeMillis = new Date(
      initialEstimatedArrivalTime!,
    ).getTime();
    const estimatedArrivalTimeMillis = new Date(
      estimatedArrivalTime!,
    ).getTime();

    return (
      estimatedArrivalTimeMillis - initialEstimatedArrivalTimeMillis >
      ORDER_DELAY_THRESHOLD_MILLIS
    );
  });
}
