export enum ExpressionBrackets {
  Balanced,
  Unbalanced,
  Invalid,
}

export function needClosingBracket(tokens: string[]) {
  const stack: string[] = [];

  for (const token of tokens) {
    if (token === '(') {
      stack.push(token);
    } else if (token === ')') {
      if (stack.length === 0) {
        return ExpressionBrackets.Invalid;
      }

      stack.pop();
    }
  }

  if (stack.length === 0) {
    return ExpressionBrackets.Balanced;
  }

  return ExpressionBrackets.Unbalanced;
}
