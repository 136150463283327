import { PolygonGeometryDto } from '@cartken/map-types';

export enum OperationType {
  OrderOperation = 'OrderOperation',
}

export enum PinCodeStrategy {
  NONE = 'None',
  FIXED = 'Fixed',
  RANDOM = 'Random',
}

export enum DisposalMode {
  NEVER = 'Never',
  ON_FAILURE = 'OnFailure',
  ALWAYS = 'Always',
}

export enum CorridorAlignmentPreference {
  CENTER = 'Center',
  LEFT = 'Left',
  RIGHT = 'Right',
}

export interface CompartmentStrategy {
  pinCodeStrategy?: PinCodeStrategy;
  pinCode?: string;
  openCompartmentOnArrival?: boolean;
}

export interface TextMessageConfiguration {
  createdBlock?: string;
  waitingForDropoffBlock?: string;
}

export interface RobotQueueEdgeHandover {
  name: string;
  displayName?: string;
}

export interface HandoverWaitingDurations {
  estimatedWaitingDuration?: number;
  maxWaitingDuration?: number;
  reminderDuration?: number;
  autoSuccessDuration?: number;
}

export enum OrderSchedulerMode {
  AS_SOON_AS_POSSIBLE = 'AsSoonAsPossible',
  MINIMIZE_MEAN_ORDER_DURATION = 'MinimizeMeanOrderDuration',
  MINIMIZE_DRIVING_DURATION = 'MinimizeDrivingDuration',
  MINIMIZE_MEAN_ORDER_AND_DRIVING_DURATION = 'MinimizeMeanOrderAndDrivingDuration',
  FIRST_IN_FIRST_OUT = 'FirstInFirstOut',
}

export interface DisposalOptions {
  disposalMode?: DisposalMode;
  disposeAtLocationId?: string | null;
}

export interface OrderOperation {
  waitingQueues?: RobotQueueEdgeHandover[];
  pickups?: RobotQueueEdgeHandover[];
  dropoffs?: RobotQueueEdgeHandover[];
  storageLocationId?: string;
  surveyUrl?: string;
  defaultWebhookUrl?: string;
  pickupCompartmentStrategy: CompartmentStrategy;
  dropoffCompartmentStrategy: CompartmentStrategy;
  defaultPickupWaitingDurations?: HandoverWaitingDurations;
  defaultDropoffWaitingDurations?: HandoverWaitingDurations;
  textMessageConfiguration?: TextMessageConfiguration;
  allowCustomPickupLocationsWithinBounds?: boolean;
  allowCustomDropoffLocationsWithinBounds?: boolean;
  maxPickupLocationRefinementDistance?: number;
  maxDropoffLocationRefinementDistance?: number;
  orderSchedulerMode?: OrderSchedulerMode;
  enableOrderSchedule?: boolean;
  snapToLocationInRadius?: number;
  pickupHandoverCompletionDelay?: number;
  dropoffHandoverCompletionDelay?: number;
  preferredCountryCodes?: string[];
  maxUnsupervisedAutonomyDuration?: number;
  freezeInsertionBeforePickup?: number;
  pickupAutomaticallyFailOrdersAfterTimeout?: boolean;
  dropoffAutomaticallyFailOrdersAfterTimeout?: boolean;
  disposalOptions?: DisposalOptions | null;
  corridorAlignmentPreference?: CorridorAlignmentPreference;
  rejectedOrderWarningThreshold?: number;
  reschedulingForbidden?: boolean;
  returnToHandoverTimeoutMins?: number;
  pickupImmediatelyEnabled?: boolean;
  maxDrivingSecondsToPickups?: number;
  maxDrivingSecondsToDropoffs?: number;
}

export interface Station {
  robotQueueEdgeId: number;
  robotQueueEdgeName?: string;
  waitingDuration?: number;
}

export interface Operation {
  id: string;
  accessGroups?: string[];
  displayName?: string;
  deleted?: boolean;
  operationRegion?: PolygonGeometryDto;
  operationData?: OrderOperation;
  operationType?: OperationType;
  timeZone?: string;
  operationStartTime?: string; //HH:mm
  operationEndTime?: string; //HH:mm
  automaticFleetUpdate?: boolean;
  emergencyStopActive?: boolean;
}
