<div class="dialog-container">
  <mat-form-field class="example-full-width">
    <mat-label>Username</mat-label>
    <input matInput [(ngModel)]="user.username" cdkFocusInitial />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Password</mat-label>
    <input matInput [(ngModel)]="user.password" cdkFocusInitial />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Display Name</mat-label>
    <input matInput [(ngModel)]="user.displayName" cdkFocusInitial />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label> Roles</mat-label>
    <mat-select placeholder=" Roles" multiple [(ngModel)]="user.roles">
      <mat-option *ngFor="let role of availableRoles" [value]="role">{{
        role
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <app-attribute-list-input
    [autocompleteAttributes]="allOperationAccessGroups"
    [(selectedAttributes)]="user.accessGroups"
    [label]="'Access Groups'"
  ></app-attribute-list-input>

  <div mat-dialog-content></div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button
      mat-button
      [mat-dialog-close]="user"
      [disabled]="!user.username && !user.password"
    >
      Create
    </button>
  </div>
</div>
