import { Component, Inject } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickAddItem } from '../core/quick-add-remove-dialog/quick-add-remove-dialog.component';
import { BackendService } from '../core/backend.service';
import { Operation } from '../operations/operation';

export type OperationsQuickAddDialogData = {
  selectedOperationIds: string[] | null;
};

@Component({
  selector: 'app-operation-quick-add-dialog',
  templateUrl: './operation-quick-add-dialog.component.html',
  styleUrls: ['./operation-quick-add-dialog.component.sass'],
})
export class OperationQuickAddDialogComponent {
  operations: Observable<QuickAddItem<string>[]>;
  isLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: OperationsQuickAddDialogData,
    private backendService: BackendService,
    public dialogRef: MatDialogRef<void, string[]>,
  ) {
    const selectedOperations = new Set(data.selectedOperationIds);
    this.operations = merge(
      of([]),
      this.backendService.get(`/operations/`).pipe(
        tap(() => {
          this.isLoaded = true;
        }),
        map((operations) =>
          operations.map(
            (operation: Operation): QuickAddItem<string> => ({
              itemName: `${operation.displayName || operation.id}`,
              isSelected: selectedOperations.has(operation.id),
              payload: operation.id,
            }),
          ),
        ),
      ),
    );
  }

  close() {
    this.dialogRef.close();
  }

  addSelectedOperations(operations: QuickAddItem<string>[]) {
    this.dialogRef.close(
      operations
        .filter((operation) => operation.isSelected)
        .map((operation) => operation.payload),
    );
  }
}
