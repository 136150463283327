export class IsExpired {
  private deadline: number | null = null;

  constructor(readonly ttlMs: number) {}

  private calcDeadline() {
    return Date.now() + this.ttlMs;
  }

  isExpired() {
    return this.deadline === null || Date.now() > this.deadline;
  }

  reset() {
    this.deadline = this.calcDeadline();
  }
}
