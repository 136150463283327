import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UserCardData } from './robot-operator-info-card.component';
import {
  OperatorStatus,
  createUsersFilter,
} from './robot-operator-overview-utils';
import { RobotOperatorOverviewDisplayStateService } from './robot-operator-overview-display-state.service';
import { UserSessionService } from '../core/user-session/user-session.service';
import { ViewName } from '../core/user-session/user-session.utils';

@Component({
  selector: 'app-robot-operator-overview',
  templateUrl: './robot-operator-overview.component.html',
  styleUrls: ['./robot-operator-overview.component.sass'],
})
export class RobotOperatorOverviewComponent implements OnInit, OnDestroy {
  private robotOperatorOverviewDisplayStateService = inject(
    RobotOperatorOverviewDisplayStateService,
  );
  selectedOperatorStatusSet$ = new BehaviorSubject<Set<OperatorStatus>>(
    this.robotOperatorOverviewDisplayStateService.getDisplayOptions(),
  );
  private destroyed$ = new Subject<void>();

  private users$!: Observable<UserCardData[]>;
  selectedUsers$!: Observable<UserCardData[]>;

  focusedUsers$: Observable<UserCardData[]>;
  focusedUserCount$: Observable<number>;

  maintenanceUsers$: Observable<UserCardData[]>;
  maintenanceUserCount$: Observable<number>;

  supervisionUser$: Observable<UserCardData[]>;
  supervisionUserCount$: Observable<number>;
  supervisionAllocation$: Observable<number>;

  constructor(private userSessionService: UserSessionService) {
    this.selectedOperatorStatusSet$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedOperatorStatusSet) => {
        this.robotOperatorOverviewDisplayStateService.setEnabledOperatorStatuses(
          selectedOperatorStatusSet,
        );
      });

    this.users$ = this.userSessionService.robotOperatorsData$.pipe(
      map(({ operators }) => operators),
    );

    const robots$ = this.userSessionService.robotOperatorsData$.pipe(
      map(({ operators }) => operators.flatMap((operator) => operator.robots)),
    );

    this.selectedUsers$ = combineLatest([
      this.users$,
      this.selectedOperatorStatusSet$,
    ]).pipe(
      map(([users, selectedOperatorStatusSet]) =>
        users.filter(createUsersFilter(selectedOperatorStatusSet)),
      ),
    );

    this.focusedUsers$ = this.users$.pipe(
      map((users) =>
        users.filter((user) => user.viewName === ViewName.FOCUSED_SUPERVISION),
      ),
    );
    this.focusedUserCount$ = this.focusedUsers$.pipe(
      map((users) => users.length),
    );

    this.maintenanceUsers$ = this.users$.pipe(
      map((users) =>
        users.filter((user) => user.viewName === ViewName.CLASSIC_SUPERVISION),
      ),
    );
    this.maintenanceUserCount$ = this.maintenanceUsers$.pipe(
      map((users) => users.length),
    );

    this.supervisionUser$ = this.users$.pipe(
      map((users) =>
        users.filter((user) => user.viewName === ViewName.MANAGED_SUPERVISION),
      ),
    );
    this.supervisionUserCount$ = this.supervisionUser$.pipe(
      map((users) => users.length),
    );
    this.supervisionAllocation$ = this.supervisionUser$.pipe(
      map((users) => users.reduce((acc, user) => acc + user.robots.length, 0)),
    );

    const managedSupervisionOperations$ = this.supervisionUser$.pipe(
      map(
        (users) =>
          new Set(
            users.flatMap((user) =>
              user.robots.map((robot) => robot.operationId),
            ),
          ),
      ),
    );
    this.supervisionAllocation$ = combineLatest([
      managedSupervisionOperations$,
      robots$,
      this.supervisionUserCount$,
      this.focusedUserCount$,
    ]).pipe(
      map(
        ([operations, robots, supervisionUserCount, focusedUserCount]) =>
          robots.filter(
            (robot) => operations.has(robot.operationId) && robot.drivingState,
          ).length /
          (supervisionUserCount + focusedUserCount),
      ),
    );
  }

  async ngOnInit() {
    await this.userSessionService.goOnline();
    this.userSessionService.setViewName(ViewName.IDLE);

    this.userSessionService.startListenRobotOperatorsData(true);
  }

  toggleDisplayOption(tabName: OperatorStatus) {
    const selectedOperatorStatusSet = this.selectedOperatorStatusSet$.value;
    const isSelected = selectedOperatorStatusSet.has(tabName);
    if (isSelected) {
      selectedOperatorStatusSet.delete(tabName);
    } else {
      selectedOperatorStatusSet.add(tabName);
    }
    this.selectedOperatorStatusSet$.next(selectedOperatorStatusSet);
  }

  async ngOnDestroy() {
    this.destroyed$.next(undefined);
    this.userSessionService.startListenRobotOperatorsData(false);
    this.userSessionService.goOffline();
  }

  trackByUser(_index: number, user: UserCardData) {
    return user.displayName;
  }
}
