import {
  ClickEvent,
  PointerMoveEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  DraggingEvent,
  Tooltip,
  ModeProps,
  GuideFeatureCollection,
  TentativeFeature,
  NonInteractiveFeatureCollection,
} from './types';

const DEFAULT_GUIDES: GuideFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};
export const DEFAULT_NON_INTERACTIVE_FEATURES: NonInteractiveFeatureCollection =
  {
    type: 'FeatureCollection',
    features: [],
  };
const DEFAULT_TOOLTIPS: Tooltip[] = [];

export class InteractiveMode {
  setActive(active: boolean, subMode?: string) {}

  // Return features that can be used as a guide for editing the data
  getGuides(props: ModeProps): GuideFeatureCollection {
    return DEFAULT_GUIDES;
  }

  getNonInteractiveFeatures(props: ModeProps): NonInteractiveFeatureCollection {
    return DEFAULT_NON_INTERACTIVE_FEATURES;
  }

  getTooltips(props: ModeProps): Tooltip[] {
    return DEFAULT_TOOLTIPS;
  }

  createTentativeFeature(props: ModeProps): TentativeFeature | undefined {
    return undefined;
  }

  // Called when the left mouse button went down and up without dragging regardless of whether something was picked
  onLeftClick(event: ClickEvent, props: ModeProps): void {}
  // Called when the right mouse button went down and up without dragging regardless of whether something was picked
  onRightClick(event: ClickEvent, props: ModeProps): void {}
  // Called when the pointer moved, regardless of whether the pointer is down, up, and whether something was picked
  onHover(event: PointerMoveEvent, props: ModeProps): void {}
  // Called when the pointer went down on something rendered by this layer and the pointer started to move
  onDragStart(event: StartDraggingEvent, props: ModeProps): void {}
  // Called when the pointer went down on something rendered by this layer, the pointer moved, and now the pointer is up
  onDragEnd(event: StopDraggingEvent, props: ModeProps): void {}
  // Called when the pointer went down and is moving, regardless of whether something was picked
  onDrag(event: DraggingEvent, props: ModeProps): void {}
  // Called when any key went up.
  onKeyUp(event: KeyboardEvent, props: ModeProps): void {}
}
