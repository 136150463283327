import {
  ElementType,
  MapElementDto,
  RoadEdgePropertiesDto,
} from '@cartken/map-types';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { ChangeHistory } from './change-history';
import { EdgeDto, isEdge, updateEdgeLength } from './edge';
import {
  boundingPolygonFromBounds,
  createBoundsFromCenterAndRadius,
} from '../../../utils/geo-tools';
import { LatLngBounds, computeDistanceBetween } from 'spherical-geometry-js';
import { NodeDto, isNode } from './node';
import { computeDrivingDirections } from './computeDrivingDirections';

export class MapElementManager {
  private _selectedMapElement$ = new BehaviorSubject<MapElementDto | undefined>(
    undefined,
  );
  private _loadedMapElements$ = new Subject<void>();
  private nextElementId = -1;
  private _mapVersion?: number;
  private loadedMapElementBounds?: LatLngBounds;

  private readonly unchangedMapElementsById = new Map<number, MapElementDto>();
  private readonly _mapElementsById$ = new BehaviorSubject<
    Map<number, MapElementDto>
  >(new Map<number, MapElementDto>());
  private readonly changeHistory = new ChangeHistory();

  readonly selectedMapElement$ = this._selectedMapElement$.asObservable();
  readonly loadedMapElements$ = this._loadedMapElements$.asObservable();
  readonly mapElementsById$ = this._mapElementsById$.asObservable();
  readonly mutexes$ = this._mapElementsById$.pipe(
    map((mapElementsById) =>
      Array.from(mapElementsById.values()).filter(
        (m) => m.elementType === ElementType.MUTEX,
      ),
    ),
  );
  readonly infrastructure$ = this._mapElementsById$.pipe(
    map((mapElementsById) =>
      Array.from(mapElementsById.values()).filter(
        (m) => m.elementType === ElementType.INFRASTRUCTURE,
      ),
    ),
  );

  loading = false;
  loadingError?: string;

  get selectedMapElement(): MapElementDto | undefined {
    return this._selectedMapElement$.value;
  }

  set selectedMapElement(mapElement: MapElementDto | undefined) {
    if (mapElement) {
      mapElement = structuredClone(mapElement);
    }

    this._selectedMapElement$.next(mapElement);
  }

  constructor(
    private readonly loadMapElementsInBounds: (
      boundingCoordinates: number[][],
      mapVersion?: number,
    ) => Promise<MapElementDto[]>,
    private readonly loadOperationRegions: (
      mapVersion?: number,
    ) => Promise<MapElementDto[]>,
  ) {
    this.changeHistory.changesAdded$.subscribe((change) =>
      this.onAddedChange(change),
    );
    this.changeHistory.changeRemoved$.subscribe((change) =>
      this.onRemovedChange(change),
    );
  }
  getMapElement(id: number): MapElementDto | undefined {
    return this._mapElementsById$.value.get(id);
  }

  getMapElements(): Map<number, MapElementDto> {
    return this._mapElementsById$.value;
  }

  replaceChanges(replacementChange: MapElementDto[]) {
    this.changeHistory.clear();
    if (replacementChange.length) {
      this.changeHistory.addChange(replacementChange);
    }
    const minId = replacementChange.reduce(
      (minId, mapElement) => Math.min(minId, mapElement.id),
      0,
    );
    this.nextElementId = minId - 1;
  }

  addChange(mapElements: MapElementDto[]) {
    this.changeHistory.addChange(mapElements);
  }

  getChanges(): MapElementDto[] {
    return this.changeHistory.getChanges();
  }

  numChanges(): number {
    return this.changeHistory.numChanges();
  }

  undoChange() {
    this.changeHistory.undo();
  }

  redoChange() {
    this.changeHistory.redo();
  }

  undoChangeAvailable(): boolean {
    return this.changeHistory.undoAvailable();
  }

  redoChangeAvailable(): boolean {
    return this.changeHistory.redoAvailable();
  }

  async setMapVersion(mapVersion?: number) {
    this._mapVersion = mapVersion;
    const bounds = this.loadedMapElementBounds ?? new LatLngBounds();
    this.loadedMapElementBounds = undefined;
    await this.loadMapElementsinBoundsIfNecessary(bounds);
  }

  setUnchangedMapElements(mapElements: MapElementDto[]) {
    this.unchangedMapElementsById.clear();
    for (const mapElement of mapElements) {
      if (!Object.values(ElementType).includes(mapElement.elementType)) {
        console.warn(
          `Skipping unknown element type '${mapElement.elementType}' of map element with id ${mapElement.id}`,
        );
        continue;
      }
      this.unchangedMapElementsById.set(mapElement.id, mapElement);
    }
    const currentMapElementsById = new Map(this.unchangedMapElementsById);
    for (const mapElement of this.changeHistory.getChanges()) {
      currentMapElementsById.set(mapElement.id, mapElement);
    }
    this._mapElementsById$.next(currentMapElementsById);
  }

  private mapReferencedIds(
    mapElement: MapElementDto,
    idMap: Map<number, number>,
  ) {
    if (mapElement.properties && 'startNodeId' in mapElement.properties) {
      mapElement.properties.startNodeId =
        idMap.get(mapElement.properties.startNodeId) ??
        mapElement.properties.startNodeId;
    }
    if (mapElement.properties && 'endNodeId' in mapElement.properties) {
      mapElement.properties.endNodeId =
        idMap.get(mapElement.properties.endNodeId) ??
        mapElement.properties.endNodeId;
    }
    if (
      mapElement.properties &&
      'infrastructureId' in mapElement.properties &&
      mapElement.properties.infrastructureId
    ) {
      mapElement.properties.infrastructureId =
        idMap.get(mapElement.properties.infrastructureId) ??
        mapElement.properties.infrastructureId;
    }
    if (
      mapElement.properties &&
      'mutexIds' in mapElement.properties &&
      mapElement.properties.mutexIds?.length
    ) {
      mapElement.properties.mutexIds = mapElement.properties.mutexIds.map(
        (mutexId) => idMap.get(mutexId) ?? mutexId,
      );
    }
    if (
      mapElement.properties &&
      'crossing' in mapElement.properties &&
      mapElement.properties.crossing?.trafficLightIds?.length
    ) {
      mapElement.properties.crossing.trafficLightIds =
        mapElement.properties.crossing.trafficLightIds.map(
          (id) => idMap.get(id) ?? id,
        );
    }
  }

  importMapElements(
    mapElements: MapElementDto[],
    createNewMapElements: boolean,
  ) {
    const idMap = new Map<number, number>();
    for (const mapElement of mapElements) {
      if (createNewMapElements || mapElement.id < 0) {
        const newId = this.generateMapElementId();
        idMap.set(mapElement.id, newId);
        mapElement.id = newId;
      }
    }
    for (const mapElement of mapElements) {
      this.mapReferencedIds(mapElement, idMap);
    }

    this.changeHistory.addChange(mapElements);
  }

  generateMapElementId(): number {
    const id = this.nextElementId;
    --this.nextElementId;
    return id;
  }

  mapVersion(): number | undefined {
    return this._mapVersion;
  }

  connectedEdges(nodeId: number): EdgeDto[] {
    const connectedEdges: EdgeDto[] = [];
    for (const [_, mapElement] of this._mapElementsById$.value) {
      if (
        !mapElement.deleted &&
        isEdge(mapElement) &&
        (mapElement.properties.startNodeId === nodeId ||
          mapElement.properties.endNodeId === nodeId)
      ) {
        connectedEdges.push(structuredClone(mapElement));
      }
    }
    return connectedEdges;
  }

  setBounds(bounds: LatLngBounds) {
    this.loadMapElementsinBoundsIfNecessary(bounds);
  }

  private async loadMapElementsinBoundsIfNecessary(
    bounds: LatLngBounds,
  ): Promise<boolean> {
    if (
      this.loadedMapElementBounds &&
      this.loadedMapElementBounds.contains(bounds.getSouthWest()) &&
      this.loadedMapElementBounds.contains(bounds.getNorthEast())
    ) {
      return false;
    }

    const radius = computeDistanceBetween(
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    );
    const loadSmallMapElements = radius < 10000;
    let loadedMapElements: Promise<MapElementDto[]>;

    if (loadSmallMapElements) {
      const loadingRequestBounds = createBoundsFromCenterAndRadius(
        bounds.getCenter(),
        10000,
      );
      loadedMapElements = this.loadMapElementsInBounds(
        boundingPolygonFromBounds(loadingRequestBounds),
        this._mapVersion,
      );
      this.loadedMapElementBounds = loadingRequestBounds;
    } else {
      loadedMapElements = this.loadOperationRegions(this._mapVersion);
      this.loadedMapElementBounds = undefined;
    }

    this.loading = true;
    try {
      const mapElements = await loadedMapElements;
      this.setUnchangedMapElements(mapElements);
      this.loadingError = undefined;
      this._loadedMapElements$.next();
      return true;
    } catch (e) {
      this.loadingError = 'Could not connect to map server';
    } finally {
      this.loading = false;
    }
    return false;
  }

  private onAddedChange(change: MapElementDto[]) {
    const changedSelectedElement = change.find(
      (m) => m.id === this.selectedMapElement?.id,
    );
    if (changedSelectedElement) {
      if (changedSelectedElement.deleted) {
        this.selectedMapElement = undefined;
      } else {
        this.selectedMapElement = changedSelectedElement;
      }
    }
    for (const mapElement of change) {
      this._mapElementsById$.value.set(mapElement.id, mapElement);
    }
    this._mapElementsById$.next(this._mapElementsById$.value);
  }

  private onRemovedChange(change: MapElementDto[]) {
    const changedSelectedElement = change.find(
      (m) => m.id === this.selectedMapElement?.id,
    );

    for (const mapElement of change) {
      const changedMapElement = this.changeHistory.findChange(mapElement.id);
      if (changedMapElement) {
        this._mapElementsById$.value.set(
          changedMapElement.id,
          changedMapElement,
        );
        continue;
      }
      const unchangedMapElement = this.unchangedMapElementsById.get(
        mapElement.id,
      );
      if (unchangedMapElement) {
        this._mapElementsById$.value.set(mapElement.id, unchangedMapElement);
      } else {
        this._mapElementsById$.value.delete(mapElement.id);
      }
    }
    if (changedSelectedElement && this.selectedMapElement?.id !== undefined) {
      this.selectedMapElement = this._mapElementsById$.value.get(
        this.selectedMapElement.id,
      );
    }
    this._mapElementsById$.next(this._mapElementsById$.value);
  }

  async generateChange(mapElement: MapElementDto): Promise<MapElementDto[]> {
    const clonedMapElement = structuredClone(mapElement);
    const change = [clonedMapElement];

    if (isNode(clonedMapElement)) {
      change.push(...(await this.generateNodeDerivedChanges(clonedMapElement)));
    }

    if (isEdge(clonedMapElement)) {
      updateEdgeLength(clonedMapElement);
      if (
        clonedMapElement.elementType === ElementType.ROAD_EDGE ||
        clonedMapElement.elementType === ElementType.CACHED_ROAD_EDGE
      ) {
        await this.updateRoadEdge(clonedMapElement);
      }

      if (clonedMapElement.deleted) {
        const deletedStartNode = this.createDeletedNodeIfOrphan(
          clonedMapElement.properties.startNodeId,
        );
        if (deletedStartNode) {
          change.push(deletedStartNode);
        }
        const deletedEndNode = this.createDeletedNodeIfOrphan(
          clonedMapElement.properties.endNodeId,
        );
        if (deletedEndNode) {
          change.push(deletedEndNode);
        }
      }
    }
    return change;
  }

  private createDeletedNodeIfOrphan(nodeId: number): MapElementDto | undefined {
    const node = this.getMapElement(nodeId);
    if (!isNode(node)) {
      return undefined;
    }
    if (this.connectedEdges(nodeId).length <= 1) {
      const deletedNode = structuredClone(node);
      deletedNode.deleted = true;
      return deletedNode;
    }
    return undefined;
  }

  private async updateRoadEdge(roadEdge: EdgeDto) {
    const props = roadEdge.properties as RoadEdgePropertiesDto;
    props.estimatedDuration = -1;
    if (roadEdge.elementType === ElementType.CACHED_ROAD_EDGE) {
      return;
    }
    try {
      const directions = await computeDrivingDirections(roadEdge, this);
      if (directions) {
        props.estimatedDuration = directions.duration;
        props.length = directions.distance;
        roadEdge.geometry.coordinates = directions.path.map((latLng) => [
          latLng.lng(),
          latLng.lat(),
        ]);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  private async generateNodeDerivedChanges(
    node: NodeDto,
  ): Promise<MapElementDto[]> {
    const derivedChanges: MapElementDto[] = [];
    const coords = node.geometry.coordinates;
    for (const clonedEdge of this.connectedEdges(node.id)) {
      if (node.deleted) {
        clonedEdge.deleted = true;
      }
      if (clonedEdge.properties.startNodeId === node.id) {
        clonedEdge.geometry.coordinates[0] = coords;
        const derivedChange = await this.generateChange(clonedEdge);
        derivedChanges.push(...derivedChange);
      }
      if (clonedEdge.properties.endNodeId === node.id) {
        clonedEdge.geometry.coordinates[
          clonedEdge.geometry.coordinates.length - 1
        ] = coords;
        const derivedChange = await this.generateChange(clonedEdge);
        derivedChanges.push(...derivedChange);
      }
    }
    return derivedChanges;
  }
}
