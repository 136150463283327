import { HandoverType, Order, OrderStatus } from '../core/order/order';

export function isOrderLate(order: Order) {
  return order.handovers.some((handover, index) => {
    return (
      (index > order.currentHandoverIndex ||
        (index === order.currentHandoverIndex &&
          order.status !== OrderStatus.WAITING_FOR_HANDOVER)) &&
      !!handover.noLaterThan &&
      !!handover.estimatedArrivalTime &&
      new Date(handover.estimatedArrivalTime).getTime() >
        new Date(handover.noLaterThan).getTime()
    );
  });
}

export function orderLateness(
  order: Order,
  handoverType: HandoverType,
): number | undefined {
  const nextNoLaterThanConstraintHandover = order.handovers.find(
    (handover, index) => {
      return (
        order.handovers[index]?.handoverType === handoverType &&
        (index > order.currentHandoverIndex ||
          (index === order.currentHandoverIndex &&
            order.status !== OrderStatus.WAITING_FOR_HANDOVER)) &&
        !!handover.noLaterThan &&
        !!handover.estimatedArrivalTime
      );
    },
  );
  if (!nextNoLaterThanConstraintHandover) {
    return undefined;
  }
  return (
    (new Date(
      nextNoLaterThanConstraintHandover.estimatedArrivalTime!,
    ).getTime() -
      new Date(nextNoLaterThanConstraintHandover.noLaterThan!).getTime()) /
    1000
  );
}
