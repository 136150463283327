import { Component, Input } from '@angular/core';
import { combineLatest, EMPTY, merge, Observable, of, throwError } from 'rxjs';
import { BackendService } from '../core/backend.service';
import { Operation, OrderOperation } from '../operations/operation';

import { RobotDto } from '../core/robots-service/backend/robot.dto';
import { visiblePageTimer } from '../../utils/page-visibility';
import { filter, exhaustMap, map, catchError } from 'rxjs/operators';
import { Order } from '../core/order/order';
import {
  ElementType,
  MapElementDto,
  RobotQueueEdgePropertiesDto,
} from '@cartken/map-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConfirmationDialog,
  ConfirmationDialogData,
} from '../core/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmergencyStopActionService } from './emergency-stop-action.service';

const DATA_POLLING_INTERVAL_MILLIS = 5000;
const OPERATION_POLLING_INTERVAL_MILLIS = 10 * 1000;

const MILLIS_IN_HOUR = 1000 * 60 * 60;

function lastHourIsoTime() {
  return new Date(Date.now() - MILLIS_IN_HOUR).toISOString();
}

@Component({
  selector: 'app-operation-overview',
  templateUrl: './operation-overview.component.html',
  styleUrls: ['./operation-overview.component.sass'],
})
export class OperationOverviewComponent {
  robots$: Observable<RobotDto[]>;
  orders$: Observable<Order[]>;
  finishedOrders$: Observable<Order[]>;
  activeWaitingQueueNames$: Observable<string[]>;
  availableWaitingQueues$: Observable<MapElementDto[]>;
  storageMapElement$: Observable<MapElementDto | undefined>;

  displayName = '';
  operationId = '';
  rejectedOrdersWarningThreshold = 0.1;
  emergencyStopActive = false;

  @Input()
  set operation(operation: Operation) {
    this.operationId = operation.id;
    this.displayName = operation.displayName ?? this.displayName;
    if (operation.operationData?.rejectedOrderWarningThreshold) {
      this.rejectedOrdersWarningThreshold =
        operation.operationData.rejectedOrderWarningThreshold;
    }
    this.emergencyStopActive = operation.emergencyStopActive ?? false;
  }

  constructor(
    private backendService: BackendService,
    private emergencyStopActionService: EmergencyStopActionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.robots$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS * 10).pipe(
        exhaustMap(() =>
          this.backendService.get(
            `/robots?assigned_operation_id=${this.operationId}`,
          ),
        ),
      ),
    );

    this.orders$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS).pipe(
        exhaustMap(() =>
          this.backendService.get(
            `/orders?operation_id=${this.operationId}&status=active&bulk=true`,
          ),
        ),
      ),
    );

    this.finishedOrders$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS).pipe(
        exhaustMap(() =>
          this.backendService.get(
            `/orders?operation_id=${
              this.operationId
            }&status=final&bulk=true&after=${lastHourIsoTime()}`,
          ),
        ),
      ),
    );

    const operationData$ = visiblePageTimer(
      0,
      OPERATION_POLLING_INTERVAL_MILLIS,
    ).pipe(
      exhaustMap(() =>
        this.backendService
          .get<Operation>(`/operations/${this.operationId}`)
          .pipe(catchError(() => EMPTY)),
      ),
    );

    const queuesInOperationRegion$ = operationData$.pipe(
      map((operationData) => operationData?.operationRegion),
      filter((operationRegion) => operationRegion !== undefined),
      exhaustMap((operationRegion) => {
        const requestPath = `/map?element-types=${ElementType.ROBOT_QUEUE_EDGE}`;
        const boundingPolygon = operationRegion?.coordinates;
        const boundsQuery = boundingPolygon?.length
          ? `&region-polygon=${JSON.stringify(boundingPolygon)}`
          : '';
        return this.backendService
          .get<MapElementDto[]>(requestPath + boundsQuery)
          .pipe(catchError(() => of([])));
      }),
    );

    this.availableWaitingQueues$ = merge(of([]), queuesInOperationRegion$).pipe(
      map((queues: MapElementDto[]) =>
        queues.filter((queue) => {
          const props = queue.properties as RobotQueueEdgePropertiesDto;
          return (
            props?.queueSlotPriorities?.length > 0 &&
            props?.names?.at(0)?.includes('waiting')
          );
        }),
      ),
    );

    const orderOperationData$ = operationData$.pipe(
      map((operation) => operation.operationData),
      filter(
        (
          operationData,
        ): operationData is Exclude<typeof operationData, undefined> =>
          operationData !== undefined,
      ),
    );

    this.activeWaitingQueueNames$ = merge(
      of([]),
      orderOperationData$.pipe(
        map((operation: OrderOperation) =>
          (operation.waitingQueues ?? []).map((q) => q.name),
        ),
      ),
    );

    this.storageMapElement$ = combineLatest(
      [orderOperationData$, queuesInOperationRegion$],
      (operation: OrderOperation, robotQueues: MapElementDto[]) => {
        const storageLocationId = operation.storageLocationId;
        if (storageLocationId === undefined) {
          return;
        }
        return robotQueues.find((queue) => {
          const names =
            (queue.properties as RobotQueueEdgePropertiesDto)?.names ?? [];
          return names.some((name) => name === storageLocationId);
        });
      },
    );
  }

  onEmergencyButtonClicked() {
    if (this.emergencyStopActive) {
      this.deactivateEmergencyStop();
      return;
    }
    this.activateEmergencyStop();
  }

  activateEmergencyStop() {
    this.dialog
      .open<ConfirmationDialog, ConfirmationDialogData>(ConfirmationDialog, {
        data: {
          message: 'Activate emergency stop?',
        },
        position: { top: '10%' },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.emergencyStopActionService
          .activate(this.operationId)
          .subscribe(() => {
            this.emergencyStopActive = true;
            this.snackBar.open('Emergency Stop Activated', undefined, {
              verticalPosition: 'top',
              duration: 2000,
            });
          });
      });
  }

  deactivateEmergencyStop() {
    this.dialog
      .open<ConfirmationDialog, ConfirmationDialogData>(ConfirmationDialog, {
        data: {
          message: 'Deactivate emergency stop?',
        },
        position: { top: '10%' },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.emergencyStopActionService
          .deactivate(this.operationId)
          .subscribe(() => {
            this.emergencyStopActive = false;
            this.snackBar.open('Emergency Stop Deactivated', undefined, {
              verticalPosition: 'top',
              duration: 2000,
            });
          });
      });
  }
}
