import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, OperatorFunction, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private errorMsgs = new Set<string>();

  private _errorMsgs$ = new BehaviorSubject<string[]>([]);
  errorMsgs$ = this._errorMsgs$.asObservable();

  private updateErrorMsg() {
    this._errorMsgs$.next(Array.from(this.errorMsgs.values()));
  }

  reportError(errorMsg: string) {
    this.errorMsgs.add(errorMsg);
    this.updateErrorMsg();
  }

  dismissError(errorMsg: string) {
    this.errorMsgs.delete(errorMsg);
    this.updateErrorMsg();
  }

  private catchStreamError<V>(errorMsg: string): OperatorFunction<V, V> {
    return catchError(() => {
      this.reportError(errorMsg);
      return EMPTY;
    });
  }

  private dismissStreamErrors<V>(errorMsg: string): OperatorFunction<V, V> {
    return tap(() => this.dismissError(errorMsg));
  }

  handleStreamErrors<V>(errorMsg: string): OperatorFunction<V, V> {
    return (source$: Observable<V>): Observable<V> => {
      return source$.pipe(
        this.dismissStreamErrors(errorMsg),
        this.catchStreamError(errorMsg),
      );
    };
  }

  isInErrorState(): boolean {
    return this.errorMsgs.size > 0;
  }
}
