import { Injectable } from '@angular/core';
import { UserSessionEventTrackingService } from '../../core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../../../app/core/user-session/user-session-interaction-events';
import { visiblePageTimer } from '../../../utils/page-visibility';
import { millisBetween } from '../../../utils/millis-between';
import { BehaviorSubject, Subject, finalize, interval, takeUntil } from 'rxjs';
import { UserSessionSystemEventName } from '../../../app/core/user-session/user-session-system-events';

export const DEAD_MAN_INTERVAL_COUNT = 15;
const DEADMAN_CHECK_IN_INTERVAL = 15 * 1000;
const DEAD_MAN_INTERACTION_GAP_THRESHOLD = 1000 * 30;

@Injectable({
  providedIn: 'root',
})
export class DeadmanSwitchService {
  private stopCountDown$ = new Subject<void>();
  private stopDeadmanSwitch$ = new Subject<void>();

  private _deadmanCountDown$ = new BehaviorSubject<number | null>(null);
  readonly deadmanCountDown$ = this._deadmanCountDown$.asObservable();

  private _triggerDeadmanSwitch$ = new Subject<void>();
  readonly triggerDeadmanSwitch$ = this._triggerDeadmanSwitch$.asObservable();

  constructor(
    private userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {
    userInteractionsTrackingService.interactionEvent$.subscribe(() => {
      this.stopCountDown$.next();
    });
  }

  stopDeadmanCountDown() {
    this.stopCountDown$.next();
    this._deadmanCountDown$.next(null);
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.DEADMAN_SWITCH_BUTTON_CLICKED,
    );
  }

  finishDeadmanSwitch() {
    this.stopDeadmanSwitch$.next();
    this.stopDeadmanCountDown();
  }

  startDeadmanSwitch() {
    visiblePageTimer(DEADMAN_CHECK_IN_INTERVAL, DEADMAN_CHECK_IN_INTERVAL)
      .pipe(takeUntil(this.stopDeadmanSwitch$))
      .subscribe(() => {
        const lastInteractionTime =
          this.userInteractionsTrackingService.getLastInteractionTime();
        const currentTime = new Date();
        if (
          millisBetween(lastInteractionTime, currentTime) >
          DEAD_MAN_INTERACTION_GAP_THRESHOLD
        ) {
          if (this._deadmanCountDown$.value === null) {
            this.userInteractionsTrackingService.trackSystemEvent(
              UserSessionSystemEventName.DEADMAN_SWITCH_TRIGGERED,
            );
            this.stopCountDown$.next();

            interval(1000)
              .pipe(
                takeUntil(this.stopCountDown$),
                finalize(() => this._deadmanCountDown$.next(null)),
              )
              .subscribe((count) => {
                const secondsLeft = DEAD_MAN_INTERVAL_COUNT - count;
                this._deadmanCountDown$.next(secondsLeft);
                if (secondsLeft < 0) {
                  this._deadmanCountDown$.next(secondsLeft);
                  this._triggerDeadmanSwitch$.next();
                  this.userInteractionsTrackingService.trackSystemEvent(
                    UserSessionSystemEventName.DEADMAN_SWITCH_EXPIRED,
                  );
                  this.stopCountDown$.next();
                  this.stopDeadmanSwitch$.next();
                }
              });
          }
        } else {
          this.stopCountDown$.next();
        }
      });
  }
}
