import { MatSnackBar } from '@angular/material/snack-bar';
import { RtcEvents } from '../webrtc/rtc-events';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { firstValueFrom } from 'rxjs';

export class DataAcquisition {
  constructor(
    private readonly rtcSendDataChannels: RtcSendDataChannels,
    private readonly rtcEvents: RtcEvents,
    private readonly snackBar: MatSnackBar,
  ) {}

  private getFreeRobotDiskSpace(): Promise<number> {
    return firstValueFrom(this.rtcEvents.freeRobotDiskSpace$);
  }

  async triggerSnapshot(): Promise<void> {
    const freeRobotDiskSpace = await this.getFreeRobotDiskSpace();
    const freeRobotDiskSpaceGB = Math.round(freeRobotDiskSpace / 2 ** 30);
    if (freeRobotDiskSpaceGB < 5.0) {
      this.snackBar.open(
        `Snapshots disabled. Not sufficient disk space left (${freeRobotDiskSpaceGB} GB)!`,
        undefined,
        {
          duration: 2000,
        },
      );
    } else {
      this.rtcSendDataChannels.sendReliable({
        label: 'triggerSnapshot',
        payload: {},
      });
      this.snackBar.open(
        `Triggering snapshot!  ${freeRobotDiskSpaceGB} GB free.`,
        undefined,
        {
          duration: 2000,
        },
      );
    }
  }

  async triggerDataCollection(): Promise<void> {
    const freeRobotDiskSpace = await this.getFreeRobotDiskSpace();
    const freeRobotDiskSpaceGB = Math.round(freeRobotDiskSpace / 2 ** 30);
    if (freeRobotDiskSpaceGB < 30) {
      this.snackBar.open(
        `Data collection disabled. Not sufficient disk space left (${freeRobotDiskSpaceGB} GB)!`,
        undefined,
        {
          duration: 2000,
        },
      );
    } else {
      this.rtcSendDataChannels.sendReliable({
        label: 'triggerDataCollectionSnapshot',
        payload: {},
      });
      this.snackBar.open(
        `Triggering data collection!  ${freeRobotDiskSpaceGB} GB free.`,
        undefined,
        {
          duration: 2000,
        },
      );
    }
  }
}
