import { BehaviorSubject } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { ManualRobotControl } from '../webrtc/types';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { completeAll } from '../../../../utils/complete-all';

export class ManualControl extends Finalizable {
  private readonly _manualControl$ = new BehaviorSubject<
    ManualRobotControl | undefined
  >(undefined);
  readonly manualControl$ = this._manualControl$.asObservable();

  private manualControlResetTimeout?: ReturnType<typeof setTimeout>;

  constructor(private readonly rtcSendDataChannels: RtcSendDataChannels) {
    super();
  }

  manualControl(control: ManualRobotControl) {
    this._manualControl$.next(control);
    this.rtcSendDataChannels.sendUnreliable({
      label: 'manualControl',
      payload: control,
    });
    this.resetManualControlWithTimeout();
  }

  private resetManualControlWithTimeout() {
    if (this.manualControlResetTimeout) {
      clearTimeout(this.manualControlResetTimeout);
    }
    this.manualControlResetTimeout = setTimeout(() => {
      this._manualControl$.next(undefined);
    }, 300);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._manualControl$);
  }
}
