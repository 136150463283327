import { Component, Input } from '@angular/core';
import { RobotCommunication } from '../../core/robots-service/robot-communication';

@Component({
  selector: 'optional-status-tree',
  templateUrl: './optional-status-tree.component.html',
  styleUrls: ['./optional-status-tree.component.sass'],
})
export class OptionalStatusTreeComponent {
  @Input()
  robotCommunication!: RobotCommunication;

  isOpen = false;

  openRobotStatus() {
    this.isOpen = true;
  }
}
