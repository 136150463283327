import { Component, Input, OnChanges } from '@angular/core';
import { RobotIssue, RobotIssueSeverity } from '../robot-issue.types';

@Component({
  selector: 'app-robot-issues-widget',
  styleUrls: ['./robot-issues-widget.component.sass'],
  templateUrl: './robot-issues-widget.component.html',
})
export class RobotIssuesWidgetComponent implements OnChanges {
  @Input() issues!: RobotIssue[];

  ngOnChanges(): void {
    // Filter to only display BREAKING issues
    this.issues = this.issues.filter(
      (issue) => issue.severity === RobotIssueSeverity.BREAKING,
    );
  }
}
