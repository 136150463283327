import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface WarningDialogData {
  textLine1: string;
  textLine2: string;
  confirmText: string;
  dismissText: string;
}

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.sass'],
})
export class WarningDialogComponent {
  textLine1: string;
  textLine2: string;

  confirmText: string;
  dismissText: string;

  constructor(
    public dialogRef: MatDialogRef<WarningDialogData, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
  ) {
    this.textLine1 = data.textLine1;
    this.textLine2 = data.textLine2;

    this.confirmText = data.confirmText;
    this.dismissText = data.dismissText;
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }
}
