import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BackendService } from './backend.service';
import { RouteDto } from './robots-service/backend/types';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(private backendService: BackendService) {}

  getRoute(
    fromLat: number,
    fromLng: number,
    toLat: number,
    toLng: number,
    operationId?: string,
  ): Observable<RouteDto | undefined> {
    const routingParams = {
      origin: { point: [fromLng, fromLat] },
      destinations: [{ point: [toLng, toLat] }],
      operationId,
    };

    return this.backendService
      .post(`/route`, routingParams)
      .pipe(map((result) => result[0].route));
  }
}
