import { Component } from '@angular/core';
import { CollectionDto } from './ml-data-types';

@Component({
  selector: 'app-ml-platform',
  templateUrl: './ml-platform.component.html',
  styleUrls: ['./ml-platform.component.sass'],
})
export class MlPlatformComponent {
  selectedCollection: CollectionDto | null = null;

  setCollection(collection: CollectionDto) {
    console.log('setCollection', collection);
    this.selectedCollection = collection;
  }
}
