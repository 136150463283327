<!-- we need to figure out an efficient way to count orders per day -->
<div class="section-title">Orders</div>

<mat-chip-set aria-label="Orders">
  <mat-chip-option
    (click)="toggleSelection('active')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'active'"
    disableRipple
  >
    Active - {{ activeOrders.length }}
  </mat-chip-option>
  <mat-chip-option
    (click)="toggleSelection('delayed')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'delayed'"
  >
    Delayed - {{ delayedOrders.length }}
  </mat-chip-option>

  <mat-chip-option
    (click)="toggleSelection('late')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'late'"
  >
    Late - {{ lateOrders.length }}
  </mat-chip-option>

  <mat-chip-option
    (click)="toggleSelection('waiting-duration-exceeded')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'waiting-duration-exceeded'"
  >
    Waiting Duration Exc. - {{ waitingDurationExceededOrders.length }}
  </mat-chip-option>

  <mat-chip-option
    (click)="toggleSelection('fulfilled')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'fulfilled'"
  >
    Fulfilled - {{ fulfilledOrders.length }}
  </mat-chip-option>

  <mat-chip-option
    (click)="toggleSelection('failed')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'failed'"
  >
    Failed - {{ failedOrders.length }}
  </mat-chip-option>

  <mat-chip-option
    (click)="toggleSelection('rejected')"
    class="remove-chip-selection-shade"
    [class.warn]="showRejectionWarning"
    [selected]="selected === 'rejected'"
  >
    Rejected - {{ rejectedOrders.length }}
  </mat-chip-option>
</mat-chip-set>

<table
  mat-table
  matSort
  [dataSource]="displayedOrders"
  *ngIf="displayedOrders.length"
>
  <ng-container matColumnDef="robot">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Robot</th>
    <td mat-cell *matCellDef="let order">
      <div *ngIf="order" class="robot-img-name">
        <img src="assets/robot.png" width="30" style="margin-right: 5px" />

        <span class="robot-name">
          {{ order.robot }}
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="created">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Created</th>
    <td mat-cell *matCellDef="let order">
      {{ order.created | prettyTime }}
    </td>
  </ng-container>

  <ng-container matColumnDef="testOrder">
    <th mat-header-cell *matHeaderCellDef>Test Order</th>
    <td mat-cell *matCellDef="let order">
      <mat-icon *ngIf="order.testOrder">build</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>ID / External ID</th>
    <td mat-cell *matCellDef="let order">
      {{ order.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="pickup">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Pickup</th>
    <td mat-cell *matCellDef="let order">
      {{ order.pickup }}
    </td>
  </ng-container>

  <ng-container matColumnDef="dropoff">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Dropoff</th>
    <td mat-cell *matCellDef="let order">
      {{ order.dropoff }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Status</th>
    <td mat-cell *matCellDef="let order">
      {{ order.status }}
    </td>
  </ng-container>

  <ng-container matColumnDef="pickupLateness">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Pickup Lateness</th>
    <td mat-cell *matCellDef="let order" [class.warn]="order.pickupLate">
      <span *ngIf="order.pickupLateness !== undefined"
        >{{ order.pickupLateness / 60 | number: '1.0-0' }} min
        {{ order.pickupLate ? 'late' : 'buffer' }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dropoffLateness">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Dropoff Lateness</th>
    <td mat-cell *matCellDef="let order" [class.warn]="order.dropoffLate">
      <span *ngIf="order.dropoffLateness !== undefined">
        {{ order.dropoffLateness / 60 | number: '1.0-0' }} min
        {{ order.dropoffLate ? 'late' : 'buffer' }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="icons">
    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let order">
      <div class="icons">
        <button
          mat-icon-button
          matTooltip="Edit Order State"
          (click)="$event.stopPropagation(); editOrder(order)"
        >
          <mat-icon>edit_note</mat-icon>
        </button>
        <span style="padding-right: 5px"></span>
        <button
          mat-icon-button
          aria-label="Cancel Order"
          (click)="$event.stopPropagation(); cancelOrder(order)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let order; columns: displayedColumns"
    [class.order-table-red]="order.actionRequired"
    class="element-row"
    (click)="showOrderDetails(order)"
  ></tr>
</table>

<!-- tables for robots -->
