import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pick-icon',
  templateUrl: './pick-icon.component.html',
  styleUrls: ['./pick-icon.component.sass'],
})
export class PickIconComponent {
  @Input()
  isPicked: boolean = false;

  constructor() {}
}
