<mat-tree
  [dataSource]="treeDataSource"
  [treeControl]="treeControl"
  class="trigger-service-config-tree"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>

      <mat-form-field appearance="fill" *ngIf="node.type == 'number'">
        <mat-label>{{ node.name }}</mat-label>
        <input
          matInput
          type="number"
          (input)="onChangeNumberField($event.target.value, node)"
          value="{{ node.val }}"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="node.type == 'string'">
        <mat-label>{{ node.name }}</mat-label>
        <input
          matInput
          type="string"
          (input)="onChangeStringField($event.target.value, node)"
          value="{{ node.val }}"
        />
      </mat-form-field>

      <mat-slide-toggle
        style="margin-left: 16px"
        color="warn"
        *ngIf="node.type == 'boolean'"
        [checked]="node.val"
        (change)="onChangeBooleanField($event.checked, node)"
        >{{ node.name }}</mat-slide-toggle
      >

      <p *ngIf="node.type == 'display_only'">
        {{ node.name }} : {{ stringify(node.val) }}
      </p>
    </li>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button
          mat-icon-button
          matTreeNodeToggle
          (click)="toggleExpansion(node)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ node.name }}
      </div>
      <ul
        [class.trigger-service-config-tree-invisible]="
          !treeControl.isExpanded(node)
        "
      >
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
