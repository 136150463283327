import { fromEvent, merge, Observable, of, partition, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

export const [inFocus$, focusLost$] = partition(
  fromEvent(document, 'visibilitychange'),
  () => document.visibilityState === 'visible',
);

export function visiblePageTimer(
  initialDelayMs?: number,
  intervalDurationMs?: number,
): Observable<number> {
  const initialValue$ = of(1);
  return merge(initialValue$, inFocus$).pipe(
    switchMap(() =>
      (intervalDurationMs
        ? timer(initialDelayMs ?? 0, intervalDurationMs)
        : timer(initialDelayMs ?? 0)
      ).pipe(takeUntil(focusLost$)),
    ),
  );
}
