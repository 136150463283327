import { Component, Input } from '@angular/core';
import { UnsupervisedAutonomyState } from '../../../../core/robots-service/backend/robot.dto';
import { UnsupervisedAutonomyUIState } from 'src/app/core/robots-service/local-logic/unsupervised-autonomy';

@Component({
  selector: 'app-unsupervised-autonomy-interaction',
  templateUrl: './unsupervised-autonomy-interaction.component.html',
  styleUrls: ['./unsupervised-autonomy-interaction.component.sass'],
})
export class UnsupervisedAutonomyInteractionComponent {
  remainingUnsupervisedAutonomyDistanceText: string = '';
  unsupervisedAutonomyAvailable = false;
  unsupervisedAutonomyActive = false;

  private _state?: UnsupervisedAutonomyUIState;
  constructor() {}

  @Input()
  set state(inputState: UnsupervisedAutonomyUIState | undefined | null) {
    this._state = inputState ?? undefined;
    if (this._state === undefined) {
      return;
    }
    this.unsupervisedAutonomyAvailable = this._state?.available ?? false;
    this.unsupervisedAutonomyActive = this._state?.active ?? false;
    const remainingDistance = this._state.remainingDistance;
    if (!remainingDistance || remainingDistance < 1 || !this._state.available) {
      this.remainingUnsupervisedAutonomyDistanceText = '';
      return;
    }
    if (remainingDistance > 99) {
      this.remainingUnsupervisedAutonomyDistanceText = `99+ m`;
      return;
    }
    this.remainingUnsupervisedAutonomyDistanceText = `${Math.trunc(
      remainingDistance,
    )}m`;
  }

  get state(): UnsupervisedAutonomyUIState | undefined | null {
    return this._state;
  }
}
