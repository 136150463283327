import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Robot } from '../robot.types';
import { groupBy } from '../../../utils/group-by';
import { getOrCreate } from '../../../utils/get-or-create';
import { RobotIssueSeverity } from '../../../app/core/robot-issues';

export interface RobotsReportData {
  selectedRobots: Robot[];
}

type OperationRobotReport = {
  operationId: string;
  operational: Robot[];
  degraded: Robot[];
  broken: Robot[];
};

function createOperationRobotReport(operationId: string): OperationRobotReport {
  return {
    operationId,
    operational: [],
    degraded: [],
    broken: [],
  };
}

function updateRobotReport(robotReport: OperationRobotReport, robots: Robot[]) {
  for (const robot of robots) {
    if (robot.highestIssueSeverity === RobotIssueSeverity.BREAKING) {
      robotReport.broken.push(robot);
    } else if (robot.highestIssueSeverity === RobotIssueSeverity.DEGRADED) {
      robotReport.degraded.push(robot);
    } else {
      robotReport.operational.push(robot);
    }
  }
}

function createOperationsToRobotReport(selectedRobot: Robot[]) {
  const operationsToRobotReport = new Map<string, OperationRobotReport>();
  const operationToRobots = groupBy(
    selectedRobot,
    (robot) => robot.assignedOperationId ?? '',
  );
  for (const [operationId, robots] of operationToRobots) {
    const robotReport = getOrCreate(operationsToRobotReport, operationId, () =>
      createOperationRobotReport(operationId),
    );
    updateRobotReport(robotReport, robots);
  }

  return Array.from(operationsToRobotReport.values()).sort((report1, report2) =>
    report1.operationId.localeCompare(report2.operationId),
  );
}

@Component({
  selector: 'app-robots-report-dialog',
  templateUrl: './robots-report-dialog.component.html',
  styleUrls: ['./robots-report-dialog.component.sass'],
})
export class RobotsReportComponent {
  operationsToRobotReport: OperationRobotReport[];

  constructor(@Inject(MAT_DIALOG_DATA) dialogInput: RobotsReportData) {
    this.operationsToRobotReport = createOperationsToRobotReport(
      dialogInput.selectedRobots,
    );
  }
}
