<div class="background">
  <div class="container">
    <mat-card *ngIf="requiredRoles$ | async as roles" class="error-card">
      <span class="insufficient-privileges"
        >Insufficient privileges, one of these roles is required</span
      >
      <div class="role-badge" *ngFor="let role of roles">{{ role }}</div>
    </mat-card>

    <div *ngIf="auth.user$ | async; then authenticated; else guest">
      <!-- template will replace this div -->
    </div>

    <!-- User NOT logged in -->
    <ng-template #guest>
      <!-- user/password sign in -->
      <mat-card class="login">
        <mat-card-content>
          <img
            src="assets/cartken-logo-dark-teal.png"
            alt="cartken logo"
            width="100%"
          />
          <form
            class="login-form-style"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
          >
            <h2 class="title">Sign In</h2>
            <mat-error *ngIf="loginFailed"> Failed to login </mat-error>
            <mat-form-field>
              <input
                matInput
                placeholder="Username"
                formControlName="username"
                required
              />
              <mat-error> Please provide a valid username </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="password"
                placeholder="Password"
                formControlName="password"
                required
              />
              <mat-error> Please provide a valid password </mat-error>
            </mat-form-field>
            <button class="button sign-in-button">Sign in</button>
          </form>
          <div class="divider-div">
            <div class="divider" data-label="or"></div>
          </div>
          <!-- google sign in -->
          <a
            class="button google-sign-in-button"
            (click)="this.auth.signInWithGoogle()"
          >
            <div class="google-logo">
              <img
                src="assets/google-logo-icon-sign-in.svg"
                width="20px"
                height="20px"
              />
            </div>
            <div class="google-text">Sign in with Google</div>
          </a>
          <br />
          <a class="button sign-in-with-sso-button" [routerLink]="'sso'">
            <div class="google-text">Sign in with SSO</div>
          </a>
        </mat-card-content>
      </mat-card>
    </ng-template>

    <!-- User logged in -->
    <ng-template #authenticated>
      <div *ngIf="auth.user$ | async as user">
        <mat-card class="loging-cart">
          <mat-card-header>
            <img
              mat-card-avatar
              src="{{ (auth.user$ | async)?.pictureUrl }}"
              class="google-profile-img"
            />
            <mat-card-title>{{ user.displayName }}</mat-card-title>
            <mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
          </mat-card-header>
          <p>Username: {{ user.username }}</p>
          <div>
            Roles:
            <div class="role-badge" *ngFor="let role of user.roles">
              {{ role }}
            </div>
          </div>

          <div class="buttons-container">
            <button
              mat-raised-button
              class="loging-home-buttons"
              (click)="logout()"
            >
              Logout
            </button>
            <button
              mat-raised-button
              class="loging-home-buttons"
              [routerLink]="''"
            >
              Home
            </button>
          </div>
        </mat-card>
      </div>
    </ng-template>
  </div>
</div>
