<div
  *ngIf="trackingCode$ | async"
  onselectstart="return false"
  id="mainBody"
  class="centered-element"
>
  <div *ngIf="showMap" class="map">
    <map
      class="inner-map"
      [mapOptions]="mapOptions"
      (mapReady)="onMap($event)"
    ></map>
    <button
      mat-mini-fab
      *ngIf="(maxDropoffRefinementDistance$ | async) !== 0"
      (click)="onRefinementClick()"
      class="refine-map-button"
      color="basic"
      matTooltip="Refine meeting point"
    >
      <mat-icon>edit_location_alt</mat-icon>
    </button>
  </div>

  <open-close-animation-dialog
    *ngIf="showOpenLidVideo"
    #videoContainer
    class="map"
    fallbackMsg="Please open the robot lid with you hand"
    [videoPath]="openAnimationSrc"
    initialFrameTime="2.4"
  ></open-close-animation-dialog>

  <open-close-animation-dialog
    fallbackMsg="Please close the robot lid with you hand"
    [videoPath]="closeAnimationSrc"
    initialFrameTime="1.35"
    *ngIf="showCloseLidVideo"
    #videoContainer
    class="map"
  ></open-close-animation-dialog>

  <div class="handover-tacker-container">
    <div class="handover-tacker">
      <div class="handover-info">
        <ng-template
          ngFor
          let-handoversInfo
          [ngForOf]="handoversInfos$ | async"
        >
          <container-element [ngSwitch]="handoversInfo.type">
            <mat-icon
              *ngSwitchCase="'pickup'"
              [class]="'round-icon'"
              [class.current-handover-icon]="handoversInfo.isCurrent"
              [class.other-handover-icon]="!handoversInfo.isCurrent"
            >
              shopping_basket
            </mat-icon>

            <mat-icon
              *ngSwitchCase="'dropoff'"
              [class]="'round-icon'"
              [class.current-handover-icon]="handoversInfo.isCurrent"
              [class.other-handover-icon]="!handoversInfo.isCurrent"
            >
              flag
            </mat-icon>
          </container-element>

          <div
            class="handover-title"
            [class.current-handover-text]="handoversInfo.isCurrent"
          >
            {{ handoversInfo.name }}

            <div
              *ngIf="
                handoversInfo.remainingSecondsToDropoff as remainingSecondsToDropoff
              "
              class="eta"
            >
              <mat-icon style="padding: 5px"> share_arrival_time </mat-icon>
              <div
                *ngIf="
                  remainingSecondsToDropoff < 60;
                  then secondsArrivingCount;
                  else minutsArrivingCount
                "
              ></div>

              <ng-template #minutsArrivingCount>
                <div style="white-space: nowrap">
                  {{ remainingSecondsToDropoff / 60.0 | number: '1.0-0' }}
                  <span style="font-size: 12px">minutes</span>
                </div>
              </ng-template>
              <ng-template #secondsArrivingCount>
                <div style="white-space: nowrap">
                  {{
                    (remainingSecondsToDropoff / 15 +
                      (1 - ((remainingSecondsToDropoff / 15) % 1))) *
                      15 | number: '1.0-0'
                  }}
                  <span style="font-size: 12px">seconds</span>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="vertical-line-area">
            <div class="vertical-line"></div>
          </div>

          <div class="order-state-area">
            <div
              *ngIf="
                handoversInfo.orderStateDescription as orderStateDescription
              "
              class="order-state"
            >
              <div>
                {{ orderStateDescription }}
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="showUnlockSlider$ | async" class="unlock-menu">
        <div style="width: 100%">
          <app-slide-to-action
            handleTitle="unlock"
            mainTitle="Slide to unlock"
            [cooldownTimeout]="sliderCooldownTimeout"
            (onCompleted)="onUnlockClick()"
          ></app-slide-to-action>
        </div>
      </div>
      <a
        mat-raised-button
        color="primary"
        *ngIf="supportPhoneNumber$ | async as supportPhoneNumber"
        href="tel:{{ supportPhoneNumber }}"
      >
        <div class="call-button-content">
          <mat-icon>call</mat-icon>
          Call support
        </div>
      </a>
    </div>
  </div>
</div>

<div *ngIf="isOffline" class="offline-warning-container">
  <div class="offline-warning">
    <mat-icon>cloud_off</mat-icon>
    <div>You are offline</div>
  </div>
</div>

<div *ngIf="orderNotFound" class="offline-warning-container">
  <div class="offline-warning">
    <mat-icon>question_mark</mat-icon>
    <div>Order not found</div>
  </div>
</div>
