<mat-form-field class="robot-form-menu" style="margin-left: 16px">
  <mat-label>Network Interface</mat-label>
  <mat-select #dataUploadInterfaceSelection>
    <mat-option
      *ngFor="let iface of ifaceList$ | async"
      [value]="iface.interfaceName"
    >
      <span *ngIf="iface.interfaceName"
        >{{ getInterfaceComposedName(iface) }}
        <span *ngIf="iface.minPing">
          ({{ iface.minPing | number: '1.1-1' }}&lt;{{
            iface.avgPing | number: '1.1-1'
          }}&lt;{{ iface.maxPing | number: '1.1-1' }}ms |
          {{ iface.packageLoss | number: '1.0-0' }}% )</span
        ></span
      >
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" style="margin-left: 16px">
  <mat-label>Data budget to add (MB)</mat-label>
  <input
    matInput
    (input)="onChangeDataBudgetMb($event.target.value)"
    type="number"
  />
</mat-form-field>

<button
  mat-raised-button
  (click)="onAddDataBudget()"
  style="margin-left: 16px"
  [disabled]="!(interfaceSelector?.value && dataBudgetMb)"
>
  Add data budget
</button>

<button
  mat-raised-button
  (click)="onResetDataBudget()"
  style="margin-left: 16px"
  [disabled]="!(interfaceSelector?.value != undefined)"
>
  Reset data budget
</button>
