import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { RobotsModule } from '../robots/robots.module';
import { FormsModule } from '@angular/forms';
import { CrossingMonitoringComponent } from './crossing-monitoring.component';
import { CrossingMonitoringLocalStateService } from './crossing-monitoring-local-state.service';

@NgModule({
  declarations: [CrossingMonitoringComponent],
  providers: [CrossingMonitoringLocalStateService],
  imports: [
    CoreModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    RobotsModule,
  ],
})
export class CrossingMonitoringModule {}
