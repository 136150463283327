import { NgModule } from '@angular/core';
import { MlPlatformComponent } from './ml-platform.component';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { DataViewerComponent } from './data-viewer/data-viewer.component';
import { FilterSelectorComponent } from './data-viewer/filter-selector.component';
import { GridImgItemComponent } from './shared/grid-img-item.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { MetadataComponent } from './shared/metadata.component';
import { MlDataService } from './ml-data.service';
import { DataViewerService } from './data-viewer/data-viewer.service';
import { DataViewerStateService } from './data-viewer/data-viewer-state.service';
import { DataViewerGridComponent } from './data-viewer/data-viewer-grid.component';
import { SelectedItemComponent } from './shared/selected-item.component';
import { CollectionComponent } from './collection-viewer/collection.component';
import { DataGridComponent } from './shared/data-grid.component';
import { CollectionGridComponent } from './collection-viewer/collection-grid.component';
import { DataViewerSelectionComponent } from './data-viewer/data-viewer-selection.component';
import { CollectionSelectionComponent } from './collection-viewer/collection-selection.component';
import { CollectionNameInputComponent } from './left-sidebar/collection-name-input.component';
import { MlActionService } from './ml-action.service';
import { SortControlComponent } from './shared/sort-control.component';
import { PickIconComponent } from './shared/pick-icon.component';
import { PickLabelComponent } from './shared/pick-label.component';
import { AddFramesToCollectionDialog } from './shared/add-frames-to-collection-dialog.component';

@NgModule({
  declarations: [
    MlPlatformComponent,
    DataViewerComponent,
    FilterSelectorComponent,
    GridImgItemComponent,
    LeftSidebarComponent,
    MetadataComponent,
    DataViewerGridComponent,
    DataGridComponent,
    SelectedItemComponent,
    CollectionComponent,
    CollectionGridComponent,
    SelectedItemComponent,
    DataViewerSelectionComponent,
    CollectionSelectionComponent,
    CollectionNameInputComponent,
    SortControlComponent,
    PickIconComponent,
    PickLabelComponent,
    AddFramesToCollectionDialog,
  ],
  imports: [CommonModule, CoreModule, MaterialModule, RouterModule],
  providers: [
    MlDataService,
    MlActionService,
    DataViewerService,
    DataViewerStateService,
  ],
})
export class MlPlatformModule {}
