import {
  BehaviorSubject,
  EMPTY,
  merge,
  Observable,
  of,
  pairwise,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Order } from '../../order/order';
import { BackendService } from '../../backend.service';
import { ErrorService } from '../../error-system/error.service';
import { RobotDto, RobotState } from './robot.dto';
import { visiblePageTimer } from '../../../../utils/page-visibility';
import { Finalizable } from '../../../../utils/finalizable';
import { completeAll } from '../../../../utils/complete-all';
import { ResourceType, RouteDto } from './types';
import { HttpErrorResponse } from '@angular/common/http';
import { isDefined } from '../../../../utils/typeGuards';
import { arrayDifference } from '../../../../utils/array-difference';
import { arraysEqual } from '../../../../utils/arrays-equal';
import { UserSessionService } from '../../user-session/user-session.service';

const ROBOT_STATE_POLLING_INTERVAL = 2000;
const ROBOT_ROUTE_POLLING_INTERVAL = 5000;
const ORDERS_POLLING_INTERVAL = 60 * 1000;

export class BackendStatePolling extends Finalizable {
  private _triggerUpdate$ = new Subject<void>();

  readonly robotState$: Observable<RobotState>;
  readonly robotRoute$: Observable<RouteDto>;
  readonly orders$: Observable<Order[]>;
  readonly robotOrderIds$: Observable<string[]>;
  readonly ordersUpdate$: Subject<any>;

  private readonly _isStopped$ = new BehaviorSubject<boolean>(false);
  readonly isStopped$ = this._isStopped$.asObservable();

  constructor(
    readonly robotId: string,
    private readonly backendService: BackendService,
    private readonly errorService: ErrorService,
    private readonly userSessionService: UserSessionService,
  ) {
    super();
    const notStopped = filter(() => !this._isStopped$.value);
    this.ordersUpdate$ = new Subject();

    this.robotState$ = merge(
      visiblePageTimer(0, ROBOT_STATE_POLLING_INTERVAL),
      this._triggerUpdate$,
    ).pipe(
      takeUntil(this.finalized$),
      notStopped,
      exhaustMap(() => {
        return this.backendService.get<RobotDto>(`/robots/${robotId}`).pipe(
          catchError(() => {
            return EMPTY;
          }),
          this.errorService.handleStreamErrors(
            `Failed get state for robot ${this.robotId}!\nTry a page refresh. If the problem persists, contact Cartken!`,
          ),
        );
      }),
      map((robot) => {
        return {
          ...robot,
          updatedAt: new Date(robot.updatedAt ?? '1970-01-01'),
          autonomyEnabledUntil: robot?.autonomyEnabledUntil
            ? new Date(robot?.autonomyEnabledUntil)
            : undefined,
        };
      }),
      filter(isDefined),
      shareReplay(1),
    );

    this.robotRoute$ = merge(
      visiblePageTimer(0, ROBOT_ROUTE_POLLING_INTERVAL),
      this._triggerUpdate$,
    ).pipe(
      takeUntil(this.finalized$),
      notStopped,
      exhaustMap(() => {
        return this.backendService
          .get<RouteDto>(`/robots/${robotId}/route`)
          .pipe(
            catchError((err: HttpErrorResponse): Observable<RouteDto> => {
              if (err.status === 404) {
                return of({
                  geometry: [],
                  distance: 0, // meters
                  duration: 0, // seconds
                });
              }
              return throwError(err);
            }),
            this.errorService.handleStreamErrors(
              `Failed get route ${this.robotId}!\nTry a page refresh. If the problem persists, contact Cartken!`,
            ),
          );
      }),
      shareReplay(1),
    );

    this.robotOrderIds$ = this.robotState$.pipe(
      takeUntil(this.finalized$),
      map((robot: RobotState) => robot.assignedOrderIds),
      shareReplay(1),
    );

    const robotOrderIdsDistinct$ = this.robotOrderIds$.pipe(
      startWith<string[]>([]),
      distinctUntilChanged((prev, current) => {
        return arraysEqual(prev, current);
      }),
      share(),
    );

    const ordersUpdate$ = robotOrderIdsDistinct$.pipe(
      switchMap((orderIds) => {
        return this.userSessionService.resourceUpdatedEvent$.pipe(
          filter((event) => {
            return (
              orderIds.includes(event.resourceId) &&
              event.resourceType === ResourceType.ORDER
            );
          }),
        );
      }),
    );

    this.orders$ = merge(
      ordersUpdate$,
      visiblePageTimer(0, ORDERS_POLLING_INTERVAL),
    ).pipe(
      takeUntil(this.finalized$),
      notStopped,
      switchMap(() => {
        return this.backendService.get(`/orders/robot/${this.robotId}`).pipe(
          catchError((error: any) => {
            if ('status' in error && error.status === 404) {
              return EMPTY;
            }
            return throwError(error);
          }),
          this.errorService.handleStreamErrors(
            `Failed get order list for a robot ${this.robotId}!\nTry a page refresh. If the problem persists, contact Cartken!`,
          ),
        );
      }),
      shareReplay(1),
    );

    robotOrderIdsDistinct$
      .pipe(pairwise())
      .subscribe(([prevIds, currentIds]) => {
        // we're checking array difference so that we don't send order changes constantly
        const [newOrderIds, goneOrderIds] = arrayDifference(
          prevIds,
          currentIds,
        );
        if (newOrderIds.length !== 0 || goneOrderIds.length !== 0) {
          this.userSessionService.updateSubscribedOrders(
            newOrderIds,
            goneOrderIds,
          );
        }
      });
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._triggerUpdate$, this._isStopped$);
  }

  triggerUpdate() {
    this._triggerUpdate$.next(undefined);
  }

  stop() {
    this._isStopped$.next(true);
  }

  start() {
    this._isStopped$.next(false);
  }
}
