<div class="background">
  <div class="container">
    <div>
      <mat-card class="login">
        <mat-card-content>
          <img
            src="assets/cartken-logo-dark-teal.png"
            alt="cartken logo"
            width="100%"
          />
          <form
            class="login-form-style"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
          >
            <h2 class="title">Sign in with SSO</h2>
            <mat-form-field>
              <input
                matInput
                placeholder="E-Mail"
                formControlName="email"
                required
                [(ngModel)]="email"
              />
              <mat-error>{{ errorText }}</mat-error>
            </mat-form-field>

            <button
              mat-stroked-button
              class="button sign-in-with-sso-button"
              [disabled]="errorText || !email"
            >
              Sign in
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
