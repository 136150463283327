import { NgModule } from '@angular/core';
import { OperationsModule } from '../operations/operations.module';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { RobotOperationModule } from '../robots/robot-operation/robot-operation.module';
import { RobotIssuesModule } from '../core/robot-issues';
import { FieldOpsViewComponent } from './field-ops-view.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CoreModule,
    RouterModule,
    RobotIssuesModule,
    OperationsModule,
    RobotOperationModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  declarations: [FieldOpsViewComponent],
  exports: [RobotOperationModule],
})
export class FieldOpsModule {}
