import { map, Observable, takeUntil } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { BackendStatePolling } from '../backend/backend-state-polling';
import { RobotActionRequestManager } from './action-request-manager';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { UserSessionInteractionEventName } from '../../user-session/user-session-interaction-events';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';

export type UnsupervisedAutonomyUIState = {
  available: boolean;
  active: boolean;
  remainingDistance: number;
};

const HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID =
  'handle-unsupervised-autonomy-abort';

function abortDescriptionEnumToText(description: string): string {
  return description
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLocaleLowerCase();
}

export class UnsupervisedAutonomy extends Finalizable {
  // prevent duplicate notifications
  private abortTimestamp: Date | null = null;

  unsupervisedAutonomyState$: Observable<UnsupervisedAutonomyUIState>;

  constructor(
    robotId: string,
    backendStatePolling: BackendStatePolling,
    robotActionRequestManager: RobotActionRequestManager,
    rtcSendDataChannels: RtcSendDataChannels,
    userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    super();

    const unsupervisedAutonomyState$ = backendStatePolling.robotState$.pipe(
      takeUntil(this.finalized$),
      map((robotState) => {
        return robotState.unsupervisedAutonomyState;
      }),
    );

    unsupervisedAutonomyState$.subscribe((state) => {
      if (state?.abortStamp === this.abortTimestamp) {
        return;
      }

      const abortReport = state?.abortReport;

      if (!abortReport) {
        robotActionRequestManager.removeNotification(
          HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID,
        );
        return;
      }

      const abortDescription = abortDescriptionEnumToText(abortReport);
      this.abortTimestamp = state?.abortStamp ?? null;
      robotActionRequestManager.notify({
        actionIdType: HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID,
        actionDescription: `Unsupervised autonomy aborted: ${abortDescription}`,
        actionButton: 'Mark handled',
        onClick: async () => {
          userSessionEventTrackingService.trackInteractionEvent(
            UserSessionInteractionEventName.UNSUPERVISED_AUTONOMY_ABORT_HANDLED,
            {
              robotId,
            },
          );
          rtcSendDataChannels.sendReliable({
            label: 'unsupervisedAutonomyAbortReportHandled',
            payload: {},
          });
        },
      });
    });

    this.unsupervisedAutonomyState$ = unsupervisedAutonomyState$.pipe(
      map((state) => {
        return {
          available: state?.available ?? false,
          active: state?.active ?? false,
          remainingDistance: state?.remainingDistance ?? 0,
        };
      }),
    );
  }

  protected override async onFinalize(): Promise<void> {
    // Nothing
  }
}
