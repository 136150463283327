import { BehaviorSubject } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { EndstopState } from '../webrtc/types';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { completeAll } from '../../../../utils/complete-all';

export class CurbClimbing extends Finalizable {
  private _curbClimbingEnabled$ = new BehaviorSubject<boolean>(false);
  readonly isCurbClimbingOn$ = this._curbClimbingEnabled$.asObservable();

  constructor(private readonly rtcSendDataChannels: RtcSendDataChannels) {
    super();
  }

  enableCurbClimbingMode(curbClimbingMode: boolean): boolean {
    this._curbClimbingEnabled$.next(curbClimbingMode);
    if (curbClimbingMode) {
      return this.rtcSendDataChannels.sendReliable({
        label: 'endstopCommand',
        payload: EndstopState.CurbClimbing,
      });
    } else {
      return this.rtcSendDataChannels.sendReliable({
        label: 'endstopCommand',
        payload: EndstopState.Turning,
      });
    }
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._curbClimbingEnabled$);
  }
}
