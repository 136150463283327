import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { vec2 } from '@tlaukkan/tsm';
import { combineLatest, of, Subject, Observable, firstValueFrom } from 'rxjs';
import { map, takeUntil, take, tap, switchMap } from 'rxjs/operators';
import {
  ManualRobotControl,
  VideoChannel,
} from '../../../core/robots-service/webrtc/types';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';
import { MouseDriveEvent } from '../common/mouse-canvas-events';
import { MouseRobotControl } from '../common/mouse-robot-control';
import { KeyboardManualRobotControl } from '../common/keyboard-manual-robot-control';
import { NormalizedManualRobotControl } from './robot-movement/control-state';
import {
  ControlPriority,
  RobotControlManager,
} from './robot-movement/robot-control-manager';
import { ManualConditionSupervised } from './manual-condition-superviser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { visiblePageTimer } from '../../../../utils/page-visibility';
import { ENABLE_AUTONOMY_FOR } from '../robot-operator-view/robot-operator-control.component';
import { RouteCorridorConfirmationState } from '../common/corridor-confirmation/route-corridor-confirmation-state';
import {
  ControlType,
  HazardLightsState,
} from '../../../core/robots-service/backend/robot.dto';
import { RobotSystemStatus } from '../../../core/robots-service/backend/types';
import { Router } from '@angular/router';
import { UserSessionEventTrackingService } from '../../../core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '../../../core/user-session/user-session-interaction-events';
import { CorridorConfirmationEvent } from '../common/corridor-confirmation/corridor-confirmation.component';

// TODO: Shall be removed as part of CK-828
const robotStateReportBlackList = new Set([
  'Driving to pickup',
  'Driving to dropoff',
  'Waiting for Handover',
  'Driving to waiting queue.',
  'Looking for free waiting queue...',
  'Waiting at waiting queue.',
]);

@Component({
  selector: 'robot-control', // TODO: Fix @angular-eslint/component-selector
  templateUrl: './robot-control.component.html',
  styleUrls: ['./robot-control.component.sass'],
})
export class RobotControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set isKeyboardEnabled(enabled: boolean) {
    this._isKeyboardEnabled = enabled;
    this.keyboardRobotControl.setActive(
      this._isKeyboardEnabled && this.activeControl,
    );
  }
  private _isKeyboardEnabled = false;

  @Output()
  needsAttention = new EventEmitter<boolean>();

  private _robotCommunication?: RobotCommunication;
  @Input()
  set robotCommunication(robot: RobotCommunication | undefined) {
    if (this._robotCommunication === robot) {
      return;
    }
    this.updateRobot(robot);
  }
  get robotCommunication(): RobotCommunication | undefined {
    return this._robotCommunication;
  }

  @Input()
  set activeControl(active: boolean) {
    if (this.manualControlSupervisor.active === active) {
      return;
    }

    this.manualControlSupervisor.active = active;
    this.keyboardRobotControl.setActive(this._isKeyboardEnabled && active);
    if (!active) {
      this.robotControlManager?.clear();
    }
  }
  get activeControl(): boolean {
    return this.manualControlSupervisor.active;
  }

  private _highResolution = false;
  @Input()
  set highResolution(highResolution: boolean) {
    this.robotCommunication?.sendVideoQualityRequest(
      VideoChannel.Default,
      highResolution,
    );
    this._highResolution = highResolution;
  }
  get highResolution(): boolean {
    return this._highResolution;
  }

  statusStrings$: Observable<string[]> = of([]);
  attentionStatusList: RobotSystemStatus[] = [];
  robotStateReport$: Observable<string> = of('');
  userClaimReport$: Observable<string> = of('');
  manualControl$: Observable<ManualRobotControl | undefined> = of(undefined);
  isConnecting$ = of(true);
  overlayIcon?: string;
  supervisionState$ = new Subject<string | undefined>();
  isBatteryCharging$?: Observable<boolean>;
  batteryPercentage$?: Observable<number>;
  operationEmergencyStopActive$?: Observable<boolean>;

  microphoneEnabled$: Observable<boolean> = of(false);

  routeCorridorConfirmationState$: Observable<
    RouteCorridorConfirmationState | undefined
  > = of(undefined);

  private manualControlSupervisor = new ManualConditionSupervised();

  private readonly unsubscribeRobot$ = new Subject<void>();

  private lastMouseRelativePosition = new vec2([0, 0]);

  private mouseRobotControl = new MouseRobotControl();
  private keyboardRobotControl = new KeyboardManualRobotControl();
  private robotControlManager?: RobotControlManager;

  constructor(
    private snackBar: MatSnackBar,
    readonly router: Router,
    private readonly userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {}

  private _destroy = new Subject();

  ngOnInit(): void {
    this.robotCommunication?.isManualMouseControl$
      .pipe(takeUntil(this._destroy))
      .subscribe((isEnabled) => {
        if (this.manualControlSupervisor.manualControl === isEnabled) {
          return;
        }
        if (!isEnabled) {
          this.robotControlManager?.clear();
        }
        this.manualControlSupervisor.manualControl = isEnabled;
      });

    this.microphoneEnabled$ = this.keyboardRobotControl.enableMicrophone$;
    this.microphoneEnabled$
      .pipe(takeUntil(this.unsubscribeRobot$))
      .subscribe((enabled) => {
        this.robotCommunication?.muteMicrophone(!enabled);
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.ENABLED_MICROPHONE,
          { robotId: this.robotCommunication?.robotId, microphoneOn: enabled },
        );
      });

    this.routeCorridorConfirmationState$
      .pipe(takeUntil(this._destroy))
      .subscribe((state) => {
        this.needsAttention.emit(false);

        if (
          undefined !== this.robotCommunication &&
          undefined !== state?.edgeIdToConfirm
        ) {
          this.needsAttention.emit(true);
        }
      });
  }

  ngAfterViewInit() {
    this.updateRobot(this._robotCommunication);
  }

  ngOnDestroy() {
    this.activeControl = false;
    this.updateRobot(undefined);
    this.snackBar.dismiss();
    this._destroy.next(undefined);
  }

  updateManualControl(mouseDrive: MouseDriveEvent) {
    this.mouseRobotControl.onMouseDrive(mouseDrive);
  }

  private updateRobot(robot?: RobotCommunication) {
    if (robot === this.robotCommunication) {
      return;
    }
    this.unsubscribeRobot$.next(undefined);

    this._robotCommunication = robot;

    if (!this.robotCommunication) {
      return;
    }

    const robotCommunication = this.robotCommunication;

    this.extractDataForRobotView(robotCommunication);

    this.subscribe(
      this.robotCommunication.reliableDataChannelOpen$,
      (_, channelOpen) => {
        if (!channelOpen) {
          return;
        }
        this.robotCommunication?.sendVideoQualityRequest(
          VideoChannel.Default,
          this.activeControl,
        );
      },
    );

    this.subscribeManualControl();

    this.subscribeOnKeyboardEvents();

    this.subscribe(robotCommunication.isInControl$, (_, inControl) => {
      this.robotCommunication?.enableCurbClimbingMode(false);
      this.manualControlSupervisor.inControl = inControl;
    });

    this.subscribe(
      robotCommunication.instructorModeActive$,
      (_, instructorModeActive) => {
        this.manualControlSupervisor.instructorModeActive =
          instructorModeActive;
      },
    );

    this.subscribe(robotCommunication.finalized$, () =>
      this.updateRobot(undefined),
    );

    this.routeCorridorConfirmationState$ =
      robotCommunication.routeCorridorConfirmationState$.pipe(
        takeUntil(this.unsubscribeRobot$),
      );
  }

  private extractDataForRobotView(robot: RobotCommunication) {
    this.robotStateReport$ = robot.robotState$.pipe(
      takeUntil(this.unsubscribeRobot$),
      map(({ stateReport }) => stateReport ?? ''),
      map((stateReport) =>
        robotStateReportBlackList.has(stateReport) ? '' : stateReport,
      ),
    );

    this.userClaimReport$ = robot.robotState$.pipe(
      map((robot) => {
        if (!robot.userClaim?.claimedBy) {
          return '';
        }
        const now = new Date();
        const claimedUntil = robot.userClaim?.claimedUntil;
        if (claimedUntil && new Date(claimedUntil) < now) {
          return '';
        }
        const claimedUntilText = claimedUntil
          ? `until ${new Date(claimedUntil).toLocaleString('en-GB')}`
          : '';
        return `Robot is claimed by ${robot.userClaim?.claimedBy} ${claimedUntilText} for development purposes`;
      }),
    );

    robot.videoChannel$
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        map((videoChannel) => {
          console.log('videoChannel', videoChannel);
          return videoChannel === VideoChannel.Reverse;
        }),
        tap((isReversed) => {
          this.mouseRobotControl.reverse = isReversed;
        }),
      )
      .subscribe();

    this.statusStrings$ = combineLatest([
      robot.controlledBy$,
      robot.latency$,
      robot.stats$,
      robot.connectedNetworkInterface$,
      robot.robotState$,
      robot.isInControl$,
    ]).pipe(
      takeUntil(this.unsubscribeRobot$),
      map(
        ([
          controlledBy,
          latency,
          stats,
          connectedNetworkInterface,
          robotState,
          inControl,
        ]) => [
          `Video: ${stats.frameWidth}x${stats.frameHeight}@${stats.fps.toFixed(
            1,
          )}fps, ${(stats.receivedVideoBitsPerSecond / 1e6).toFixed(1)}MBit`,
          `Connection: ${latency.toFixed(0)}ms, ${(
            stats.receivedBitsPerSecond / 1e6
          ).toFixed(1)}MBit${
            connectedNetworkInterface ? `, ${connectedNetworkInterface}` : ''
          }`,
          `Supervised by: ${
            inControl ? 'you' : controlledBy ? controlledBy.displayName : '-'
          }`,
          robotState.assignedOperationId
            ? `Op: ${robotState.assignedOperationId}`
            : '',
        ],
      ),
      map((statusStrings) =>
        statusStrings.filter(
          // It hides the blinking of the values if somebody else controls the robots with autonomy
          (statusString) => statusString.length > ENABLE_AUTONOMY_FOR,
        ),
      ),
    );
    this.isConnecting$ = combineLatest([robot.connected$, robot.isAlive$]).pipe(
      map(([isConnected, isAlive]) => {
        return !isConnected && isAlive;
      }),
    );

    this.isBatteryCharging$ = robot.robotState$.pipe(
      map((robot) => robot.isCharging ?? false),
    );
    this.batteryPercentage$ = robot.robotState$.pipe(
      map((robot) => robot.batteryPercentage ?? 100),
    );

    this.operationEmergencyStopActive$ = robot.robotState$.pipe(
      map((robot) => robot.operationEmergencyStopActive ?? false),
    );

    combineLatest([robot.isAlive$, robot.robotState$])
      .pipe(takeUntil(this.unsubscribeRobot$), takeUntil(this._destroy))
      .subscribe(([isAlive, robotState]) => {
        if (isAlive === false) {
          this.overlayIcon = 'cloud_off';
          this.supervisionState$.next('Robot is offline');
          return;
        }

        if (robotState.arrivedAtStop !== false) {
          this.overlayIcon = 'flag';
          this.supervisionState$.next('Robot has arrived');
          return;
        }

        this.overlayIcon = undefined;
        this.supervisionState$.next(undefined);
      });

    this.manualControl$ = combineLatest([
      robot.manualControl$,
      robot.isInControl$,
    ]).pipe(
      map(([manualControl, isInControl]) => {
        return isInControl ? manualControl : undefined;
      }),
    );

    // For some reason async pipe subscription does not make it emit value
    this.subscribe(robot.robotSystemStatuses$, (_, attentionStatusList) => {
      this.attentionStatusList = attentionStatusList;
    });

    this.robotControlManager?.clear();
    this.robotControlManager =
      this.robotCommunication &&
      new RobotControlManager(
        this.robotCommunication,
        this.manualControlSupervisor,
      );
  }

  private subscribeOnKeyboardEvents() {
    this.subscribe(
      this.keyboardRobotControl.obstacleOverrideControl$,
      (robot) => {
        robot.overrideSafetySupervisor();
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.OVERRIDE_OBSTACLE,
          { robotId: robot.robotId },
        );
      },
    );

    this.subscribe(this.keyboardRobotControl.resetState$, (robot) => {
      robot.sendImageCommand({
        type: 'zoom',
        xFraction: -1,
        yFraction: -1,
      });
      this.robotCommunication?.muteMicrophone(true);
    });

    this.subscribe(this.keyboardRobotControl.toggleHazardLights$, (robot) => {
      if (!this.manualControlSupervisor.inControl) {
        return;
      }
      firstValueFrom(robot.robotState$)
        .then((robotState) => {
          const currentHazardsState =
            robotState.desiredOperatorHazardLightsState;
          const newHazardsState =
            currentHazardsState == HazardLightsState.HAZARD
              ? HazardLightsState.AUTO
              : HazardLightsState.HAZARD;
          this.userInteractionsTrackingService.trackInteractionEvent(
            UserSessionInteractionEventName.TOGGLE_HAZARD_LIGHT_MODE,
            { robotId: robot.robotId, hazardLightState: newHazardsState },
          );
          robot.sendLightingCommand(newHazardsState);
        })
        .catch(() => {});
    });
    this.subscribe(
      this.keyboardRobotControl.videoChannel$,
      (robot, videoChannel) => {
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.VIDEO_CHANNEL_UPDATE,
          { robotId: robot.robotId, videoChannel },
        );
        robot.sendVideoQualityRequest(videoChannel, this._highResolution);
      },
    );

    this.keyboardRobotControl.zoomControl$
      .pipe(
        takeUntil(this.unsubscribeRobot$),
        switchMap((zoom) => (zoom ? visiblePageTimer(0, 500) : of(-1))),
      )
      .subscribe((count) => {
        if (!this.robotCommunication) {
          return;
        }
        const xFraction = count >= 0 ? this.lastMouseRelativePosition.x : -1;
        const yFraction = count >= 0 ? this.lastMouseRelativePosition.y : -1;
        this.robotCommunication.sendImageCommand({
          type: 'zoom',
          xFraction,
          yFraction,
        });
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.ZOOM_ENABLED,
          {
            robotId: this.robotCommunication.robotId,
            x: xFraction,
            y: yFraction,
          },
        );
      });
  }

  private sendRobotControlCommand(
    normalizedControlCommand: NormalizedManualRobotControl,
    priority: ControlPriority,
  ) {
    if (this.robotCommunication) {
      this.robotControlManager?.moveRobot(normalizedControlCommand, priority);
    }
  }

  private async logManualIntervention() {
    if (this.robotCommunication === undefined) {
      return;
    }

    const robotState = await firstValueFrom(
      this.robotCommunication.robotState$.pipe(take(1)),
    );

    if (robotState?.controlType === ControlType.AUTONOMY) {
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.MANUAL_INTERVENTION,
        { robotId: this.robotCommunication.robotId },
      );
    }
  }

  private subscribeManualControl() {
    this.subscribe(this.mouseRobotControl.control$, async (_, control) => {
      // sanity check
      if (this.manualControlSupervisor.manualControl) {
        this.sendRobotControlCommand(control, 5);
        this.userInteractionsTrackingService.trackInteractionEvent(
          UserSessionInteractionEventName.MANUAL_DRIVING_WITH_MOUSE,
          {
            robotId: this.robotCommunication?.robotId,
            acceleration: control.normalizedSpeed,
            turn: control.normalizedTurnRate,
          },
        );
        await this.logManualIntervention();
      }
    });

    this.subscribe(this.keyboardRobotControl.brake$, () => {
      this.sendRobotControlCommand(
        {
          normalizedSpeed: 0,
          normalizedTurnRate: 0,
        },
        10,
      );
      this.userInteractionsTrackingService.trackInteractionEvent(
        UserSessionInteractionEventName.BRAKE_ROBOT,
        { robotId: this.robotCommunication?.robotId },
      );

      this.robotCommunication?.enableAutonomyFor(0);
    });
  }

  private subscribe<T>(
    observable: Observable<T>,
    callback: (robot: RobotCommunication, value: T) => void,
  ): void {
    observable.pipe(takeUntil(this.unsubscribeRobot$)).subscribe((value: T) => {
      if (!this.robotCommunication) {
        return;
      }
      callback(this.robotCommunication, value);
    });
  }

  updateLastMouseRelativePosition(lastMouseRelativePosition: vec2) {
    this.lastMouseRelativePosition = lastMouseRelativePosition;
  }

  confirmRouteCorridorEdgeId(edgeConfirmation: CorridorConfirmationEvent) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.CONFIRM_ROUTE_CORRIDOR,
      {
        robotId: this.robotCommunication?.robotId,
        distanceToCorridor: edgeConfirmation.distanceToCorridor,
        confirmedAfterSeconds: edgeConfirmation.confirmedAfterSeconds,
      },
    );
    this.robotCommunication?.confirmRouteCorridorEdge(edgeConfirmation.edgeId);
  }

  revokeRouteCorridorConfirmation(edgeId: number) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.REVOKE_ROUTE_CORRIDOR,
      { robotId: this.robotCommunication?.robotId, edgeId },
    );
    this.robotCommunication?.revokeRouteCorridorConfirmation();
  }

  abortInfrastructureTransaction() {
    this.robotCommunication?.abortInfrastructureTransaction();
  }

  hideStatus(status: string) {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.HIDE_ATTENTION_STATUS,
      { robotId: this.robotCommunication?.robotId, attentionStatus: status },
    );
    this.robotCommunication?.snoozeStatus(status);
  }

  async arriveAtStop() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ARRIVED_AT_STOP_TRIGGERED,
      { robotId: this.robotCommunication?.robotId },
    );
    await this.robotCommunication?.arrivedAtStop();
  }
}
