export function min(values: number[]): number | undefined {
  if (values.length === 0) {
    return undefined;
  }
  let minValue = values[0]!;
  for (let i = 1; i < values.length; i++) {
    const currentValue = values[i]!;
    if (currentValue < minValue) {
      minValue = currentValue;
    }
  }
  return minValue;
}
