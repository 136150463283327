<div class="cancel-order" mat-dialog-content>
  <mat-form-field appearance="fill" class="cancel-reason-input">
    <mat-label>{{ cancelMessage }}</mat-label>
    <input matInput [(ngModel)]="cancelReason" />
  </mat-form-field>
  <div class="buttons-container">
    <button mat-button (click)="close()">Close</button>
    <button mat-button [disabled]="!cancelReason" (click)="confirmCancel()">
      Cancel order
    </button>
  </div>
</div>
