<robot-control
  [robotCommunication]="supervisionTask?.robotCommunication"
  [activeControl]="true"
  [highResolution]="true"
></robot-control>
<div class="controls">
  <mat-form-field class="robotCommunication-form-menu">
    <mat-label>Hazard Lights</mat-label>
    <mat-select
      [ngModel]="supervisionTask?.robotCommunication.hazardLightsState$ | async"
      [disabled]="!(supervisionTask?.robotCommunication.isInControl$ | async)"
      (selectionChange)="
        supervisionTask?.robotCommunication.sendLightingCommand($event.value)
      "
    >
      <mat-option *ngFor="let state of HAZARD_LIGHTS_STATES" [value]="state">
        {{ state }} ({{
          (supervisionTask?.robotCommunication.robotState$ | async)
            ?.hazardLightsState
        }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button
    mat-raised-button
    (click)="goToSupervision(supervisionTask?.robotCommunication.robotId)"
  >
    Full Robot View
  </button>

  <button
    [disabled]="
      !(supervisionTask?.robotCommunication.enableArrivedButton$ | async)
    "
    mat-raised-button
    (click)="arrivedAtStop()"
  >
    Arrived
  </button>

  <button
    *ngIf="supervisionTask?.task.maxUnsupervisedAutonomyDuration"
    [disabled]="!(supervisionTask?.robotCommunication.isInControl$ | async)"
    mat-raised-button
    (click)="approveAutonomy()"
  >
    Approve Autonomy
  </button>
  <button mat-raised-button (click)="nextTask()">Next task</button>
</div>
