import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebRtcLabels, WebRtcSendMessage } from './types';
import { WebRtcManager } from './webrtc-manager';
import { Finalizable } from '../../../../utils/finalizable';
import { completeAll } from '../../../../utils/complete-all';

function sendData(dataChannel: RTCDataChannel | undefined, data: string) {
  if (!dataChannel || dataChannel.readyState !== 'open') {
    return false;
  }
  try {
    dataChannel.send(data);
  } catch (e) {
    console.error('Failed to send message', data, e);
    return false;
  }
  return true;
}

export class RtcSendDataChannels extends Finalizable {
  private readonly _dataChannelsReady$ = new Subject();

  private reliableDataChannel?: RTCDataChannel;
  private unreliableDataChannel?: RTCDataChannel;

  private readonly _reliableDataChannelOpen$ = new BehaviorSubject<boolean>(
    false,
  );

  readonly reliableDataChannelOpen$ = this._reliableDataChannelOpen$;

  private _dataStream$ = new Subject<unknown>();
  readonly dataStream$ = this._dataStream$.asObservable();

  constructor(private readonly WebRtcManager: WebRtcManager) {
    super();
    this.WebRtcManager.dataChannel$
      .pipe(takeUntil(this.finalized$))
      .subscribe((dataChannel: RTCDataChannel) => {
        this.onDataChannel(dataChannel);
      });
  }

  sendReliable<K extends WebRtcLabels>(message: WebRtcSendMessage<K>) {
    return sendData(this.reliableDataChannel, JSON.stringify(message));
  }

  sendUnreliable<K extends WebRtcLabels>(message: WebRtcSendMessage<K>) {
    return sendData(this.unreliableDataChannel, JSON.stringify(message));
  }

  sendBoth<K extends WebRtcLabels>(message: WebRtcSendMessage<K>) {
    return [this.sendReliable(message), this.sendUnreliable(message)].some(
      (isDone) => isDone,
    );
  }

  private onDataChannel(dataChannel: RTCDataChannel) {
    if (dataChannel.label === 'reliable') {
      this.reliableDataChannel = dataChannel;
      dataChannel.onclose = () => {
        this.reliableDataChannel = undefined;
        this._reliableDataChannelOpen$.next(false);
      };
      dataChannel.onopen = () => {
        this._reliableDataChannelOpen$.next(true);
      };
    } else if (dataChannel.label === 'unreliable') {
      this.unreliableDataChannel = dataChannel;

      dataChannel.onclose = () => {
        this.unreliableDataChannel = undefined;
      };
    } else {
      console.error(`Got unexpected data channel ${dataChannel.label}`);
      return;
    }

    if (
      this.reliableDataChannel !== undefined &&
      this.unreliableDataChannel !== undefined
    ) {
      this._dataChannelsReady$.next(undefined);
    }

    dataChannel.onmessage = (event: MessageEvent) => {
      this._dataStream$.next(event.data);
    };
  }

  protected async onFinalize(): Promise<void> {
    completeAll(
      this._dataChannelsReady$,
      this._reliableDataChannelOpen$,
      this._dataStream$,
    );
  }
}
