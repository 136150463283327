import { millisBetween } from './millis-between';

export class StopWatch {
  private confirmationStarted = new Map<string, Date>();

  constructor(private resetOnStart: boolean) {}

  setResetOnStart(resetOnStart: boolean) {
    this.resetOnStart = resetOnStart;
  }

  start(id: string = 'default') {
    if (!this.confirmationStarted.has(id) || this.resetOnStart) {
      this.confirmationStarted.set(id, new Date());
    }
  }

  getMillisAndStop(id: string = 'default'): number | null {
    const started = this.confirmationStarted.get(id);
    if (started === undefined) {
      return null;
    }
    const duration = millisBetween(started, new Date());
    this.confirmationStarted.delete(id);
    return duration;
  }
}
