import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutDialogComponent } from './shortcut-dialog.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
})
export class ToolbarComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  openHomePage() {
    this.router.navigate(['']);
  }

  openProfile() {
    this.router.navigate(['/login']);
  }

  openShortcuts() {
    this.dialog.open(ShortcutDialogComponent);
  }
}
