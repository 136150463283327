<div class="confirmation-container" *ngIf="state !== undefined">
  <div
    *ngIf="revokeConfirmationAvailable"
    (click)="revokeCorridor()"
    class="backgroud-icon-with-distance"
  >
    <mat-icon class="active-confirmation-icon">delete</mat-icon>
    <div class="confirmation-text">Revoke confirmation</div>
  </div>

  <div
    *ngIf="edgeIdToConfirm"
    (click)="confirmCorridor()"
    class="backgroud-icon-with-distance"
  >
    <mat-icon class="active-confirmation-icon">check</mat-icon>
    <div class="confirmation-text">Confirm corridor</div>
  </div>

  <div
    *ngIf="!edgeIdToConfirm && distanceToConfirmation"
    class="backgroud-icon-with-distance"
  >
    <mat-icon class="confirmation-icon">check</mat-icon>
    <div class="distance-text">
      {{ distanceToConfirmation | number : '1.0-0' }}m
    </div>
  </div>
</div>
