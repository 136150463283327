import {
  COORDINATE_SYSTEM,
  CompositeLayer,
  CompositeLayerProps,
  DefaultProps,
  GetPickingInfoParams,
  Layer,
  LayersList,
  PickingInfo,
  UpdateParameters,
} from '@deck.gl/core/typed';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers/typed';
import type { MeshAttribute, MeshAttributes } from '@loaders.gl/schema';
import { makeHeightMesh } from './height-map-mesh';
import { HeightMap, Texture } from './utils';

const DUMMY_DATA = [1];

const defaultProps: DefaultProps<LocalizationMapTileLevelLayerProps> = {
  data: { type: 'object', value: undefined },
  textures: { type: 'array', value: undefined },
  textureOpacities: { type: 'array', value: undefined, compare: true },
  cellSize: { type: 'number', value: 1.0 },
  minDisplayAltitude: { type: 'number', value: 0.0 },
  maxDisplayAltitude: { type: 'number', value: Infinity },
  altitudeScale: { type: 'number', value: 1.0 },
  altitudeOffset: { type: 'number', value: 0.0 },
};

export type TesselatedHeightMap = {
  loaderData?: object;
  header?: object;
  mode?: number;
  attributes: MeshAttributes;
  indices?: MeshAttribute;
};

// All properties supported by LocalizationMapTileLevelLayer
export type LocalizationMapTileLevelLayerProps =
  _LocalizationMapTileLevelLayerProps & CompositeLayerProps;

// Props added by the LocalizationMapTileLevelLayer
type _LocalizationMapTileLevelLayerProps = {
  // Height map that defines the geometry of this level.
  data: HeightMap | undefined;
  // Textures and their opacities in the order they shall be rendered.
  textures: Texture[] | undefined;
  textureOpacities: number[] | undefined;
  // The cell size in meters that each pixel from the height grid has.
  cellSize?: number;
  // Altitude filtering & transform parameters.
  minDisplayAltitude?: number;
  maxDisplayAltitude?: number;
  altitudeScale?: number;
  altitudeOffset?: number;
  // [Lng,Lat,Alt] of the tile origin
  tileOrigin: number[];
};

function applyOpacity(texture: Texture, opacity?: number): Texture {
  if (!opacity) {
    return texture;
  }
  const transparentTexture = structuredClone(texture);
  const alpha = Math.round(255 * opacity);
  for (let i = 3; i < texture.data.length; i += 4) {
    if (transparentTexture.data[i]) {
      transparentTexture.data[i] = alpha;
    }
  }
  return transparentTexture;
}

export class LocalizationMapTileLevelLayer<
  ExtraPropsT extends {} = {},
> extends CompositeLayer<
  ExtraPropsT & Required<_LocalizationMapTileLevelLayerProps>
> {
  static override defaultProps = defaultProps;
  static override layerName = 'LocalizationMapTileLevelLayer';
  declare state: {
    heightMap?: TesselatedHeightMap;
    transparentTextures: Texture[];
  };

  override initializeState(): void {
    this.setState({ transparentTextures: [] });
  }

  override updateState({ props, oldProps }: UpdateParameters<this>): void {
    const recreateMesh =
      props.data !== oldProps.data ||
      props.tileOrigin !== oldProps.tileOrigin ||
      props.cellSize !== oldProps.cellSize ||
      props.minDisplayAltitude !== oldProps.minDisplayAltitude ||
      props.maxDisplayAltitude !== oldProps.maxDisplayAltitude ||
      props.altitudeScale !== oldProps.altitudeScale ||
      props.altitudeOffset !== oldProps.altitudeOffset;

    if (recreateMesh && props.data) {
      this.setState({ heightMap: makeHeightMesh(props) });
    }

    let updateTextures = false;
    const initAllTextures =
      this.state.transparentTextures.length !== props.textures?.length;

    for (let i = 0; i < (props.textures?.length ?? 0); ++i) {
      const textureChanged =
        initAllTextures || props.textures?.[i] !== oldProps.textures?.[i];
      const opacityChanged =
        props.textureOpacities?.[i] !== oldProps.textureOpacities?.[i];

      if ((opacityChanged || textureChanged) && props.textures?.[i]) {
        this.state.transparentTextures[i] = applyOpacity(
          props.textures[i],
          props.textureOpacities?.[i],
        );
        updateTextures = true;
      }
    }

    if (updateTextures) {
      this.setState({ transparentTextures: this.state.transparentTextures });
    }
  }

  renderLayers(): Layer | null | LayersList {
    if (!this.props.data || !this.state.heightMap) {
      return null;
    }
    const scaledTileOrigin: [number, number, number] = [
      this.props.tileOrigin[0],
      this.props.tileOrigin[1],
      (this.props.tileOrigin[2] ?? 0) * this.props.altitudeScale,
    ];

    if (!this.props.textures?.length) {
      return null;
    }

    return this.state.transparentTextures.map(
      (texture, i) =>
        new SimpleMeshLayer(this.getSubLayerProps({ id: `mesh-${i}` }), {
          data: DUMMY_DATA,
          mesh: this.state.heightMap,
          coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
          coordinateOrigin: scaledTileOrigin,
          texture,
          _instanced: false,
          getPosition: [0, 0, 0],
          material: { diffuse: 1 },
          visible: (this.props.textureOpacities?.[i] ?? 1) > 0,
        }),
    );
  }

  override getPickingInfo({
    info,
  }: GetPickingInfoParams): PickingInfo & { altitude?: number } {
    if (!this.props.tileOrigin || !this.props.data) {
      return info;
    }
    // Converts lat/lng to a local cartesian ENU frame with origin at the tileOrigin.
    const deltaLat = (info.coordinate?.[1] ?? 0) - this.props.tileOrigin[1];
    const deltaLng = (info.coordinate?.[0] ?? 0) - this.props.tileOrigin[0];
    const easting =
      deltaLng *
      (111111 * Math.cos((Math.PI / 180) * this.props.tileOrigin[1]));
    const northing = deltaLat * 111111;
    // Lookup altitude at tile grid coordinate.
    const y = Math.floor(northing / this.props.cellSize);
    const x = Math.floor(easting / this.props.cellSize);
    const width = this.props.data.width ?? 0;
    const height = this.props.data.data[y * width + x];
    const altitude = (this.props.tileOrigin[2] ?? 0) + height;
    return isFinite(altitude ?? NaN) ? { ...info, altitude } : info;
  }
}
