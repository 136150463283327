<div class="video-layout-container">
  <div class="column">
    <div class="robot-name" [class.robot-name-active-text-size]="active">
      <!-- '!=' is intentional here since serialNumber and shortName have different types -->
      {{ robotState?.serialNumber }}
      {{
        robotState?.shortName &&
        robotState?.shortName != robotState?.serialNumber
          ? '(' + robotState?.shortName + ')'
          : ''
      }}
    </div>
    <div class="robot-data-overview">
      <div class="robot-status">
        <div *ngFor="let statusString of statusStrings">
          {{ statusString }}
        </div>
      </div>
      <div class="batery-and-speed">
        <app-battery-status
          [batteryPercentage]="batteryPercentage"
          [charging]="!!isCharging"
        ></app-battery-status>
        <div
          class="speed"
          [class.autonomous-speed]="robotState?.controlType === 'Autonomy'"
        >
          <mat-icon>speed</mat-icon>
          {{ robotState?.velocity | number: '1.0-1' }} m/s
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="column-two-top">
      <div style="position: absolute">
        <div
          *ngIf="(robotStateReport?.length ?? 0) > 0"
          class="robot-state-report"
          [class.attention-status-active-text-size]="active"
        >
          {{ robotStateReport }}
        </div>

        <div
          *ngIf="operationEmergencyStopActive"
          class="emergency-stop-active"
          [class.attention-status-active-text-size]="active"
        >
          {{
            'Emergency Stop Activated: Autonomous mode is disabled. Please park safely and promptly. '
          }}
        </div>

        <div
          *ngIf="(supervisionState?.length ?? 0) > 0"
          class="robot-state-report"
          [class.attention-status-active-text-size]="active"
        >
          {{ supervisionState }}
        </div>

        <div
          *ngIf="(userClaimReport?.length ?? 0) > 0"
          class="robot-state-report"
          [class.attention-status-active-text-size]="active"
        >
          {{ userClaimReport }}
        </div>

        <div
          *ngIf="robotState?.awxUpdateActive"
          class="robot-state-report"
          [class.attention-status-active-text-size]="active"
        >
          {{
            'Robot is currently being updated. Please contact Cartken before putting it into operation.'
          }}
        </div>

        <div
          class="attention-status"
          [class.attention-status-active-text-size]="active"
          [class.red-text]="attentionStatus.type === 'error'"
          [class.yellow-text]="attentionStatus.type === 'warning'"
          *ngFor="
            let attentionStatus of attentionStatusListTexts;
            trackBy: trackByText
          "
        >
          <span
            class="attention-status-text"
            (click)="onStatusClick.emit(attentionStatus.originalMessage)"
            >{{ attentionStatus.text }}</span
          >
          <span class="mute-text"
            ><mat-icon>snooze</mat-icon> Click to snooze</span
          >
        </div>
      </div>
    </div>

    <div class="column-two-bottom">
      <img
        class="order-detected-icon"
        *ngIf="isOrderDetected"
        height="50"
        src="assets/scale_white_24dp.svg"
      />
    </div>
  </div>
</div>
