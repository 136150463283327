<app-toolbar>
  <button mat-menu-item [routerLink]="''">Home</button>
</app-toolbar>

<div class="ops-grid">
  <app-operation-overview
    *ngFor="let operation of operations"
    [operation]="operation"
  ></app-operation-overview>
</div>

<button class="add-button" mat-icon-button (click)="openAddOperationsDialog()">
  <mat-icon>add_task</mat-icon>
</button>
