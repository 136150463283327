import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { DisplayableRoute, routes } from '../app-routing.module';

export interface SiteSwitch {
  name: string;
  url: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass'],
})
export class LandingPageComponent {
  switchSiteName = environment.siteSwitch.name;

  accessibleRoutes = new ReplaySubject<DisplayableRoute[]>(1);

  constructor(
    public auth: AuthService,
    private router: Router,
  ) {
    this.auth.user$.subscribe((user) => {
      if (user && user.landingPage) {
        this.router.navigate([user.landingPage]);
        return;
      }
      const accessibleRoutes = routes.filter((route) => {
        if (route.displayName === undefined) {
          return false;
        }
        return route.data?.allowedRoles.some(
          (allowedRole) =>
            user?.roles.includes(allowedRole) || allowedRole == '*',
        );
      });
      this.accessibleRoutes.next(accessibleRoutes);
    });
  }

  switchSite() {
    window.location.href = environment.siteSwitch.url;
  }
}
