import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  filter,
  map,
  take,
  takeUntil,
  switchMapTo,
  exhaustMap,
} from 'rxjs/operators';
import { HazardLightsState } from '../../core/robots-service/backend/robot.dto';

import { ErrorService } from '../../core/error-system/error.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../../app/core/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupervisionTask } from '../supervision-task';
import { TemporaryValue } from '../../../utils/temporary-value';
import { detectInactivity } from '../../../utils/inactive';
import { MapConfirmationDialog } from '../../../app/core/confirmation-dialog/map-confirmation-dialog.component';

const CONTROLLED_BY_SOMEONE_ELSE_NOTIFICATION_DURATION = 5000;
const HIDE_STATUS_LIST_RESET_TIMEOUT_MILLIS = 1000 * 15;
const ACTIVITY_DEADLINE_MILLIS = 1000 * 30;

@Component({
  selector: 'app-robot-task',
  templateUrl: './robot-task.component.html',
  styleUrls: ['./robot-task.component.sass'],
})
export class RobotTaskComponent implements OnDestroy {
  hideStatusList = new TemporaryValue(
    false,
    HIDE_STATUS_LIST_RESET_TIMEOUT_MILLIS,
  );

  private _supervisionTask?: SupervisionTask;
  @Input()
  set supervisionTask(supervisionTask: SupervisionTask | undefined) {
    if (this.supervisionTask === supervisionTask) {
      return;
    }
    this.updateSupervisionTask(supervisionTask);
  }

  get supervisionTask(): SupervisionTask | undefined {
    return this._supervisionTask;
  }
  robotId$!: Observable<string>;
  readonly HAZARD_LIGHTS_STATES = Object.values(HazardLightsState);

  readonly _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    detectInactivity(ACTIVITY_DEADLINE_MILLIS)
      .pipe(
        takeUntil(this._destroy$),
        exhaustMap(() => {
          this.supervisionTask?.robotCommunication.claimRobotControl(false);
          return this.snackBar
            .open(
              `No activity is detected for more than ${Math.round(
                ACTIVITY_DEADLINE_MILLIS / 1000,
              )} seconds`,
              'Get new task',
            )
            .onAction();
        }),
      )
      .subscribe(() => {
        this._supervisionTask?.finalize();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(undefined);
  }

  updateSupervisionTask(supervisionTask: SupervisionTask | undefined): void {
    this._supervisionTask = supervisionTask;
    this._supervisionTask?.robotCommunication.enableManualMouseControl(true);
    this._supervisionTask?.robotCommunication.enableAutonomy(false);
    if (!this.supervisionTask) {
      return;
    }
    this.supervisionTask.robotCommunication.connected$
      .pipe(
        filter((connected) => connected),
        takeUntil(this.supervisionTask.robotCommunication.finalized$),
        switchMapTo(this.supervisionTask.robotCommunication.controlledBy$),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe((controlledBy) => {
        if (controlledBy === undefined) {
          this.supervisionTask?.robotCommunication.claimRobotControl(true);
        } else {
          this.snackBar.open(
            `The robot is already controlled by ${controlledBy.displayName} come back to a task search`,
            undefined,
            { duration: CONTROLLED_BY_SOMEONE_ELSE_NOTIFICATION_DURATION },
          );
          this.supervisionTask?.robotCommunication.finalize();
        }
      });
  }

  goToSupervision(id: string) {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Open full control view`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        map(() => {
          this.router.navigateByUrl(`/robots/supervise/${id}?active=${id}`);
        }),
        this.errorService.handleStreamErrors(
          'Failed to navigate to full robot supervision view',
        ),
      )
      .subscribe();
  }

  approveAutonomy() {
    const maxUnsupervisedAutonomyDuration =
      this.supervisionTask?.task.maxUnsupervisedAutonomyDuration;

    if (maxUnsupervisedAutonomyDuration === undefined) {
      this.snackBar.open('The task does not support autonomy');
      return;
    }

    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Let the robot proceed autonomously until the next check-in?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        map(() => {
          this.supervisionTask?.robotCommunication.enableAutonomyFor(
            this.supervisionTask.task.maxUnsupervisedAutonomyDuration ?? 0,
          );
        }),
        this.errorService.handleStreamErrors('Failed to approve autonomy'),
      )
      .subscribe();
  }

  nextTask() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: 'Go to next task',
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        map(() => {
          this.supervisionTask?.robotCommunication.claimRobotControl(false);
          this.supervisionTask?.finalize();
        }),
        this.errorService.handleStreamErrors('Failed to skip current robot'),
      )
      .subscribe();
  }

  arrivedAtStop() {
    this.dialog
      .open(MapConfirmationDialog, {
        data: {
          message: `Confirm arrival?`,
        },
      })
      .afterClosed()
      .pipe(filter((isConfirmed: boolean) => isConfirmed))
      .subscribe(() => {
        this.supervisionTask?.robotCommunication.arrivedAtStop();
      });
  }
}
