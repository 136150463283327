import { inject, Injectable } from '@angular/core';
import { DataSortingOrder, MlDataService } from '../ml-data.service';
import {
  DataViewerStateService,
  DataViewerTabName,
} from './data-viewer-state.service';
import { BehaviorSubject, merge, throttleTime } from 'rxjs';
import { FrameDto, SnippetDto } from '../ml-data-types';
import { PageFetcher } from './page-fetchers/page-fetcher';
import { ConsistentRandomPageFetcher } from './page-fetchers/consistent-random-page-fetcher';
import { InOrderPageFetcher } from './page-fetchers/in-order-page-fetcher';

export enum ItemType {
  SNIPPET = 'snippet',
  FRAME = 'frame',
}

export type DataItem = (FrameDto | SnippetDto) & {
  isPicked?: boolean;
  itemType: ItemType;
};

@Injectable()
export class DataViewerService {
  private dataViewerStateService = inject(DataViewerStateService);
  private readonly _canFetchMore$ = new BehaviorSubject<boolean>(true);
  private items: (SnippetDto | FrameDto)[] = [];

  private _isAnyItemsPicked$ = new BehaviorSubject<boolean>(false);
  readonly isAnyItemsPicked$ = this._isAnyItemsPicked$.asObservable();

  private pickedItems = new Set<number>();

  canFetchMore$ = this._canFetchMore$.asObservable();
  readonly items$ = new BehaviorSubject<DataItem[]>([]);

  private _selectedDataViewerTab$ = new BehaviorSubject<DataViewerTabName>(
    this.dataViewerStateService.getTabName(),
  );
  selectedDataViewerTab$ = this._selectedDataViewerTab$.asObservable();

  private _selectedSortingOrder$ = new BehaviorSubject<DataSortingOrder>(
    'newest',
  );
  selectedSortingOrder$ = this._selectedSortingOrder$.asObservable();

  private _selectedItemId$ = new BehaviorSubject<number | undefined>(undefined);
  selectedItemId$ = this._selectedItemId$.asObservable();

  private _searchString$ = new BehaviorSubject<string>('');
  searchString$ = this._searchString$.asObservable();

  pageFetcher: PageFetcher | undefined = undefined;

  constructor(private mlDataService: MlDataService) {
    merge(
      this.searchString$,
      this.selectedDataViewerTab$,
      this.selectedSortingOrder$,
    )
      .pipe(throttleTime(100))
      .subscribe(async () => {
        this.items = [];
        this._canFetchMore$.next(true);
        if (this._selectedSortingOrder$.value == 'random') {
          this.pageFetcher = new ConsistentRandomPageFetcher(
            this.mlDataService,
            this._selectedDataViewerTab$.value,
            this._searchString$.value,
          );
        } else {
          this.pageFetcher = new InOrderPageFetcher(
            this.mlDataService,
            this._selectedDataViewerTab$.value,
            this._searchString$.value,
            this._selectedSortingOrder$.value,
          );
        }

        this.getNextPage();
        this.pickedItems.clear();

        this._isAnyItemsPicked$.next(false);
      });
  }

  selectDataViewerTab(tabName: DataViewerTabName) {
    if (this._selectedDataViewerTab$.value !== tabName) {
      this.dataViewerStateService.setTabName(tabName);
      this._selectedDataViewerTab$.next(tabName);
      this._selectedItemId$.next(undefined);
      this._searchString$.next('');
    }
  }

  selectItemId(item: DataItem) {
    if (this._selectedItemId$.value === item.id) {
      this._selectedItemId$.next(undefined);
      return;
    }
    this._selectedItemId$.next(item.id);
  }

  setSortingOrder(order: DataSortingOrder) {
    this._selectedSortingOrder$.next(order);
  }

  setSearchString(searchString: string) {
    if (this._searchString$.value !== searchString) {
      this._searchString$.next(searchString);
    }
  }

  async getNextPage() {
    if (this.pageFetcher == undefined) {
      throw new Error('PageFetcher is undefined even though it shouldnt be');
    }

    const newItems = await this.pageFetcher?.step();
    if (newItems.length == 0) {
      this._canFetchMore$.next(false);
    }
    this.items = [...this.items, ...newItems];
    this.updatedItemsPickStatus();
  }

  toggleItemPick(item: DataItem) {
    if (!this.pickedItems.has(item.id)) {
      this.pickedItems.add(item.id);
    } else {
      this.pickedItems.delete(item.id);
    }
    this.updatedItemsPickStatus();
    this._isAnyItemsPicked$.next(this.pickedItems.size > 0);
  }

  pickAllItems() {
    for (const item of this.items) {
      this.pickedItems.add(item.id);
    }
    this.updatedItemsPickStatus();
    this._isAnyItemsPicked$.next(true);
  }

  unpickAllItems() {
    this.pickedItems.clear();
    this.updatedItemsPickStatus();
    this._isAnyItemsPicked$.next(false);
  }

  private updatedItemsPickStatus() {
    const items = this.items.map((item) => ({
      ...item,
      isPicked: this.pickedItems.has(item.id),
      itemType:
        this._selectedDataViewerTab$.value === 'frames'
          ? ItemType.FRAME
          : ItemType.SNIPPET,
    }));
    this.items$.next(items);
  }
}
