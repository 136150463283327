import {
  IsInt,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { MappingDataAcquisitionInfoDto } from './mapping-data-acquisition-info.dto'
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class MapElementTransientDataDto {
  @Expose()
  @IsInt()
  id!: number;

  @Expose()
  @IsOptional()
  @Type(() => MappingDataAcquisitionInfoDto)
  @ValidateNested()
  mappingDataAcquisitionInfo?: MappingDataAcquisitionInfoDto;
}
