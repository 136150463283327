import { Injectable } from '@angular/core';
import { DeltaAggregator } from './delta-aggregator';
import { StatisticalAggregator, Statistics } from './statistical-aggregator';

@Injectable()
export class MetricsService {
  private currentRobotCount?: number;

  private readonly robotTraveledDistanceDelta = new DeltaAggregator();
  private readonly latencyStatistics = new StatisticalAggregator();

  putControllingLatency(maxLatency: number) {
    this.latencyStatistics.put(maxLatency);
  }

  getControllingLatencyStatistics(): Statistics | null {
    return this.latencyStatistics.readStatisticsAndReset();
  }

  putCurrentRobotCount(currentRobotCount: number) {
    this.currentRobotCount = currentRobotCount;
  }

  getCurrentRobotCount() {
    return this.currentRobotCount;
  }

  putTraveledDistance(robotId: string, traveledDistance: number) {
    this.robotTraveledDistanceDelta.put(robotId, traveledDistance);
  }

  getTravelDistanceDeltaAndReset() {
    return this.robotTraveledDistanceDelta
      .readDeltaAndResetAll()
      .reduce((sum, value) => sum + value, 0);
  }

  deleteTravelDistanceDelta(robotId: string) {
    this.robotTraveledDistanceDelta.delete(robotId);
  }
}
