<div class="supervision-settings-container">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Select access groups</mat-label>
    <mat-select
      placeholder="Select access groups"
      multiple
      [(ngModel)]="selectedAccessGroups"
      (selectionChange)="selectAccessGroup()"
    >
      <mat-option *ngFor="let accessGroup of accessGroups" [value]="accessGroup"
        >{{ accessGroup }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
