import { Component, Input } from '@angular/core';
import { CollectionDto, FrameDto } from '../ml-data-types';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.sass'],
})
export class CollectionComponent {
  @Input()
  collection!: CollectionDto;

  selectedFrame?: FrameDto;

  selectFrame(frame: FrameDto): void {
    if (this.selectedFrame === frame) {
      this.selectedFrame = undefined;
      return;
    }
    this.selectedFrame = frame;
  }
}
