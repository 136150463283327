import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersViewComponent } from './orders-view/orders-view.component';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '../core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableExporterModule } from 'mat-table-exporter';
import { OrderStatsDialog } from './orders-view/order-stats-dialog.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';

@NgModule({
  declarations: [
    OrdersViewComponent,
    OrderDetailsDialogComponent,
    OrderStatsDialog,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatIconModule,
    CoreModule,
    MatDialogModule,
    MatTableExporterModule,
  ],
})
export class OrdersModule {}
