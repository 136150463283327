import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { OperatorTasksComponent } from './operator-tasks.component';
import { RobotTaskComponent } from './robot-task/robot-task.component';
import { MaterialModule } from '../material/material.module';
import { RobotsModule } from '../robots/robots.module';
import { FormsModule } from '@angular/forms';
import { WaitingRobotsComponent } from './waiting-robots/waiting-robots.component';

@NgModule({
  declarations: [
    OperatorTasksComponent,
    RobotTaskComponent,
    WaitingRobotsComponent,
  ],
  imports: [
    CoreModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    RobotsModule,
  ],
})
export class OperatorTasksModule {}
