import {
  DataSortingOrder,
  MlDataService,
  PAGE_SIZE,
} from '../../ml-data.service';
import { FrameDto, SnippetDto } from '../../ml-data-types';
import { PageFetcher } from './page-fetcher';

export class InOrderPageFetcher implements PageFetcher {
  private page: number = 0;

  constructor(
    private dataService: MlDataService,
    private modality: 'snippets' | 'frames',
    private searchString: string,
    private sortingOrder: DataSortingOrder,
  ) {}

  async step(): Promise<(FrameDto | SnippetDto)[]> {
    if (this.modality == 'snippets') {
      const snippets = await this.dataService.getSnippets(
        this.page,
        this.searchString,
        this.sortingOrder,
      );
      this.page += 1;
      return snippets;
    } else if (this.modality == 'frames') {
      const frames = await this.dataService.getFrames(
        this.page,
        this.searchString,
        this.sortingOrder,
      );
      this.page += 1;
      return frames;
    } else {
      throw Error(`Got unsupported modality: '${this.modality}'`);
    }
  }
}
