import { NgModule } from '@angular/core';
import { DefaultPipe } from './default.pipe';
import { DurationPipe } from './duration.pipe';
import { LengthPipe } from './length.pipe';
import { PrettyTimePipe } from './pretty-time.pipe';

@NgModule({
  imports: [],
  declarations: [PrettyTimePipe, DurationPipe, DefaultPipe, LengthPipe],
  exports: [PrettyTimePipe, DurationPipe, DefaultPipe, LengthPipe],
})
export class PipesModule {}
