<div class="automation-settings">
  <div class="region-row">
    <mat-form-field appearance="fill" class="region-dropdown">
      <mat-label>Select operation region</mat-label>
      <mat-select
        [(value)]="selectedOperationId"
        cdkFocusInitial
        (selectionChange)="setOperationRegion()"
      >
        <mat-option
          *ngFor="let region of operationRegions"
          [value]="region.properties.operationId"
        >
          {{ region.properties.operationId }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="upsert-row" *ngIf="selectedOperationId">
    <button mat-raised-button color="primary" (click)="upsertAcquisitions()">
      Request {{ numDesiredAcquisitionsOnUpsert }} acquisition(s)
    </button>
    <mat-slider
      class="upsert-slider"
      min="0" max="5" step="1" showTickMarks discrete
      [(value)]="numDesiredAcquisitionsOnUpsert"
    >
      <input matSliderThumb>
    </mat-slider>
  </div>
</div>
