<div class="selected-item-column" *ngIf="selectedItem">
  <img
    [src]="previewImage"
    *ngIf="previewImage != undefined"
    style="width: 100%"
  />
  <video
    controls
    *ngIf="previewVideo != undefined"
    [src]="previewVideo"
    type="video/mp4"
    class="video-preview"
  >
    Your browser does not support the video tag.
  </video>
  <app-metadata class="metadata" [item]="metadataItem"></app-metadata>
  <div *ngIf="snippetFrames.length > 0" class="side-bar-img-grid">
    <app-grid-img-item
      *ngFor="let item of snippetFrames; index as i"
      [class.selected-img-item]="item.id === metadataItem?.id"
      [item]="item"
      (click)="selectItem.emit(item)"
    ></app-grid-img-item>
  </div>
</div>
