import { ElementType, MapElementDto } from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { DrawPolygonMode } from './draw-polygon-mode';
import { Feature } from '../visualization/geojson-types';

export class CreateOperationRegionMode extends DrawPolygonMode {
  constructor(private mapElementManager: MapElementManager) {
    super();
  }

  override setActive(active: boolean) {
    super.setActive(active);
    if (active) {
      this.mapElementManager.selectedMapElement = undefined;
    }
  }

  protected override addPolygon(polygon: Feature) {
    const operationRegion = {
      ...polygon,
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.mapVersion() ?? 0,
      elementType: ElementType.OPERATION_REGION,
    } as MapElementDto;

    this.mapElementManager.addChange([operationRegion]);
    this.mapElementManager.selectedMapElement = operationRegion;
  }
}
