import { Component, OnDestroy } from '@angular/core';
import { DataSortingOrder, SORTING_ORDER } from '../ml-data.service';
import { DataViewerService } from './data-viewer.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  AddFramesToCollectionDialog,
  AddFramesToCollectionDialogData,
} from '../shared/add-frames-to-collection-dialog.component';

@Component({
  selector: 'app-data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrls: ['./data-viewer.component.sass'],
})
export class DataViewerComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  selectedItemIndex = 10;

  constructor(
    readonly dataViewerService: DataViewerService,
    private matDialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  sortingOptions = SORTING_ORDER;

  updateSortingOrder(sortingOrder: DataSortingOrder) {
    this.dataViewerService.setSortingOrder(sortingOrder);
  }

  openAddFramesToCollectionDialog() {
    const items = this.dataViewerService.items$.value;
    this.matDialog.open<
      AddFramesToCollectionDialog,
      AddFramesToCollectionDialogData
    >(AddFramesToCollectionDialog, {
      data: { frames: items.filter((item) => item.isPicked) },
    });
  }
}
