<div mat-dialog-content>
  <form (submit)="latLng && goToLocation()">
    <mat-form-field
      appearance="fill"
      class="filter-dropdown"
      style="width: 32rem"
    >
      <mat-label
        >Element ID, Location Name/ID, Address or Latitude,Longitude</mat-label
      >
      <input #location matInput placeholder="Location" />
    </mat-form-field>
  </form>
  <button
    mat-icon-button
    color="primary"
    aria-label="Go to location"
    matTooltip="Go to location"
    (click)="goToLocation()"
    [disabled]="!latLng"
  >
    <mat-icon>location_on</mat-icon>
  </button>
  <div class="nearby-locations">
    <button
      mat-button
      *ngFor="let location of nearbyLocations"
      (click)="goToLocation(location.latLng)"
    >
      {{ location.displayName }}
    </button>
  </div>
</div>
