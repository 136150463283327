import { LineStringCoordinates } from '../visualization/geojson-types';
import lineIntersect from '@turf/line-intersect';
import { lineString as turfLineString } from '@turf/helpers';

export function lineStringsOverlap(
  lineStringCoords1: LineStringCoordinates,
  lineStringCoords2: LineStringCoordinates,
) {
  const currentLine = turfLineString(lineStringCoords1);
  const otherLines = turfLineString(lineStringCoords2);
  const intersectingPoints = lineIntersect(currentLine, otherLines);
  return intersectingPoints.features.length > 0;
}

// Checks if the last segment of the linestring self-overlaps.
export function lineStringSelfOverlaps(
  lineStringCoords: LineStringCoordinates,
) {
  const len = lineStringCoords.length;
  if (len < 4) {
    return false;
  }

  return lineStringsOverlap(
    lineStringCoords.slice(-2),
    lineStringCoords.slice(0, -2),
  );
}
