import { distance } from 'fastest-levenshtein';

export function fuzzyStringEqual(
  a: string,
  b: string,
  threshold: number = 0.25,
): boolean {
  const distanceValue = distance(a, b);
  return distanceValue / a.length < threshold;
}
