<div class="filter-container">
  <div class="filter-padding">
    <div class="error-message">{{ errorMessage }}</div>
    <div class="filter-selector" [class.show-error]="errorMessage">
      <div *ngFor="let token of tokens" class="token-item">
        {{ token }}
      </div>
      <input
        class="filter-input"
        type="text"
        [placeholder]="placeholder"
        aria-label="Number"
        matInput
        [matAutocomplete]="auto"
        (input)="onInput($event.target.value)"
        (keyup.enter)="applyInput()"
        (keyup.backspace)="removeLastToken()"
        (keyup.space)="applyInput()"
        (onBlur)="applyInput()"
        (click)="onInput('')"
        [value]="currentInput"
        autofocus
      />
      <mat-autocomplete
        class="filter-input"
        #auto="matAutocomplete"
        (optionSelected)="onInput($event.option.value); applyInput()"
      >
        <mat-option
          *ngFor="let autoCompleteOption of autocompleteOptions"
          [value]="autoCompleteOption"
        >
          {{ autoCompleteOption }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
</div>
