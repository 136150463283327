<div
  *ngIf="displayNewTriggerConfigAvailable"
  style="margin-top: 16px"
  class="triggerServiceConfigSuccessBox"
>
  <p style="margin: 16px">A new configuration is available.</p>
  <button
    mat-raised-button
    (click)="onLoadNewTriggerConfig()"
    style="margin: 16px; margin-top: 0"
  >
    Load
  </button>
</div>

<div
  *ngIf="displayRobotTriggerConfigMatchesCurrentConfig"
  style="margin-top: 16px"
  class="triggerServiceConfigSuccessBox"
>
  <p style="margin: 16px">
    Robot configuration updated to current configuration.
  </p>
</div>

<p *ngIf="lastRobotTriggerConfigUpdate">
  Received last update at:
  {{ lastRobotTriggerConfigUpdate?.receiveTime | date: 'shortTime' }}
</p>
<trigger-service-config-tree #triggerConfigTree></trigger-service-config-tree>

<div
  *ngIf="changedTriggerConfigComponents.length > 0"
  style="margin-top: 16px"
  class="triggerServiceConfigAttentionBox"
>
  <p style="margin-left: 16px; margin-top: 16px">
    The following components will be updated:
  </p>
  <ul>
    <li
      *ngFor="let component of changedTriggerConfigComponents"
      style="margin-left: 16px"
    >
      {{ component }}
    </li>
  </ul>
</div>
<button
  mat-raised-button
  (click)="onApply()"
  [disabled]="changedTriggerConfigComponents.length == 0"
  style="margin-left: 16px; margin-top: 16px"
>
  Apply
</button>

<button
  mat-raised-button
  (click)="onReset()"
  [disabled]="changedTriggerConfigComponents.length == 0"
  style="margin-left: 16px; margin-top: 16px"
>
  Reset
</button>
