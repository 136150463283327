import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { map, takeUntil } from 'rxjs/operators';
import { completeAll } from '../../../../utils/complete-all';
import { InControlManager } from './in-control-manager';
import { ManualControl } from './manual-control';

export class InstructorModeManager extends Finalizable {
  private _instructorModeActive = new BehaviorSubject<boolean>(false);

  readonly instructorModeActive$ = this._instructorModeActive.asObservable();

  constructor(
    private readonly inControlManager: InControlManager,
    private readonly manualControl: ManualControl,
  ) {
    super();
    combineLatest([
      this.inControlManager.isInControl$,
      this.manualControl.manualControl$,
    ])
      .pipe(takeUntil(this.finalized$))
      .subscribe({
        next: ([inControl, manualControl]) => {
          if (!inControl && manualControl) {
            this.inControlManager.claimControl(true);
          }
        },
      });
  }

  setInstructorModeActive(active: boolean) {
    this._instructorModeActive.next(active);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._instructorModeActive);
  }
}
