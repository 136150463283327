import {
  IsString,
  IsDefined,
  IsInt,
  IsOptional,
  IsPositive,
  IsBoolean,
} from 'class-validator';

export class MovablePlatformEdgePropertiesDto {
  @IsDefined()
  @IsInt()
  startNodeId!: number;

  @IsDefined()
  @IsInt()
  endNodeId!: number;

  @IsDefined()
  @IsPositive()
  length!: number;

  @IsOptional()
  @IsBoolean()
  oneWay?: boolean;
}
