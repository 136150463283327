export function max(values: number[]): number | undefined {
  if (values.length === 0) {
    return undefined;
  }
  let maxValue = values[0]!;
  for (let i = 1; i < values.length; i++) {
    const currentValue = values[i]!;
    if (currentValue > maxValue) {
      maxValue = currentValue;
    }
  }
  return maxValue;
}
