<div>
  <mat-toolbar>
    <span style="margin: 0 auto">{{ operationDisplayName }}</span>
  </mat-toolbar>

  <app-handover-location-title-splitter
    title="Pickups"
  ></app-handover-location-title-splitter>

  <div class="grid">
    <div *ngFor="let pickup of pickups">
      <button
        mat-raised-button
        class="select-button"
        [routerLink]="[pickup.name]"
        queryParamsHandling="true"
      >
        {{ pickup.displayName || pickup.name }}
      </button>
    </div>
  </div>

  <app-handover-location-title-splitter
    title="Dropoffs"
  ></app-handover-location-title-splitter>

  <div class="grid">
    <div *ngFor="let dropoff of dropoffs">
      <button
        mat-raised-button
        class="select-button"
        [routerLink]="[dropoff.name]"
        queryParamsHandling="true"
      >
        {{ dropoff.displayName || dropoff.name }}
      </button>
    </div>
  </div>
</div>
