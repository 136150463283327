<app-toolbar>
  <button mat-menu-item [routerLink]="'/robot-operators-overview'">
    Back to Operators View
  </button>
</app-toolbar>

<br />
<br />

<div class="container">
  <div>
    <h1>Blackbox Aggregation for {{ user?.displayName }}</h1>
    <div class="container">
      <mat-form-field appearance="fill">
        <mat-label>Time Zone</mat-label>
        <mat-select [(value)]="selectedTimeZone">
          <mat-option></mat-option>
          <mat-option
            *ngFor="let timeZone of supportedTimeZones"
            [value]="timeZone"
          >
            {{ timeZone }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 10px">
        <mat-label>Start Time</mat-label>
        <input
          matInput
          type="datetime-local"
          step="1"
          [ngModel]="blackboxRequestStartTime"
          (ngModelChange)="blackboxRequestStartTime = $event"
        />
      </mat-form-field>

      <mat-form-field style="margin-left: 10px">
        <mat-label>End Time</mat-label>
        <input
          matInput
          type="datetime-local"
          step="1"
          [ngModel]="blackboxRequestEndTime"
          (ngModelChange)="blackboxRequestEndTime = $event"
        />
      </mat-form-field>

      <button
        mat-button
        (click)="requestAggregation()"
        style="margin-left: 10px"
      >
        Request aggregation
      </button>

      <button mat-icon-button (click)="onUpdate()" style="margin-left: 10px">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="content">
    <div class="aggregations">
      <mat-card
        *ngFor="let aggregation of aggregations"
        class="example-card"
        appearance="outlined"
      >
        <mat-card-header>
          <mat-card-title>
            {{
              applyTimezone(aggregation.aggregationStartTime) | date: 'medium'
            }}
            <br />
            to <br />
            {{ applyTimezone(aggregation.aggregationEndTime) | date: 'medium' }}
            <br />
            Requested at
            {{
              applyTimezone(aggregation.aggregationRequestTime) | date: 'medium'
            }}
          </mat-card-title>
          <mat-card-subtitle
            >{{ aggregation.aggregatedBlackboxRecordings.length }}
            recordings

            {{ countAvailableVideos(aggregation) }} available <br />
            {{ countUploadingVideos(aggregation) }} uploading <br />
            {{ countRobots(aggregation) }} robots <br />
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content> </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="onPlayClick(aggregation)">Play</button>
        </mat-card-actions>
      </mat-card>
    </div>

    <mat-divider vertical></mat-divider>
    <div class="video-container">
      <video
        id="video"
        autoplay
        muted
        style="max-width: 890px"
        controls
      ></video>
      <div *ngIf="activeAggregation !== undefined">
        <div>
          {{ activeVideoIndex + 1 }} of
          {{ activeAggregation.aggregatedBlackboxRecordings.length }}

          <button
            mat-icon-button
            (click)="nextVideoInAggregation()"
            style="margin-left: 10px"
          >
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="previousVideoInAggregation()"
            style="margin-left: 10px"
          >
            <mat-icon>arrow_back_ios</mat-icon>
          </button>

          <button mat-button (click)="speed1x()" style="margin-left: 10px">
            1x
          </button>
          <button mat-button (click)="speed2x()" style="margin-left: 10px">
            2x
          </button>
          <button mat-button (click)="speed4x()" style="margin-left: 10px">
            4x
          </button>

          <button mat-button (click)="speed8x()" style="margin-left: 10px">
            8x
          </button>
        </div>
        <div>
          Operation Id
          {{
            activeAggregation.aggregatedBlackboxRecordings[activeVideoIndex]
              .operationId
          }}
        </div>
        <div>
          Duration
          {{
            activeAggregation.aggregatedBlackboxRecordings[activeVideoIndex]
              .duration
          }}
        </div>
        <div>
          {{
            activeAggregation.aggregatedBlackboxRecordings[activeVideoIndex]
              .robotDisplayName
          }}
        </div>

        <div>
          Crossing Id
          {{
            activeAggregation.aggregatedBlackboxRecordings[activeVideoIndex]
              .crossingId
          }}
        </div>
        <div>
          Supervisor Id Sequence
          {{
            activeAggregation.aggregatedBlackboxRecordings[activeVideoIndex]
              .supervisorIdSequence
          }}
        </div>
      </div>
    </div>
  </div>
</div>
