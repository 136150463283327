<div *ngIf="notifications.length > 0" class="snoozable-container">
  <div class="snoozable-countdown-overlay">
    <div class="message">
      <div class="notification-count" *ngIf="notifications?.length > 1">
        <mat-icon
          (click)="changeActiveNotificationIndex(-1)"
          class="change-active-index-arrow"
          [class.change-active-index-disabled]="activeNotificationIndex === 0"
          >chevron_left</mat-icon
        >
        {{ this.activeNotificationIndex + 1 }}
        <mat-icon
          (click)="changeActiveNotificationIndex(1)"
          class="change-active-index-arrow"
          [class.change-active-index-disabled]="
            activeNotificationIndex === notifications.length - 1
          "
          >chevron_right</mat-icon
        >
      </div>

      {{ notifications?.[activeNotificationIndex]?.actionDescription }}
      <span *ngIf="notifications?.[activeNotificationIndex]?.countdown">
        {{ notifications?.[activeNotificationIndex]?.countdown | duration }}
      </span>
    </div>
    <button mat-button color="primary" (click)="onClick()">
      {{ notifications?.[activeNotificationIndex]?.actionButton }}
    </button>
  </div>
</div>
