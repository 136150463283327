import { BehaviorSubject } from 'rxjs';
import { MouseDriveEvent } from './mouse-canvas-events';
import { NormalizedManualRobotControl } from '../robot-control/robot-movement/control-state';

export class MouseRobotControl {
  private readonly _normalizedControl$ =
    new BehaviorSubject<NormalizedManualRobotControl>({
      normalizedSpeed: 0,
      normalizedTurnRate: 0,
    });

  readonly control$ = this._normalizedControl$.asObservable();

  reverse = false;

  constructor() {}

  onMouseDrive(mouseDrive: MouseDriveEvent) {
    if (!mouseDrive.speedVector) {
      this._normalizedControl$.next({
        normalizedSpeed: 0,
        normalizedTurnRate: 0,
      });
      return;
    }
    const scalingFactor = this.reverse ? -1 : 1;
    this._normalizedControl$.next({
      normalizedSpeed: scalingFactor * mouseDrive.speedVector.y,
      normalizedTurnRate: mouseDrive.speedVector.x * -1, // multiplication by -1 make correct turn durection, empirical
    });
  }
}
