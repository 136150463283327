import { MapElementDto } from '@cartken/map-types';
import { Subject } from 'rxjs';

export class ChangeHistory {
  private _changeAdded$ = new Subject<MapElementDto[]>();
  private _changeRemoved$ = new Subject<MapElementDto[]>();
  private currentChangeIndex = -1;
  private changeStack: MapElementDto[][] = [];

  changesAdded$ = this._changeAdded$.asObservable();
  changeRemoved$ = this._changeRemoved$.asObservable();

  clear() {
    this.currentChangeIndex = -1;
    const changes = this.changeStack;
    this.changeStack = [];
    for (const m of changes) {
      this._changeRemoved$.next(m);
    }
  }

  numChanges(): number {
    return this.currentChangeIndex + 1;
  }

  addChange(mapElements: MapElementDto[]) {
    if (!mapElements.length) {
      return;
    }
    this.changeStack.splice(this.currentChangeIndex + 1);
    this.changeStack.push(mapElements);
    this.currentChangeIndex = this.changeStack.length - 1;
    this._changeAdded$.next(mapElements);
  }

  getChanges(): MapElementDto[] {
    const changes: MapElementDto[] = [];
    const changedMapElementIds = new Set<number>();
    for (let i = this.currentChangeIndex; i >= 0; --i) {
      const change = this.changeStack[i]!;
      for (let j = 0; j < change.length; ++j) {
        const mapElement = change[j]!;
        if (!changedMapElementIds.has(mapElement.id)) {
          changes.push(mapElement);
          changedMapElementIds.add(mapElement.id);
        }
      }
    }
    return changes.filter(
      (mapElement) => !(mapElement.deleted && mapElement.id < 0),
    );
  }

  findChange(mapElementId: number): MapElementDto | undefined {
    for (let i = this.currentChangeIndex; i >= 0; --i) {
      const change = this.changeStack[i]!;
      for (let j = 0; j < change.length; ++j) {
        if (change[j]?.id === mapElementId) {
          return change[j];
        }
      }
    }
    return undefined;
  }

  undo() {
    if (!this.undoAvailable()) {
      return;
    }
    const mapElements = this.changeStack[this.currentChangeIndex]!;
    --this.currentChangeIndex;
    this._changeRemoved$.next(mapElements);
  }

  redo() {
    if (!this.redoAvailable()) {
      return;
    }
    ++this.currentChangeIndex;
    const mapElements = this.changeStack[this.currentChangeIndex]!;
    this._changeAdded$.next(mapElements);
  }

  undoAvailable(): boolean {
    return this.currentChangeIndex >= 0;
  }

  redoAvailable(): boolean {
    return this.currentChangeIndex < this.changeStack.length - 1;
  }
}
