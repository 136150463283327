import { BehaviorSubject, partition } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { exhaustMap, finalize, takeUntil } from 'rxjs/operators';
import { visiblePageTimer } from '../../../../utils/page-visibility';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { completeAll } from '../../../../utils/complete-all';

const SIREN_MESSAGE_UPDATE_INTERVAL_MILLIS = 1000;

export class SirenManager extends Finalizable {
  private readonly _sirenOn$ = new BehaviorSubject<boolean>(false);
  readonly sirenOn$ = this._sirenOn$.asObservable();

  constructor(rtcSendDataChannels: RtcSendDataChannels) {
    super();
    // Siren request has to be sent repeatedly when the siren is activated because the siren stops
    // automatically after a certain amount of time when no request is received
    const [startSiren$, stopSiren$] = partition(this._sirenOn$, Boolean);
    startSiren$
      .pipe(
        exhaustMap(() =>
          visiblePageTimer(0, SIREN_MESSAGE_UPDATE_INTERVAL_MILLIS).pipe(
            takeUntil(stopSiren$),
            takeUntil(this.finalized$),
            finalize(() =>
              rtcSendDataChannels.sendReliable({
                label: 'sirenRequest',
                payload: 'off',
              }),
            ),
          ),
        ),
      )
      .subscribe(() => {
        rtcSendDataChannels.sendReliable({
          label: 'sirenRequest',
          payload: 'on',
        });
      });
  }

  enableSirenAndAlarm(value: boolean) {
    this._sirenOn$.next(value);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._sirenOn$);
  }
}
