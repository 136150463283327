import { Component, Input } from '@angular/core';
import { RobotIssue } from '../robot-issue.types';

@Component({
  selector: 'app-robot-issue-panel',
  styleUrls: ['./robot-issues-panel.component.sass'],
  templateUrl: './robot-issues-panel.component.html',
})
export class RobotIssuesPanelComponent {
  @Input() issues!: RobotIssue[];
  displayedColumns = ['id', 'severity', 'name'];
}
