const kNumAttributeBits = 10;
const kSemanticClassIdMask = 0xffff << kNumAttributeBits;

// These three highlevel categories are mutually exclusive (one-hot). They reserve the 3 most
// significant bits.
enum SemanticClassId {
  kUnknown = 0,
  kDrivableArea = 1 << kNumAttributeBits,
  kPerson = 2 << kNumAttributeBits,
  kCycleLike = 3 << kNumAttributeBits,
  kVehicle = 4 << kNumAttributeBits,
  kRobot = 5 << kNumAttributeBits,
  kBackground = 0b111111 << kNumAttributeBits,
}

// Bit 1 to 13 can be freely used as an n-hot encoding for different attributes within the highlevel
// categories
enum DrivableAreaAttributes {
  kPedestrianZone = 1 << 0,
  kBikeZone = 1 << 1,
  kCarZone = 1 << 2,
  kHoleCover = 1 << 3,
  kTactilePaving = 1 << 4,
}

enum PersonAttributes {
  kRider = 1 << 0,
}

function isSemanticClass(
  semanticValue: number,
  classId: SemanticClassId,
): boolean {
  return (semanticValue & kSemanticClassIdMask) == classId;
}

function rgbToCvVec3b(rgb: number): [number, number, number] {
  return [rgb & 0xff, (rgb >> 8) & 0xff, (rgb >> 16) & 0xff]; //
}

function getEncodingToColorMap(): Map<number, [number, number, number]> {
  return new Map([
    [SemanticClassId.kDrivableArea, rgbToCvVec3b(0xa0acab)],
    [
      SemanticClassId.kDrivableArea + DrivableAreaAttributes.kPedestrianZone,
      rgbToCvVec3b(0x6be140),
    ],
    [
      SemanticClassId.kDrivableArea + DrivableAreaAttributes.kBikeZone,
      rgbToCvVec3b(0x2e9be5),
    ],
    [
      SemanticClassId.kDrivableArea + DrivableAreaAttributes.kCarZone,
      rgbToCvVec3b(0xe7282c),
    ],
    [
      SemanticClassId.kDrivableArea + DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0xa8bf8b),
    ],
    [
      SemanticClassId.kDrivableArea + DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xc2a782),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone,
      rgbToCvVec3b(0x4ce0a2),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kCarZone,
      rgbToCvVec3b(0xdf7d1e),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0x69ba62),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x7eb74a),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone,
      rgbToCvVec3b(0xba3aa5),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0x4880be),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x4ea3aa),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0xc64a3a),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xb64b65),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xbe8c99),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone,
      rgbToCvVec3b(0x5450e7),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0x57b19a),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x5ab97f),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0xbb7b37),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xc45a32),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x95c978),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0xa45181),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x8654a8),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x78a8c3),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xd07972),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover,
      rgbToCvVec3b(0x6854c2),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x5d73db),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x8ec2a0),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xcf90e8),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0xb9729d),
    ],
    [
      SemanticClassId.kDrivableArea +
        DrivableAreaAttributes.kPedestrianZone +
        DrivableAreaAttributes.kBikeZone +
        DrivableAreaAttributes.kCarZone +
        DrivableAreaAttributes.kHoleCover +
        DrivableAreaAttributes.kTactilePaving,
      rgbToCvVec3b(0x948bc5),
    ],
    [SemanticClassId.kPerson, rgbToCvVec3b(0x2166ea)],
    [SemanticClassId.kPerson + PersonAttributes.kRider, rgbToCvVec3b(0x44d1d6)],
    [SemanticClassId.kCycleLike, rgbToCvVec3b(0xea3315)],
    [SemanticClassId.kVehicle, rgbToCvVec3b(0x8541df)],
    [SemanticClassId.kRobot, rgbToCvVec3b(0xddaf12)],
  ]);
}

export const encodingToColorMap = getEncodingToColorMap();

export function visualizeSemanticClassAsRgba(
  val: number,
): [number, number, number, number] {
  const resultRgba: [number, number, number, number] = [0, 0, 0, 0];
  if (isSemanticClass(val, SemanticClassId.kUnknown)) {
    return resultRgba;
  }
  resultRgba[3] = 255;
  if (isSemanticClass(val, SemanticClassId.kBackground)) {
    resultRgba[0] = 0xa9;
    resultRgba[1] = 0x3c;
    resultRgba[2] = 0xc6;
    return resultRgba;
  }
  const color_it = encodingToColorMap.get(val);
  if (color_it) {
    resultRgba[0] = color_it[2];
    resultRgba[1] = color_it[1];
    resultRgba[2] = color_it[0];
  }

  return resultRgba;
}
