import { MapElementDto } from '@cartken/map-types';
import { LatLngBounds } from 'spherical-geometry-js';

export function boundsFromCoordinates(
  coordinates?: number[][],
): LatLngBounds | undefined {
  if (!coordinates || coordinates.length < 1) {
    return undefined;
  }
  const bounds = new LatLngBounds(coordinates[0] as [number, number]);
  for (let i = 1; i < coordinates.length; ++i) {
    bounds.extend(coordinates[i] as [number, number]);
  }
  return bounds;
}

export function getBoundingBox(
  mapElement: MapElementDto,
): LatLngBounds | undefined {
  if (mapElement.geometry.type === 'Point') {
    return boundsFromCoordinates([mapElement.geometry.coordinates]);
  }
  if (mapElement.geometry.type === 'LineString') {
    return boundsFromCoordinates(mapElement.geometry.coordinates);
  }
  if (mapElement.geometry.type === 'Polygon') {
    return boundsFromCoordinates(mapElement.geometry.coordinates[0]);
  }

  return undefined;
}
