import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil, distinctUntilChanged, take } from 'rxjs/operators';

import { AuthService } from '../../auth.service';
import {
  SignalingMessage,
  ConnectionError,
  ServerMessage,
  NetworkMessage,
  ClientInfo,
} from './signaling-server-messages';
import { environment } from '../../../../environments/environment';
import { SignalingConnection } from './signaling-connection';
import { visiblePageTimer } from '../../../../utils/page-visibility';
import { isDefined } from '../../../../utils/typeGuards';
import { Finalizable } from '../../../../utils/finalizable';
import { completeAll } from '../../../../utils/complete-all';
import { Cache } from '../../../../utils/cache.decorator';

const SERVER_PING_INTERVAL_MILLIS = 5000;

@Injectable()
export class SignalingService extends Finalizable implements OnDestroy {
  private websocket?: WebSocket;

  private readonly connectedClientIds = new Set<string>();

  private readonly _connectedToServer$ = new BehaviorSubject<boolean>(false);
  readonly connectedToServer$ = this._connectedToServer$
    .asObservable()
    .pipe(distinctUntilChanged());

  private readonly _signalingMessage$ = new Subject<SignalingMessage>();
  readonly signalingMessage$ = this._signalingMessage$.asObservable();

  private readonly _networkMessage$ = new Subject<NetworkMessage>();
  readonly networkMessage$ = this._networkMessage$.asObservable();

  private readonly _connectionError$ = new Subject<ConnectionError>();
  readonly connectionError$ = this._connectionError$.asObservable();

  private readonly _openedClientConnection$ = new ReplaySubject<ClientInfo>();
  readonly openedClientConnection$ =
    this._openedClientConnection$.asObservable();
  private readonly _closedClientConnection$ = new ReplaySubject<string>();
  readonly closedClientConnection$ =
    this._closedClientConnection$.asObservable();

  constructor(private authService: AuthService) {
    super();
    const connectionLost$ = this.connectedToServer$.pipe(
      takeUntil(this.finalized$),
      filter((isConnected) => !isConnected),
    );
    connectionLost$.pipe(takeUntil(this.finalized$)).subscribe(async () => {
      await this.connectedWithAuth();
    });
  }

  ngOnDestroy(): void {
    this.finalize();
  }

  private async connectedWithAuth() {
    const accessToken = await this.authService.accessToken$
      .pipe(filter(isDefined), takeUntil(this.finalized$), take(1))
      .toPromise();
    this.connect(accessToken);
  }

  async connectToClient(targetClientId: string) {
    await this.waitConnection();
    this.send(JSON.stringify({ connectToClientId: targetClientId }));
  }

  isClientConnected(targetClientId: string): boolean {
    return (
      this._connectedToServer$.value &&
      this.connectedClientIds.has(targetClientId)
    );
  }

  disconnectFromClient(connectedClientId: string) {
    if (this.connectedClientIds.has(connectedClientId)) {
      console.log('Disconnects from robot', connectedClientId);
      this.closeClientConnection(connectedClientId);
      this.send(JSON.stringify({ disconnectFromClientId: connectedClientId }));
    }
  }

  async sendSignalingMessage(message: SignalingMessage): Promise<void> {
    await this.waitConnection();

    if (!this.validateClient(message)) {
      return;
    }

    this.send(JSON.stringify({ signalingMessage: message }));
  }

  async selectNetworkInterfaceMessage(message: NetworkMessage): Promise<void> {
    await this.waitConnection();

    if (!this.validateClient(message)) {
      return;
    }

    this.send(JSON.stringify({ networkMessage: message }));
  }

  private validateClient(message: NetworkMessage | SignalingMessage): boolean {
    const connectedClientId = message.connectedClientId;
    if (connectedClientId === undefined) {
      console.error('Signaling message has to client ID');
      return false;
    }

    if (!this.connectedClientIds.has(connectedClientId)) {
      this.disconnectFromClient(connectedClientId);
      return false;
    }
    return true;
  }

  async waitConnection() {
    await this.connectedToServer$.pipe(filter(Boolean), take(1)).toPromise();
  }

  getSignalingConnection(robotId: string) {
    return new SignalingConnection(robotId, this);
  }

  @Cache()
  async getIceServers(): Promise<RTCIceServer[]> {
    const defaultIceServers = [
      { urls: ['stun:stun.l.google.com:19302'] },
    ] as RTCIceServer[];
    try {
      const accessToken = await this.authService.accessToken$
        .pipe(take(1))
        .toPromise();
      const url = environment.backendUrl + '/v1/ice-servers';

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.body}`);
      }

      return response.json();
    } catch (e) {
      console.warn(`Could not retrieve ICE servers, using default ones: `, e);
      return defaultIceServers;
    }
  }

  private send(message: string) {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      return;
    }
    this.websocket.send(message);
  }

  private connect(accessToken?: string) {
    if (this.websocket !== undefined) {
      return;
    }

    if (!accessToken) {
      return;
    }

    console.log('Connecting signaling websocket');

    const socketUrl = environment.socketUrl + '/v1/robot-uplink';
    this.websocket = new WebSocket(socketUrl);
    this.websocket.onmessage = (event) => {
      this.onServerMessage(JSON.parse(event.data));
    };
    this.websocket.onclose = (event) => {
      console.warn('Signaling websocket closed', event);
      this.close();
    };
    this.websocket.onopen = (event) => {
      this.send(
        JSON.stringify({
          authentication: {
            accessToken,
            instanceUuid: this.authService.instanceUuid,
          },
        }),
      );
    };
    this.websocket.onerror = (event) => {
      console.warn('Signaling websocket error', event);
      this.close();
    };
  }

  private closeClientConnection(connectedClientId: string) {
    if (this.connectedClientIds.has(connectedClientId)) {
      this.connectedClientIds.delete(connectedClientId);
      this._closedClientConnection$.next(connectedClientId);
    }
  }

  private onServerMessage(message: ServerMessage) {
    if (message.authenticationSuccess !== undefined) {
      if (!message.authenticationSuccess) {
        this.authService.refreshAccessToken();
        console.log(
          'Signaling websocket authentication failed, refreshing access token',
        );
        this.close();
        return;
      }
      this._connectedToServer$.next(true);
      const disconnect$ = this._connectedToServer$.pipe(
        filter((connected) => !connected),
        take(1),
      );
      visiblePageTimer(0, SERVER_PING_INTERVAL_MILLIS)
        .pipe(takeUntil(disconnect$))
        .subscribe(() => {
          this.send(JSON.stringify({ ping: Date.now() }));
        });
    }
    if (message.signalingMessage) {
      this._signalingMessage$.next(message.signalingMessage);
    }
    if (message.networkMessage) {
      this._networkMessage$.next(message.networkMessage);
    }
    if (message.connectedToClient) {
      this.connectedClientIds.add(message.connectedToClient.id);
      this._openedClientConnection$.next(message.connectedToClient);
    }
    if (message.connectionError) {
      console.warn('Close connection error message', message.connectionError);
      this.closeClientConnection(message.connectionError.connectedClient.id);
    }
    if (message.pong !== undefined) {
      if (Date.now() - message.pong > 3000) {
        this.close();
      }
    }
  }

  private close() {
    if (this.websocket && this.websocket.readyState !== this.websocket.CLOSED) {
      this.websocket.close();
    }
    this.websocket = undefined;
    this._connectedToServer$.next(false);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(
      this._connectedToServer$,
      this._signalingMessage$,
      this._networkMessage$,
      this._connectionError$,
      this._openedClientConnection$,
      this._closedClientConnection$,
    );
    this.close();
  }
}
