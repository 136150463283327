import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operation } from '../../operations/operation';

export interface LoadOperationRegionDialogData {
  selectedOperationId?: string;
  operations: Operation[];
  dialogTitle?: string;
  displayAccessGroups?: boolean;
}

@Component({
  selector: 'select-operation-dialog',
  styleUrls: ['select-operation-dialog.component.sass'],
  templateUrl: 'select-operation-dialog.component.html',
})
export class SelectOperationDialogComponent {
  textFilter = '';
  filteredOperations: Operation[] = [];
  columnsToDisplay = ['displayName', 'id'];

  constructor(
    public dialogRef: MatDialogRef<SelectOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadOperationRegionDialogData,
  ) {
    if (data.displayAccessGroups) {
      this.columnsToDisplay.push('accessGroups');
    }
    this.applyFilter();
  }

  applyFilter() {
    const filter = this.textFilter.toLowerCase();
    this.filteredOperations = this.data.operations
      .filter(
        (operation) =>
          operation.id.toLowerCase().includes(filter) ||
          operation.displayName?.toLowerCase().includes(filter) ||
          operation.accessGroups?.includes(filter),
      )
      .sort((a, b) => a.id.localeCompare(b.id));
  }

  selectOperation(operation: Operation) {
    this.dialogRef.close(operation);
  }
}
