import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { WebSocketConnection } from './web-socket-connection';
import { CustomError } from 'ts-custom-error';
import { firstValueFrom } from 'rxjs';
import { isDefined } from '../../../utils/typeGuards';

export class WebSocketCreationError extends CustomError {
  constructor(message: string) {
    super(`Failed to establish connection due ${message}`);
  }
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private readonly backendUrl = `${environment.socketUrl}/v1`;

  constructor(private authService: AuthService) {}

  connect<REQ = unknown, RES = unknown>(
    path: string,
    sessionId: string,
  ): WebSocketConnection<REQ, RES> {
    const accessToken = firstValueFrom(
      this.authService.accessToken$.pipe(filter(isDefined)),
    );
    const user = firstValueFrom(
      this.authService.user$.pipe(first(), filter(isDefined)),
    );

    if (user && accessToken) {
      return new WebSocketConnection(
        `${this.backendUrl}/${path}`,
        accessToken,
        user,
        sessionId,
      );
    } else {
      throw new WebSocketCreationError(
        'user data or access token does not exist',
      );
    }
  }
}
