<mat-form-field appearance="fill" class="access-group-container">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let attribute of selectedAttributes"
      (removed)="removeAttribute(attribute)"
    >
      {{ attribute }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="New..."
      #attributeInput
      [formControl]="formCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addAttribute($event.value)"
    />
    <mat-error
      *ngIf="formCtrl.hasError('whitespace') && !formCtrl.hasError('required')"
    >
      Whitespace is not allowed
    </mat-error>
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="addAttribute($event.option.viewValue)"
  >
    <mat-option
      *ngFor="let attribute of filteredAttributes | async"
      [value]="attribute"
    >
      {{ attribute }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
