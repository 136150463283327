export interface RouteVertexDto {
  latitude: number;
  longitude: number;
  width?: number; // meters
}

export interface RouteDto {
  geometry?: RouteVertexDto[];
  distance: number; // meters
  duration: number; // seconds
}

export interface CombinedDirectionsResult {
  externalRoute: google.maps.DirectionsResult;
  cartkenRoute?: RouteDto;
}

// TODO: should be part of API
export interface RobotSystemStatus {
  name: string;
  message: string;
  type?: string;
}

export interface ScheduledStopDto {
  route: RouteDto;
  arrivedAtStop: boolean;
  estimatedArrivalTime: Date;
  estimatedWaitingDuration: number;
  robotQueueId?: number;
  orderId?: string;
  orderHandoverIndex?: number;
}

export enum ResourceType {
  ORDER = 'order',
}
