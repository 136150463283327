<div class="warning-content">
  <p>
    {{ textLine1 }}
  </p>
  <p>
    {{ textLine2 }}
  </p>
  <mat-icon class="warning-icon">report_gmailerrorred</mat-icon>
  <div class="buttons">
    <button mat-raised-button (click)="onCancelClick()">
      {{ dismissText }}
    </button>
    <button mat-raised-button (click)="onConfirmClick()">
      {{ confirmText }}
    </button>
  </div>
</div>
