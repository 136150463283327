import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ErrorService } from './error-system/error.service';
import { BaseBackendService } from './base-backend.service';

@Injectable({
  providedIn: 'root',
})
export class MlBackendService extends BaseBackendService {
  constructor(
    http: HttpClient,
    snackBar: MatSnackBar,
    errorService: ErrorService,
    router: Router,
  ) {
    super(environment.mlBackendUrl, http, snackBar, errorService, router);
  }
}
