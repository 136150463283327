<div mat-dialog-content class="edit-robot-container">
  <div class="update-form-fields">
    <mat-slide-toggle
      [(ngModel)]="data.robot.readyForOrders"
      [disabled]="!data.robot.assignedOperationId"
      (change)="setRobotReadyForOrders(data.robot.readyForOrders)"
      style="padding-bottom: 10px"
    >
      Ready for Orders</mat-slide-toggle
    >

    <mat-form-field appearance="fill">
      <mat-label>Assigned Operation</mat-label>
      <mat-select
        [(value)]="data.robot.assignedOperationId"
        (selectionChange)="
          updateRobot.assignedOperationId = data.robot.assignedOperationId
        "
      >
        <mat-option
          *ngFor="let operationId of operationIds"
          [value]="operationId"
        >
          {{ operationId }}
        </mat-option>
        <mat-option [value]="''">-</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Operation Specific Name</mat-label>
      <input
        matInput
        placeholder="Operation Specific Name"
        [(ngModel)]="data.robot.shortName"
        (change)="updateRobot.shortName = data.robot.shortName"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Picture URL</mat-label>
      <input
        matInput
        placeholder="URL"
        [(ngModel)]="data.robot.pictureUrl"
        (change)="updateRobot.pictureUrl = data.robot.pictureUrl"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Robot Color</mat-label>
      <input
        matInput
        placeholder="Color"
        [(ngModel)]="data.robot.displayColor"
        (change)="updateRobot.displayColor = data.robot.displayColor"
      />
    </mat-form-field>

    <app-attribute-list-input
      [autocompleteAttributes]="['cartken-robots']"
      [(selectedAttributes)]="data.robot.accessGroups"
      (selectedAttributesChange)="
        updateRobot.accessGroups = data.robot.accessGroups
      "
      [label]="'Access Groups'"
    ></app-attribute-list-input>
  </div>
  <div mat-dialog-content></div>
  Uber

  <br />

  <mat-form-field appearance="fill">
    <mat-label>Vehicle Id</mat-label>
    <input
      matInput
      placeholder="Vehicle Id"
      [ngModel]="data.robot.integrationUber?.vehicleId"
      (change)="setUberVehicleId($event.target.value)"
    />
  </mat-form-field>

  <br />

  <mat-slide-toggle
    [ngModel]="
      data.robot.integrationUber?.isEnabled || updateRobot.uberIsEnabled
    "
    [disabled]="
      !data.robot.integrationUber?.vehicleId &&
      updateRobot.uberVehicleId === undefined
    "
    (change)="updateRobot.uberIsEnabled = $event.checked"
    style="padding-bottom: 10px"
  >
    Enable Uber</mat-slide-toggle
  >
</div>

<div mat-dialog-content></div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="updateRobot">Update</button>
</div>
