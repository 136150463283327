<div class="add-frames-container">
  Adding {{ data.frames.length }} frames to collection
  <div class="collection-set">
    <mat-checkbox
      *ngFor="let collection of collections"
      [checked]="selectedCollectionIds.has(collection.id)"
      (change)="onChange($event.checked, collection.id)"
    >
      {{ collection.name }}
    </mat-checkbox>
  </div>

  <div *ngIf="!successCount && !failureCount" class="add-frames-button-panel">
    <button mat-raised-button color="primary" (click)="onAddFrames()">
      Submit
    </button>

    <button mat-raised-button color="primary" (click)="onCancel()">
      Cancel
    </button>
  </div>

  <div style="width: 100%" *ngIf="successCount">
    Success count: {{ successCount }}
    <mat-progress-bar
      mode="determinate"
      [value]="
        (successCount / (data.frames.length * selectedCollectionIds.size)) * 100
      "
    ></mat-progress-bar>
  </div>

  <div style="width: 100%" *ngIf="failureCount">
    Failure count: {{ failureCount }}
    <mat-progress-bar
      color="warn"
      mode="determinate"
      [value]="
        (failureCount / (data.frames.length * selectedCollectionIds.size)) * 100
      "
    ></mat-progress-bar>
  </div>

  <mat-expansion-panel hideToggle *ngIf="errorMessages.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title> Errors </mat-panel-title>
      <mat-panel-description>
        Click to see error which occurred while adding frames to collection
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let errorMessage of errorMessages">
      {{ errorMessage }}
    </div>
  </mat-expansion-panel>
</div>
