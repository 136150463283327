import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';

import { RobotIssueService } from './robot-issue.service';

import { CreateRobotIssueDialogComponent } from './create-robot-issue-dialog';
import { RobotIssuesWidgetComponent } from './robot-issues-widget';
import { IssuePrettyPipe } from './issue-pretty.pipe';
import { RobotIssuesPanelComponent } from './robot-issues-panel';
import { RobotIssuesPopoverComponent } from './robot-issues-popover';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  declarations: [
    CreateRobotIssueDialogComponent,
    RobotIssuesPanelComponent,
    RobotIssuesWidgetComponent,
    RobotIssuesPopoverComponent,
    IssuePrettyPipe,
  ],
  providers: [RobotIssueService],
  exports: [
    CreateRobotIssueDialogComponent,
    RobotIssuesWidgetComponent,
    IssuePrettyPipe,
    RobotIssuesPanelComponent,
    RobotIssuesPopoverComponent,
  ],
})
export class RobotIssuesModule {}
