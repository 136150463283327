import {
  MapElementDto,
  PolygonGeometryDto,
  ElementType,
  LocalizationMapTilesPropertiesDto,
} from '@cartken/map-types';

export interface LocalizationMapTilesDto extends MapElementDto {
  properties: LocalizationMapTilesPropertiesDto;
  geometry: PolygonGeometryDto;
}

export function isLocalizationMapTilesDto(
  mapElement: MapElementDto,
): mapElement is LocalizationMapTilesDto {
  return (
    mapElement.elementType === ElementType.LOCALIZATION_MAP_TILES &&
    !!mapElement.properties
  );
}
