import { Injectable } from '@angular/core';

const COMPONENT_LOCAL_STORAGE_PREFIX = 'tracking-view';

const CONFIRMED_ORDER_TRACKING_CODES = `${COMPONENT_LOCAL_STORAGE_PREFIX}_confirmed-order-tracking-code`;
const CONFIRMED_ORDER_TRACKING_CODES_STORAGE_SIZE = 10;

@Injectable()
export class TackingViewStateService {
  confirmOrderDropoff(trackingCode: string) {
    const confirmedOrdersTrackingCodes = this.readLastConfirmedOrderIds();
    confirmedOrdersTrackingCodes.unshift(trackingCode);
    localStorage.setItem(
      CONFIRMED_ORDER_TRACKING_CODES,
      JSON.stringify(
        confirmedOrdersTrackingCodes.slice(
          0,
          CONFIRMED_ORDER_TRACKING_CODES_STORAGE_SIZE,
        ),
      ),
    );
  }

  isOrderDropoffConfirmed(trackingCode: string): boolean {
    const confirmedOrdersTrackingCodes = this.readLastConfirmedOrderIds();
    return confirmedOrdersTrackingCodes.includes(trackingCode);
  }

  private readLastConfirmedOrderIds(): string[] {
    const confirmedOrdersTrackingCodesString = localStorage.getItem(
      CONFIRMED_ORDER_TRACKING_CODES,
    );

    if (confirmedOrdersTrackingCodesString === null) {
      return [];
    }

    return JSON.parse(confirmedOrdersTrackingCodesString);
  }
}
