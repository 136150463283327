import { BehaviorSubject, combineLatest } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { map, filter, switchMap, takeUntil } from 'rxjs/operators';
import { visiblePageTimer } from '../../../../utils/page-visibility';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { InControlManager } from './in-control-manager';

const ENABLE_AUTONOMY_FOR = 7; // seconds, might get manually configurable
const ENABLE_AUTONOMY_UPDATE_PERIOD_MILLIS = 1000;

export class AutonomyRobotControl extends Finalizable {
  private _isAutonomyEnabled$ = new BehaviorSubject(false);
  readonly isAutonomyEnabled$ = this._isAutonomyEnabled$.asObservable();

  constructor(
    private readonly rtcSendDataChannels: RtcSendDataChannels,
    private readonly inControlManager: InControlManager,
  ) {
    super();
    this.initAutonomy();
  }

  private initAutonomy() {
    const enableAutonomy$ = combineLatest([
      this.inControlManager.isInControl$,
      this.isAutonomyEnabled$,
    ]).pipe(
      map(
        ([isInControl, isAutonomyEnabled]) => isInControl && isAutonomyEnabled,
      ),
    );

    const disableAutonomy$ = this.isAutonomyEnabled$.pipe(
      filter((isAutonomyEnabled) => !isAutonomyEnabled),
    );

    disableAutonomy$.pipe(takeUntil(this.finalized$)).subscribe(() => {
      this.stopAutonomy();
    });

    enableAutonomy$
      .pipe(
        takeUntil(this.finalized$),
        switchMap(() =>
          visiblePageTimer(0, ENABLE_AUTONOMY_UPDATE_PERIOD_MILLIS).pipe(
            takeUntil(disableAutonomy$),
            takeUntil(this.finalized$),
          ),
        ),
      )
      .subscribe(() => {
        this.sendAutonomyExtension();
      });
  }

  enableAutonomy(isEnabled: boolean) {
    this._isAutonomyEnabled$.next(isEnabled);
  }

  private stopAutonomy() {
    this.rtcSendDataChannels.sendReliable({
      label: 'autonomyEnabled',
      payload: { autonomyEnabledFor: 0 },
    });
    this.rtcSendDataChannels.sendReliable({
      label: 'path_corridor',
      payload: {
        action: 'stop',
        x: 0,
        y: 0,
        width: 0,
      },
    });
  }

  private sendAutonomyExtension() {
    this.rtcSendDataChannels.sendReliable({
      label: 'autonomyEnabled',
      payload: { autonomyEnabledFor: ENABLE_AUTONOMY_FOR },
    });
  }

  protected async onFinalize(): Promise<void> {
    this.stopAutonomy();
    this._isAutonomyEnabled$.complete();
  }
}
