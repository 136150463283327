<div class="page-inner-container">
  <div class="data-viewer-column">
    <app-collection-grid
      [collection]="collection"
      [selectedItemId]="selectedFrame?.id"
      class="grid-container"
      (itemClick)="selectFrame($event)"
    ></app-collection-grid>
  </div>

  <app-collection-selection
    class="data-viewer-column"
    [collection]="collection"
    [selectedFrame]="selectedFrame"
  ></app-collection-selection>
</div>
