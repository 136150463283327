<div class="settings-header">
  <div class="filter-row">
    <mat-form-field appearance="fill" class="filter-dropdown">
      <mat-label>Filter by operation</mat-label>
      <mat-select
        [(value)]="selectedOperationFilter"
        (selectionChange)="applyOperationFilter()"
      >
        <mat-option>unfiltered</mat-option>
        <mat-option
          *ngFor="let operationId of operationIds"
          [value]="operationId"
        >
          {{ operationId }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="coverage-attach-row">
    <input
      type="file"
      class="coverage-input"
      (change)="displayAttachedCoverage($event)"
      #coverageAttach
      multiple
    />
    <button mat-mini-fab color="primary" (click)="coverageAttach.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
    <div class="coverage-attach-text">
      <span *ngIf="coverageStatus === undefined" class="coverage-attach-hint">
        Show overlay: select debug_coverage_map.png and meta.json
      </span>
      <span *ngIf="coverageStatus !== undefined">
        {{ coverageStatus }}
      </span>
    </div>
  </div>
</div>
<table
  mat-table
  [dataSource]="dataSource"
  [hidden]="dataSource.data.length == 0"
  class="mat-elevation-z8"
  matSort
  matSortActive="robot"
  matSortDirection="asc"
  matSortDisableClear
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="robot">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot</th>
    <td mat-cell *matCellDef="let element">
      <div class="robot-name-and-icon">
        <img
          *ngIf="element.robotPictureUrl"
          class="robot-img"
          src="{{ element.robotPictureUrl }}"
        />
        {{ element.robotName }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
    <td mat-cell *matCellDef="let element">
      {{ element.start | date: 'short' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Distance</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.distanceLeft !== undefined"
        >{{ element.distanceLeft | number: '1.0-0' }} m</span
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="warnings">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Warnings</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.warning !== undefined" class="warning-icon-and-text">
        <mat-icon>warning</mat-icon>{{ element.warning }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mapping Actions</th>
    <td mat-cell *matCellDef="let element">
      <button
        [matTooltip]="element.action.tooltip"
        color="primary"
        mat-mini-fab
        (click)="handleAction(element)"
      >
        <mat-icon>{{ element.action.icon }}</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="localization-actions">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Loc-Data Recording
    </th>
    <td mat-cell *matCellDef="let element">
      <button
        *ngIf="element.localizationAction !== undefined"
        [matTooltip]="element.localizationAction.tooltip"
        color="primary"
        mat-mini-fab
        (click)="handleLocAction(element)"
      >
        <mat-icon>{{ element.localizationAction.icon }}</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="element-row"
    [class.robot-not-ready]="!row.isReady"
    (mouseout)="hideTrajectory()"
    (mouseover)="showTrajectory(row)"
  ></tr>
</table>
