import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Operation } from '../../operations/operation';
import { RobotDto } from '../../core/robots-service/backend/robot.dto';
import { UpdateRobot } from './update-robot';
import { BackendService } from '../../core/backend.service';
import { retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

interface EditRobotDialogData {
  robot: RobotDto;
  operations: Operation[];
}

@Component({
  selector: 'app-edit-robot-dialog',
  templateUrl: './edit-robot-dialog.component.html',
  styleUrls: ['./edit-robot-dialog.component.sass'],
})
export class EditRobotDialogComponent {
  updateRobot: UpdateRobot = {};
  operationIds: string[] = [];

  constructor(
    private backendService: BackendService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: EditRobotDialogData,
  ) {
    this.backendService.get(`/operations/`).subscribe(
      (operations: Operation[]) => {
        this.operationIds = operations.map((operation) => operation.id);
      },
      (error) => {},
    );
  }

  setRobotReadyForOrders(readyForOrders?: boolean) {
    this.backendService
      .post(`/robots/${this.data.robot.id}/set-ready-for-orders`, {
        readyForOrders: readyForOrders ?? false,
      })
      .pipe(retry(5))
      .subscribe(
        () => {},
        () =>
          this.openSnackBarWithConfirmation(
            'Failed to update robot ready for order status!',
          ),
      );
  }

  private openSnackBarWithConfirmation(message: string) {
    this.snackBar.open(
      `Cart ${this.data.robot.serialNumber}\n${message}`,
      'Got it!',
      {
        panelClass: ['snackbar'],
        verticalPosition: 'top',
      },
    );
  }

  setUberVehicleId(vehicleId: string) {
    this.updateRobot.uberVehicleId = vehicleId === '' ? null : vehicleId;
  }
}
