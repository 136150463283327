import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  CreateRobotIssueDialogComponent,
  CreateRobotIssueError,
  RobotIssue,
} from '../../core/robot-issues';
import { BackendService } from '../../core/backend.service';
import { Operation } from '../../operations/operation';
import { Robot } from '../robot.types';
import {
  RobotsReportComponent,
  RobotsReportData,
} from './robots-report-dialog.componen';
import { HttpErrorResponse } from '@angular/common/http';

export interface CreateRobotAwxJobTracker {
  interfaceStrategy: string;
  robotId: string;
  awxJobTemplate?: AwxJobTemplate;
  versionTag?: string;
}

export interface MassActionData {
  selectedRobots: Robot[];
  updateState(): void;
}

export enum AwxJobTemplate {
  COMBINED_UPDATE = 'CombinedUpdate',
  COMBINED_UPDATE_FASTEST_INTERFACE = 'CombinedUpdateFastestInterface',
  UPDATE_JETSON_FASTEST_INTERFACE = 'UpdateJetsonFastestInterface',
  UPDATE_PI_FASTEST_INTERFACE = 'UpdatePiFastestInterface',
  UPDATE_JETSON = 'UpdateJetson',
  UPDATE_PI = 'UpdatePi',
}

export enum InterfaceStrategy {
  PREFER_WIFI = 'PreferWifi',
  PREFER_MODEM1 = 'PreferModem1',
  PREFER_MODEM2 = 'PreferModem2',
  PREFER_MODEM3 = 'PreferModem3',
  FORCE_WIFI = 'ForceWifi',
  FORCE_MODEM1 = 'ForceModem1',
  FORCE_MODEM2 = 'ForceModem2',
  FORCE_MODEM3 = 'ForceModem3',
}

export class AwxUpdateData {
  availableJobTemplates: string[] = Object.values(AwxJobTemplate);
  selectedJobTemplate: AwxJobTemplate | undefined;
  availableInterfaceStrategies: string[] = Object.values(InterfaceStrategy);
  selectedInterfaceStrategy: InterfaceStrategy | undefined;
  versionTag: string | undefined;
}

class OperationIdSelection {
  operationIds: string[] = [];
  selectedOperationId?: string;
}

@Component({
  selector: 'app-mass-action-dialog',
  templateUrl: './mass-action-dialog.component.html',
  styleUrls: ['./mass-action-dialog.component.sass'],
})
export class MassActionDialogComponent {
  awxUpdateData = new AwxUpdateData();
  operationIdSelection = new OperationIdSelection();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInput: MassActionData,
    private backendService: BackendService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.backendService
      .get(`/operations/`)
      .subscribe((operations: Operation[]) => {
        this.operationIdSelection.operationIds = operations.map(
          (operation) => operation.id,
        );
      });
  }

  unlockRobots(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/open-compartments`, {})
        .toPromise();
    });
  }

  lockRobots(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/close-compartments`, {})
        .toPromise();
    });
  }

  readyForOrder(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/set-ready-for-orders`, {
          readyForOrders: true,
        })
        .toPromise();
    });
  }

  notReadyForOrder(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/set-ready-for-orders`, {
          readyForOrders: false,
        })
        .toPromise();
    });
  }

  setOperationId(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .patch(`/robots/${robotId}`, {
          assignedOperationId: this.operationIdSelection.selectedOperationId,
        })
        .toPromise();
    });
  }

  sendAwxLaunchCommand(): void {
    if (!this.launchUpdatePossible()) {
      return;
    }
    const body = {
      createRobotAwxJobTrackers: this.dialogInput.selectedRobots.map(
        (robot) => {
          return <CreateRobotAwxJobTracker>{
            robotId: robot.id,
            versionTag: this.awxUpdateData.versionTag,
            awxJobTemplate: this.awxUpdateData.selectedJobTemplate,
            interfaceStrategy: this.awxUpdateData.selectedInterfaceStrategy,
          };
        },
      ),
    };

    this.backendService.post('/awx/create-tracked-jobs', body).subscribe(() =>
      this.snackBar.open('Awx job tracker created!', 'Got it', {
        duration: 5000,
      }),
    );
  }

  showInterfaceSelection(): boolean {
    return (
      this.awxUpdateData.selectedJobTemplate !== undefined &&
      this.awxUpdateData.selectedJobTemplate !==
        AwxJobTemplate.UPDATE_JETSON_FASTEST_INTERFACE &&
      this.awxUpdateData.selectedJobTemplate !==
        AwxJobTemplate.UPDATE_PI_FASTEST_INTERFACE &&
      this.awxUpdateData.selectedJobTemplate !==
        AwxJobTemplate.COMBINED_UPDATE_FASTEST_INTERFACE
    );
  }

  launchUpdatePossible(): boolean {
    if (
      !this.awxUpdateData.selectedJobTemplate ||
      !this.awxUpdateData.versionTag
    ) {
      return false;
    }
    if (
      this.awxUpdateData.selectedJobTemplate ===
        AwxJobTemplate.UPDATE_JETSON_FASTEST_INTERFACE ||
      this.awxUpdateData.selectedJobTemplate ===
        AwxJobTemplate.UPDATE_PI_FASTEST_INTERFACE ||
      this.awxUpdateData.selectedJobTemplate ===
        AwxJobTemplate.COMBINED_UPDATE_FASTEST_INTERFACE
    ) {
      return true;
    }
    return this.awxUpdateData.selectedInterfaceStrategy !== undefined;
  }

  openRobotsReport() {
    this.dialog.open<RobotsReportComponent, RobotsReportData>(
      RobotsReportComponent,
      {
        data: {
          selectedRobots: this.dialogInput.selectedRobots,
        },
      },
    );
  }
  createClickupTickets() {
    const snackOptions: MatSnackBarConfig = { verticalPosition: 'top' };

    this.dialog
      .open(CreateRobotIssueDialogComponent, {
        hasBackdrop: true,
        data: {
          robotIds: this.dialogInput.selectedRobots.map((robot) => robot.id),
        },
      })
      .afterClosed()
      .subscribe(
        async (
          robotIssues:
            | Promise<RobotIssue[] | CreateRobotIssueError[]>
            | undefined,
        ) => {
          if (!robotIssues) return;

          const loader = this.snackBar.open(
            'Submitting issue...',
            '',
            snackOptions,
          );

          try {
            const issues = await robotIssues;
            this.snackBar.open(
              `${issues.length} Issues have been reported!
              `,
              'Close',
              snackOptions,
            );
          } catch (thrown: HttpErrorResponse | unknown) {
            if (thrown instanceof HttpErrorResponse)
              this.snackBar.open(
                `Failed to report issues: ${
                  (thrown.error as { message: string }).message
                }`,
                'Close',
                snackOptions,
              );
          } finally {
            loader.dismiss();
          }
        },
      );
  }

  private forEachRobot(action: (robotId: string) => Promise<void>) {
    Promise.all(
      this.dialogInput.selectedRobots.map(async (robot) => {
        try {
          await action(robot.id);
        } catch (e) {
          this.snackBar.open(`Failed to run action for ${robot.id}`);
          console.error(e);
        }
      }),
    )
      .then(() => {
        this.dialogInput.updateState();
      })
      .catch((e) => console.warn(e));
  }
}
