import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { RobotDto } from './backend/robot.dto';
import { RouteDto } from './backend/types';
import { Observable, firstValueFrom, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Cache } from '../../../utils/cache.decorator';

@Injectable()
export class RobotsBackendService {
  constructor(private readonly backendService: BackendService) {}

  getRobots(): Promise<RobotDto[]> {
    return firstValueFrom(this.backendService.get<RobotDto[]>('/robots'));
  }

  getRobot(robotId: string): Promise<RobotDto> {
    return firstValueFrom(
      this.backendService.get<RobotDto>(`/robots/${robotId}`),
    );
  }

  @Cache({ ttl: 3 * 1000 })
  getRobotRoute(robotId: string): Promise<RouteDto> {
    return firstValueFrom(
      this.backendService.get<RouteDto>(
        `/robots/${robotId}/route`,
        undefined,
        (error: HttpErrorResponse): Observable<RouteDto> => {
          if (error.status === 404) {
            return of({ geometry: [], distance: 0, duration: 0 });
          }

          return throwError(() => error);
        },
      ),
    );
  }
}
