import { Component } from '@angular/core';
import { ErrorService } from './error.service';

@Component({
  selector: 'app-error-system-overlay',
  templateUrl: './error-system-overlay.component.html',
  styleUrls: ['./error-system-overlay.component.sass'],
})
export class ErrorSystemOverlayComponent {
  constructor(readonly errorService: ErrorService) {}

  trackByIndex(index: number) {
    // it is necessary so, that angular correctly associate DOM objects with relevant elements in an array
    // otherwise, it uses equality and it was causing flaky behavior
    return index;
  }

  dismiss(errorMsg: string) {
    this.errorService.dismissError(errorMsg);
  }
}
