import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { exhaustMap, takeUntil } from 'rxjs/operators';
import { visiblePageTimer } from '../../../utils/page-visibility';
import { BackendService } from '../../../app/core/backend.service';
import { ErrorService } from '../../core/error-system/error.service';
import { RobotPreviewDto } from './robot-preview.dto';

const ROBOTS_PREVIEW_POLLING_INTERVAL_MS = 15 * 1000;

const ROBOTS_PREVIEW_COUNT = 9;
@Component({
  selector: 'waiting-robots',
  templateUrl: './waiting-robots.component.html',
  styleUrls: ['./waiting-robots.component.sass'],
})
export class WaitingRobotsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  robotList$!: Observable<RobotPreviewDto[]>;

  @Output()
  triggerTask = new EventEmitter<string>();

  constructor(
    private readonly backendService: BackendService,
    private sanitizer: DomSanitizer,
    private errorService: ErrorService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

  ngOnInit(): void {
    this.robotList$ = visiblePageTimer(
      0,
      ROBOTS_PREVIEW_POLLING_INTERVAL_MS,
    ).pipe(
      takeUntil(this.destroy$),
      exhaustMap(() =>
        this.backendService
          .get(`/operator-tasks/preview?max-count=${ROBOTS_PREVIEW_COUNT}`)
          .pipe(
            this.errorService.handleStreamErrors(
              'Can not poll robots for preview',
            ),
          ),
      ),
    );
  }

  emitTriggerTask(robotPreview: RobotPreviewDto): void {
    return this.triggerTask.emit(robotPreview.id);
  }

  transform(image: Buffer) {
    return this.sanitizer.bypassSecurityTrustUrl(
      'data:image/jpeg;base64,' + image.toString('base64'),
    );
  }
}
