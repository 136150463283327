<div class="global-overlay-container">
  <div class="error-message-list">
    <div
      *ngFor="
        let errorMsg of errorService.errorMsgs$ | async;
        index as i;
        trackByIndex
      "
      class="error-message-item"
    >
      <div>{{ errorMsg }}</div>
      <button mat-button color="accent" (click)="dismiss(errorMsg)">
        Close
      </button>
    </div>
  </div>
</div>
