import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  RobotState,
  UserClaim,
} from '../../../core/robots-service/backend/robot.dto';
import { lastValueFrom } from 'rxjs';
import { BackendService } from '../../../core/backend.service';

export type ClaimRobotDialogData = {
  robot: RobotState;
};

@Component({
  selector: 'app-claim-robot-dialog',
  templateUrl: './claim-robot-dialog.component.html',
  styleUrls: ['./claim-robot-dialog.component.sass'],
})
export class ClaimRobotDialogComponent {
  userClaim?: UserClaim;
  isClaimed: boolean;
  robotId: string;

  claimedByInput?: string;
  reasonInput?: string;
  forHowLongInput?: number;
  untilInput?: Date;
  errorService: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ClaimRobotDialogData,
    public dialogRef: MatDialogRef<void, void>,
    private readonly backendService: BackendService,
  ) {
    this.userClaim = data.robot.userClaim;
    this.isClaimed =
      !!data.robot.userClaim &&
      !!data.robot.userClaim.claimedBy &&
      (!data.robot.userClaim.claimedUntil ||
        new Date(data.robot.userClaim.claimedUntil) > new Date());
    this.robotId = data.robot.id;
  }

  async unclaimRobot() {
    await this.unclaimRobotImpl();
  }

  async claimRobot() {
    if (!this.claimedByInput) {
      return;
    }
    await this.claimRobotImpl(
      this.claimedByInput,
      this.reasonInput,
      this.untilInput,
    );
  }

  updateClaimedUntilInput() {
    if (!this.forHowLongInput) {
      return;
    }
    this.untilInput = new Date(
      Date.now() + this.forHowLongInput * 60 * 60 * 1000,
    );
  }

  private async claimRobotImpl(
    claimedBy: string,
    reason?: string,
    claimedUntil?: Date,
  ): Promise<void> {
    console.log(claimedBy, reason, claimedUntil);
    await lastValueFrom(
      this.backendService.post(`/robots/${this.robotId}/claim`, {
        reason,
        claimedUntil,
        claimedBy,
      }),
    );
  }

  private async unclaimRobotImpl(): Promise<void> {
    await lastValueFrom(
      this.backendService.post(`/robots/${this.robotId}/unclaim`, {}),
    );
  }
}
