export type LimitOffset = {
  limit: number;
  offset: number;
};

export function pageToLimitOffset(
  page: number = 0,
  pageSize: number = 10,
): LimitOffset {
  return {
    limit: pageSize,
    offset: page * pageSize,
  };
}
