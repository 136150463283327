<div class="section-title">Robots</div>
<mat-chip-set aria-label="Robots">
  <mat-chip-option
    (click)="toggleSelection('online')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'online'"
    disableRipple
  >
    Online - {{ onlineRobots.length }}
  </mat-chip-option>
  <mat-chip-option
    (click)="toggleSelection('offline')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'offline'"
    [class.warn]="offlineRobotInOps"
  >
    Offline - {{ offlineRobots.length }}
  </mat-chip-option>
  <mat-chip-option
    (click)="toggleSelection('readyForOrders')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'readyForOrders'"
  >
    In Ops - {{ readyForOrdersRobots.length }}
  </mat-chip-option>
  <mat-chip-option
    (click)="toggleSelection('blocked')"
    class="remove-chip-selection-shade"
    [selected]="selected === 'blocked'"
  >
    Blocked - {{ blockedRobots.length }}
  </mat-chip-option>
  <mat-chip-option
    (click)="toggleSelection('needOperator')"
    class="remove-chip-selection-shade"
    [class.warn]="needOperatorRobots.length"
    [selected]="selected === 'needOperator'"
  >
    Need Operator - {{ needOperatorRobots.length }}
  </mat-chip-option>
</mat-chip-set>

<table
  mat-table
  [dataSource]="displayedRobots"
  class="mat-elevation-z8"
  *ngIf="displayedRobots.length"
>
  <ng-container matColumnDef="robotView">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot View</th>
    <td mat-cell *matCellDef="let robot">
      <a
        href="/robots/supervise/{{ robot.id }}"
        target="_blank"
        style="position: relative"
      >
        <img
          *ngIf="robot.robotView"
          [src]="transform(robot.robotView)"
          class="robot-view"
        />
        <div class="operator-name-badge">
          {{ robot.operatorDisplayName }}
        </div>
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="batteryPercentage">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Battery</th>
    <td mat-cell *matCellDef="let robot">
      <app-battery-status
        [batteryPercentage]="robot.batteryPercentage"
        [charging]="robot.isCharging"
      ></app-battery-status>
    </td>
  </ng-container>
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot Name</th>
    <td mat-cell *matCellDef="let robot">
      Cart {{ robot.serialNumber }} ({{ robot.shortName }})
    </td>
  </ng-container>
  <ng-container matColumnDef="readyForOrders">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ready</th>
    <td mat-cell *matCellDef="let robot" id="ready-for-orders">
      <mat-slide-toggle
        [ngModel]="robot.readyForOrders"
        (change)="toggleRobotReadyForOrder(robot)"
      ></mat-slide-toggle>
    </td>
  </ng-container>
  <ng-container matColumnDef="assignedOperator">
    <th mat-header-cell *matHeaderCellDef>Assigned Operator</th>
    <td mat-cell *matCellDef="let robot"></td>
  </ng-container>
  <ng-container matColumnDef="awxUpdate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Updating</th>
    <td mat-cell *matCellDef="let robot">
      <mat-icon *ngIf="robot.awxUpdateActive">downloading</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="hasOrder">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
    <td mat-cell *matCellDef="let robot">
      <mat-icon *ngIf="robot.hasOrder">shopping_basket</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="blockedSince">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Since</th>
    <td mat-cell *matCellDef="let robot">
      <div class="blocked-since-cell">
        <mat-icon matTooltip="Blocked by someone or something 😟"
          >pan_tool</mat-icon
        >
        {{ robot.stopState.stoppedSince | prettyTime }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="connectivity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Connectivity</th>
    <td mat-cell *matCellDef="let robot">
      <app-connectivity-status
        *ngIf="robot.modem1Connected !== undefined"
        [modem1Connected]="robot.modem1Connected"
        [modem2Connected]="robot.modem2Connected"
        [modem3Connected]="robot.modem3Connected"
        [wifiConnected]="robot.wifiConnected"
      ></app-connectivity-status>
    </td>
  </ng-container>

  <!--tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr-->
  <tr
    mat-row
    *matRowDef="let robot; columns: columnsToDisplay"
    class="element-row"
  ></tr>
</table>

<!-- tables for robots -->
