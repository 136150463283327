import { VoidAsyncFunction } from '../../../../utils/type-utils';
import {
  BehaviorSubject,
  combineLatest,
  interval,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs';
import { update } from 'ramda';
import { Finalizable } from '../../../../utils/finalizable';

export type RobotActionInfo = {
  actionIdType: string;
  actionDescription: string;
  actionButton?: string;
};

function robotActionInfoEquals(a: RobotActionInfo, b: RobotActionInfo) {
  return a.actionIdType === b.actionIdType;
}

export type RobotActionRequest = RobotActionInfo & {
  onClick?: VoidAsyncFunction;
  onExpire?: VoidAsyncFunction;
  expiresAt?: Date;
};

export type RobotActionRequestNotification = {
  countdown?: number;
} & RobotActionInfo;

export class RobotActionRequestManager extends Finalizable {
  private readonly _notification$ = new BehaviorSubject<RobotActionRequest[]>(
    [],
  );
  readonly notification$ = combineLatest([
    interval(1000),
    this._notification$,
  ]).pipe(
    takeUntil(this.finalized$),
    map(([_, notifications]) => {
      const now = Date.now();
      return notifications.map((notification) => {
        const expiresAtMs = notification.expiresAt?.getTime();
        const countdown = expiresAtMs
          ? Math.max(0, expiresAtMs - now)
          : undefined;

        if (countdown === 0) {
          this.onExpire(notification);
        }
        return {
          actionIdType: notification.actionIdType,
          actionDescription: notification.actionDescription,
          actionButton: notification.actionButton,
          countdown,
        };
      });
    }),
    shareReplay(1),
  );

  notify(newNotification: RobotActionRequest) {
    const notification = this.updateOrAddNotification(newNotification);
    this._notification$.next(notification);
  }

  private updateOrAddNotification(newNotification: RobotActionRequest) {
    const existingNotification = this._notification$.value;
    const index = existingNotification.findIndex((notification) =>
      robotActionInfoEquals(notification, newNotification),
    );

    if (index === -1) {
      return [...existingNotification, newNotification];
    } else {
      return update(index, newNotification, existingNotification);
    }
  }

  async onClick(notification: RobotActionRequestNotification) {
    const action = this.findNotification(notification);
    if (action) {
      await action.onClick?.();
      this.removeNotification(action.actionIdType);
    }
  }

  async onExpire(notification: RobotActionRequestNotification) {
    const action = this.findNotification(notification);
    if (action) {
      await action.onExpire?.();
      this.removeNotification(action.actionIdType);
    }
  }

  private findNotification(actionNotification: RobotActionRequestNotification) {
    return this._notification$.value.find((actionRequest) =>
      robotActionInfoEquals(actionRequest, actionNotification),
    );
  }

  removeNotification(actionIdType: string) {
    const notifications = this._notification$.value.filter(
      (notification) => notification.actionIdType !== actionIdType,
    );
    if (notifications.length !== this._notification$.value.length) {
      this._notification$.next(notifications);
    }
  }

  protected override async onFinalize(): Promise<void> {
    // Clear all notifications
  }
}
