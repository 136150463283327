import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingViewComponent } from './tracking-view/tracking-view.component';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { RefinementDialogComponent } from './refinement-dialog/refinement-dialog.component';
import { SlideToActionComponent } from './tracking-view/slide-to-action/slide-to-action.component';
import { MatStepperModule } from '@angular/material/stepper';
import { OpenCloseAnimationDialogComponent } from './tracking-view/open-close-animation-dialog.component';
import { TackingViewStateService } from './tracking-view/tracking-view-state.service';

@NgModule({
  declarations: [
    TrackingViewComponent,
    RefinementDialogComponent,
    SlideToActionComponent,
    OpenCloseAnimationDialogComponent,
  ],
  providers: [TackingViewStateService],
  imports: [
    CoreModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    MatStepperModule,
  ],
})
export class OrderTrackingModule {}
