import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { Operation } from '../operations/operation';

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  constructor(private backendService: BackendService) {}

  getOperations(): Observable<Operation[]> {
    return this.backendService.get<Operation[]>('/operations');
  }
}
