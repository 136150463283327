import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { AuthService } from '../../core/auth.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
/*import {
  CreateUserDialogComponent,
  CreateUserData,
} from '../create-user-dialog/create-user-dialog.component';*/
import { Role, User } from '../../core/user';
import {
  CreateUserDialogComponent,
  CreateUserData,
} from '../create-user-dialog/create-user-dialog.component';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { UserUpdate } from '../user-update.dto';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

type FilterOptions = {
  NAME?: string;
  ROLES?: string[];
  ACCESS_GROUPS?: string[];
  ROBOTS?: boolean;
};

function toFirstPage(dataSource: MatTableDataSource<User>) {
  dataSource.paginator?.firstPage();
}

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class UsersViewComponent implements AfterViewInit {
  users: User[] = [];
  allRoles: string[] = [];
  allAccessGroups: string[] = [];
  dataSource = new MatTableDataSource<User>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  readonly columnsToDisplay = [
    'picture',
    'displayName',
    'username',
    'roles',
    'accessGroups',
    'edit',
  ];
  expandedElement: User | null = null;

  private createUserDialogRef?: MatDialogRef<CreateUserDialogComponent>;

  constructor(
    private backendService: BackendService,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    // Add '-' to filter for users with no roles
    this.allRoles = ['-', ...Object.values(Role)].sort();

    this.backendService
      .get<string[]>('/operations/access-groups')
      .subscribe((accessGroups: string[]) => {
        // Add '-' to filter for users with no access groups
        this.allAccessGroups = [
          '-',
          ...accessGroups.filter((ag) => !!ag),
        ].sort();
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.createFilterFunction();
    this.dataSource.filter = JSON.stringify({});

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.pullData();
      }
    });
  }

  pullData() {
    this.backendService.get(`/users/`).subscribe((users: User[]) => {
      this.dataSource.data = users;
    });
  }

  pushUserChanges(userId: string, userUpdate: UserUpdate) {
    console.log(userUpdate);
    this.backendService
      .patch(`/users/${userId}`, userUpdate)
      .subscribe(() => this.pullData());
  }

  createUser() {
    this.createUserDialogRef = this.dialog.open(CreateUserDialogComponent, {
      width: '230px',
    });
    this.createUserDialogRef
      .afterClosed()
      .subscribe((userData?: CreateUserData) => {
        if (!userData) {
          return;
        }

        this.backendService
          .post(`/users`, {
            username: userData.username,
            password: userData.password,
            roles: userData.roles,
            displayName: userData.displayName,
            accessGroups: userData.accessGroups,
          })
          .subscribe(() => this.pullData());
        this.createUserDialogRef = undefined;
      });
  }

  editUser(user: User) {
    const editUserDialogRef = this.dialog.open(EditUserDialogComponent, {
      width: 'auto',
      data: { user: user },
      minWidth: '80vh',
      autoFocus: false,
      maxHeight: '90vh',
    });
    editUserDialogRef.afterClosed().subscribe((updateUser?: UserUpdate) => {
      if (!updateUser) {
        this.pullData();
        return;
      }
      this.pushUserChanges(user.id, updateUser);
    });
  }

  applyNameFilter(event: Event) {
    const currentFilters = JSON.parse(this.dataSource.filter);
    currentFilters['NAME'] = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    this.dataSource.filter = JSON.stringify(currentFilters);

    toFirstPage(this.dataSource);
  }

  applyRoleFilter(roles: string[]) {
    const currentFilters = JSON.parse(this.dataSource.filter);
    currentFilters['ROLES'] = roles;
    this.dataSource.filter = JSON.stringify(currentFilters);

    toFirstPage(this.dataSource);
  }

  applyAccessGroupFilter(accessGroups: string[]) {
    const currentFilters = JSON.parse(this.dataSource.filter);
    currentFilters['ACCESS_GROUPS'] = accessGroups;
    this.dataSource.filter = JSON.stringify(currentFilters);

    toFirstPage(this.dataSource);
  }

  applyRobotsFilter(event: MatSlideToggleChange) {
    const currentFilters = JSON.parse(this.dataSource.filter);
    currentFilters['ROBOTS'] = event.checked;
    this.dataSource.filter = JSON.stringify(currentFilters);

    toFirstPage(this.dataSource);
  }

  createFilterFunction(): (user: User, filter: string) => boolean {
    return function (user: User, filter: string): boolean {
      const filters: FilterOptions = JSON.parse(filter);

      let keep = true;

      const nameFilter = filters['NAME'] ?? '';
      keep =
        keep &&
        (user.displayName.toLowerCase().includes(nameFilter) ||
          user.username.toLowerCase().includes(nameFilter));

      const rolesFilter = filters['ROLES'] ?? [];
      const userRoles = !user.roles.length ? ['-'] : user.roles;
      keep =
        keep &&
        (rolesFilter.length == 0 ||
          intersection(rolesFilter, userRoles).length == rolesFilter.length);

      const accessGroupsFilter = filters['ACCESS_GROUPS'] ?? [];
      const userAccessGroups = !user?.accessGroups?.length
        ? ['-']
        : user.accessGroups;
      keep =
        keep &&
        (accessGroupsFilter.length == 0 ||
          intersection(accessGroupsFilter, userAccessGroups).length ==
            accessGroupsFilter.length);

      const robotsFilter = filters['ROBOTS'] ?? true;
      keep =
        keep &&
        (robotsFilter || intersection([Role.ROBOT], user.roles).length == 0);

      return keep;
    };
  }
}

function intersection(a1: unknown[], a2?: unknown[]) {
  return a1.filter((x) => a2?.includes(x));
}
