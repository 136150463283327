import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '../core/backend.service';

@Injectable()
export class EmergencyStopActionService {
  constructor(private backendService: BackendService) {}

  activate(operationId: string): Observable<void> {
    const url = `/robots/activate-emergency-stop`;
    return this.backendService.post<void>(
      `${url}?operation_id=${operationId}`,
      {},
    );
  }

  deactivate(operationId: string): Observable<void> {
    const url = `/robots/deactivate-emergency-stop`;
    return this.backendService.post(`${url}?operation_id=${operationId}`, {});
  }
}
