import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { User } from '../../user';
import { ClientInfo } from '../webrtc/signaling-server-messages';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { RtcStreamManager } from '../webrtc/rtc-stream-manager';
import { completeAll } from '../../../../utils/complete-all';

export class InControlManager extends Finalizable {
  readonly isInControl$: Observable<boolean>;

  private _claimControl = new Subject<boolean>();

  constructor(
    private readonly rtcSendDataChannels: RtcSendDataChannels,
    rtcStreams: RtcStreamManager,
    authService: AuthService,
  ) {
    super();
    this.isInControl$ = merge(
      combineLatest([rtcStreams.controlledBy$, authService.user$]).pipe(
        takeUntil(this.finalized$),
        map(
          ([controlledBy, user]: [ClientInfo | undefined, User | undefined]) =>
            user?.id !== undefined && user?.id === controlledBy?.id,
        ),
      ),
      this._claimControl,
    ).pipe(takeUntil(this.finalized$));
  }

  claimControl(checked: boolean) {
    this._claimControl.next(checked);
    if (checked) {
      return this.rtcSendDataChannels.sendBoth({ claimControl: true });
    } else {
      return this.rtcSendDataChannels.sendBoth({ releaseControl: true });
    }
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._claimControl);
  }
}
