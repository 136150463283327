<h1 mat-dialog-title>Save Changeset</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Title</mat-label>
    <input matInput placeholder="An awesome title" [(ngModel)]="data.title" />
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Description</mat-label>
    <textarea
      matInput
      placeholder="What was changed?"
      [(ngModel)]="data.description"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="data"
    [disabled]="!data.title || !data.description"
  >
    Ok
  </button>
</div>
