<div class="infrastructure-container" *ngIf="status || error">
  <div class="infrastructure-icon-background">
    <mat-icon
      class="infrastructure-icon"
      [ngClass]="{ 'infrastructure-error': error }"
      >elevator</mat-icon
    >
    <div class="status-text">
      {{ status }}
    </div>

    <button
      mat-fab
      color="primary"
      class="infrastructure-abort-button"
      aria-label="Abort infrastructure transaction"
      (click)="abortInfrastructureTransaction.emit()"
      matTooltip="Abort infrastructure transaction"
      matTooltipPosition="before"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
