import {
  MapElementDto,
  SlippyTilesPropertiesDto,
  PolygonGeometryDto,
  ElementType,
} from '@cartken/map-types';

export interface SlippyTilesDto extends MapElementDto {
  properties: SlippyTilesPropertiesDto;
  geometry: PolygonGeometryDto;
}

export function isSlippyTilesDto(
  mapElement: MapElementDto,
): mapElement is SlippyTilesDto {
  return (
    mapElement.elementType === ElementType.SLIPPY_TILES &&
    !!mapElement.properties &&
    'tilesBaseUrl' in mapElement.properties &&
    'name' in mapElement.properties &&
    mapElement.geometry.type === 'Polygon'
  );
}
