<h1 mat-dialog-title>Change Map Version</h1>
<div mat-dialog-content>
  <mat-selection-list
    #changesets
    [multiple]="false"
    [(ngModel)]="selectedVersions"
  >
    <mat-list-option
      *ngFor="let changeset of data.changesets"
      [value]="changeset.committedAsVersion"
      lines="3"
    >
      <span matListItemTitle
        >{{ changeset.committedAsVersion }}: {{ changeset.title }}</span
      >
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="changesets.selectedOptions.selected[0]?.value"
    [disabled]="!changesets.selectedOptions.hasValue()"
  >
    Ok
  </button>
</div>
