<section class="leaflet-map-container">
  <main
    (dblclick)="doubleClick()"
    (mousedown)="panning()"
    (scroll)="panning()"
    class="leaflet-map-canvas"
    #mapCanvas
  ></main>
  <aside *ngIf="shouldShowMapProviderSelection" class="leaflet-map-providers">
    <mat-form-field appearance="fill" class="leaflet-map-select">
      <mat-select
        [value]="currentProvider"
        (selectionChange)="switchProvider($event)"
      >
        <mat-option *ngFor="let provider of mapProviders" [value]="provider">
          {{ provider }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </aside>
</section>
